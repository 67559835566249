import { favDataHelper } from "../../../../../../../utils/utils";
import { toast } from "react-toastify";
import { leftSidebarIdConstant } from "../../../leftsidebar/utils/constant";
import { trashItems } from "new-dw-ui/src/new-ui/components/workspace/utils/service/apiService";

export const getNumOfPeopleFilter = (numOfPeople: any) => {
  if (numOfPeople !== undefined) {
    const numOfModels = Number(numOfPeople);
    if (numOfModels !== 9999) {
      return { noOfModels: { value: numOfModels } };
    } else if (numOfModels === 9999) {
      return { noOfModels: { low_value: 3 } };
    }
  } else {
    return {};
  }
};

export const LspGetThumbSrc = (
  item: any,
  type: any,
  withExtention?: any,
  tempId?: any
) => {
  let src = "";
  if (tempId) {
    src = `${process.env.REACT_APP_TEMPLATE_THUMB_SRC}${tempId}${
      withExtention ? ".webp" : ""
    }`;
  } else if (type === "templates") {
    src = `${process.env.REACT_APP_TEMPLATE_THUMB_SRC}${item?.templateId}${
      withExtention ? ".webp" : ""
    }`;
  } else if (item?._source) {
    if (type === "videos") {
      src = item._source.thumb;
    } else {
      src = `${process.env.REACT_APP_WBM_ASSET_THUMB_SRC}${
        item._source.productId
          ? item._source.productId
          : item._source.wbmId
          ? item._source.wbmId
          : item._id
      }${withExtention ? ".webp" : ""}`;
    }
  } else if (type === "images") {
    src = `${process.env.REACT_APP_WBM_ASSET_THUMB_SRC}${
      item._source.productId
        ? item._source.productId
        : item._source.wbmId
        ? item._source.wbmId
        : item._id
    }.webp`;
  }
  return src;
};

export const getInitLspImgApiPayload = (
  searchText?: any,
  filter?: any,
  transparent?: any
) => {
  return {
    page: 0,
    resultsPerPage: 45,
    ...(searchText ? { query: searchText } : {}),
    ...(filter ? { filter: filter } : {}),
    ...(transparent ? { transparent: transparent } : {}),
  };
};

export const getInitialLspImgApiPayload = (
  searchText?: any,
  filter?: any,
  transparent?: any,
  numOfModels?: any
) => {
  let textFilters: any = {};
  if (filter && searchText) {
    if (filter === "wbmId") {
      textFilters.wbmIdList = searchText;
    } else if (filter === "shootid") {
      textFilters.shootIdList = searchText;
    } else if (filter === "originalPath") {
      textFilters.fileNameList = searchText;
    }
  } else if (searchText) {
    textFilters.searchterm = searchText;
  }

  return {
    pagenum: 0,
    querynum_size: 40,
    dwTypeList: "photo",
    ...textFilters,
    ...getNumOfPeopleFilter(numOfModels),
    ...(transparent !== undefined && transparent !== null
      ? { isTransparent: transparent }
      : {}),
  };
};

export const getSimilarAssetsPayload = (wbmId: any) => {
  return {
    pagenum: 0,
    querynum_size: 40,
    wbmId: wbmId,
  };
};

export const getInitLspVidApiPayload = (
  searchText: any,
  filter: any,
  numOfModels: any,
  transparent: any,
  is4k: any
) => {
  return {
    library: "wbmvideo",
    page: 0,
    resultsPerPage: 45,
    ...(searchText ? { query: searchText } : {}),
    ...(filter ? { filter: filter } : {}),
    ...(numOfModels !== undefined ? { noOfModels: numOfModels } : {}),
    ...(transparent !== undefined && transparent !== null
      ? { transparent: transparent }
      : {}),
    ...(is4k !== undefined ? { is4k: is4k } : {}),
  };
};

export const getInitialLspVidApiPayload = (
  searchText: any,
  filter: any,
  numOfModels: any,
  transparent: any,
  is4k: any,
  aiAssets: any
) => {
  let textFilters: any = {};
  if (filter && searchText) {
    if (filter === "wbmId") {
      textFilters.wbmIdList = searchText;
    } else if (filter === "shootid") {
      textFilters.shootIdList = searchText;
    } else if (filter === "originalPath") {
      textFilters.fileNameList = searchText;
    }
  } else if (searchText) {
    textFilters.searchterm = searchText;
  }

  if (is4k) {
    textFilters.height = 2160;
    textFilters.width = 4096;
  } else if (is4k === false) {
    textFilters.height = 1080;
    textFilters.width = 1920;
  }

  return {
    dwTypeList: "video",
    pagenum: 0,
    querynum_size: 40,
    ...textFilters,
    ...getNumOfPeopleFilter(numOfModels),
    ...(transparent !== null && transparent !== undefined
      ? { isTransparent: transparent }
      : {}),
    ...(aiAssets !== null && aiAssets !== undefined ? { aiAssets: aiAssets } : {}),
  };
};

export const getInitialLspStickersApiPayload = (
  searchText: any,
  filter: any,
  numOfModels: any,
  transparent: any,
  is4k: any
) => {
  let textFilters: any = {};
  if (filter && searchText) {
    if (filter === "wbmId") {
      textFilters.wbmIdList = searchText;
    } else if (filter === "shootid") {
      textFilters.shootIdList = searchText;
    } else if (filter === "originalPath") {
      textFilters.fileNameList = searchText;
    }
  } else if (searchText) {
    textFilters.searchterm = searchText;
  }

  if (is4k) {
    textFilters.height = 2160;
    textFilters.width = 4096;
  }

  return {
    dwTypeList: "animatedSticker",
    pagenum: 0,
    querynum_size: 40,
    ...textFilters,
    ...(numOfModels !== undefined ? { noOfModels: numOfModels } : {}),
    ...(transparent !== undefined && transparent !== null
      ? { isTransparent: transparent }
      : {}),
  };
};

export const getInitLspFavApiPayload = (searchText?: any) => {
  return {
    filter: {
      where: {
        or: [{ favoriteType: "images" }, { favoriteType: "templates" }],
        isTrashed: false,
      },
      offset: 0,
      limit: 30,
      order: "id DESC",
    },
  };
};

export const getInitLspFavAssetApiPayload = (searchText?: any) => {
  return {
    filter: {
      where: {
        or: [{ favoriteType: "images" }],
        isTrashed: false,
      },
      offset: 0,
      limit: 30,
      order: "id DESC",
    },
  };
};

export const getInitialLspFavAssetApiPayload = (searchText?: any) => {
  return {
    favoriteType: "images",
    isTrashed: false,
    limit: 30,
    offset: 0,
    order: "id DESC",
  };
};

export const getInitLspFavGraphicsPayload = (searchText?: any) => {
  return {
    favoriteType: "graphics",
    isTrashed: false,
    limit: 30,
    offset: 0,
    order: "id DESC",
  };
};

export const getInitLspFavVideoApiPayload = (searchText?: any) => {
  return {
    filter: {
      where: {
        or: [{ favoriteType: "videos" }],
        isTrashed: false,
      },
      offset: 0,
      limit: 30,
      order: "id DESC",
    },
  };
};

export const getInitialLspFavVideoApiPayload = (searchText?: any) => {
  return {
    favoriteType: "videos",
    isTrashed: false,
    limit: 30,
    offset: 0,
    order: "id DESC",
  };
};

export const getInitLspFavAnimatedStickersApiPayload = (searchText?: any) => {
  return {
    favoriteType: "animatedStickers",
    isTrashed: false,
    limit: 30,
    offset: 0,
    order: "id DESC",
  };
};

export const getInitLspFavTempApiPayload = (searchText?: any) => {
  return {
    filter: {
      where: {
        or: [{ favoriteType: "templates" }],
        isTrashed: false,
      },
      offset: 0,
      limit: 30,
      order: "id DESC",
    },
  };
};

export const getFinalDataForImageTemplate = (
  data: any,
  setState: any,
  withLastData?: any
) => {
  setState((prevState: any) => {
    if (prevState && data && withLastData) {
      let item = prevState;
      // let _data = data.templates.map((e: any) => {
      //   return {
      //     templateId: e.templateId,
      //     categoryId: e.categoryId,
      //     price: e.price,
      //   };
      // });
      item["templates"] = [...prevState["templates"], ...data];
      // item["hits"] = [...prevState["hits"], ...data["hits"]];
      item["total"] = data.total;
      return item;
    } else {
      // let _data = { templates: data.templates, total: data.total };
      // _data.templates = _data.templates.map((e: any) => {
      //   return {
      //     templateId: e.templateId,
      //     categoryId: e.categoryId,
      //     price: e.price,
      //   };
      // });
      return {
        templates: data,
        total: data.length,
      };
    }
  });
};

export const getFinalDataForImage = (
  data: any,
  setState: any,
  withLastData?: any
) => {
  let newData = data.map((val: any) => {
    // val.hideAsset = true;
    return val;
  });

  setState((prevState: any) => {
    if (prevState && newData && withLastData) {
      let item = prevState;
      // item["hits"]["hits"] = [
      //   ...prevState?.["hits"]["hits"],
      //   ...data?.["hits"]["hits"],
      // ];
      item = [...prevState, ...newData];
      // item["hits"] = [...prevState["hits"], ...data["hits"]];
      return item;
    } else {
      return newData;
    }
  });
};
export const getFinalDataForVideos = (
  data: any,
  setState: any,
  withLastData?: any
) => {
  let newData = data.map((val: any) => {
    // val.hideAsset = true;
    return val;
  });

  setState((prevState: any) => {
    if (prevState && newData && withLastData) {
      let item = prevState;
      // item["hits"]["hits"] = [
      //   ...prevState?.["hits"]["hits"],
      //   ...data?.["hits"]["hits"],
      // ];
      item = [...prevState, ...newData];
      // item["hits"] = [...prevState["hits"], ...data["hits"]];
      return item;
    } else {
      return newData;
    }
  });
};

export const getFinalDataForFav = (
  data: any,
  setState: any,
  withLastData?: any
) => {
  let newData = data.map((val: any) => {
    // val.hideAsset = true;
    return val;
  });

  setState((prevState: any) => {
    if (prevState && newData && withLastData) {
      let item = prevState;
      item = [...prevState, ...data];
      // item["hits"] = [...prevState["hits"], ...data["hits"]];
      return item;
    } else {
      return newData;
    }
  });
};

export const getFinalDataForUpload = (
  data: any,
  setState: any,
  withLastData?: any
) => {
  setState((prevState: any) => {
    if (prevState && data && withLastData) {
      let item = prevState;
      item = [...prevState, ...data];
      // item["hits"] = [...prevState["hits"], ...data["hits"]];
      return item;
    } else {
      return data;
    }
  });
};

export const getFinalDataForUploadFont = (
  data: any,
  setState: any,
  withLastData?: any
) => {
  setState((prevState: any) => {
    if (prevState && data && withLastData) {
      let item = prevState;
      item = [...prevState, ...data];
      // item["hits"] = [...prevState["hits"], ...data["hits"]];
      return item;
    } else {
      return data;
    }
  });
};

export const handleRefreshInfList = (dispatch: any) => {
  dispatch({
    type: "setLspInfinityScrollRefresh",
    lspInfinityScrollRefresh: Date.now(),
  });
};

export const trashAssets = async (
  type: any,
  ids: any,
  isTrashed: any = true,
  dispatch: any = false
) => {
  try {
    let userData: any = localStorage.getItem("user");
    userData = JSON.parse(userData);

    let data: any = {};
    data.type = type;
    data.ids = ids;
    data.isTrashed = isTrashed;
    await trashItems(userData.userId, data);
    if (dispatch) {
      handleRefreshInfList(dispatch);
    }
    toast.success("Assets Trashed Successfully");
  } catch (error) {
    toast.error("Something went wrong");
    return { error: error };
  }
};

export const getInitLspTextPharsesApiPayload = (searchText?: any) => {
  return {
    from: 0,
    size: 20,
    body: searchText
      ? {
          query: {
            bool: {
              must: [
                {
                  query_string: {
                    query: searchText,
                    fields: ["content"],
                  },
                },
              ],
            },
          },
        }
      : {},
  };
};

export const getFinalDataForTextPharases = (
  data: any,
  setState: any,
  withLastData?: any
) => {
  setState((prevState: any) => {
    if (prevState && data && withLastData) {
      let item = prevState;
      item["textBlocks"] = [
        ...prevState?.["textBlocks"],
        ...data?.["textBlocks"],
      ];
      // item["hits"] = [...prevState["hits"], ...data["hits"]];
      return item;
    } else {
      return data;
    }
  });
};
//-----------------------------------------favorite functionality----------------------------------

export const handleAddFavourite = (objfav: any) => {
  objfav.e.stopPropagation();
  let id = "";
  switch (objfav.selected) {
    case leftSidebarIdConstant.template:
      id = objfav.item.templateId;
      break;
    case leftSidebarIdConstant.element:
      id = objfav.item._id;
      break;
    // case "videos":
    //   id = objfav.item._id;
    //   break;
  }
  let alreadyExist = objfav.favouritesData.filter((data: any) => {
    if (id === data._id) {
      return data;
    }
  });

  let favObj = {
    alreadyExist: alreadyExist,
    id: id,
    item: objfav.item,
    addFavourite: objfav.addFavourite,
    selected: objfav.selected,
    templateFavoriteEvent: objfav.templateFavoriteEvent,
    assetFavoriteEvent: objfav.assetFavoriteEvent,
  };
  objfav.addToFavourite(favObj);

  if (!alreadyExist.length) {
    var obj: any = {};
    if (objfav.item._index === "dw-asset" && objfav.item._source.productId) {
      obj = { assetId: parseInt(objfav.item._source.productId) };
      // assetFavoriteEvent(objfav.;
    } else if (objfav.item._index === "wbm-photo" && objfav.item._id) {
      obj = { assetId: parseInt(objfav.item._id) };
      // assetFavoriteEvent(objfav.;
    }

    objfav.assetFavoriteEvent(obj);
    toast.dismiss();
    // toast.success(`${objfav.selected} added to your favorites`);
  }
  //  else {
  //   toast.error(`This ${objfav.selected} is already in your favorites list.`);
  // }
};

export const fetchFavData = (obj: any) => {
  let favObj = {
    favData: obj.favData?.data,
    users_favorites: obj.favData?.data,
    favouritesData: obj.favouritesData,
    setFavouritesData: obj.setFavouritesData,
  };
  favDataHelper(favObj);
};

export const getInitialLspGraphicApiPayload = (
  searchText?: any,
  filter?: any,
  transparent?: any,
  numOfModels?: any
) => {
  let textFilters: any = {};
  if (filter && searchText) {
    if (filter === "wbmId") {
      textFilters.wbmIdList = searchText;
    } else if (filter === "shootid") {
      textFilters.shootIdList = searchText;
    } else if (filter === "originalPath") {
      textFilters.fileNameList = searchText;
    }
  } else if (searchText) {
    textFilters.searchterm = searchText;
  }

  return {
    pagenum: 0,
    querynum_size: 40,
    dwTypeList: "graphic",
    ...textFilters,
    ...getNumOfPeopleFilter(numOfModels),
    ...(transparent !== undefined && transparent !== null
      ? { isTransparent: transparent }
      : {}),
  };
};

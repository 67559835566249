import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { GetTutorialsFaq } from "../../../../lib/contexts/Queries";
import { from, useQuery } from "@apollo/client";
import { motion } from "framer-motion";
import {
  TutorialVideoUI,
  TutorialVideoList,
  NewFeature,
  VideoBox,
  Polygon,
  PolyGon,
  SVG,
  Overlay,
  PlayBtn,
  AccourdianTextWrap,
  HeadingScroll,
  AssetsListController,
  CloseAccordionArrow,
  OpenAccordionArrow,
  useStyles,
} from "./accourdianStyle";
import { getVideoId } from "./utils/AccordionStyled";
import { getUniqueId } from "../../../../constants";

export default function ControlledAccordions(props: any) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const GetTutorials = useQuery(GetTutorialsFaq, {
    variables: {
      filter: "{}",
      token: `${localStorage.getItem("token")}`,
    },
  });
  if (GetTutorials.loading) return <>Loading...</>;
  if (GetTutorials.error) return <>Error...</>;

  return (
    <div className={classes.root}>
      <HeadingScroll>Frequently Asked Questions</HeadingScroll>
      <motion.div
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        // whileTap={{ scale: 0.8 }}
        // exit={{ y: -100 }}
        transition={{ duration: 0.1 }}
      >
        {GetTutorials.data.GET_tutorialsFAQs.map(
          (faqData: any, index: number) => {
            const videoProvider = getVideoId(faqData.videoUrl).provider;
            const videoId = getVideoId(faqData.videoUrl).id;
            const vidUrl =
              videoProvider === "youtube"
                ? `https://www.youtube.com/embed/${videoId}`
                : `https://player.vimeo.com/video/${videoId}`;
            let srcStr: any;
            if (props.imgData.length > 2) {
              srcStr = props.imgData.filter((item: any) => {
                if (item.id === videoId) {
                  return item.src;
                }
              });
            }
            if (faqData.isFAQ) {
              return (
                <Accordion
                  className={classes.accourdianBox}
                  expanded={expanded === `panel${index + 1}`}
                  onChange={handleChange(`panel${index + 1}`)}
                >
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    style={{ padding: "0px" }}
                  >
                    {expanded !== `panel${index + 1}` ? (
                      <CloseAccordionArrow className="caret"></CloseAccordionArrow>
                    ) : (
                      <OpenAccordionArrow className="caret"></OpenAccordionArrow>
                    )}

                    <Typography
                      className={classes.heading}
                      style={{
                        color: `${
                          expanded === `panel${index + 1}` ? "#f296a3" : "white"
                        }`,
                      }}
                    >
                      {faqData.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accourdianMainBox}>
                    <AccourdianTextWrap>
                      <Typography className={classes.accourdianText}>
                        <AssetsListController>
                          {/* <AssetsListResultScroller> */}
                          {faqData.body}
                          {/* </AssetsListResultScroller> */}
                        </AssetsListController>
                      </Typography>
                    </AccourdianTextWrap>
                    <AccourdianTextWrap>
                      <TutorialVideoUI>
                        <TutorialVideoList>
                          <VideoBox>
                            <Overlay>
                              <PlayBtn
                                onClick={() => {
                                  props.vidData({
                                    title: faqData.title,
                                    url: vidUrl,
                                  });
                                }}
                              >
                                <Polygon>
                                  <SVG height="70" width="70">
                                    <PolyGon
                                      points="35,10, 60,57,10,57"
                                      style={{
                                        strokeWidth: "3",
                                        fill: "transparent",
                                      }}
                                    ></PolyGon>
                                    Sorry, your browser does not support inline
                                    SVG.
                                  </SVG>
                                </Polygon>
                              </PlayBtn>
                            </Overlay>
                            <NewFeature
                              src={
                                srcStr ? srcStr[0].src : "/videoPlaceHolder.png"
                              }
                            />
                          </VideoBox>
                        </TutorialVideoList>
                      </TutorialVideoUI>
                    </AccourdianTextWrap>
                  </AccordionDetails>
                </Accordion>
              );
            }
          }
        )}
      </motion.div>
    </div>
  );
}

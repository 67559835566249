import styled from "styled-components";
export const MainDiv1 = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s;
  display: block;
  font-size: 16px;
`;
export const MainDiv2 = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: -webkit-linear-gradient(
    83deg,
    #455b98 0,
    #2d3559 43%,
    #11243b 100%
  );
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
`;
export const MainDiv3 = styled.div`
  color: #e6e6e8;
  width: 680px;
  margin: 32px auto 0;
  text-align: center;
`;
export const LogoWrapper = styled.div`
  height: 85px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #e6e6e8;
  width: 680px;
  margin: 150px auto 0;
  text-align: center;
`;
export const Header1 = styled.span`
  color: #2fcba9;
  margin-bottom: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: center;
  font-family: "Lato", sans-serif !important;
`;
export const Header2 = styled.span`
  color: #2fcba9;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;
export const SemiCircle = styled.div`
  background-color: #2fcba9;
  transform: translate(-50%, -50%);
  width: 30px !important;
  height: 15px !important;
  border-radius: 150px 150px 0 0;
  background-color: #2fcba9;
`;
export const Circle = styled.div`
  background-color: #2fcba9;
  padding: 0;
  margin: 0;
  width: 30px !important;
  height: 30px !important;
  outline: none;
  -webkit-border-radius: 30px;
  display: inline-block;
  position: relative;
  text-align: center;
`;
export const ButtonContainer = styled.div`
  margin-top: 30px;
  width: 150px;
  cursor: pointer;
  display: inline-block;
  margin-left: 15px;
  color: #e6e6e8;
`;
export const Button = styled.button`
  color: #fbfbff !important;
  outline: none !important;
  border: 1px solid #2fc6c0 !important;
  padding: 7px;
  background-color: #2fc6c0;
  background-image: linear-gradient(30deg, #208884, #2ab1ac, #3dcbd1, #67b9db);
  min-height: 50px;
  -webkit-appearance: button;
  min-width: 125px;
  border-radius: 3px;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1 !important;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  &:hover {
    background-color: #1b7470 !important;
    color: #fbfbff !important;
    border-color: #1b7470 !important;
    border: 1px solid #2fc6c0 !important;
  }
`;
export const ButtonSpan = styled.span`
  text-align: center;
  padding-left: 0;
  width: 100%;
  line-height: 30px;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
  z-index: 22;
  color: #fbfbff !important;
  outline: none !important;
  border: 0;
  padding: 7px;
  background-color: #2fc6c0;
  background-image: linear-gradient(30deg, #208884, #2ab1ac, #3dcbd1, #67b9db);
  transition: border 0.3s ease, background-color 0.3s ease;
`;

import styled from "styled-components";

export const StyledHeaderDiv = styled.div`
  display: flex;
  height: 72px;
  background: #222841;
  color: white;
  justify-content: space-between;
  align-items: center;
`;
export const LogoDiv = styled.div`
  margin: 6px 3px 0 7px;
  cursor: pointer;
`;
export const NavDiv = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 12px;
  align-items: center;
`;
// export const Logo = styled.img`
//   width: 60px;
//   height: 60px;
// `;
export const Button = styled.button`
  border: 1px solid #2fc6c0;
  font-size: 16px;
  font-weight: 700;
  font-family: "Lato", sans-serif !important;
  color: #2fc6c0;
  width: 100px;
  height: 30px;
  text-align: center;
  padding: 0;
  border-radius: 3px;
  background: #222841;
  cursor: pointer;
  &:hover {
    background: #2fc6c0;
    color: #222841;
  }
`;

export const TitleBar = styled.div`
  display: flex;
  align-items: center;
  width: calc(225px);
`;

export const TitleBarSpan = styled.div`
  padding: 2px 15px 2px 0;
  display: inline-block;
  width: 100%;
  margin-left: 2%;
  font-size: 0.9em;
`;

export const Title = styled.span`
  color: #2fc6c0;
  font-weight: bold;
  font-size: 14px;
`;

export const TitleInput = styled.input`
  text-overflow: ellipsis;
  max-width: 160px;
  font-family: inherit;
  font-size: 0.9rem;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 160px;
  box-shadow: none;
  outline: none;
  border: none;
  background: transparent;
  color: #fbfbff;
`;
export const Credit = styled.div`
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: white;
  font-size: 12px;
  margin: auto 17px auto 0;
`;
export const CreditSpan = styled.span`
  outline: none;
`;
export const CreditOne = styled.div`
  padding: 3px 10px 2px 8px;
  display: inline-block;
  background-color: #2fc6c0;
  color: #232428;
  border-radius: 10px;
  font-style: normal;
  margin-left: -159px;
`;
export const IconStar = styled.div`
  vertical-align: middle;
  margin-top: -3px;
`;

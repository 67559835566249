import { useQuery } from "@tanstack/react-query";
import {
  getUserFavorites,
  getuserUploads,
  getTutorialsFAQs,
  getTutorialsCategoriesFAQs,
  categorizations,
} from "./workspace";

import { getinAppNotifications } from "./dashoboard";

export const useUserfavorites = (filters: Object = {}) => {
  return useQuery({
    queryKey: ["userFavorites"],
    queryFn: () => getUserFavorites(filters),
  });
};

export const useUserUploads = () => {
  return useQuery({
    queryKey: ["userUploads"],
    queryFn: getuserUploads,
  });
};

export const useTutorialFAQs = (filters: Object = {}) => {
  return useQuery({
    queryKey: ["tutorialFAQ"],
    queryFn: () => getTutorialsFAQs(filters),
  });
};

export const useTutorialsCategoriesFAQs = (filters: Object = {}) => {
  return useQuery({
    queryKey: ["tutorialsCategoriesFAQs"],
    queryFn: () => getTutorialsCategoriesFAQs(filters),
  });
};

export const useInAppNotifications = (filters: Object = {}) => {
  return useQuery({
    queryKey: ["notificationData"],
    queryFn: getinAppNotifications,
  });
};
export const useCategorizations = (filters: Object = {}) => {
  return useQuery({
    queryKey: ["categorizations"],
    queryFn: () => categorizations(filters),
  });
};

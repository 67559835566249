import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  OuterDiv,
  Model,
  ModalContent,
  ConfirmStyled,
  ModalBody,
  ModalBodyOne,
  ContentWrapper,
  Textrow,
  Maintext,
  ContentWrappertwo,
  Buttonswrapper,
  Buttonwrapperone,
  Discard,
  DiscardButton,
  Discardbuttontext,
  DiscardText,
  Buttonwrappertwo,
  Confirm,
  ConfirmButton,
  ConfirmButtonText,
  ConfirmText,
} from "./ChangeUploadPopupStyled";
export const ChangeUploadPopup = (props: any) => {
  const { isRunAdminSaveTemp, user } = useSelector((state: any) => {
    return state;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (isRunAdminSaveTemp !== null && isRunAdminSaveTemp === false) {
      props.handleClose();
    }
  }, [isRunAdminSaveTemp]);

  return (
    <OuterDiv
      initial={{ opacity: 0, y: 300, scale: 1.3, x: 10 }}
      animate={{ opacity: 1, y: 0, scale: 1, x: 0 }}
      transition={{ transition: "linear" }}
    >
      <Model>
        <ModalContent>
          <ConfirmStyled>
            <ModalBody>
              <ModalBodyOne onClick={props.handleClose}>
                <span style={{ color: "#cbcbd0" }}>x</span>
              </ModalBodyOne>
              <ContentWrapper>
                <Textrow>
                  <Maintext>
                    <span style={{ fontSize: "1.9em" }}>
                      Do you wish to save your Design?
                    </span>
                    <br />
                    <br />
                    <span style={{ fontSize: "1.3em" }}>
                      Your saved designs will be stored in <em>My Designs</em>
                    </span>
                  </Maintext>
                </Textrow>
              </ContentWrapper>

              <ContentWrappertwo>
                <Buttonswrapper>
                  <Buttonwrapperone>
                    <Discard onClick={() => props.confirmation("discard")}>
                      <DiscardButton>
                        <Discardbuttontext style={{ paddingRight: "10px" }}>
                          <DiscardText>Discard</DiscardText>
                        </Discardbuttontext>
                      </DiscardButton>
                    </Discard>
                  </Buttonwrapperone>
                  <Confirm onClick={() => props.confirmation("save")}>
                    <ConfirmButton>
                      <ConfirmButtonText>
                        <ConfirmText>Save</ConfirmText>
                      </ConfirmButtonText>
                    </ConfirmButton>
                  </Confirm>

                  <Buttonwrappertwo></Buttonwrappertwo>
                </Buttonswrapper>
                {user === "wbm" && (
                  <Confirm onClick={() => props.confirmation("saveTemp")}>
                    <ConfirmButton>
                      <ConfirmButtonText
                        style={{ width: "100%", marginTop: "10px" }}
                      >
                        <ConfirmText>Save As Template</ConfirmText>
                      </ConfirmButtonText>
                    </ConfirmButton>
                  </Confirm>
                )}
              </ContentWrappertwo>
            </ModalBody>
          </ConfirmStyled>
        </ModalContent>
      </Model>
    </OuterDiv>
  );
};

import React from "react";
import { Preview } from "./NotificationPopupStyled";

const NotificationPreview = (props: any) => {
  return (
    <Preview>
      {props.otherData && props.otherData.name === "giftTime" && (
        <video
          width="560px"
          height="300px"
          //  autoPlay="autoPlay"
          loop
          autoPlay
        >
          <source
            src={`https://templates.designwizard.com/339351f0-1fe7-11e9-a3ac-e1091dd0d76b.mp4`}
            type="video/mp4"
          />
        </video>
      )}
    </Preview>
  );
};

export default NotificationPreview;

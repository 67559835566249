import React, { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { Loader } from "pages/private/dashboard/workspace/Loader/loaders";
import { useDispatch } from "react-redux";
import { useUserData } from "utils/useUserData";
export const Template = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useUserData();

  const checkUser = () => {
    if (userData) return true;
    else return false;
  };

  const getCompositionData = async (tempId: any) => {
    await axios
      .get(`${process.env.REACT_APP_API_REDIRECT}/templates/${tempId}`, {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const categoryId = res?.data?.categoryId;
        dispatch({
          type: "setResizeId",
          resizeTitleId: categoryId,
        });
        if (checkUser()) {
          history.push("/dashboard/workspace");
        } else {
          history.push("/");
        }
      })
      .catch(() => {
        if (checkUser()) {
          history.push("/dashboard/workspace");
        } else {
          history.push("/");
        }
        dispatch({
          type: "isCanvasLoad",
          isCanvasLoadData: {
            type: "tempData",
            status: false,
          },
        });
      });
  };
  useEffect(() => {
    var tempId = decodeURIComponent(window.location.href).split("template/")[1];
    sessionStorage.setItem("tempId", JSON.stringify(tempId));
    getCompositionData(tempId);
  }, []);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default Template;

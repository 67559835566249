import axios from "axios";
import { toast } from "react-toastify";
import { debounce } from "lodash";

export const request = (googleToken: any = false) => {
  const token: any = googleToken ? googleToken : localStorage.getItem("token");
  const apiReq = axios.create({
    // Later read this URL from an environment variable
    baseURL: process.env.REACT_APP_API_REDIRECT,
    headers: {
      authorization: token,
    },
  });

  apiReq.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        logoutAndRedirect();
      }
      return Promise.reject(error);
    }
  );
  return apiReq;
};

const logoutAndRedirect = debounce(
  () => {
    toast.error("Session expired, please log in again.");
    setTimeout(() => {
      window.location.href = "/";
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }, 3000);
  },
  10000,
  { leading: true, trailing: false }
);
export default request;

import _ from "lodash";
import ContentComponent from "../TourGuideItem";
import { leftSidebarIdConstant } from "../../../workspace/layout/leftside/leftsidebar/utils/constant";

export const JourneyLocalKeys = {
  userJourney: "userJourney",
  userAllSteps: "userJourneyAllSteps",
};

export const activeCompConst = {
  wsHeader: "first_screen_welcome_workspace",
  wsLeftSideBar: "wsLeftSideBar",
  wsLeftSideTemplate: "wsLeftSideTemplate",
  wsLeftSideMyItem: "wsLeftSideMyItem",
  wsLeftSideElement: "wsLeftSideElement",
  wsLeftSideText: "wsLeftSideText",
  wsLeftSideBrandingKit: "wsLeftSideBrandingKit",
  wsLeftSidePanel: "wsLeftSidePanel",
};

// import { TourAssets } from "new-ui/TourAssets";
export const homeTour = [
  // {
  //   target: ".tour_accountSettingMenu",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       title: "Activate your account",
  //       description: "Active your account to get benefits",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "bottom",
  //   spotlightClicks: true,
  //   hideBackButton: true,
  //   // showProgress: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 1,
  // },
  // {
  //   target: ".tour_activateAccount",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       title: "Activate your account",
  //       description: "Active your account to get benefits",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "bottom",
  //   spotlightClicks: true,
  //   hideBackButton: true,
  //   // showProgress: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 2,
  // },
  // {
  //   target: ".tour_upgrade",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       title: "You can buy the design",
  //       description:
  //         "You can buy some images and video after upgrading account ",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "left",
  //   spotlightClicks: true,
  //   // showProgress: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 3,
  // },
  // {
  //   target: ".tour_purchaseHistory",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       title: "You can see the purchase list",
  //       description: "In this list you can see your purchase list",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "bottom",
  //   spotlightClicks: true,
  //   // showProgress: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 4,
  // },
  // {
  //   target: ".tour_myProfile",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       title: "You can visit you profile",
  //       description:
  //         "Here your all details are available so you can visit so see your profile detail",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "bottom",
  //   spotlightClicks: true,
  //   // showProgress: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 5,
  // },
  {
    target: ".tour_homeMenu",
    content: {
      key: null,
      ref: null,
      props: {
        description:
          "Welcome to your Home Page! From here you can start designing straightaway or navigate to other pages on Design Wizard including your brand kit, recommended for you templates and your projects.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 29,
  },
  {
    target: ".tour_search",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Search your design",
        description:
          "Search our full template library to discover the exact design you need in a variety of canvas sizes.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    // hideBackButton: true,
    placement: "left",
    spotlightClicks: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 6,
  },
  // {
  //   target: ".tourCustomSize",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       title: "We can create the custom size canvas to create your designs.",
  //       description:
  //         "You can create your custom size template and you can customize canvas width and height",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "right",
  //   spotlightClicks: true,
  //   disableScrolling: false,
  //   // showProgress: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 7,
  // },
  // {
  //   target: ".tourGuideVideo",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       title: "You can use video canvas",
  //       description: "From here you can create video",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "right",
  //   spotlightClicks: true,
  //   // showProgress: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 8,
  // },
  // {
  //   target: ".tourGuideTemplate",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       title: "You can use template ",
  //       description: "From here you can create exciting template",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "left",
  //   spotlightClicks: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 9,
  // },
  // {
  //   target: ".tour_homeRecentDesign",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       title: "Your Recent Design",
  //       description: "Here is your created designs are available",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "left",
  //   spotlightClicks: true,
  //   disableScrolling: false,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 10,
  // },

  {
    target: ".tour_designSize",
    content: {
      key: null,
      ref: null,
      props: {
        description:
          "Select your design size from the our categories menu. Choose from video, social media, cards, invitations, marketing materials and so many more. You can also create your own dimensions by clicking on Custom Size.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "top",
    spotlightClicks: true,
    // disableScrolling: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 30,
  },
  {
    target: ".tour_recentDesign",
    content: {
      key: null,
      ref: null,
      props: {
        description:
          "Here you can open up any of our previous designs. Click on the one you want to keep working on and it will instantly open in the workspace where you can continue to design. When you save it again, the updated version will appear here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "top",
    spotlightClicks: true,
    // disableScrolling: false,
    // scrollOffset: 20,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 31,
  },
];

// -------------current my project-------
export const myProjectTourForDesign = [
  {
    target: ".tour_myProjects",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "My Projects",
        description:
          "Welcome to your Projects. Where you can access all of your designs and keep them organised.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "left",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 32,
  },
  {
    target: ".tour_myDesignSearch",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Search your design",
        description:
          "Search all of your designs to instantly find the one you want to keep working on.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "left",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 14,
  },
  {
    target: ".tour_recentDesign",
    content: {
      key: null,
      ref: null,
      props: {
        description:
          "Browse all of your most recent design, click to select and the design will open in the workspace, ready for you to continue customising.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 15,
  },
  // {
  //   target: ".tourCreateNewFolder",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       description: "Click here to create your new folder.",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "bottom",
  //   spotlightClicks: true,
  //   disableScrolling: false,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 16,
  // },
  // {
  //   target: ".tour_newFolder",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       description: "In this new created folder you can add your designs",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "right",
  //   spotlightClicks: true,
  //   disableScrolling: false,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 17,
  // },
  // {
  //   target: ".tour_MyDesign",
  //   content: {
  //     key: null,
  //     ref: null,
  //     props: {
  //       description: "Here is your created designs",
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "right",
  //   spotlightClicks: true,
  //   disableScrolling: false,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 18,
  // },
  {
    target: ".tour_folderSection",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Create Folder",
        description:
          "Create folders for your designs to keep them organised and to hand. Click Create New to open a new folder and rename it to begin. When your folder has been created, you can click any design in your My Designs gallery to move it to your preferred folder.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    // disableScrolling: false,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 33,
  },
];

export const templatesTour = [
  {
    target: ".tour_templateSection",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Template Section",
        description:
          "Welcome to your Templates, Check out our most popular and trending templates here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "left",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 34,
  },
  {
    target: ".tour_templateSearch",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Search your design",
        description:
          "Search for any image and video template from our full template library.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "left",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 19,
  },
  {
    target: ".tour_browseCategory",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Browse Category",
            children:
              "Browse our easy to search template categories to discover collections of professionally created templates.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "top",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 20,
  },
  {
    target: ".tour_bookCover",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Book Covers",
            children:
              "Check out our most popular and trending templates here. Choose the one that suits your needs and simply click to begin customising.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "top",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 21,
  },
];

// -------current branding kit ------
export const brandingKitTour = [
  {
    target: ".tour_brandingKit",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            // title: "Branding Kit",
            children:
              "Welcome to your Branding Kit, an easy-to-access space where you can store all of your important brand assets.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 35,
  },
  {
    target: ".tour_addBranding",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            // title: "Add New Brand",
            children:
              "Click Add New to create one or multiple brand kits so that you can separate and organise your brand assets.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 36,
  },
  {
    target: ".tour_brandingSection",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            // title: "Branding Kit Section",
            children:
              "Store your brand logos, images, font and colour palette all in the in separate folders but all in the one convenient place.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 37,
  },
  // {
  //   target: ".tour_AddLogos",
  //   content: {
  //     type: "div",
  //     key: null,
  //     ref: null,
  //     props: {
  //       children: {
  //         type: "p",
  //         key: null,
  //         ref: null,
  //         props: {
  //           children: "Click here to Add Your Logos and graphics.",
  //         },
  //         _owner: null,
  //         _store: {},
  //       },
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "bottom",
  //   spotlightClicks: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 24,
  // },
  // {
  //   target: ".tour_uplodeFonts",
  //   content: {
  //     type: "div",
  //     key: null,
  //     ref: null,
  //     props: {
  //       children: {
  //         type: "p",
  //         key: null,
  //         ref: null,
  //         props: {
  //           children: "Click here to upload your FontFamily.",
  //         },
  //         _owner: null,
  //         _store: {},
  //       },
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "bottom",
  //   spotlightClicks: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 25,
  // },
  // {
  //   target: ".tour_addColor",
  //   content: {
  //     type: "div",
  //     key: null,
  //     ref: null,
  //     props: {
  //       children: {
  //         type: "p",
  //         key: null,
  //         ref: null,
  //         props: {
  //           children: "Click here to add color in your color palette.",
  //         },
  //         _owner: null,
  //         _store: {},
  //       },
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "bottom",
  //   spotlightClicks: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 26,
  // },
  // {
  //   target: ".tour_brandingColors",
  //   content: {
  //     type: "div",
  //     key: null,
  //     ref: null,
  //     props: {
  //       children: {
  //         type: "p",
  //         key: null,
  //         ref: null,
  //         props: {
  //           children: "Click here to change color of your color palette.",
  //         },
  //         _owner: null,
  //         _store: {},
  //       },
  //     },
  //     _owner: null,
  //     _store: {},
  //   },
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   placement: "bottom",
  //   spotlightClicks: true,
  //   styles: {
  //     options: {
  //       zIndex: 10000,
  //     },
  //   },
  //   apiStepId: 27,
  // },
];

export const trashTour = [
  {
    target: ".tour_trashList",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            children: "Click here to change color of your color palette.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 28,
  },
];

// --------------------workspace-----------------

export const wsTour = [
  // ------------header-------------------
  {
    target: ".tour_designName",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Name Your Design",
        description:
          "Name your design for marketing or personal purposes. Naming your designs will make them easier to find and organise later.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 532,
    activeComp: activeCompConst?.wsHeader,
    tourId: 6,
    // apiStepId: "change ws2",
  },
  {
    target: ".tour_canvasSize",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Canvas Size",
        description:
          "Here is the size of your current design to easily keep track of what you are working.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 533,
    activeComp: activeCompConst?.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_canvasAction",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "",
        description:
          "Magic resize instantly changes the size of your current design to any other dimensions in our library. Save, Download and Share your design here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 534,
    activeComp: activeCompConst?.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsPlayVideo",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Play Button",
            description:
              "You can play or pause the video by clicking on this button. Control your video playback with ease.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    disableScrolling: false,
    placement: "top",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 535,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsUndoRedo",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Undo and Redo Actions",
            description:
              "You can effortlessly revert or reapply changes made to objects on the canvas.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 536,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsZoom",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            // title: "Canvas with Zoom",
            description:
              "With our zoom feature, you have the power to get up close and see the bigger picture. Whether you need to focus on intricate details or take a step back to view the entire canvas, zooming in and out is at your fingertips.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 537,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsSnap",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Snap Your Canvas Objects",
            description:
              "Discover the power of precise alignment with our snapping feature. Easily position and arrange your canvas objects with pixel-perfect precision.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 538,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },

  // -----------------------lspBar------------
  {
    target: ".tour_sidebarMenu",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Sidebar Menu",
        description:
          "Welcome to your design workspace. This is your main menu where you can access all your media libraries and branding kits.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 539,
    activeComp: activeCompConst?.wsLeftSideBar,
    tourId: 7,
  },

  // ------------Template-------------
  {
    target: ".tour_templateMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Template",
        description:
          "This is our templates library where you will find our full collections of image and videos templates.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 540,
    activeComp: activeCompConst?.wsLeftSideTemplate,
    tourId: 8,
  },
  {
    target: ".tour_wsSearchTemplate",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Search Template",
        description:
          "Search any keyword to discover professional templates that can be customised in a couple of clicks.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 541,
    activeComp: activeCompConst?.wsLeftSideTemplate,
    tourId: 8,
  },
  {
    target: ".tour_templateList",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Template Section",
        description: "Explore the list of available templates in this panel.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 542,
    activeComp: activeCompConst?.wsLeftSideTemplate,
    tourId: 8,
  },
  // ------------My Items--------------
  {
    target: ".tour_myItemMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Template",
        description:
          "Here is where you will find all of your own stored media.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 543,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_textSection",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Text Fonts",
        description:
          "This is your uploaded fonts library. Click to select and open on the canvas.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 544,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_uploadSection",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Upload Assets",
        description:
          "Let's begin by uploading your assets. This feature allows you to add images to your canvas effortlessly.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 545,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_favItem",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Favourite Item",
        description:
          "You can favourite any image, video and template by clicking on the little heart icon in the top right hand corner. When you do, they are automatically saved here for instant and easy access.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 546,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    // apiStepId: "change ws9",
    tourId: 9,
  },
  // ------------elements---------------
  {
    target: ".tour_elementMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Element Section",
        description: "Browse our full video and image libraries here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 547,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
  {
    target: ".tour_searchElement",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Text Fonts",
        description:
          "Search keywords to discover premium assets that are exactly what you’re looking for.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 548,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
  {
    target: ".tour_elementData",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Image, Video and Shape Element",
        description:
          "Search videos, images and shapes separately. Click the heart icon on any asset to instantly save it to your My Items folder.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 549,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
  // --------------------------text--------------
  {
    target: ".tour_textMenu",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Text Section",
        description: "Access our full font library here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 550,
    activeComp: activeCompConst?.wsLeftSideText,
    tourId: 11,
  },
  {
    target: ".tour_defaultText",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Fonts Text",
        description:
          "Start by choosing either a heading or normal paragraph text. It will instantly open in the centre of the workspace and the text menu will appear for you to customise your text.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 551,
    activeComp: activeCompConst?.wsLeftSideText,
    tourId: 11,
  },
  // ---------------Branding kit-------
  {
    target: ".tour_brandingMenu",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            description: "Access our full branding kit library here.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 552,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
  {
    target: ".tour_wsBrandingAssets",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Branding Assets",
            description:
              "In this section, you can effortlessly enhance your project's appeal by adding branding assets to the canvas.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 553,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
  {
    target: ".tour_wsBrandingFonts",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Branding Fonts",
            description:
              "In this section, you can effortlessly enhance your project's appeal by adding branding fonts to the canvas.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 554,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
  {
    target: ".tour_wsBrandingColors",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Branding Color",
            description:
              "Explore the power of customization! With this feature, you can easily change the color of objects on the canvas. Tailor your designs to match your brand's color scheme effortlessly",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 555,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
];

export const wsHeaderTour = [
  {
    target: ".tour_designName",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Name Your Design",
        description:
          "Name your design for marketing or personal purposes. Naming your designs will make them easier to find and organise later.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 532,
    activeComp: activeCompConst?.wsHeader,
    tourId: 6,
    // apiStepId: "change ws2",
  },
  {
    target: ".tour_canvasSize",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Canvas Size",
        description:
          "Here is the size of your current design to easily keep track of what you are working.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 533,
    activeComp: activeCompConst?.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_canvasAction",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "",
        description:
          "Magic resize instantly changes the size of your current design to any other dimensions in our library. Save, Download and Share your design here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 534,
    activeComp: activeCompConst?.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsPlayVideo",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Play Button",
            description:
              "You can play or pause the video by clicking on this button. Control your video playback with ease.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    disableScrolling: false,
    placement: "top",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 535,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsUndoRedo",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Undo and Redo Actions",
            description:
              "You can effortlessly revert or reapply changes made to objects on the canvas.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 536,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsZoom",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            // title: "Canvas with Zoom",
            description:
              "With our zoom feature, you have the power to get up close and see the bigger picture. Whether you need to focus on intricate details or take a step back to view the entire canvas, zooming in and out is at your fingertips.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 537,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsSnap",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Snap Your Canvas Objects",
            description:
              "Discover the power of precise alignment with our snapping feature. Easily position and arrange your canvas objects with pixel-perfect precision.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 538,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },
];

//New Workspace Tour start

export const welcomeWorkspaceTour = [
  // ------------header-------------------
  {
    target: ".tour_designName",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Name Your Design",
        description:
          "Name your design for marketing or personal purposes. Naming your designs will make them easier to find and organise later.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 532,
    activeComp: activeCompConst?.wsHeader,
    tourId: 6,
    // apiStepId: "change ws2",
  },
  {
    target: ".tour_canvasSize",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Canvas Size",
        description:
          "Here is the size of your current design to easily keep track of what you are working.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 533,
    activeComp: activeCompConst?.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_canvasAction",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "",
        description:
          "Magic resize instantly changes the size of your current design to any other dimensions in our library. Save, Download and Share your design here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 534,
    activeComp: activeCompConst?.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsPlayVideo",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Play Button",
            description:
              "You can play or pause the video by clicking on this button. Control your video playback with ease.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    disableScrolling: false,
    placement: "top",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 535,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsUndoRedo",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Undo and Redo Actions",
            description:
              "You can effortlessly revert or reapply changes made to objects on the canvas.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 536,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsZoom",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            // title: "Canvas with Zoom",
            description:
              "With our zoom feature, you have the power to get up close and see the bigger picture. Whether you need to focus on intricate details or take a step back to view the entire canvas, zooming in and out is at your fingertips.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 537,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },
  {
    target: ".tour_wsSnap",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Snap Your Canvas Objects",
            description:
              "Discover the power of precise alignment with our snapping feature. Easily position and arrange your canvas objects with pixel-perfect precision.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 538,
    activeComp: activeCompConst.wsHeader,
    tourId: 6,
  },

  // -----------------------lspBar------------
  {
    target: ".tour_sidebarMenu",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Sidebar Menu",
        description:
          "Welcome to your design workspace. This is your main menu where you can access all your media libraries and branding kits.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 539,
    activeComp: activeCompConst.wsHeader,
    tourId: 7,
  },

  // ------------Template-------------
  {
    target: ".tour_templateMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Template",
        description:
          "This is our templates library where you will find our full collections of image and videos templates.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 540,
    activeComp: activeCompConst.wsHeader,
    tourId: 8,
  },
  {
    target: ".tour_wsSearchTemplate",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Search Template",
        description:
          "Search any keyword to discover professional templates that can be customised in a couple of clicks.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 541,
    activeComp: activeCompConst.wsHeader,
    tourId: 8,
  },
  {
    target: ".tour_templateList",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Template Section",
        description: "Explore the list of available templates in this panel.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 542,
    activeComp: activeCompConst.wsHeader,
    tourId: 8,
  },
];

export const myItemsWorkspaceTour = [
  // ------------My Items--------------
  {
    target: ".tour_myItemMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Template",
        description:
          "Here is where you will find all of your own stored media.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 543,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_textSection",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Text Fonts",
        description:
          "This is your uploaded fonts library. Click to select and open on the canvas.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 544,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_uploadSection",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Upload Assets",
        description:
          "Let's begin by uploading your assets. This feature allows you to add images to your canvas effortlessly.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 545,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_favItem",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Favourite Item",
        description:
          "You can favourite any image, video and template by clicking on the little heart icon in the top right hand corner. When you do, they are automatically saved here for instant and easy access.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: false,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 546,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    // apiStepId: "change ws9",
    tourId: 9,
  },
];

export const ElementsWorkspaceTour = [
  // ------------elements---------------
  {
    target: ".tour_elementMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Element Section",
        description: "Browse our full video and image libraries here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 547,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
  {
    target: ".tour_searchElement",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Text Fonts",
        description:
          "Search keywords to discover premium assets that are exactly what you’re looking for.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 548,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
  {
    target: ".tour_elementData",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Image, Video and Shape Element",
        description:
          "Search videos, images and shapes separately. Click the heart icon on any asset to instantly save it to your My Items folder.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 549,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
];

export const TextWorkspaceTour = [
  // --------------------------text--------------
  {
    target: ".tour_textMenu",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Text Section",
        description: "Access our full font library here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 550,
    activeComp: activeCompConst?.wsLeftSideText,
    tourId: 11,
  },
  {
    target: ".tour_defaultText",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Fonts Text",
        description:
          "Start by choosing either a heading or normal paragraph text. It will instantly open in the centre of the workspace and the text menu will appear for you to customise your text.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 551,
    activeComp: activeCompConst?.wsLeftSideText,
    tourId: 11,
  },
];

export const BrandingKitWorkspaceTour = [
  {
    target: ".tour_brandingMenu",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            description: "Access our full branding kit library here.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 552,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
  {
    target: ".tour_wsBrandingAssets",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Branding Assets",
            description:
              "In this section, you can effortlessly enhance your project's appeal by adding branding assets to the canvas.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 553,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
  {
    target: ".tour_wsBrandingFonts",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Branding Fonts",
            description:
              "In this section, you can effortlessly enhance your project's appeal by adding branding fonts to the canvas.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 554,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
  {
    target: ".tour_wsBrandingColors",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Branding Color",
            description:
              "Explore the power of customization! With this feature, you can easily change the color of objects on the canvas. Tailor your designs to match your brand's color scheme effortlessly",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 555,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
];

//New Workspace Tour end

export const wsSidebarMenuTour = [
  {
    target: ".tour_sidebarMenu",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Sidebar Menu",
        description:
          "Welcome to your design workspace. This is your main menu where you can access all your media libraries and branding kits.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 539,
    activeComp: activeCompConst?.wsLeftSideBar,
    tourId: 7,
  },
];

export const wsLeftSidePanelTour = [
  // ------------Template-------------
  {
    target: ".tour_templateMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Template",
        description:
          "This is our templates library where you will find our full collections of image and videos templates.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 540,
    activeComp: activeCompConst?.wsLeftSideTemplate,
    tourId: 8,
  },
  {
    target: ".tour_wsSearchTemplate",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Search Template",
        description:
          "Search any keyword to discover professional templates that can be customised in a couple of clicks.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 541,
    activeComp: activeCompConst?.wsLeftSideTemplate,
    tourId: 8,
  },
  {
    target: ".tour_templateList",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Template Section",
        description: "Explore the list of available templates in this panel.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 542,
    activeComp: activeCompConst?.wsLeftSideTemplate,
    tourId: 8,
  },
  // ------------My Items--------------
  {
    target: ".tour_myItemMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Template",
        description:
          "Here is where you will find all of your own stored media.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 543,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_textSection",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Text Fonts",
        description:
          "This is your uploaded fonts library. Click to select and open on the canvas.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 544,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_uploadSection",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Upload Assets",
        description:
          "Let's begin by uploading your assets. This feature allows you to add images to your canvas effortlessly.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 545,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_favItem",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Favourite Item",
        description:
          "You can favourite any image, video and template by clicking on the little heart icon in the top right hand corner. When you do, they are automatically saved here for instant and easy access.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 546,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    // apiStepId: "change ws9",
    tourId: 9,
  },
  // ------------elements---------------
  {
    target: ".tour_elementMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Element Section",
        description: "Browse our full video and image libraries here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 547,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
  {
    target: ".tour_searchElement",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Text Fonts",
        description:
          "Search keywords to discover premium assets that are exactly what you’re looking for.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 548,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
  {
    target: ".tour_elementData",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Image, Video and Shape Element",
        description:
          "Search videos, images and shapes separately. Click the heart icon on any asset to instantly save it to your My Items folder.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 549,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
  // --------------------------text--------------
  {
    target: ".tour_textMenu",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Text Section",
        description: "Access our full font library here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 550,
    activeComp: activeCompConst?.wsLeftSideText,
    tourId: 11,
  },
  {
    target: ".tour_defaultText",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Fonts Text",
        description:
          "Start by choosing either a heading or normal paragraph text. It will instantly open in the centre of the workspace and the text menu will appear for you to customise your text.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 551,
    activeComp: activeCompConst?.wsLeftSideText,
    tourId: 11,
  },
  // ---------------Branding kit-------
  {
    target: ".tour_brandingMenu",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            description: "Access our full branding kit library here.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 552,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
  {
    target: ".tour_wsBrandingAssets",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Branding Assets",
            description:
              "In this section, you can effortlessly enhance your project's appeal by adding branding assets to the canvas.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 553,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
  {
    target: ".tour_wsBrandingFonts",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Branding Fonts",
            description:
              "In this section, you can effortlessly enhance your project's appeal by adding branding fonts to the canvas.",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 554,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
  {
    target: ".tour_wsBrandingColors",
    content: {
      type: "div",
      key: null,
      ref: null,
      props: {
        children: {
          type: "p",
          key: null,
          ref: null,
          props: {
            title: "Branding Color",
            description:
              "Explore the power of customization! With this feature, you can easily change the color of objects on the canvas. Tailor your designs to match your brand's color scheme effortlessly",
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 555,
    activeComp: activeCompConst.wsLeftSideBrandingKit,
    tourId: 12,
  },
];

export const wsTemplateTour = [
  {
    target: ".tour_templateMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Template",
        description:
          "This is our templates library where you will find our full collections of image and videos templates.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 540,
    activeComp: activeCompConst?.wsLeftSideTemplate,
    tourId: 8,
  },
  {
    target: ".tour_wsSearchTemplate",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Search Template",
        description:
          "Search any keyword to discover professional templates that can be customised in a couple of clicks.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 541,
    activeComp: activeCompConst?.wsLeftSideTemplate,
    tourId: 8,
  },
  {
    target: ".tour_templateList",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Template Section",
        description: "Explore the list of available templates in this panel.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 542,
    activeComp: activeCompConst?.wsLeftSideTemplate,
    tourId: 8,
  },
];

export const wsMyItemTour = [
  {
    target: ".tour_myItemMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Template",
        description:
          "Here is where you will find all of your own stored media.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 543,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_textSection",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Text Fonts",
        description:
          "This is your uploaded fonts library. Click to select and open on the canvas.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 544,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_uploadSection",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Upload Assets",
        description:
          "Let's begin by uploading your assets. This feature allows you to add images to your canvas effortlessly.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 545,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    tourId: 9,
  },
  {
    target: ".tour_favItem",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Favourite Item",
        description:
          "You can favourite any image, video and template by clicking on the little heart icon in the top right hand corner. When you do, they are automatically saved here for instant and easy access.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 546,
    activeComp: activeCompConst?.wsLeftSideMyItem,
    // apiStepId: "change ws9",
    tourId: 9,
  },
];

export const wsElementTour = [
  {
    target: ".tour_elementMenu",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Element Section",
        description: "Browse our full video and image libraries here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 547,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
  {
    target: ".tour_searchElement",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Text Fonts",
        description:
          "Search keywords to discover premium assets that are exactly what you’re looking for.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 548,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
  {
    target: ".tour_elementData",
    content: {
      key: null,
      ref: null,
      props: {
        title: "Image, Video and Shape Element",
        description:
          "Search videos, images and shapes separately. Click the heart icon on any asset to instantly save it to your My Items folder.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "right",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 549,
    activeComp: activeCompConst?.wsLeftSideElement,
    tourId: 10,
  },
];

export const wsTextTour = [
  {
    target: ".tour_textMenu",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Text Section",
        description: "Access our full font library here.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 550,
    activeComp: activeCompConst?.wsLeftSideText,
    tourId: 11,
  },
  {
    target: ".tour_defaultText",
    content: {
      key: null,
      ref: null,
      props: {
        // title: "Fonts Text",
        description:
          "Start by choosing either a heading or normal paragraph text. It will instantly open in the centre of the workspace and the text menu will appear for you to customise your text.",
      },
      _owner: null,
      _store: {},
    },
    disableBeacon: true,
    disableOverlayClose: true,
    placement: "bottom",
    spotlightClicks: true,
    hideBackButton: true,
    // showProgress: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    apiStepId: 551,
    activeComp: activeCompConst?.wsLeftSideText,
    tourId: 11,
  },
];

export const handleFormatJourneyData = (
  userData: any,
  journeyData: any,
  journeyStepData: any,
  prevJourneyData?: any
) => {
  const userType = userData?.realm ? userData?.realm : "public";
  const returnData = journeyData
    .filter((journeyItem: any) => journeyItem.userRoles?.includes(userType))
    .map((journeyItem: any) => {
      const journeyStepsData = journeyItem.stepsIds.map((stepId: any) => {
        const step = journeyStepData.find((s: any) => s.id === stepId);
        return {
          id: step?.id,
          step_title: step?.step_title,
          step_description: step?.step_description,
          imageUrl: step?.imageUrl,
          videoUrl: step?.videoUrl,
        };
      });
      const prevData = prevJourneyData?.filter(
        (prevItem: any) => prevItem?.id === journeyItem?.id
      );
      return {
        id: journeyItem.id,
        lastStep: prevData?.[0]?.lastStep || journeyItem.stepsIds?.[0],
        status: prevData?.[0]?.status || "not_started",
        journeyDetail: journeyItem.type,
        userRoles: journeyItem.userRoles,
        journeyStepsData: journeyStepsData,
        sectionType: journeyItem?.sectionType,
        destinationId:
          journeyItem.stepsIds?.[journeyItem?.stepsIds?.length - 1],
        // stepIndex: 0,
      };
    });

  localStorage.setItem(
    JourneyLocalKeys.userJourney,
    JSON.stringify(returnData)
  );
  localStorage.setItem(
    JourneyLocalKeys.userAllSteps,
    JSON.stringify(journeyStepData)
  );
  return returnData;
};

export const getJourneyStepsBySectionType = (userJourney: any) => {
  const allSteps = {
    home: homeTour,
    "my-project": myProjectTourForDesign,
    "start-templates": templatesTour,
    "branding-kit": brandingKitTour,
    trash: trashTour,
  };
  const { sectionType } = userJourney;
  let returnData: any = [];
  switch (sectionType) {
    case "home":
      returnData = homeTour;
      break;

    default:
      break;
  }
  return returnData;
};

export const handleJourneyTest = () => {
  const local = localStorage?.getItem?.(JourneyLocalKeys.userJourney);
  if (local) var userJourneyData = JSON?.parse?.(local ? local : "");

  const newHomeTour = trashTour.map((item: any, i) => {
    item["apiStepId"] = userJourneyData?.[4]?.journeyStepsData?.[i]?.id;
    return item;
  });
};

export const handleJourneyLocal = (updateJourneyData: any) => {
  const local = localStorage?.getItem?.(JourneyLocalKeys.userJourney);
  if (local) var userJourneyData = JSON.parse?.(local ? local : "[]");
  let newData = userJourneyData;

  const data = newData?.map((item: any) => {
    if (item?.sectionType === updateJourneyData?.sectionType) {
      const lastStep = item?.journeyStepsData[updateJourneyData?.index]?.id;
      item["lastStep"] = lastStep;
      item["status"] =
        lastStep === item?.destinationId
          ? "completed"
          : updateJourneyData?.status;
      item["stepIndex"] = updateJourneyData?.index;
    }
    return item;
  });
  localStorage.setItem(JourneyLocalKeys.userJourney, JSON.stringify(data));
};

export const getUserJourneyLocal = (key: any) => {
  const local = localStorage?.getItem?.(key);
  if (local) var userJourneyData = JSON.parse(local ? local : "[]");
  return userJourneyData;
};

export const getCurrentSectionJourneyLocal = (sectionType: any) => {
  const local = localStorage?.getItem?.(JourneyLocalKeys.userJourney);
  if (local) var userJourneyData = JSON.parse?.(local ? local : "[]");
  let returnData: any = {};

  const data = userJourneyData?.map((item: any) => {
    if (item?.sectionType === sectionType) {
      returnData = item;
    }
  });

  return returnData;
};
export const getStepsIndexByLastStep = (sectionType: any, lastStep?: any) => {
  const local = localStorage?.getItem?.(JourneyLocalKeys.userJourney);
  if (local) var userJourneyData = JSON.parse(local ? local : "[]");
  let returnData: any = {};

  userJourneyData?.map((item: any) => {
    const finalLastStep = lastStep || item?.lastStep;
    if (
      item?.sectionType === sectionType &&
      finalLastStep < item.destinationId
    ) {
      returnData = _.findIndex(
        item?.journeyStepsData,
        (element: any) => element?.id === finalLastStep
      );
    }
  });
  return returnData;
};

export const handleJourneyStatus = (journetItem: any) => {
  if (journetItem?.destinationId < journetItem?.lastStep) {
    return "completed";
  } else if (!journetItem?.lastStep) {
    return "completed";
  } else {
    return journetItem?.status;
  }
};

// For Testing Static Data

export const journeyStepsTesting = [
  {
    id: 1,
    step_title: "This is profile menu.",
    step_description:
      "From profile menu you can visit account detail and purchase history list",
    imageUrl: ["profileMenu.45bb95ea.png"],
    videoUrl: [],
  },
  {
    id: 2,
    step_title: "Activate your account",
    step_description: "Active your account to get benefits",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 3,
    step_title: "You can buy the design",
    step_description:
      "You can buy some images and video after upgrading account ",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 4,
    step_title: "You can see the purchase list",
    step_description: "In this list you can see your purchase list",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 5,
    step_title: "You can visit you profile",
    step_description:
      "Here your all details are available so you can visit so see your profile detail",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 6,
    step_title: "Search your design",
    step_description:
      "Search our full template library to discover the exact design you need in a variety of canvas sizes.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 7,
    step_title: "We can create the custom size canvas to create your designs.",
    step_description:
      "You can create your custom size template and you can customize canvas width and height",
    imageUrl: ["canvasSize.96537d56.png"],
    videoUrl: [],
  },
  {
    id: 8,
    step_title: "You can use video canvas",
    step_description: "From here you can create video",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 9,
    step_title: "You can use template ",
    step_description: "From here you can create exciting template",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 10,
    step_title: "Your Recent Design",
    step_description: "Here is your created designs are available",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 11,
    step_title: "Search your design",
    step_description: "You can search your created design",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 12,
    step_title: "Create new folder",
    step_description: "From here you can create your new folder.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 13,
    step_title: "Manage your folders",
    step_description: "In this new created folder you can add your designs",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 14,
    step_title: "Search your design",
    step_description:
      "Search all of your designs to instantly find the one you want to keep working on.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 15,
    // step_title: "Your Designs",
    step_description:
      "Browse all of your most recent design, click to select and the design will open in the workspace, ready for you to continue customising.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 16,
    step_title: "Organize Your Projects",
    step_description: "Initiate a new folder to manage your design projects.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 17,
    step_title: "Manage Your Folder",
    step_description:
      "Effortlessly manage your design projects by organizing them within dedicated folders for a streamlined experience",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 18,
    step_title: "Explore Your Project Designs",
    step_description: "Navigate and access all your created project designs.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 19,
    step_title: "Search Design Templates",
    step_description:
      "Discover the perfect design templates with our powerful search tool, simplifying your template selection process",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 20,
    step_title: "Explore Template Categories",
    step_description:
      "Effortlessly navigate through design templates by exploring categorized themes and styles.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 21,
    step_title: "Book Cover Templates",
    step_description:
      "Explore a wide range of book cover templates tailored to your creative needs for professional-looking publications.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 22,
    step_title: "Customize Branding Name",
    step_description:
      "Personalize your branding kit by adjusting its name to align perfectly with your brand identity",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 23,
    step_title: "Remove Branding Kit",
    step_description:
      "Delete existing branding kits to maintain brand consistency and declutter your workspace",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 24,
    step_title: "Incorporate Logos and Graphics",
    step_description:
      "Elevate your brand's identity by adding logos and graphics for a polished and professional look.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 25,
    step_title: "Upload Custom Fonts",
    step_description:
      "Upload your preferred fonts to maintain brand integrity and achieve the desired typography in your designs.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 26,
    step_title: "Expand Your Color Palette",
    step_description:
      "Incorporate new colors into your brand's palette for added diversity and vibrancy in your designs.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 27,
    step_title: "Adjust Branding Colors",
    step_description:
      "Make precise color adjustments to ensure your brand's colors stay consistent across all design assets.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 28,
    step_title: "Visit the Trash",
    step_description:
      "Review and manage deleted items from your workspace, allowing for easy restoration or permanent removal.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 29,
    step_description:
      "Welcome to your Home Page! From here you can start designing straightaway or navigate to other pages on Design Wizard including your brand kit, recommended for you templates and your projects.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 30,
    step_description:
      "Select your design size from the our categories menu. Choose from video, social media, cards, invitations, marketing materials and so many more. You can also create your own dimensions by clicking on Custom Size.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 31,
    step_description:
      "Here you can open up any of our previous designs. Click on the one you want to keep working on and it will instantly open in the workspace where you can continue to design. When you save it again, the updated version will appear here.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 32,
    step_description:
      "Welcome to your Projects. Where you can access all of your designs and keep them organised.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 33,
    step_description:
      "Create folders for your designs to keep them organised and to hand. Click Create New to open a new folder and rename it to begin. When your folder has been created, you can click any design in your My Designs gallery to move it to your preferred folder.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 34,
    step_description:
      "Welcome to your Templates, Check out our most popular and trending templates here.",
    imageUrl: [],
    videoUrl: [],
  },
  // {
  //   id: 35,
  //   step_title: "Search your design",
  //   step_description:
  //     "Search for any image and video template from our full template library.",
  //   imageUrl: [],
  //   videoUrl: [],
  // },
  // {
  //   id: 36,
  //   step_description:
  //     "Browse our easy to search template categories to discover collections of professionally created templates.",
  //   imageUrl: [],
  //   videoUrl: [],
  // },
  // {
  //   id: 37,
  //   step_description:
  //     "Browse our easy to search template categories to discover collections of professionally created templates.",
  //   imageUrl: [],
  //   videoUrl: [],
  // },
  {
    id: 35,
    step_description:
      "Welcome to your Branding Kit, an easy-to-access space where you can store all of your important brand assets.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 36,
    step_description:
      "Click Add New to create one or multiple brand kits so that you can separate and organise your brand assets.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 15,
    step_description:
      "Store your brand logos, images, font and colour palette all in the in separate folders but all in the one convenient place.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 542,
    step_description: "Explore the list of available templates in this panel.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 545,
    step_description:
      "Let's begin by uploading your assets. This feature allows you to add images to your canvas effortlessly.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 539,
    // step_title: "Branding Kit Section",
    step_description:
      "Welcome to your design workspace. This is your main menu where you can access all your media libraries and branding kits.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 532,
    // step_title: "Branding Kit Section",
    step_description:
      "Name your design for marketing or personal purposes. Naming your designs will make them easier to find and organise later.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 533,
    // step_title: "Branding Kit Section",
    step_description:
      "Here is the size of your current design to easily keep track of what you are working.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 534,
    // step_title: "Branding Kit Section",
    step_description:
      "Magic resize instantly changes the size of your current design to any other dimensions in our library. Save, Download and Share your design here.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 540,
    // step_title: "Branding Kit Section",
    step_description:
      "This is our templates library where you will find our full collections of image and videos templates.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 541,
    // step_title: "Branding Kit Section",
    step_description:
      "Search any keyword to discover professional templates that can be customised in a couple of clicks.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 543,
    // step_title: "Branding Kit Section",
    step_description:
      "Here is where you will find all of your own stored media.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 544,
    // step_title: "Branding Kit Section",
    step_description:
      "This is your uploaded fonts library. Click to select and open on the canvas.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 546,
    // step_title: "Branding Kit Section",
    step_description:
      "You can favourite any image, video and template by clicking on the little heart icon in the top right hand corner. When you do, they are automatically saved here for instant and easy access.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 547,
    step_title: "Element Section",
    step_description: "Browse our full video and image libraries here.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 548,
    step_title: "Text Fonts",
    step_description:
      "Search keywords to discover premium assets that are exactly what you’re looking for.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 549,
    step_title: "Image, Video and Shape Element",
    step_description:
      "Search videos, images and shapes separately. Click the heart icon on any asset to instantly save it to your My Items folder.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 550,
    step_description: "Access our full font library here.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 551,
    step_description:
      "Start by choosing either a heading or normal paragraph text. It will instantly open in the centre of the workspace and the text menu will appear for you to customise your text.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 535,
    step_title: "Play Button",
    step_description:
      "You can play or pause the video by clicking on this button. Control your video playback with ease.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 536,
    step_title: "Undo and Redo Actions",
    step_description:
      "You can effortlessly revert or reapply changes made to objects on the canvas.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 537,
    step_description:
      "With our zoom feature, you have the power to get up close and see the bigger picture. Whether you need to focus on intricate details or take a step back to view the entire canvas, zooming in and out is at your fingertips.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 538,
    step_title: "Snap Your Canvas Objects",
    step_description:
      "Discover the power of precise alignment with our snapping feature. Easily position and arrange your canvas objects with pixel-perfect precision.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 552,
    step_description: "Access our full branding kit library here.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 553,
    step_title: "Branding Assets",
    step_description:
      "In this section, you can effortlessly enhance your project's appeal by adding branding assets to the canvas.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 554,
    step_title: "Branding Fonts",
    step_description:
      "In this section, you can effortlessly enhance your project's appeal by adding branding fonts to the canvas.",
    imageUrl: [],
    videoUrl: [],
  },
  {
    id: 555,
    step_title: "Branding Color",
    step_description:
      "Explore the power of customization! With this feature, you can easily change the color of objects on the canvas. Tailor your designs to match your brand's color scheme effortlessly.",
    imageUrl: [],
    videoUrl: [],
  },
];

export const journeyDataTesting = [
  {
    id: 1,
    type: "welcome_dashboard",
    userRoles: ["public", "wbm"],
    stepsIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 29, 30, 31],
    totalSteps: 13,
    sectionType: "home",
    activeItem: null,
  },
  {
    id: 2,
    type: "welcome_dashboard",
    userRoles: ["public", "wbm"],
    stepsIds: [14, 15, 16, 17, 18, 32, 33],
    totalSteps: 7,
    sectionType: "my-projects",
    activeItem: null,
  },
  {
    id: 3,
    type: "welcome_dashboard",
    userRoles: ["public", "wbm"],
    stepsIds: [19, 20, 21, 34],
    totalSteps: 7,
    sectionType: "start-templates",
    activeItem: null,
  },
  {
    id: 4,
    type: "welcome_dashboard",
    userRoles: ["public", "wbm"],
    stepsIds: [22, 23, 24, 25, 26, 27, 35, 36, 37],
    totalSteps: 9,
    sectionType: "branding-kit",
    activeItem: null,
  },
  {
    id: 5,
    type: "welcome_workspace",
    userRoles: ["public", "wbm"],
    stepsIds: [532, 533, 534, 535, 536, 537, 538],
    totalSteps: 6,
    sectionType: activeCompConst.wsHeader,
    activeItem: null,
  },
  {
    id: 6,
    type: "welcome_workspace",
    userRoles: ["public", "wbm"],
    stepsIds: [539],
    totalSteps: 6,
    sectionType: activeCompConst.wsLeftSideBar,
    activeItem: null,
  },
  {
    id: 7,
    type: "welcome_workspace",
    userRoles: ["public", "wbm"],
    stepsIds: [540, 541, 542],
    totalSteps: 6,
    sectionType: activeCompConst.wsLeftSideTemplate,
    activeItem: null,
  },
  {
    id: 8,
    type: "welcome_workspace",
    userRoles: ["public", "wbm"],
    stepsIds: [543, 544, 545, 546],
    totalSteps: 6,
    sectionType: activeCompConst.wsLeftSideMyItem,
    activeItem: null,
  },
  {
    id: 9,
    type: "welcome_workspace",
    userRoles: ["public", "wbm"],
    stepsIds: [547, 548, 549],
    totalSteps: 6,
    sectionType: activeCompConst.wsLeftSideElement,
    activeItem: null,
  },
  {
    id: 10,
    type: "welcome_workspace",
    userRoles: ["public", "wbm"],
    stepsIds: [550, 551],
    totalSteps: 6,
    sectionType: activeCompConst.wsLeftSideText,
    activeItem: null,
  },
  {
    id: 11,
    type: "welcome_workspace",
    userRoles: ["public", "wbm"],
    stepsIds: [552, 553, 554, 555],
    totalSteps: 6,
    sectionType: activeCompConst.wsLeftSideBrandingKit,
    activeItem: null,
  },
];

export const tourGuideOnSelectedMenu = (
  dispatch: any,
  setStepIndex: any,
  setRunTour: any,
  selectedMenu: any
) => {
  setRunTour(false);

  switch (selectedMenu) {
    // case leftSidebarIdConstant.template:
    //   setStepIndex(0);
    //   dispatch({
    //     type: "setWsTourMenu",
    //     wsTourMenu: activeCompConst.wsLeftSideTemplate,
    //   });
    //   setRunTour(false);

    //   break;

    case leftSidebarIdConstant.upload:
      // setStepIndex(0);
      dispatch({
        type: "setWsTourMenu",
        wsTourMenu: activeCompConst.wsLeftSideMyItem,
      });
      setRunTour(false);

      break;

    case leftSidebarIdConstant.element:
      // setStepIndex(0);
      dispatch({
        type: "setWsTourMenu",
        wsTourMenu: activeCompConst.wsLeftSideElement,
      });
      setRunTour(false);

      break;

    case leftSidebarIdConstant.text:
      // setStepIndex(0);
      dispatch({
        type: "setWsTourMenu",
        wsTourMenu: activeCompConst.wsLeftSideText,
      });
      setRunTour(false);

      break;

    case leftSidebarIdConstant.branding:
      // setStepIndex(0);
      dispatch({
        type: "setWsTourMenu",
        wsTourMenu: activeCompConst.wsLeftSideBrandingKit,
      });
      setRunTour(false);

      break;

    default:
      break;
  }
};

export const addTourStepSection = (
  data: any,
  dispatch: any,
  setStepIndex: any,
  setRunTour: any,
  canvas: any,
  selectedMenu: any
) => {
  // const { action, index, type, step, status } = data;

  // setStepIndex(0);
  // setRunTour(false);
  // dispatch({
  //   type: "setIsTimelineOpen",
  //   isTimelineOpen: false,
  // });

  switch (step?.activeComp) {
    case activeCompConst.wsHeader:
      if (data?.status === "finished" || data?.status === "skipped") {
        setStepIndex(0);
        dispatch({
          type: "setWsTourMenu",
          wsTourMenu: activeCompConst.wsLeftSideBar,
        });
        setRunTour(false);
      }
      break;

    case activeCompConst.wsLeftSideBar:
      setStepIndex(0);
      if (data?.status === "finished" || data?.status === "skipped") {
        dispatch({
          type: "setWsTourMenu",
          wsTourMenu: activeCompConst.wsLeftSidePanel,
          // wsTourMenu: activeCompConst.wsLeftSideTemplate,
        });
        setRunTour(false);
      }
      break;

    // case activeCompConst.wsLeftSideTemplate:
    //   setStepIndex(0);
    //   if (data?.status === "finished" || data?.status === "skipped") {
    //     dispatch({
    //       type: "setWsTourMenu",
    //       wsTourMenu: activeCompConst.wsLeftSidePanel,
    //     });
    //     setRunTour(false);
    //   }
    //   break;

    case activeCompConst.wsLeftSidePanel:
      // if (data?.status === "finished" || data?.status === "skipped") {
      // dispatch({
      //   type: "setWsTourMenu",
      //   wsTourMenu: activeCompConst.wsLeftSideMyItem,
      // });
      setRunTour(false);
      setStepIndex(0);
      // tourGuideOnSelectedMenu(
      //   dispatch,
      //   setStepIndex,
      //   setRunTour,
      //   selectedMenu
      // );
      // }
      break;

    // case activeCompConst.wsLeftSideMyItem:
    //   setStepIndex(0);
    //   if (data?.status === "finished" || data?.status === "skipped") {
    //     dispatch({
    //       type: "setWsTourMenu",
    //       wsTourMenu: activeCompConst.wsLeftSideElement,
    //     });
    //     setRunTour(false);
    //     tourGuideOnSelectedMenu(
    //       dispatch,
    //       setStepIndex,
    //       setRunTour,
    //       selectedMenu
    //     );
    //   }
    //   break;

    // case activeCompConst.wsLeftSideElement:
    //   setStepIndex(0);
    //   if (data?.status === "finished" || data?.status === "skipped") {
    //     dispatch({
    //       type: "setWsTourMenu",
    //       wsTourMenu: activeCompConst.wsLeftSideText,
    //     });
    //     setRunTour(false);
    //     tourGuideOnSelectedMenu(
    //       dispatch,
    //       setStepIndex,
    //       setRunTour,
    //       selectedMenu
    //     );
    //   }
    //   break;

    default:
      break;
  }
};

export const listForTourGuide = (tour: string, tourGuide: any) => {
  let tourGuideCopy = tourGuide;
  let tourGuideList = _.filter(tourGuideCopy, {
    activeComp: tour,
    // tourId: tour,
  });
  return tourGuideList;

  // let tourGuideCopy = tourGuide;
  // let tourGuideList = _.filter(tourGuideCopy, {
  //   activeTour: tour,
  // });
  // console.log("tourGuideList", tourGuideList);

  // return tourGuideList;
};

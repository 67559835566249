import React, { useEffect } from "react";

import {
  Binding,
  NotificationSecond,
  BindingP,
  Dropdownitem,
  Dropdownitemscope,
  Notificationswrapper,
  Notificationlist,
  Transclude,
  Transcludescope,
  Scope,
  Element,
  Elementthumbnail,
  ElementthumbnailVideo,
  Elementbody,
  Elementbodyone,
  ElementP,
  Notification,
  Psrailx,
  Psraily,
  DropdownDiv,
} from "./UserNotificationStyled";

export const UserNotification = (props: any) => {
  const [notificationColor, setNotificationColor] = React.useState("black");

  return (
    <DropdownDiv>
      <Dropdownitem>
        <Dropdownitemscope>
          <Notificationswrapper>
            <Notificationlist>
              <Transclude>
                <Transcludescope>
                  <Scope>
                    <Element>
                      <Elementthumbnail>
                        <ElementthumbnailVideo></ElementthumbnailVideo>
                      </Elementthumbnail>
                      <Elementbody
                        onClick={() => props.checkIsPopupOpen(true, props)}
                      >
                        <Elementbodyone>
                          <Binding>{props.name}</Binding>
                          <BindingP>
                            {props.body}
                            {/* {props.name} */}
                          </BindingP>
                        </Elementbodyone>
                        <ElementP>
                          <Notification>
                            {props.time}
                            {props.time > 1 ? " days left" : "day left"}
                          </Notification>
                          <NotificationSecond>Learn More</NotificationSecond>
                        </ElementP>
                      </Elementbody>
                    </Element>
                  </Scope>
                </Transcludescope>
              </Transclude>

              <Psrailx>
                <div></div>
              </Psrailx>
              <Psraily>
                <div></div>
              </Psraily>
            </Notificationlist>
          </Notificationswrapper>
        </Dropdownitemscope>
      </Dropdownitem>
    </DropdownDiv>
  );
};

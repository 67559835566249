import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes";
import { PrivateRoute } from "./privateRoute";
import { PublicRoute } from "./publicRoute";
import { GoogleLoginCallback } from "./GoogleLoginCallback";
import { DeleteAccount } from "./DeleteAccountCallback/DeleteAccountCallback";
import { LoginCallBackErr } from "./CallBackError/CallBackError";
import { EmailVerified } from "./EmailVerified/emailVerified";
import { AnimatePresence } from "framer-motion";
import { Transaction } from "./Transaction/transaction";
import { ResetPassword } from "./resetPassword/ResetPasswordRoute";
import PaymentPopup from "../pages/private/dashboard/payService/PaymentPopup";
import { PickUpload } from "./pickWizardUpload/upload";
import { Template } from "./designWizard/template";
import { PwRedirect } from "./PwRedirect/index";
import { PwImageRedirect } from "./PwRedirect/imageredirect";
import { getUniqueId } from "../constants";
export const AppRouting = (props: any) => {
  return (
    <AnimatePresence>
      <Switch>
        {privateRoutes.map((route, index) => {
          return (
            <PrivateRoute
              key={index}
              component={route.component}
              path={route.link}
              // exact
              {...props}
            />
          );
        })}
        {publicRoutes.map((route, index) => {
          return (
            <PublicRoute
              key={index}
              component={route.component}
              path={route.link}
              exact
            />
          );
        })}
        <Route path="/callbackerror" component={LoginCallBackErr} />
        <Route path="/callback" component={GoogleLoginCallback} />
        <Route path="/close-account" component={DeleteAccount} />
        <Route path="/verify" component={EmailVerified} />
        <Route path="/taxamo" component={Transaction} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/payment" component={PaymentPopup} />
        <Route path="/importImage" component={PickUpload} />
        <Route path="/template" component={Template} />
        <Route path="/redirect-to-template" component={PwRedirect} />
        <Route path="/redirect-to-image" component={PwImageRedirect} />
        <Redirect path="*" to="/" />
      </Switch>
    </AnimatePresence>
  );
};

export const getVideoId = function (videoUrl: string) {
  const match = videoUrl.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
  );

  if (match && match[0].indexOf("vimeo") !== -1) {
    return { provider: "vimeo", id: match[6] };
  } else if (match && match[0].indexOf("youtube") !== -1) {
    return { provider: "youtube", id: match[6] };
  }
  return { provider: "noProvider", id: "" };
};

import { getUniqueId } from "../../../../constants";
import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { ReactComponent as BackArrow } from "./../../../../assets/svg/simpleArrowintut.svg";
import {
  GoBackWrapper,
  GoBack,
  RecommededVideosWrapper,
  VideoBox,
  Overlay,
  PlayBtn,
  Polygon,
  PolyGon,
  SVG,
  NewFeature,
} from "./TutorialPopupStyled";

const VideoPlayer = (props: any) => {
  const [url, setUrl] = useState(props.videoData.url);
  const [title, setTitle] = useState(props.videoData.title);
  useEffect(() => {
    setUrl(props.videoData.url);
    setTitle(props.videoData.title);
  }, [props.videoData.url, props.videoData.title]);
  var getVideoId = function (videoUrl: string) {
    const match = videoUrl.match(
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
    );

    if (match && match[0].indexOf("vimeo") !== -1) {
      return { provider: "vimeo", id: match[6] };
    } else if (match && match[0].indexOf("youtube") !== -1) {
      return { provider: "youtube", id: match[6] };
    }
    return { provider: "noProvider", id: "" };
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <GoBackWrapper onClick={() => props.goBack(false)}>
            <BackArrow style={{ height: "25px", width: "25px" }} />
            <GoBack>Go back</GoBack>
          </GoBackWrapper>
          <h2
            style={{
              paddingLeft: "20%",
              fontSize: "32px",
              marginTop: "0",
              width: "100%",
            }}
          >
            {title}
          </h2>
        </div>
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%",
            marginTop: "0",
            marginLeft: "5%",
          }}
        >
          <ReactPlayer
            url={url}
            controls={true}
            width="100%"
            height="100%"
            style={{
              width: "100% !important",
              height: "100% !important",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        </div>
      </div>
      <RecommededVideosWrapper>
        {props.toVid.map((item: any) => {
          const videoProvider = getVideoId(item.videoUrl).provider;
          const videoId = getVideoId(item.videoUrl).id;
          const vidUrl =
            videoProvider === "youtube"
              ? `https://www.youtube.com/embed/${videoId}`
              : `https://player.vimeo.com/video/${videoId}`;
          let srcStr: any;
          if (props.imgData.length > 2) {
            srcStr = props.imgData.filter((item: any) => {
              if (item.id === videoId) {
                return item.src;
              }
            });
          }
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <VideoBox
                style={{ marginLeft: "0px", marginRight: "0px", width: "80%" }}
              >
                <Overlay>
                  <PlayBtn
                    onClick={() => {
                      setUrl(vidUrl);
                      setTitle(item.title);
                    }}
                  >
                    <Polygon>
                      <SVG height="70" width="70">
                        <PolyGon
                          points="35,10, 60,57,10,57"
                          style={{
                            strokeWidth: "3",
                            fill: "transparent",
                          }}
                        ></PolyGon>
                        Sorry, your browser does not support inline SVG.
                      </SVG>
                    </Polygon>
                  </PlayBtn>
                </Overlay>
                <NewFeature
                  src={srcStr ? srcStr[0].src : "/videoPlaceHolder.png"}
                  style={{
                    backgroundImage: "url('/videoPlaceHolder.png')",
                  }}
                />
              </VideoBox>
              <div
                style={{
                  // marginLeft: "21%",
                  marginBottom: "10%",
                  marginTop: "2%",
                  width: "80%",
                }}
              >
                {item.title}
              </div>
            </div>
          );
        })}
      </RecommededVideosWrapper>
    </div>
  );
};

export default VideoPlayer;

import _ from "lodash";

export const DwCanvasObjType = {
  Text: ["textbox", "text"],
  Image: ["image"],
  ShapeAndLine: ["rect", "triangle", "ellipse", "line", "circle", "star"],
  Shape: ["rect", "triangle", "ellipse", "circle", "star"],
  line: ["line"],
  Crop: ["crop"],
  Svg: ["svg"],
  Video: ["video"],
  MultiObj: [
    "textbox",
    "text",
    "rect",
    "triangle",
    "ellipse",
    "line",
    "circle",
    "star",
  ],
  Path: ["path"],
};

export const objectTypeConst = {
  Text: "Text",
  MultiObj: "MultiObj",
  Shape: "Shape",
  Image: "Image",
};

export const isActiveObjTypeMatched = (canvas: any, type: any) => {
  const activeObj = canvas?.getActiveObject();

  if (activeObj && _.includes(type, activeObj?.type)) {
    return true;
  }
  return false;
};

export const handleActiveObjTypeMatched = (canvas: any, type: any) => {
  const activeObj = canvas?.getActiveObject();
  if (activeObj && _.includes(type, activeObj?.type)) {
    return activeObj?.type;
  } else return "";
};

export const WsHandleLsp = (dispatch: any, type: any) => {
  dispatch({
    type: "setToolbarLeftsidePanelRXState",
    toolbarLeftsidePanelRXState: type,
  });
};

export const WsHandleObjectFilter = (dispatch: any, type: any) => {
  dispatch({
    type: "setObjectClrType",
    objectClrType: type,
  });
};

export const WsHandleObjectColor = (dispatch: any, obj: any) => {
  dispatch({
    type: "setObjectClrs",
    objectColor: obj,
  });
};

export const filterTypeToolbarConst = {
  canvas: "canvas",
  shape: "shape",
  text: "text",
  mask: "masking",
  pencil: "pencil",
};

// this function used to set selected left side menu item
export const WsHandleSelectedMenu = (dispatch: any, type: any) => {
  dispatch({
    type: "setSelectedMenu",
    selectedMenu: type,
  });
};

// this function used to set branding fonts
export const WsHandleBrandingFonts = (dispatch: any, list: any) => {
  dispatch({
    type: "setBrandingFonts",
    brandingFontList: list,
  });
};

// this function used to set branding colors
export const WsHandleBrandingColors = (dispatch: any, list: any) => {
  dispatch({
    type: "setBrandColors",
    brandingColorList: list,
  });
};

// this function used to set branding assets
export const WsHandleBrandingAssets = (dispatch: any, list: any) => {
  dispatch({
    type: "setBrandingAssets",
    brandingAssetList: list,
  });
};

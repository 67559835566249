import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { Switch } from "@material-ui/core";
import { ReactComponent as CancelBtn } from "../../../../assets/svg/cross.svg";
import range from "lodash/range";
//import PaymentPopup from "../dashboard/payService/PaymentPopup";
import { SettingsApplications } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUniqueId } from "../../../../new-ui/utils/utils";
import {
  annualPlan,
  proPlan,
  downloadTerms,
} from "../../../../utils/constants";
const zoomIn = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    -ms-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
`;
const bounce1 = keyframes`
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
  40% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  60% {
    -webkit-transform: translateY(-5px) rotate(-45deg);
    transform: translateY(-5px) rotate(-45deg);
  }
`;

const PopupBox = styled.div`
  position: fixed;
  background: rgb(246, 246, 246);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  font-family: Arial !important;
  animation-name: ${zoomIn};
  animation-duration: 0.6s;
  color: #2d3559;
  font-family: Arial !important;
  text-align: center;
  scroll-behavior: smooth;
`;
const Box = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: auto;
  max-height: 100vh;
  margin-top: 0;
  background: rgb(246, 246, 246);
  border-radius: 4px;
  padding: 0;
  overflow: auto;
  z-index: 9999;
`;
const CloseIcon = styled.span`
   {
    //content: "x";
    cursor: pointer;
    position: fixed;
    right: 32px;
    top: 20px;
    background: rgb(246, 246, 246);
    color: #000000;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-out-in;
    font-size: 10px;
    &:hover {
      transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-out-in;
      transform: rotate(90deg);
    }
  }
`;
const PlanBox = styled.div`
   {
    margin: 0 auto;
  }
`;
const SectionOne = styled.section`
  display: flex;
  justify-content: center;
  @media (max-height: 850px), screen and (max-width: 1200px) {
    padding: 25px 0 !important;
    margin-top: -20px;
  }
  @media (max-height: 768px), screen and (max-width: 1366px) {
    padding: 25px 0;
  }
  padding: 75px 0;
`;

const SectionTwo = styled.section`
  display: flex;
  background-color: #2d3559;
  justify-content: center;
  @media (max-height: 850px), screen and (max-width: 1200px) {
    padding: 25px 0 !important;
    margin-top: -20px;
  }
  @media (max-height: 768px), screen and (max-width: 1366px) {
    padding: 25px 0;
  }
  padding: 75px 0;
`;

const SectionOneWrapper = styled.div`
  max-width: 1015px;
  width: 100%;
  @media (max-height: 850px), screen and (max-width: 1200px) {
    max-width: unset !important;
    transform: scale(0.8);
  }
  @media (max-height: 768px), screen and (max-width: 1366px) {
    transform: scale(0.93);
  }
`;

const SectionTwoWrapper = styled.div`
  padding: 70px 0;
  display: flex;
  border-radius: 10px;
  max-width: 1015px;
  width: 85%;
  background-color: #2d3559;
  @media (max-height: 850px), screen and (max-width: 1200px) {
    max-width: unset !important;
    transform: scale(0.8);
  }
  @media (max-height: 768px), screen and (max-width: 1366px) {
    transform: scale(0.93);
  }
`;

const SectionThreeWrapper = styled.div`
  width: 100%;
  margin: auto;
  max-width: 1015px;
  @media (max-height: 850px), screen and (max-width: 1200px) {
    max-width: unset !important;
    transform: scale(0.8);
  }
  @media (max-height: 768px), screen and (max-width: 1366px) {
    transform: scale(0.93);
  }
`;

const MainHeadingDiv = styled.div`
  margin: 0 auto;
`;
const HeadOne = styled.h1`
  font-weight: bold;
  font-size: 40px;
  color: #303858;
  // margin-top: 50px;
  margin-top: 50px;
  margin-bottom: 0px;
`;

const SwitchDiv = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 35px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  position: relative;
  left: -18px;
`;

const MonthlySpan = styled.span`
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
  display: inline-block;
`;

const AnnualSpan = styled.span`
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
  display: inline-block;
`;

const DiscountSpan = styled.span`
  font-size: 0.7em;
  display: inline-block;
  font-weight: bold;
  margin-left: 5px;
`;

const HeadTow = styled.h2`
  margin-top: 25px;
  font-weight: bold;
  font-size: 19.8px;
  color: #303858;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  text-align: center;
`;

const HeadThree = styled.h2`
  margin-top: 15px;
  font-weight: bold;
  font-size: 1.225em;
  color: #303858;
  margin-right: 0;
  margin-bottom: 40px;
  margin-left: 0;
  text-align: center;
`;
const AnnualUL = styled.ul`
  margin: 0;
  padding: 0;
  margin: 0 auto 30px auto;
  text-align: center;
`;
const AnnualList = styled.li`
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  color: #2d3559;
  display: inline-block;
`;
const AnnualListSpan = styled.span`
  font-size: 10px;
`;

const Plan = styled.ul`
  margin: 0;
  // padding-left: 24px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  padding: 0px;
`;

const PlanlList = styled.li`
  // margin: 0 20px;
  color: #2d3559;
  display: inline-block;
  border-radius: 10px;
  border: 1px solid #2d3559;
  // width: 310px;
  color: #fbfbff;
  background-color: #2d3559;
  // margin-bottom: 40px;
  position: relative;
  padding-bottom: 30px;
  // height: 460px;
  flex: 1;
  animation-name: ${zoomIn};
  animation-duration: 1s;
  animation-fill-mode: both;
  margin-right: 40px;
  width: 265px;
  @media (max-width: 1366px) {
    margin-right: 25px !important;
  }
`;

const PlanHeadOne = styled.h1`
   {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0 0 0;
    line-height: 42.24px;
  }
`;

const PlanHeadTwo = styled.h2`
   {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const PlanHeadThree = styled.h3`
   {
    color: #35dad3;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    // margin-top: 18px;
    margin-bottom: 18px;
    @media (min-width: 1500px) {
      font-size: 1.1em;
    }
  }
`;

const PlanMost = styled.p`
   {
    text-align: center;
    color: #fff;
    background-color: #2d3559;
    padding: 4px 35px;
    border-radius: 8px 8px 0 0;
    position: relative;
    left: 0;
    top: -21px;
    font-size: 13px;
    font-weight: bold;
    display: inline-block;
    right: 0;
    position: absolute;
    width: 92px;
    margin: 0 auto;
  }
`;

const PlanHeadTwoSpan = styled.span`
   {
    font-size: 12px;
  }
`;

const ListULList = styled.li`
  width: 75%;
  margin: 0 auto;
  padding-bottom: 15px;
  font-size: 12px;
  list-style: none;
  text-align: left;
  font-weight: 600;
`;

const ListULListSpan = styled.span`
  color: #35dad3;
`;

const PlanBoxTable = styled.div`
   {
    background-color: #2d3559;
    padding: 100px;
    margin-top: 28px;
  }
`;

const PlanTableWrap = styled.div`
   {
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    color: #2d3559;
    padding: 40px;
  }
`;

const PlanTableOne = styled.div`
   {
    flex: 2;
  }
`;

const PlanTableTwo = styled.div`
   {
    flex: 1;
    border-left: 1px solid #e5e6e6;
  }
`;

const PlanTableHead = styled.h3`
   {
    height: 35px;
    font-weight: bold;
    font-size: 28px;
    margin: 0 0 40px 0;
    text-align: center;
  }
`;

const PlanTableUL = styled.ul`
  padding: 0;
  margin: 0;
`;

const PlanTableULList = styled.li`
  width: 75%;
  margin: 0 auto;
  font-size: 14px;
  list-style: none;
  text-align: left;
  font-weight: 600;
  height: 33px;
`;

const PlanTableULTwo = styled.ul`
  padding: 0;
  margin: 0;
`;

const PlanTableULListTwo = styled.li`
  margin: 0 auto;
  font-size: 12px;
  list-style: none;
  text-align: left;
  font-weight: 600;
  height: 33px;
  text-align: center;
  width: 75%;
`;

const PlanTableULListTwoSpan = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

const FaqMain = styled.div`
  padding-bottom: 70px;
`;

const FaqWrap = styled.div`
  display: flex;
  width: 90%;
  color: #2d3559;
  text-align: left;
  margin: auto;
  justify-content: space-between;
`;

const FaqUL = styled.ul<{ padding: String; onSmallPaddingLeft?: String }>`
  // padding: ${(props: any) => (props?.padding ? props?.padding : "0px")};
  ${(props: any) => (props.onSmallPaddingLeft ? " padding-left : 7%;" : "")}
  margin: 0;
  flex: 1;
  list-style: none;
  width: 50%;
`;

const FaqULList = styled.li<{ dynamic1?: Boolean; dynamic2?: Boolean }>`
  margin-bottom: 15px;
  display: ${(props) => (props.dynamic1 ? "none" : "block")};
  @media (max-height: 850px) {
    display: ${(props) => (props.dynamic2 ? "none" : "block")};
  }
`;

const FaqULListHead = styled.h4`
  color: #35dad3;
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 13px 0;
`;
const StyledSpan = styled.span`
  // margin-left: -5px;
  // margin-bottom: -11px;
  margin-bottom: 3px;
  margin-left: 3px;
  // border: solid;
  // border-width: 0 0 3px 3px;
  // height: 1px;
  // display: inline-block;
  padding: 5px;
  // transform-origin: 3px 1px;
  // transform: rotate(360deg);
  // marginRight:10px;
  border-left: 2px solid #2d3559;
  border-bottom: 2px solid #2d3559;

  opacity: 0.5;
`;
const FaqULListText = styled.p`
  font-size: 13px;
  margin: 0;
  color: #2d3559;
  opacity: 0.9;
  margin-bottom: 1rem;
`;
const CartDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto;
  position: relative;
  width: 40px;
  border: 1px solid #909090;
  cursor: pointer;
  height: 40px;
  top: 25px;
  margin-bottom: 5px;
  background-color: transparent;
  animation: ${bounce1} 4s 2s infinite;
  &:hover {
    transition: 0.3s background-color ease-in-out;
  }
  left: -12px;
  margin-bottom: 50px;
`;
const FirstPage = styled.div`
  padding: 0 140px;
  margin: auto;
  text-align: center;
  display: bolck;
`;

const Plans = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const PlanPage = styled.div`
  display: flex;
  justify-content: center;
`;

const EmaiTextWrapper = styled.span`
  color: black;
  text-decoration: underline;
  &:hover {
    color: #35dad3;
  }
`;
const BootstrapButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "8px 21px",
    border: "1px solid",
    lineHeight: 1.5,
    background: "#733cfe",
    borderColor: "#733cfe",
    color: "#fff",
    width: "85%",
    fontFamily: "Arial",
    borderRadius: "2px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#733cfe",
      borderColor: "#733cfe",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#733cfe",
      borderColor: "#733cfe",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);

const UpgradePopup = (props: any) => {
  const updateProfile = useSelector((state: any) => {
    return state.isProfileUpdated;
  });
  const [plan, setPlan] = React.useState<Array<string>>([]);
  const [trialTried, setTrialtried] = useState([""]);

  const checkJSON = (str: any) => {
    try {
      return JSON.parse(str);
    } catch (e: any) {
      return false;
    }
  };

  useEffect(() => {
    const user: any = localStorage.getItem("user");
    const userData: any = JSON.parse(user);
    let prevTrial: any = userData.other?.previousTrialsSubscriptions
      ? checkJSON(userData.other.previousTrialsSubscriptions)
        ? JSON.parse(userData.other.previousTrialsSubscriptions)
        : userData.other.previousTrialsSubscriptions
      : null;

    setTrialtried(prevTrial);
    let arr: string[] = [];
    for (let i of userData.roles) {
      if (i.name.includes("business")) {
        arr.push("business");
      }
      if (i.name.includes("master")) {
        arr.push("pro");
      }
    }
    setPlan(arr);
  }, [updateProfile]);

  const isPopupClosed = useSelector((state: any) => {
    return state.isPopupClosed;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const elm = document.querySelector<HTMLElement>("body")!;

    elm.style.overflow = "hidden";

    return () => {
      elm.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      <PopupBox
      // initial={{ opacity: 0, scale: 0.1 }}
      // animate={{ opacity: 1, scale: 1 }}
      // transition={{ duration: 0.6 }}
      >
        <Box>
          {/* <motion.div animate={{
        rotate: close ? 90 : 0
      }}> */}
          <CloseIcon
            onClick={() => {
              /* dispatch({
                type: "setOpenUpgradePopup",
                openUpgradePopup: false,
              });*/
              dispatch({
                type: "closePopup",
                isPopupClosed: false,
              });
              props.handleClose();
            }}
          >
            <CancelBtn
              style={{ height: "35px", width: "28px", color: "#A0A0A0" }}
            />
          </CloseIcon>
          {props.content}
          {/* </motion.div> */}
          {/* <PlanBox> */}
          <SectionOne>
            <SectionOneWrapper>
              <MainHeadingDiv>
                <HeadOne>Choose a plan that's right for you</HeadOne>
                <HeadTow>
                  Start a free trial or join a plan today. Cancel anytime.
                </HeadTow>

                <SwitchDiv>
                  <MonthlySpan>Monthly</MonthlySpan>
                  <Switch
                    onChange={() => {
                      props.setIsMonthlyPlan(!props.isMonthlyPlan);
                    }}
                    style={{ color: " #35dad3" }}
                  />{" "}
                  <AnnualSpan>
                    Annual
                    <DiscountSpan>(save 25%)</DiscountSpan>
                  </AnnualSpan>
                </SwitchDiv>
              </MainHeadingDiv>

              {/* <AnnualUL>
              <AnnualList>
                Monthly{" "}

              </AnnualList>
              <AnnualList>
                Annual<AnnualListSpan> (save 25%)</AnnualListSpan>
              </AnnualList>
            </AnnualUL> */}
              <Plans>
                {/* <FirstPage> */}
                <PlanPage>
                  <Plan>
                    {/* <motion.div
                    initial={{ opacity: 0, scale: 0.1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.2, duration: 1 }}
                  > */}
                    <PlanlList style={{ animationDelay: "0.6s" }}>
                      <PlanHeadOne>Basic</PlanHeadOne>
                      <PlanHeadTwo>$0</PlanHeadTwo>
                      <PlanHeadThree></PlanHeadThree>
                      <br />
                      <ul
                        style={{
                          padding: 0,
                          margin: "20px auto 40px auto",
                          height: props.isMonthlyPlan ? "14.5rem" : "12rem",
                        }}
                      >
                        <ListULList>
                          Pay As You Go Image & Video Downloads
                        </ListULList>
                        <ListULList>Resize Image Designs</ListULList>
                        <ListULList>
                          Add Text, Images & Shapes to Designs
                        </ListULList>
                        <ListULList>Custom Colour Palette</ListULList>
                        <ListULList>Reseller Rights</ListULList>
                        <ListULList>Lifetime Licence</ListULList>
                        <ListULList>Ad Sponsored</ListULList>
                      </ul>
                    </PlanlList>
                    {/* </motion.div> */}
                    {/* <motion.div
                    initial={{ opacity: 0, scale: 0.1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.4, duration: 1 }}
                  > */}
                    <PlanlList style={{ animationDelay: "0.9s" }}>
                      <PlanHeadOne>Pro</PlanHeadOne>
                      <PlanHeadTwo>
                        {props.isMonthlyPlan ? "$9.99" : "$7.42"}{" "}
                        <PlanHeadTwoSpan>/mo</PlanHeadTwoSpan>{" "}
                      </PlanHeadTwo>
                      <p
                        style={{
                          fontSize: "12px",
                          visibility: props.isMonthlyPlan ? "hidden" : "unset",
                        }}
                      >
                        $89 Billed Annually
                      </p>
                      <PlanHeadThree>All Pro features +</PlanHeadThree>
                      <ul
                        style={{
                          padding: 0,
                          margin: "20px auto 0px auto",
                          // height: props.isMonthlyPlan ? "14.5rem" : "12rem",
                          minHeight: "260px",
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {proPlan.map((plan: any, i: number) => (
                          <ListULList
                            key={i}
                            dangerouslySetInnerHTML={{ __html: plan.title }}
                          ></ListULList>
                        ))}
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "0px auto",
                            fontSize: "11px",
                            width: "75%",
                            color: "#8a8a8a",
                            paddingTop: "20px",
                            fontWeight: "400",
                          }}
                          dangerouslySetInnerHTML={{ __html: downloadTerms }}
                        ></p>
                      </ul>
                      {!plan.includes("pro") && (
                        <>
                          {props.isMonthlyPlan &&
                            !trialTried?.includes("master_monthly_trial") && (
                              <BootstrapButton
                                onClick={() => {
                                  props.setPaymentTitle("Pro Plan");
                                  props.setPaymentAmount("$9.99");
                                  props.setPaymentList(proPlan);
                                  props.setPlanName("Start Pro Trial");
                                  // props.setIsPaymentPopupOpen(true);
                                  dispatch({
                                    type: "closePopup",
                                    isPopupClosed: true,
                                  });
                                  props.onClose();
                                }}
                              >
                                Start Pro Trial
                              </BootstrapButton>
                            )}
                          {(!props.isMonthlyPlan ||
                            trialTried?.includes("master_monthly_trial")) && (
                            <BootstrapButton
                              onClick={() => {
                                props.setPaymentTitle("Pro Plan");
                                props.setPaymentAmount("$9.99");
                                props.setPaymentList(annualPlan);
                                props.setPlanName("Get Pro");
                                // props.setIsPaymentPopupOpen(true);
                                dispatch({
                                  type: "closePopup",
                                  isPopupClosed: true,
                                });
                                props.onClose();
                              }}
                            >
                              Get Pro
                            </BootstrapButton>
                          )}
                        </>
                      )}
                    </PlanlList>
                    {/* </motion.div> */}
                    {/* <motion.div
                    initial={{ opacity: 0, scale: 0.1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.6, duration: 1 }}
                  > */}
                    {/* </motion.div> */}
                  </Plan>
                </PlanPage>
                {/* </FirstPage> */}
              </Plans>
              <a href="#section-two">
                <CartDiv>
                  <a
                    href="#section-two"
                    className="main"
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "40px",
                    }}
                  >
                    <StyledSpan
                    //className="caret"
                    // style={{
                    //   // marginLeft: "-5px",
                    //   // marginBottom: "-11px",
                    //   // border: "solid",
                    //   // borderWidth: "0 0 3px 3px",
                    //   // // height: "1px",
                    //   // display: "inline-block",
                    //   // padding: "5px",
                    //   // transformOrigin: "3px 1px",
                    //   // transform: "rotate(313deg)",
                    //   // // marginRight: "10px",
                    //   // borderLeft: "2px solid #2d3559",
                    //   // borderBottom: "2px solid #2d3559",
                    //   // animation: "bounce1 4s 2s infinite",
                    //   // opacity: "0.5",
                    // }}
                    ></StyledSpan>
                  </a>
                </CartDiv>
              </a>
            </SectionOneWrapper>
          </SectionOne>
          <SectionTwo id="section-two" className="main">
            <SectionTwoWrapper>
              {/* <PlanBoxTable> */}
              <PlanTableWrap style={{ width: "100%" }}>
                <PlanTableOne>
                  <PlanTableHead></PlanTableHead>
                  <PlanTableUL>
                    <PlanTableULList>Pay As You Go Downloads</PlanTableULList>
                    <PlanTableULList>Resize Image Designs</PlanTableULList>
                    <PlanTableULList>Add Text to Image Designs</PlanTableULList>
                    <PlanTableULList>Add Images to Designs</PlanTableULList>
                    <PlanTableULList>
                      Add Shapes to Image Designs
                    </PlanTableULList>
                    <PlanTableULList>
                      Download in JPG, PNG and PDF
                    </PlanTableULList>
                    <PlanTableULList>Reseller Rights</PlanTableULList>
                    <PlanTableULList>Lifetime Licence</PlanTableULList>
                    <PlanTableULList>Custom Colour Palette</PlanTableULList>
                    <PlanTableULList>
                      Share Directly to Social Media
                    </PlanTableULList>
                    <PlanTableULList>Storage Space</PlanTableULList>
                    <PlanTableULList>Image Uploads</PlanTableULList>
                    <PlanTableULList>Font Uploads</PlanTableULList>
                    <PlanTableULList>
                      Unlimited Standard Video Downloads
                    </PlanTableULList>
                    <PlanTableULList>Video Uploads</PlanTableULList>
                    <PlanTableULList>Add Text to Videos</PlanTableULList>
                    <PlanTableULList>Add Shapes to Videoss</PlanTableULList>
                    <PlanTableULList>Add Logos to Videos</PlanTableULList>
                    <PlanTableULList>Animated Text Templates</PlanTableULList>
                    <PlanTableULList>MP4 Video Downloads</PlanTableULList>
                    <PlanTableULList>Priority Customer Support</PlanTableULList>
                  </PlanTableUL>
                </PlanTableOne>
                <PlanTableTwo>
                  <PlanTableHead>Basic</PlanTableHead>
                  <PlanTableULTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <PlanTableULListTwoSpan> 15MB </PlanTableULListTwoSpan>
                    </PlanTableULListTwo>
                  </PlanTableULTwo>
                </PlanTableTwo>
                <PlanTableTwo>
                  <PlanTableHead>Pro</PlanTableHead>
                  <PlanTableULTwo>
                    <PlanTableULListTwo>
                      <PlanTableULListTwoSpan>
                        {" "}
                        60 Image Design Downloads Per Month
                      </PlanTableULListTwoSpan>
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <PlanTableULListTwoSpan> 1GB </PlanTableULListTwoSpan>
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                    <PlanTableULListTwo>
                      <DoneIcon style={{ color: "#35dad3" }} />
                    </PlanTableULListTwo>
                  </PlanTableULTwo>
                </PlanTableTwo>
              </PlanTableWrap>
              {/* </PlanBoxTable> */}
            </SectionTwoWrapper>
          </SectionTwo>
          <SectionOne>
            <SectionThreeWrapper>
              <FaqMain>
                <HeadOne>Frequently Asked Questions:</HeadOne>
                <HeadThree>
                  Ask us anything at
                  <Typography
                    style={{ display: "inline-block", marginLeft: "3px" }}
                  >
                    <Link
                      style={{
                        color: "#303858",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                      href="mailto:info@designwizard.com"
                    >
                      <EmaiTextWrapper>info@designwizard.com</EmaiTextWrapper>
                    </Link>
                  </Typography>
                </HeadThree>
                <FaqWrap>
                  <FaqUL padding="0px 74px 0px 90px">
                    <FaqULList>
                      <FaqULListHead>
                        How long does my subscription last for?
                      </FaqULListHead>
                      <FaqULListText>
                        Monthly: Your subscription will continue on a monthly
                        basis, but you can cancel anytime.
                      </FaqULListText>

                      <FaqULListText>
                        Annual: Your subscription will last for 12 months. At
                        the end of this period, it will automaticlly renew. If
                        you want do not wish to renew it, you can cancel it
                        before the 12 month period has ended.
                      </FaqULListText>
                    </FaqULList>

                    <FaqULList>
                      <FaqULListHead>
                        How long does my free trial last for?
                      </FaqULListHead>
                      <FaqULListText>
                        Your free trial on Pro will last for 7 days, after which
                        time you will become a subscriber. If you do not wish to
                        subscribe, you can cancel before your trial has end.
                      </FaqULListText>
                    </FaqULList>

                    <FaqULList>
                      <FaqULListHead>When will I be debited?</FaqULListHead>
                      <FaqULListText>
                        Monthly: Your account will be debited once per month
                        (every 30 days) on the date which you began your
                        subscription.
                      </FaqULListText>

                      <FaqULListText>
                        Annual: Your account will be debited once only,
                        immediately after purchase.
                      </FaqULListText>
                    </FaqULList>
                    <FaqULList dynamic1={true}>
                      <FaqULListHead>Can I change plans?</FaqULListHead>
                      <FaqULListText>
                        Sure! You can upgrade to Pro by clicking on the button
                        above or via your My Profile section of the app.
                      </FaqULListText>
                    </FaqULList>
                  </FaqUL>
                  <FaqUL padding="0px 0px 0px 0px" onSmallPaddingLeft="7%">
                    <FaqULList dynamic2={true}>
                      <FaqULListHead>Can I change plans?</FaqULListHead>
                      <FaqULListText>
                        Sure! You can upgrade to Pro by clicking on the button
                        above or via your My Profile section of the app.
                      </FaqULListText>
                    </FaqULList>
                    <FaqULList>
                      <FaqULListHead>
                        Can I cancel my subscription whenever I want?
                      </FaqULListHead>
                      <FaqULListText>
                        Yes. You can cancel your subscription at any time from
                        the profile section of Design Wizard.
                      </FaqULListText>
                    </FaqULList>

                    <FaqULList>
                      <FaqULListHead>What are credits?</FaqULListHead>
                      <FaqULListText>
                        Credits are our in-app currency and 1 credit = 1
                        download.
                      </FaqULListText>
                    </FaqULList>

                    <FaqULList>
                      <FaqULListHead>Is my payment secure?</FaqULListHead>
                      <FaqULListText>
                        Yes. We use Stripe, one of the world leaders in online
                        payments to process our payments.
                      </FaqULListText>
                    </FaqULList>
                  </FaqUL>
                </FaqWrap>
              </FaqMain>
            </SectionThreeWrapper>
          </SectionOne>
          {/* </PlanBox> */}
        </Box>
      </PopupBox>
    </>
  );
};
export default UpgradePopup;

import styled from "styled-components";
import { motion } from "framer-motion";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const TutorialVideoUI = styled.ul`
   {
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }
`;

export const TutorialVideoList = styled.li`
   {
    list-style: none;
    display: inline-block;
    width: 100%;
    margin: 15px 0;
  }
`;

export const NewFeature = styled.img`
  width: 100%;
  margin: auto;
  display: block;
`;

export const VideoBox = styled.div`
   {
    width: 100%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 225px;
    height: 126.58px;
    font: 16px;
  }
`;

export const Polygon = styled.div`
  transform: rotate(90deg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 70px;
  height: 70px;
  transition: stroke 0.5s ease;
  stroke: #2fc6c0;
`;
export const PolyGon = styled.polygon`
  stroke: #f296a3;
  &:hover {
    stroke: #f296a3;
  }
`;
export const SVG = styled.svg``;
export const Overlay = styled.div`
   {
    background-color: rgba(0, 0, 0, 0.55);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
    transition: background-color 0.5s;
    &:hover {
      background-color: rgba(0, 0, 0, 0.765);
      transition: background-color 0.5s;
      &: hover ${PolyGon} {
        stroke: #2fc6c0;
        &:hover{
          transition: all 0.3s ease-in-out;
          stroke: #f296a3;
        }
      }
    }
    
`;

export const PlayBtn = styled.div`
   {
    transition: transform 0.5s;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
    width: 39px;
    height: 39px;
  }
`;

export const AccourdianTextWrap = styled.div`
   {
    display: block;
  }
`;

export const HeadingScroll = styled.div`
  margin-top: 0;
  font-size: 18px;
  font-weight: bold;
`;
export const AssetsListController = styled.div`
  overflow: auto;
  position: relative;
  transition: opacity 0.5s linear;
  height: 100px;
  // &:hover {
  //   ::-webkit-scrollbar {
  //     width: 12px;
  //   }
  //   ::-webkit-scrollbar-track {
  //     background-color: #c0c0c0;
  //   }
  //   ::-webkit-scrollbar-thumb {
  //     background: #696969;
  //     border-radius: 6px;
  //     height: 20px;
  //     background-color: #c0c0c0;
  //   }
  //   ::-webkit-scrollbar-thumb:hover {
  //     background: #696969;
  //   }
  //   overflow-y: auto;
  // }
  ::-webkit-scrollbar {
    width: 8px;
  }
  &:hover {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-track:hover {
      background-color: #c1c1c1;
    }
    ::-webkit-scrollbar-thumb {
      background: #696969;
      border-radius: 6px;
      height: 20px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #696969;
    }
    overflow-y: auto;
  }
`;
export const AssetsListResultScroller = styled.div`
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 10px;
  overflow-y: scroll;
  &:hover {
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 6px;
      height: 20px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    overflow-y: overlay;
  }
`;

export const CloseAccordionArrow = styled.span`
  content: "";
  position: absolute;
  // top: 3px;
  left: -20px;
  width: 10px;
  height: 10px;
  border: solid #f296a3;
  border-width: 0 1px 1px 0;
  transform: rotate(-45deg);
  transition: transform 0.3s;
`;

export const OpenAccordionArrow = styled.span`
  content: "";
  position: absolute;
  // top: 3px;
  left: -20px;
  width: 10px;
  height: 10px;
  border: solid #f296a3;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  transition: transform 0.3s;
  // margin: 5px;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(12),
      flexShrink: 0,
      fontFamily: ["Lato", "sans-serif  !important"].join(","),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.secondary,
    },
    accourdianBox: {
      background: "none",
      boxShadow: "none",
    },
    accourdianText: {
      color: "#fff1d3",
      fontSize: "12px",
      height: "70px",
      fontFamily: ["Lato", "sans-serif  !important"].join(","),
      width: "225px",
      lineHeight: "1.35",
      wordbreak: "break-word",
    },
    accourdianMainBox: {
      display: "block",
      height: "190px",
    },
  })
);

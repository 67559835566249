import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { TransactionP } from "./TransactionStyled";
import { useTaxamoSubscribeMuatation } from "new-dw-ui/src/lib/api/mutationhooks";

export const Transaction = () => {
  const [taxamoData, setTaxamoData] = useState<any>({});
  const [transactionMsg, setTransactionMsg] = useState("");
  const dispatch = useDispatch();

  const taxamo = useTaxamoSubscribeMuatation({
    onSuccess: (data: any) => {
      window.parent.postMessage(
        { message: "Subscribed", data: taxamoData },
        "*"
      );
      dispatch({
        type: "closePopup",
        isPopupClosed: false,
      });
      setTransactionMsg("Transaction Completed successfully");
    },
    onError: () => {
      setTransactionMsg("Transaction failed");
    },
  });

  const { templateId } = useSelector((state: any) => {
    return state;
  });

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = async () => {
    var url = window.location.href.split("=")[1];

    await axios
      .get(
        `${process.env.REACT_APP_API_REDIRECT}/users/taxamoTransaction/${url} `
      )
      .then((resp) => {
        if (resp.data) {
          var paymentResult = { payment_result: {} };
          paymentResult.payment_result = resp.data;
          setTaxamoData(paymentResult);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    var obj = Object.keys(taxamoData);

    let value: number = parseInt(taxamoData?.payment_result?.amount);
    let transactionId: string = taxamoData?.payment_result?.key;
    // let payload: any = { transactionId, templateId, value };

    if (obj.length > 0) {
      if (taxamoData.payment_result.description) {
        taxamoSubscribe();
        //   paymentEvent(payload);
      } else {
        window.parent.postMessage(
          { message: "PaymentDone", data: taxamoData },
          "*"
        );
        //  paymentEvent(payload);
        return;
      }
    }
  }, [taxamoData]);

  const taxamoSubscribe = async () => {
    taxamo.mutate(taxamoData);
  };
  return (
    <>
      <TransactionP>{transactionMsg}</TransactionP>
    </>
  );
};

import React from "react";

import { ReactComponent as LogoWord } from "../../assets/svg/designwizard.svg";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { confirm_Email } from "../../lib/contexts/Queries";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  MainDiv1,
  MainDiv2,
  MainDiv3,
  LogoWrapper,
  Header1,
  Header2,
  Circle,
  ButtonContainer,
  Button,
  ButtonSpan,
} from "./EmailVerifiedStyled";
import { useUserData } from "utils/useUserData";

export const EmailVerified = () => {
  const [confirmEmail, confirmEmaildata] = useMutation(confirm_Email);
  const history = useHistory();
  const userData = useUserData();

  React.useEffect(() => {
    sendConfirmEmail();
  }, []);

  const sendConfirmEmail = async () => {
    let location = window.location.href;
    let uid = location.split("uid=")[1].split("&")[0];
    let tok = location.split("uid=")[1].split("token=")[1];
    const data = await confirmEmail({
      variables: {
        uid: uid,
        token: tok,
      },
    });
    if (
      data?.data?.POST_users_confirmEmailVerify === '{"emailVerified":true}'
    ) {
      userData["emailVerified"] = true;
      localStorage.setItem("user", JSON.stringify(userData));
    }
  };

  const handleStartOnClick = () => {
    history.push("/choose-business");
  };

  return (
    <>
      <MainDiv1>
        <MainDiv2>
          <MainDiv3>
            <LogoWrapper>
              <Logo
                style={{
                  color: "rgb(255, 255, 255)",
                  width: "85px",
                  height: "85px",
                }}
              />
              <LogoWord
                style={{
                  color: "rgb(255, 255, 255)",
                  width: "270px",
                  display: "inline-block",
                  marginTop: "13px",
                }}
              />
            </LogoWrapper>
            <Header1>
              <Circle></Circle>
              <Header2>Success! You have verified your account.</Header2>
            </Header1>
            <p>
              Welcome to Design Wizard! You can now start creating , sharing and
              downloading your designs!
            </p>
            <ButtonContainer>
              <Button onClick={handleStartOnClick}>
                <ButtonSpan>Start now</ButtonSpan>
              </Button>
            </ButtonContainer>
          </MainDiv3>
        </MainDiv2>
      </MainDiv1>
      <div></div>
    </>
  );
};

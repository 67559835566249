// import { DasboardWorkspace } from "../pages/private/dashboard/workspace";
// import PublicHome from "../pages/public/home";
// import SignUp from "../pages/public/signUp";
// import { PrivateHome } from "../pages/private/home/Index";
// import { Profile } from "../pages/private/dashboard/profile";
// import { ForgetPassword } from "../pages/public/forgetPassword";
// import { PurchaseHistory } from "../pages/private/dashboard/purchaseHistory";
// import { ChooseTypes } from "../pages/private/dashboard/chooseTypes";
// import { ChooseSize } from "../pages/private/dashboard/chooseSize";
// import { TrialOffer } from "../pages/private/home/TrialOffer/trialOffer";
// import { ChooseBusiness } from "../pages/private/dashboard/chooseBusiness";
// import { ChooseTemplate } from "../pages/private/dashboard/chooseTemplate";
import { URL_CONSTANTS } from "./history";
// import { ResetPassword } from "../pages/public/resetPassword";
// import { TutorialPopup } from "../pages/private/home/TutorialPopup/tutorialPopup";
// import { Landing } from "../admin/Landing/Landing";
// import { TutorialsAdmin } from "../admin/TutorialsAdmin/TutorialsAdmin";
// import { TutorialsRightSide } from "../admin/TutorialsRightSide/TutorialsRightSide";
// import { Notification } from "../admin/Notification/Notification";
// import { NotificationPopup } from "../admin/NotificationPopup/NotificationPopup";
// import { Account } from "../admin/Account/Account";
// import { LandingTitle } from "../admin/LandingTitle/LandingTitle";
// import { ReviewAsset } from "../admin/ReviewAsset/ReviewAsset";
// import { PageResponsiveMsg } from "./PageResponsiveMsg/pageResponsiveMsg";
// import { Test } from "../pages/private/dashboard/workspace/Test";
import withSuspense from "hoc/withSuspense";
// import { PrivateHome } from "./LazyRoutesComponent/LazyRoutesComponent";

import {
  DasboardWorkspace,
  PrivateHome,
  Profile,
  ForgetPassword,
  PurchaseHistory,
  ChooseTypes,
  ChooseSize,
  TrialOffer,
  ChooseBusiness,
  ChooseTemplate,
  ResetPassword,
  TutorialPopup,
  Landing,
  TutorialsAdmin,
  TutorialsRightSide,
  Notification,
  NotificationPopup,
  Account,
  LandingTitle,
  ReviewAsset,
  PageResponsiveMsg,
  Test,
  PublicHome,
  SignUp,
  UserVotingTable,
  HomeLayout
} from "./LazyRoutesComponent/LazyRoutesComponent";
export const privateRoutes = [
  {
    link: URL_CONSTANTS.DASHBOARD_WORKSPACE(),
    component: withSuspense(DasboardWorkspace),
  },
  {
    link: URL_CONSTANTS.PRIVATE_HOME(),
    component: withSuspense(HomeLayout),
  },
  {
    link: URL_CONSTANTS.PROFILE(),
    component: withSuspense(Profile),
  },
  {
    link: URL_CONSTANTS.CHOOSE_TYPES(),
    component: withSuspense(ChooseTypes),
  },
  {
    link: URL_CONSTANTS.CHOOSE_SIZE(),
    component: withSuspense(ChooseSize),
  },
  {
    link: URL_CONSTANTS.PURCHASE_HISTORY(),
    component: withSuspense(PurchaseHistory),
  },
  {
    link: URL_CONSTANTS.TRIAL_OFFER(),
    component: withSuspense(TrialOffer),
  },
  {
    link: URL_CONSTANTS.CHOOSE_BUSINESS(),
    component: withSuspense(ChooseBusiness),
  },
  {
    link: URL_CONSTANTS.CHOOSE_TEMP(),
    component: withSuspense(ChooseTemplate),
  },
  {
    link: URL_CONSTANTS.TUTORIAL(),
    component: withSuspense(TutorialPopup),
  },
  {
    link: URL_CONSTANTS.LANDING_PAGE(),
    component: withSuspense(Landing),
  },
  {
    link: URL_CONSTANTS.TUTORIALS_ADMIN(),
    component: withSuspense(TutorialsAdmin),
  },
  {
    link: URL_CONSTANTS.TUTORIALS_RIGHTSIDE(),
    component: withSuspense(TutorialsRightSide),
  },
  {
    link: URL_CONSTANTS.NOTIFICATION(),
    component: withSuspense(Notification),
  },
  {
    link: URL_CONSTANTS.NOTIFICATIONPOPUP(),
    component: withSuspense(NotificationPopup),
  },
  {
    link: URL_CONSTANTS.ACCOUNT(),
    component: withSuspense(Account),
  },
  {
    link: URL_CONSTANTS.LANDING_TITLE(),
    component: withSuspense(LandingTitle),
  },
  {
    link: URL_CONSTANTS.REVIEW_ASSET(),
    component: withSuspense(ReviewAsset),
  },
  {
    link: URL_CONSTANTS.DASHBOARD_START_TEMPLATES(),
    component: withSuspense(HomeLayout)
  },
  {
    link: URL_CONSTANTS.DASHBOARD_TEMPLATEPAGE(),
    component: withSuspense(HomeLayout)
  },
  {
    link: URL_CONSTANTS.DASHBOARD_MY_PROJECTS(),
    component: withSuspense(HomeLayout)
  },
  {
    link: URL_CONSTANTS.DASHBOARD_FOLDER_PAGE(),
    component: withSuspense(HomeLayout)
  },
  {
    link: URL_CONSTANTS.DASHBOARD_BRANDKIT(),
    component: withSuspense(HomeLayout)
  },
  {
    link: URL_CONSTANTS.DASHBOARD_SEARCH_TEMPLATES(),
    component: withSuspense(HomeLayout)
  },
  {
    link: URL_CONSTANTS.DASHBOARD_TRASH_PAGE(),
    component: withSuspense(HomeLayout)
  },
  // {
  //   link: URL_CONSTANTS.USER_VOTING(),
  //   component: withSuspense(UserVotingTable),
  // },
  // {
  //   link: URL_CONSTANTS.MOBILE_REGISTRATION(),
  //   component: withSuspense(PageResponsiveMsg),
  // },
  {
    link: URL_CONSTANTS.Testttt(),
    component: withSuspense(Test),
  }
];

export const publicRoutes = [
  {
    link: URL_CONSTANTS.ROOT(),
    component: withSuspense(PublicHome),
  },
  {
    link: URL_CONSTANTS.ROOTPLANNEDFEATURE(),
    component: withSuspense(PublicHome),
  },
  {
    link: URL_CONSTANTS.SIGNUP(),
    component: withSuspense(SignUp),
  },
  {
    link: URL_CONSTANTS.FORGET_PASSWORD(),
    component: withSuspense(ForgetPassword),
  },
  {
    link: URL_CONSTANTS.RESET_PASSWORD(),
    component: withSuspense(ResetPassword),
  },
  {
    link: URL_CONSTANTS.USER_VOTING(),
    component: withSuspense(UserVotingTable),
  },
  {
    link: URL_CONSTANTS.MOBILE_REGISTRATION(),
    component: withSuspense(PageResponsiveMsg),
  },
];

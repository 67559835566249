// eslint-disable-next-line no-unused-vars
import React from "react";
import { loginEvent } from "lib/gtmEvents";
import { signUpEvent } from "lib/gtmEvents";
import { useCookies } from "react-cookie";
import { useProfileDataForLogin } from "../lib/api/queryhooks";

export const GoogleLoginCallback: any = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  let txt = window.location.href;
  const getUserDetail = useProfileDataForLogin(
    "refresh",
    txt.split("/")[4].split("?")[0]
  );
  if (txt.split("&")[1].split("=")[1].includes("true") && !window.location?.search?.includes("provider=designwizard")) {
    window.location.href = "/callback-error/email-not-exist";
    return;
  }

  if (getUserDetail.isLoading) return <>loading...</>;
  if (getUserDetail.isError) {
    setTimeout(() => {
      window.location.href = "/";
    }, 3000);
  }

  if (getUserDetail.data) {
    let data = getUserDetail.data;
    localStorage.setItem("user", JSON.stringify(data));
    localStorage.setItem("email", JSON.stringify(data.email));
    localStorage.setItem("token", data.id);
    localStorage.setItem("userId", data.userId);

    if (txt.split("&")[1].split("=")[1].includes("false")) {
      let obj: any = {
        method: window.location.href.includes("google") ? "google" : "facebook",
      };
      loginEvent(obj);
      if (cookies["previous-url"] === "planned-features") {
        window.location.href = "/planned-features";
      } else {
        window.location.href = data?.preferences?.usingNewUi
          ? "/dashboard/home"
          : "/home";
      }
    }
    if (txt.split("&")[1].split("=")[1].includes("true")) {
      let obj: any = {
        method: window.location.href.includes("google") ? "google" : "facebook",
      };
      signUpEvent(obj);
      window.location.href = "/trial-offer";
    }
  }
};

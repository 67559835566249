import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { GetUserUpload, UploadImage } from "lib/contexts/Queries";
import { useHistory } from "react-router-dom";
import { Loader } from "pages/private/dashboard/workspace/Loader/loaders";
import { useForkRef } from "@mui/material";
import { useUserData } from "utils/useUserData";
export const PickUpload = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(false);
  const [data, setData] = useState({});
  const [imageId, setImageId] = useState("");
  const [alreadyUpload, setAlreadyUpload] = useState([]);
  const [uploadImg] = useMutation(UploadImage);
  const userData = useUserData();

  const getUpload = useQuery(GetUserUpload, {
    variables: {
      token: `${localStorage.getItem("token")}`,
    },
    skip: skip,
  });

  if (getUpload.data) {
    setSkip(true);
    setAlreadyUpload(getUpload.data.GET_users_uploads);
  }

  const uploadFile = async (base: string, appId: any, assetId: any) => {
    const res = await uploadImg({
      variables: {
        token: `${localStorage.getItem("token")}`,
        input: {
          base64: base,
          // source: appId,
          //assetId: assetId,
        },
      },
    });
    return res;
  };

  const checkUser = () => {
    if (userData) return true;
    else return false;
  };
  const checkAlreadyUploaded = (assetId: any) => {
    alreadyUpload.forEach((item: any) => {
      if (item.id === assetId) {
        setData(item);
        return false;
      }
    });
    return true;
  };
  useEffect(() => {
    var location: any, appId: any, assetId: any, imageUrl: any;
    location = decodeURIComponent(window.location.href);
    assetId = location.split("assetId=")[1];
    appId = location.split("appId=")[1].split("&")[0];
    imageUrl = location.split("imageUrl=")[1].split("&")[0];
    if (appId || assetId) {
      sessionStorage.setItem("url", imageUrl);
      sessionStorage.setItem("appId", JSON.stringify(appId));
      sessionStorage.setItem("assetId", JSON.stringify(assetId));
      if (checkUser()) {
        history.push("/dashboard/workspace");
      } else {
        history.push("/signup");
      }
    }
  }, []);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default PickUpload;

import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { ChangeUploadPopup } from "../../../../pages/private/dashboard/workspace/ChangeUploadPopup/ChangeUploadPopup";
import { VIDEO_IDS, updateCategory } from "../../../../constants";
import {
  ToggleWrapper,
  ToggleWrapperBtn,
  ToggleWrapperBtnSpan,
} from "./ImageVideoEditorToggleStyled";
import useCanvasEvent from "hooks/useCanvasEvent";

export const ImageVideoEditorToggle = (props: any) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [canvasObj, setCanvasObj] = useState<Array<any>>([]);
  const [btn, setBtn] = useState("");
  const location: any = useLocation();
  const history: any = useHistory();
  const dispatch = useDispatch();
  const isCanvasLoadData = useSelector((state: any) => {
    return state.isCanvasLoadData;
  });

  const canvas = useSelector((state: any) => {
    return state.canvas;
  });
  const handleChangeUploadClose = () => {
    dispatch({
      type: "isCanvasLoad",
      isCanvasLoadData: {
        type: "loadCanvas",
        status: false,
      },
    });
    setShowPopup(!showPopup);
  };
  const toSave = useSelector((state: any) => {
    return state.toSave;
  });

  const compositionId = useSelector((state: any) => {
    return state.compositionId;
  });

  const resizeTitleId = useSelector((state: any) => {
    return state.resizeTitleId;
  });

  const editor = useSelector((state: any) => {
    return state.editor;
  });

  const isRunAdminSaveTemp = useSelector((state: any) => {
    return state.isRunAdminSaveTemp;
  });
  /**
   * Checks if id belongs to the editor when toggled to image/editor
   * (Hello world)
   */

  const checkId = (editor: any) => {
    if (editor === "image") {
      if (VIDEO_IDS.includes(resizeTitleId)) {
        updateCategory(dispatch, 1200, 900, "Facebook Post", 256);
      }
    } else {
      dispatch({
        type: "setTitle",
        title: "Untitled 1",
      });
      updateCategory(dispatch, 1920, 1080, "Videos", 13);
    }
  };

  // useEffect(() => {
  //   if (canvas) {
  //     canvas.on("object:added", () => {
  //       setCanvasObj(canvas.getObjects());
  //     });
  //     canvas.on("object:removed", () => {
  //       setCanvasObj(canvas.getObjects());
  //     });
  //   }
  // }, [canvas]);

  useCanvasEvent(
    "object:added",
    () => {
      setCanvasObj(canvas.getObjects());
    },
    canvas
  );

  useCanvasEvent(
    "object:removed",
    () => {
      setCanvasObj(canvas.getObjects());
    },
    canvas
  );

  const videoToggle = () => {
    if (
      canvas &&
      canvas.getObjects() &&
      canvas.getObjects().length > 1 &&
      !compositionId
    ) {
      setShowPopup(true);
      return;
    }
    checkId("video");
    dispatch({
      type: "updateEditor",
      editor: "video",
    });
    window.history.pushState(null, "", `/dashboard/workspace`);
  };
  const imageToggle = () => {
    if (
      canvas &&
      canvas.getObjects() &&
      canvas.getObjects().length > 1 &&
      !compositionId
    ) {
      setShowPopup(true);
      return;
    }
    checkId("image");
    dispatch({
      type: "updateEditor",
      editor: "image",
    });
    window.history.pushState(null, "", `/dashboard/workspace`);
  };

  useEffect(() => {
    if (btn === "discard" && !canvasObj.length) {
      editor === "video" ? imageToggle() : videoToggle();
      setBtn("");
    }
    if (btn === "save" && !toSave) {
      editor === "video" ? imageToggle() : videoToggle();
      setBtn("");
    }
    if (isRunAdminSaveTemp === false && btn === "saveTemp") {
      editor === "video" ? imageToggle() : videoToggle();
      setBtn("");
    }
  }, [btn, canvasObj, toSave, isRunAdminSaveTemp]);

  const confirmation = (btn: any) => {
    if (btn === "discard") {
      // let state = { ...history.location.state };
      // delete state.title;
      // delete state.template;
      // history.replace({ ...history.location, state });
      setShowPopup(false);
      dispatch({
        type: "setTemplateId",
        templateId: "",
      });
      canvas.clear();
      setCanvasObj([]);
      setBtn("discard");
    }
    if (btn === "save") {
      dispatch({
        type: "toSave",
        toSave: true,
      });
      setBtn("save");
      setCanvasObj([]);
    }

    if (btn === "saveTemp") {
      dispatch({
        type: "updateAdminSaveTemp",
        isRunAdminSaveTemp: true,
      });
      setBtn("saveTemp");
      setCanvasObj([]);
    }
  };

  useEffect(() => {
    if (toSave) {
      setShowPopup(false);
    }
  }, [toSave]);
  return (
    <>
      <ToggleWrapper>
        <ToggleWrapperBtn
          disabled={isCanvasLoadData.status}
          onClick={videoToggle}
          className={editor === "video" ? "activeEditorBtn" : ""}
        >
          <ToggleWrapperBtnSpan>Video Editor</ToggleWrapperBtnSpan>
        </ToggleWrapperBtn>

        <ToggleWrapperBtn
          disabled={isCanvasLoadData.status}
          onClick={imageToggle}
          className={editor === "image" ? "activeEditorBtn" : ""}
        >
          <ToggleWrapperBtnSpan>Image Editor</ToggleWrapperBtnSpan>
        </ToggleWrapperBtn>
      </ToggleWrapper>
      {showPopup && (
        <ChangeUploadPopup
          confirmation={confirmation}
          handleClose={handleChangeUploadClose}
        />
      )}
    </>
  );
};

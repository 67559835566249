import React, { useState, useEffect } from "react";

import proGray from "../../assets/svg/New folder/proGrey.png";
import Gift from "../../assets/svg/New folder/gift.png";
import Clock from "../../assets/svg/New folder/clock.png";
import { ReactComponent as Cross } from "../../assets/svg/New folder/cross.svg";
import {
  LeftSide,
  LeftSideContent,
  ModalTitle,
  Label,
  InputOne,
  LabelOne,
  Footer,
  OptionWrapper,
  Option,
  OptionText,
  OptionTwo,
  OptionDiv,
  OptionThree,
  OptionThreeDiv,
  UpgradeNoti,
  LeftSpan,
  HurryUpSpan,
  UpgradeToSpan,
  NotificationPopupWrapper,
} from "./NotificationPopupStyled";
import NotificationRightSidePopup from "./NotificationRightSidePopup";
import NotificationPreview from "./NotificationPreview";

export const NotificationPopup = (props: any) => {
  const [fulTitle, setFullTitle] = useState("");
  const [fullBody, setFullBody] = useState("");
  const [compactTitle, setCompactTitle] = useState("");
  const [compactBody, setCompactBody] = useState("");
  // const [popupData, setPopupData] = useState<any>({});
  useEffect(() => {
    if (props.otherData) {
      setFullTitle(props.otherData.fullTitle);
      setFullBody(props.otherData.fullBody);
      setCompactTitle(props.otherData.compactTitle);
      setCompactBody(props.otherData.compactBody);
    }
  }, [props]);

  return (
    <>
      <NotificationPopupWrapper id="notify">
        <LeftSide>
          <LeftSideContent>
            {props.from !== "accountMenu" ? (
              <ModalTitle>
                <Label
                  placeholder="notification full title"
                  onChange={(e) => {
                    setFullTitle(e.target.value);
                  }}
                  value={fulTitle}
                />

                <InputOne></InputOne>
              </ModalTitle>
            ) : (
              <ModalTitle
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  style={{
                    color: "black",
                    textAlign: "center",
                    paddingTop: "15px",
                    paddingLeft: "180px",
                  }}
                >
                  {props.propsDetail.name}
                </div>
                <Cross
                  style={{
                    color: "white",
                    width: "25px",
                    height: "66px",
                    marginRight: "3px",
                    marginTop: "-14px",
                  }}
                  onClick={() => {
                    props.setOpenPopUp(false);
                  }}
                ></Cross>
              </ModalTitle>
            )}

            {props.from !== "accountMenu" ? (
              <LabelOne
                rows={5}
                placeholder="Describe Notification Body here...."
                onChange={(e) => {
                  setFullBody(e.target.value);
                }}
                value={fullBody}
              />
            ) : (
              <div
                style={{ color: "black", fontSize: "12px", padding: "30px" }}
              >
                {" "}
                {props.propsDetail.body}
              </div>
            )}

            <NotificationPreview {...props} />
            <Footer>
              <OptionWrapper>
                <Option>
                  <img
                    src={proGray}
                    style={{
                      marginRight: "400px",
                      verticalAlign: "middle",
                      marginLeft: "51px",
                    }}
                    alt="pro gray"
                  />
                  <OptionText>
                    <UpgradeToSpan>Upgrade to </UpgradeToSpan>
                    <span style={{ color: " #444549", marginLeft: "102px" }}>
                      Free Trial
                    </span>
                  </OptionText>
                </Option>
                <OptionTwo>
                  <img src={Gift} />
                  <OptionDiv>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#854f8f",
                        display: "block",
                        lineHeight: " 1.2",
                        textAlign: "left",
                      }}
                    >
                      5 Free Videos{" "}
                    </span>
                    <span style={{ color: "#444549" }}>
                      {props.otherData && props.otherData.offersLeft} deals
                      left!
                    </span>
                  </OptionDiv>
                </OptionTwo>
                <OptionThree>
                  <img src={Clock} alt="clock" />
                  <OptionThreeDiv>
                    <LeftSpan>
                      {props.otherData && props.otherData.durationInDays} days
                      left
                    </LeftSpan>
                    <HurryUpSpan>Hurry up!</HurryUpSpan>
                  </OptionThreeDiv>
                  <UpgradeNoti
                    onClick={() => {
                      props.setOpenPopUp(false);
                    }}
                  >
                    AND GET FREE VIDEOS
                  </UpgradeNoti>
                </OptionThree>
              </OptionWrapper>
            </Footer>
          </LeftSideContent>
        </LeftSide>
      </NotificationPopupWrapper>

      <NotificationRightSidePopup
        {...props}
        setCompactTitle={setCompactTitle}
        compactTitle={compactTitle}
        setCompactBody={setCompactBody}
        compactBody={compactBody}
      />
    </>
  );
};

export const wsActiveToolbarDispatch = (dispatch: any, obj: any) => {
  dispatch({
    type: "setActiveToolbarItem",
    activeToolbar: obj,
  });
};

export const toolbarItemConst = {
  clear: "clear",
  bg: "bg",
  fill: "fill",
  borderClr: "borderClr",
  fontFamily: "fontFamily",
  fontSize: "fontSize",
  position: "position",
  textEffect: "textEffect",
  opacity: "opacity",
  align: "align",
  spacing: "spacing",
  bold: "bold",
  italic: "italic",
  underline: "underline",
  textCases: "textCases",
  copy: "copy",
  delete: "delete",
  lock: "lock",
  imageEffect: "imageEffect",
  reposition: "reposition",
  layer: "layer",
  masking: "masking",
  animation: "animation",
  resizeToHD: "resize to HD size",
  shapeEffect: "shapeEffect",
  svgEffect: "svgEffect",
  videoSizeWarning: "videoSizeWarning",
  videoEffect: "videoEffect", 
  similarAssetSearch: "similarAssetSearch",
  pencilEffect: "pencilEffect",
};

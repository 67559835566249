import styled from "styled-components";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      float: "right",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "20px",
    },
  })
);

export const MainDiv = styled.div`
  //opacity: 0;
  position: absolute;
  z-index: 1111;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
`;
export const Modal = styled.div`
  transform: translateY(-50%) scale(0.75) !important;
  top: 50%;
  margin: auto;
  right: 0;
  left: 0;
  position: absolute;
  z-index: 1111;
  width: 70%;
  box-shadow: 0 0 50px 0 rgb(0 0 0 / 80%);
  -webkit-border-radius: 0;
`;
export const ModalDialog = styled.div`
  background-color: white;
  border-radius: 5px !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border: none;
  // max-height: 70vh;
  overflow-y: auto;
  // padding-bottom: 50px;
`;
export const AddTask = styled.div`
  outline: none;
`;
export const Header = styled.div`
  position: relative;
  padding: 15px;
  border-bottom: none;
  align-items: center;
`;
export const Close = styled.div`
  background: transparent;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  position: relative;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  z-index: 99999;
  opacity: 1;
  transition: background-color 0.25s ease;
  top: 20px;
  right: 20px;
  &: hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
export const ModalBody = styled.div`
  font-family: Arial !important;
  padding: 0px 60px 0px 60px;
  background-color: white;
  // height: 70vh;
  // overflow-y: scroll;
`;
export const InputSearch = styled.input`
  width: 200px;
  height: 29px;
`;
export const Task = styled.div`
  outline: none;
`;
export const Table = styled.div`
  width: 100%;
  height: auto;
  max-height: 75vh;
  margin-bottom: 50px;
`;
export const TableBody = styled.div`
  outline: none;
  margin-top: 50px;
  height: 100%;
  overflow: auto;
  padding-bottom: 5%;
`;

export const BottomActionButtonWrapper = styled.div`
  display: flex;
  // float: right;
  padding: 12px 0px 12px 0px;
  position: relative;
  right: 0px;
  bottom: 0px;
  justify-content: end;
`;

export const BottomActionButton = styled.button`
  font-size: 16px;
  padding: 4px 6px;
  // border: 2px solid black;
  margin-left: 4px;
  margin-right: 4px;
`;

export const TblH = styled.th<{ width: String }>`
  font-weight: 700;
  font-family: "Arial";
  font-size: 16px;
  border: 1px solid #232428;
  padding: 10px;
  text-align: left;
  width: ${(props: any) => props.width};
  // height: calc(2vh + 10px);
  vertical-align: middle;

  // @media (min-height: 913px) {
  //   font-size: 23px;
  //   height: calc(2vh + 20px);
  // }
`;

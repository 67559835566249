import { useEffect, useRef } from "react";
import dwCanvasUtils from "../new-dw-ui/src/utils/dwCanvasUtils";

function useCanvasEvent(eventName, handler, canvas) {
  // Create a ref that stores handler
  const savedHandler = useRef();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(
    () => {
      // Make sure canvas supports addEventListener
      // On
      const isSupported = canvas?.on;
      if (!isSupported) return;
      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => {
        if (dwCanvasUtils.isLoadingFromJSON) {
          return;
        }
        savedHandler.current(event);
      };
      // Add event listener
      canvas.on(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        canvas.off(eventName, eventListener);
      };
    },
    [eventName, canvas] // Re-run if eventName or element changes
  );
}

export default useCanvasEvent;

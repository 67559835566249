import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { TaxamoSubscribe } from "../../lib/contexts/Queries";
import { TransactionP } from "./TransactionStyled";
import { paymentEvent } from "lib/gtmEvents";

export const Transaction = () => {
  const [taxamoData, setTaxamoData] = useState<any>({});
  const [transactionMsg, setTransactionMsg] = useState("");
  const [taxamo] = useMutation(TaxamoSubscribe);
  const dispatch = useDispatch();
  const { templateId } = useSelector((state: any) => {
    return state;
  });

  useEffect(() => {
    apiCall();
  }, []);

  const apiCall = async () => {
    var url = window.location.href.split("=")[1];

    await axios
      .get(
        `${process.env.REACT_APP_API_REDIRECT}/users/taxamoTransaction/${url} `
      )
      .then((resp) => {
        if (resp.data) {
          var paymentResult = { payment_result: {} };
          paymentResult.payment_result = resp.data;
          setTaxamoData(paymentResult);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    var obj = Object.keys(taxamoData);

    let value: number = parseInt(taxamoData?.payment_result?.amount);
    let transactionId: string = taxamoData?.payment_result?.key;
    // let payload: any = { transactionId, templateId, value };

    if (obj.length > 0) {
      if (taxamoData.payment_result.description) {
        taxamoSubscribe();
        //   paymentEvent(payload);
      } else {
        window.parent.postMessage(
          { message: "PaymentDone", data: taxamoData },
          "*"
        );
        //  paymentEvent(payload);
        return;
      }
    }
  }, [taxamoData]);

  const taxamoSubscribe = async () => {
    await taxamo({
      variables: {
        input: JSON.stringify(taxamoData),
        token: `${localStorage.getItem("token")}`,
      },
    }).then((data) => {
      if (data.data.POST_users_me_taxamoSubscribe) {
        window.parent.postMessage(
          { message: "Subscribed", data: taxamoData },
          "*"
        );
        dispatch({
          type: "closePopup",
          isPopupClosed: false,
        });
        setTransactionMsg("Transaction Completed successfully");
        // toast.success("Transaction Completed successfully");
      } else {
        setTransactionMsg("Transaction failed");
      }
    });
  };
  return (
    <>
      <TransactionP>{transactionMsg}</TransactionP>
    </>
  );
};

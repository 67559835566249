import { Modal } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { SystemPref } from "lib/contexts/Queries";
import { useDispatch, useSelector } from "react-redux";
import { switchUiEvent } from "lib/gtmEvents";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "routing/history";
import { URL_CONSTANTS as NEW_URL_CONSTANTS } from "../../../../new-dw-ui/src/routing/history";

const RetirementOldUiPopup = (props: any) => {
  const { open, setOpen, cookies } = props;

  const [uiChange, { loading: uiChangeLoading, error: uiChangeerrLoading }] =
    useMutation(SystemPref);

  const updateProfile = useSelector((state: any) => {
    return state.isProfileUpdated;
  });
  const dispatch = useDispatch();

  const handleUiChange = async () => {
    let userVals: any = localStorage.getItem("user");
    userVals = JSON.parse(userVals);
    if (!userVals.preferences) {
      userVals.preferences = {};
    }
    userVals.preferences.usingNewUi = true;
    const obj: any = { label: "New UI - Banner" };
    switchUiEvent(obj);
    await uiChange({
      variables: {
        input: JSON.stringify({
          preferences: {
            ...userVals.preferences,
          },
        }),
        token: localStorage.getItem("token"),
      },
      onCompleted: () => {
        dispatch({
          type: "updateProfile",
          isProfileUpdated: !updateProfile,
        });
        localStorage.setItem("user", JSON.stringify(userVals));
        toast.success("Updated Successfully");
        closeUiRetirementPopup();
        const oldUrl: any = URL_CONSTANTS;
        const newUrl: any = NEW_URL_CONSTANTS;
        const newPath: any = Object.keys(oldUrl).filter(
          (k: any) => oldUrl[k]() === window.location.pathname
        );
        if (!newPath || newPath.length === 0) {
          window.location.reload();
        } else {
          const newRedirect = newUrl[newPath]();
          const newLocationChange = window.location.origin + newRedirect;
          window.location.href = newLocationChange;
        }
      },
    });
    if (uiChangeLoading) return <>'updating....'</>;
    if (uiChangeerrLoading)
      return <>`Submission error! ${uiChangeerrLoading.message}`</>;
  };

  const closeUiRetirementPopup = (isRemindLater = false) => {
    const expirationDate = new Date();
    if (isRemindLater) {
      expirationDate.setDate(expirationDate.getDate() + 1);
    } else {
      expirationDate.setDate(expirationDate.getDate() + 30);
    }
    cookies.set("hideRetirementPopup", `true`, {
      path: "/",
      expires: expirationDate,
    });
    setOpen(false);
  };

  return (
    <div>
      <Modal open={open}>
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "550px",
            height: "300px",
            border: "none",
            backgroundColor: "#fff",
            boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px",
            borderRadius: "10px",
            outline: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 20px 30px 20px",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            This UI is being retired in September
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            Our old interface is being retired to make way for a fresh, new
            design! Transition to the updated UI for a more seamless and
            enhanced experience. We're excited for you to explore the new
            features and improvements. Thank you for adapting with us!
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            You can switch to new-ui to try the new experience. You can switch
            back to old one from profile page
          </div>
          <div
            style={{
              display: "flex",
              margin: "30px 20px 20px 20px",
              justifyContent: "space-evenly",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "10px 15px",
                backgroundColor: "rgb(64, 40, 112)",
                color: "#fff",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={async () => await handleUiChange()}
            >
              Switch to new-ui
            </div>
            <div
              style={{
                display: "flex",
                padding: "10px 15px",
                border: "1px solid #333",
                borderRadius: "5px",
                cursor: "pointer"
              }}
              onClick={() => closeUiRetirementPopup(true)}
            >
              Remind me later
            </div>
            <div
              style={{
                display: "flex",
                padding: "10px 15px",
                backgroundColor: "#777",
                color: "#fff",
                borderRadius: "5px",
                cursor: "pointer"
              }}
              onClick={() => closeUiRetirementPopup()}
            >
              Close
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RetirementOldUiPopup;

import { useQuery } from "@tanstack/react-query";
import {
  getTemplateCategories,
  getUserFolders,
  getUserCompositions,
  getUserCompositionsCustom,
  getDesignCounts,
  getCategoryData,
  getCategoryTemplates,
  getdwFonts,
  getUserFonts,
  getBrandingKit,
  brandingKitCount,
  waveflowTasks,
  getUsage,
  getUsageCount,
  getProfile,
  searchTemplateCategories,
  getProfileForGoogleLogin,
} from "./dashoboard";

export const useTemplateCategories = (filters: Object = {}, key: any = {}) => {
  return useQuery({
    queryKey: ["templateCategories", key],
    queryFn: () => {
      return getTemplateCategories(filters);
    },
  });
};

export const useTemplateCategoriesSearch = (
  data: Object = {},
  key: any = {}
) => {
  return useQuery({
    queryKey: ["templateCategories-home", key],
    queryFn: () => {
      return searchTemplateCategories({
        ...data,
      });
    },
  });
};

export const useUserFolders = (userId: String, filters: Object) => {
  return useQuery({
    queryKey: ["userFolders"],
    cacheTime: 0,
    queryFn: () => {
      return getUserFolders(userId, filters);
    },
  });
};

export const useUserCompositions = (
  filters: Object,
  key: any,
  enabled: any = true
) => {
  return useQuery({
    queryKey: ["userCompositions", key],
    cacheTime: 0,
    queryFn: () => {
      return getUserCompositionsCustom(filters);
    },
    enabled: enabled,
  });
};

export const useCompositionCount = (where: Object) => {
  return useQuery({
    queryKey: ["userCompositionCount"],
    cacheTime: 0,
    queryFn: () => {
      return getDesignCounts(where);
    },
  });
};

export const useCategoryData = ({
  data,
  key,
  enabled = true,
}: {
  data: Object;
  key: any;
  enabled?: Boolean;
}) => {
  return useQuery({
    queryKey: ["categoryData", key],
    queryFn: () => {
      return getCategoryData(data);
    },
    enabled: !!enabled,
  });
};

export const useGetCategoryTemplate = ({
  data,
  key,
  enabled = true,
}: {
  data: Object;
  key: any;
  enabled?: Boolean;
}) => {
  return useQuery({
    queryKey: ["categoryTemplate", key],
    queryFn: () => {
      return getCategoryTemplates(data);
    },
    enabled: !!enabled,
  });
};

export const useDwFonts = (filter: Object) => {
  return useQuery({
    queryKey: ["dwFonts"],
    queryFn: () => {
      return getdwFonts(filter);
    },
  });
};

export const useUserFonts = () => {
  return useQuery({
    queryKey: ["userFonts"],
    queryFn: getUserFonts,
  });
};

export const useBrandingKits = ({
  data,
  key,
  enabled = true,
}: {
  data: { userId: String; filter: Object };
  key: any;
  enabled?: Boolean;
}) => {
  return useQuery({
    queryKey: ["brandingKits", key],
    queryFn: () => {
      return getBrandingKit(data.userId, data.filter);
    },
    enabled: !!enabled,
  });
};

export const useBrandingKitsCount = ({
  data,
  key,
  enabled = true,
}: {
  data: { userId: String; filter: Object };
  key: any;
  enabled?: Boolean;
}) => {
  return useQuery({
    queryKey: ["brandingKitsCount", key],
    queryFn: () => {
      return brandingKitCount(data.userId, data.filter);
    },
    enabled: !!enabled,
  });
};

export const useWaveflowTasks = ({
  filter,
  key,
  enabled = true,
}: {
  filter: Object;
  key?: any;
  enabled?: Boolean;
}) => {
  return useQuery({
    queryKey: ["waveflowTasks"],
    queryFn: () => {
      return waveflowTasks(filter);
    },
    enabled: !!enabled,
  });
};

export const useUsage = ({
  filter,
  key,
  enabled = true,
}: {
  filter: Object;
  key?: any;
  enabled?: Boolean;
}) => {
  return useQuery({
    queryKey: ["purchaseHistory"],
    queryFn: () => {
      return getUsage(filter);
    },
    enabled: !!enabled,
  });
};

export const useUsageCount = () => {
  return useQuery({
    queryKey: ["purchaseHistoryCount"],
    queryFn: getUsageCount,
  });
};

export const useProfileData = () => {
  return useQuery({
    queryKey: ["profileData"],
    cacheTime: 0,
    queryFn: getProfile,
  });
};

export const useProfileDataForLogin = (filter: any, token: any) => {
  return useQuery({
    queryKey: ["profileDataForLogin"],
    queryFn: () => getProfileForGoogleLogin(filter, token),
  });
};

import React, { useState, useEffect } from "react";
//import myLogo from "./images/designwizard-logo.png";
import { ReactComponent as Logo } from "../../../../assets/svg/logo.svg";
import AccountMenu from "../AccountMenu/accountMenu";
import { logOut } from "../../../../lib/contexts/Queries";
// import { Button } from '@material-ui/core';
import UpgradePayemntPopUp from "../../../../pages/private/home/upgradePayment";
import { ImageVideoEditorToggle } from "../ImageVideoEditorToggle/imageVideoEditorToggle";
import { useLocation, Link, useHistory } from "react-router-dom";
import { ReactComponent as Star } from "../../../../assets/svg/New folder/star.svg";
import { useDispatch, useSelector } from "react-redux";
// import {ReactComponent as Create} from "../../../../assets/svg/New folder/create.svg"
import { ChangeUploadPopup } from "../../../../pages/private/dashboard/workspace/ChangeUploadPopup/ChangeUploadPopup";
import { useMutation } from "@apollo/client";
import {
  StyledHeaderDiv,
  LogoDiv,
  NavDiv,
  Button,
  TitleBar,
  TitleBarSpan,
  Title,
  TitleInput,
  Credit,
  CreditSpan,
  CreditOne,
  IconStar,
} from "./HeaderStyled";
import useCanvasEvent from "hooks/useCanvasEvent";
import NewUiBanner from "../AlertPopup/newUiBanner";
import isWbmUser from "../../../../lib/isWbmUser";
import { useUserData } from "utils/useUserData";
import Cookies from "universal-cookie";
import { isPaidUser } from "lib/isPaidUser";
import RetirementOldUiPopup from "../AlertPopup/retirementOldUiPopup";

export const PrivateHeader = (props: any) => {
  // const [showNewUiBanner, setShowNewUiBanner] = useState(false);
  const [showRetirementPopup, setShowRetirementPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const userData = useUserData();
  //const [title, setTitle] = useState("Untitled");
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const [canvasObj, setCanvasObj] = useState<Array<any>>([]);
  const [btn, setBtn] = useState("");
  const [path, setPath] = useState("");
  const [logoutData] = useMutation(logOut);
  const dispatch = useDispatch();
  const title = useSelector((state: any) => {
    return state.title;
  });
  const canvas = useSelector((state: any) => {
    return state.canvas;
  });
  const toSave = useSelector((state: any) => {
    return state.toSave;
  });
  const compositionId = useSelector((state: any) => {
    return state.compositionId;
  });

  const userType = useSelector((state: any) => {
    return state.user;
  });
  const editor = useSelector((state: any) => {
    return state.editor;
  });

  const openUpgradePopup = useSelector((state: any) => {
    return state.openUpgradePopup;
  });

  const isPremiumTempOpen = useSelector((state: any) => {
    return state.isPremiumTempOpen;
  });

  const cookies = new Cookies();
  const location: any = useLocation();
  const history = useHistory();
  const togglePopup = () => {
    setIsOpen((old) => !old);
  };
  const handleLogout = () => {
    logoutData({
      variables: {
        token: localStorage.getItem("token"),
      },
    });
    window.location.href = "/";
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };
  useEffect(() => {
    for (let i of userData.roles) {
      if (i.name === "admin") {
        setIsAdmin(true);
      }
    }
    // if (!cookies.get("hideBannerFromOldUi")) {
    //   setShowNewUiBanner(true);
    // }
    if (!cookies.get("hideRetirementPopup")) {
      setShowRetirementPopup(true);
    }
  }, []);

  useCanvasEvent(
    "object:added",
    () => {
      setCanvasObj(canvas.getObjects());
    },
    canvas
  );

  useCanvasEvent(
    "object:removed",
    () => {
      setCanvasObj(canvas.getObjects());
    },
    canvas
  );

  const confirmation = (btn: any) => {
    if (btn === "discard") {
      setShowDiscardPopup(false);
      canvas.clear();
      setCanvasObj([]);
      setBtn("discard");
    }
    if (btn === "save") {
      dispatch({
        type: "toSave",
        toSave: true,
      });
      setBtn("save");
      setCanvasObj([]);
    }
  };

  const handleChangeUploadClose = () => {
    dispatch({
      type: "isCanvasLoad",
      isCanvasLoadData: {
        type: "loadCanvas",
        status: false,
      },
    });
    setShowDiscardPopup(!showDiscardPopup);
  };

  useEffect(() => {
    if (btn === "discard" && !canvasObj.length) {
      if (path === "logout") {
        handleLogout();
      } else {
        history.push(path);
      }
      setBtn("");
    }
    if (btn === "save" && !toSave) {
      setShowDiscardPopup(false);
      if (path === "logout") {
        handleLogout();
      } else {
        history.push(path);
      }
      setBtn("");
    }
  }, [btn, canvasObj, toSave]);

  const changeAddress = (path: string) => {
    if (
      canvas &&
      canvas.getObjects() &&
      canvas.getObjects().length > 1 &&
      !compositionId
    ) {
      setShowDiscardPopup(true);
      setPath(path);
    } else {
      if (path === "logout") {
        handleLogout();
      } else {
        history.push(path);
      }
    }
  };

  return (
    <>
      <div>
        <StyledHeaderDiv id="app-header">
          <div style={{ display: "flex" }}>
            {/* <Link style={{ color: "white" }} to="/home"> */}
            <LogoDiv onClick={() => changeAddress("/home")}>
              <Logo style={{ width: "60px", height: "60px" }} />
            </LogoDiv>
            {/* </Link> */}
            {location.pathname !== "/home" &&
              location.pathname !== "/planned-features" && (
                <>
                  <TitleBar>
                    <TitleBarSpan>
                      <Title>Title: </Title>
                      <TitleInput
                        type="text"
                        value={title}
                        onChange={(e: any) => {
                          dispatch({
                            type: "setTitle",
                            title: e.target.value,
                          });
                        }}
                      />
                    </TitleBarSpan>
                  </TitleBar>
                </>
              )}
          </div>
          {editor === "image" &&
            userData.limits.credits !== 0 &&
            useLocation().pathname === "/dashboard/workspace" && (
              <Credit>
                <CreditSpan>
                  <CreditOne>
                    <IconStar>
                      <Star style={{ width: "17px", height: "17px" }} />{" "}
                      {userData.limits.credits}/ {userData.limits.totalCredits}
                    </IconStar>
                  </CreditOne>
                </CreditSpan>
              </Credit>
            )}
          {editor === "video" &&
            userData.limits.videoCredits !== 0 &&
            useLocation().pathname === "/dashboard/workspace" && (
              <Credit>
                <CreditSpan>
                  <CreditOne>
                    <IconStar>
                      <Star style={{ width: "17px", height: "17px" }} />{" "}
                      {userData.limits.videoCredits}/{" "}
                      {userData.limits.totalVideoCredits}
                    </IconStar>
                  </CreditOne>
                </CreditSpan>
              </Credit>
            )}
          {useLocation().pathname.includes("/dashboard/workspace") && (
            <ImageVideoEditorToggle {...props} />
          )}

          <NavDiv>
            {!isAdmin &&
              userType !== "wbm" &&
              !isPaidUser() &&
              location.pathname !== "/planned-features" && (
                <Button onClick={togglePopup}>Upgrade</Button>
              )}
            <AccountMenu
              changeAddress={changeAddress}
              canvasObj={canvasObj}
              {...props}
            />
          </NavDiv>

          {/* {isAdmin && (
            <NavDiv>
              <AccountMenu 
              changeAddress={changeAddress}
             canvasObj={canvasObj}
              {...props} />
            </NavDiv>
          )} */}
        </StyledHeaderDiv>
      </div>
      {isOpen || openUpgradePopup ? (
        <UpgradePayemntPopUp handleClose={togglePopup} />
      ) : (
        <></>
      )}
      {showDiscardPopup && (
        <ChangeUploadPopup
          confirmation={confirmation}
          handleClose={handleChangeUploadClose}
        />
      )}

      {/* removed this banner showing retirement popup instead */}
      {/* {showNewUiBanner && !isPremiumTempOpen ? (
        <NewUiBanner
          showNewUiBanner={showNewUiBanner}
          setShowNewUiBanner={setShowNewUiBanner}
          cookies={cookies}
        />
      ) : (
        <></>
      )} */}
      {showRetirementPopup ? (
        <RetirementOldUiPopup
          open={showRetirementPopup}
          setOpen={setShowRetirementPopup}
          cookies={cookies}
        />
      ) : (
        <></>
      )}
    </>
  );
};

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { URL_CONSTANTS } from "./history";
import { useQuery } from "@tanstack/react-query";
import { getProfile } from "../lib/api/dashoboard";
interface IProps {
  Component?: any;
  component: any;
  path: any;
  exact: boolean;
}

export const PrivateRoute = ({ component: Component, ...rest }: IProps) => {
  const loginCheck = localStorage.getItem("token");
  // const loginCheck = useQuery({
  //   queryKey: ["profileData"],
  //   queryFn: getProfile,
  // });
  // if (loginCheck.isLoading) return <></>;
  // if (loginCheck.isError) {
  //   window.location.href = "/";
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("user");
  //   return <></>;
  // }

  return (
    <Route
      {...rest}
      render={(props) =>
        loginCheck ? (
          <Component {...props} />
        ) : (
          <Redirect to={URL_CONSTANTS.ROOT()} />
        )
      }
    />
  );
};

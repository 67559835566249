import React, { useState, useEffect } from "react";

//import myLogo from "./images/designwizard-logo.png";
import { ReactComponent as Logo } from "../../../../assets/svg/logo.svg";
import UpgradePayemntPopUp from "../../../../pages/private/home/upgradePayment";
import { ImageVideoEditorToggle } from "../ImageVideoEditorToggle/imageVideoEditorToggle";
import { useLocation, Link, useHistory } from "react-router-dom";
import { ReactComponent as Star } from "../../../../assets/svg/New folder/star.svg";
import { useDispatch, useSelector } from "react-redux";
// import {ReactComponent as Create} from "../../../../assets/svg/New folder/create.svg"
import { ChangeUploadPopup } from "../../../../pages/private/dashboard/workspace/ChangeUploadPopup/ChangeUploadPopup";
import useCanvasEvent from "hooks/useCanvasEvent";
import { logOut } from "new-dw-ui/src/lib/api/workspace";

export const PrivateHeader = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const userData: any = localStorage.getItem("user");
  //const [title, setTitle] = useState("Untitled");
  const [showDiscardPopup, setShowDiscardPopup] = useState(false);
  const [canvasObj, setCanvasObj] = useState<Array<any>>([]);
  const [btn, setBtn] = useState("");
  const [path, setPath] = useState("");
  const dispatch = useDispatch();
  const title = useSelector((state: any) => {
    return state.title;
  });
  const canvas = useSelector((state: any) => {
    return state.canvas;
  });
  const toSave = useSelector((state: any) => {
    return state.toSave;
  });
  const compositionId = useSelector((state: any) => {
    return state.compositionId;
  });

  const userType = useSelector((state: any) => {
    return state.user;
  });
  const editor = useSelector((state: any) => {
    return state.newEditor;
  });

  const location: any = useLocation();
  const history = useHistory();
  const togglePopup = () => {
    setIsOpen((old) => !old);
  };
  const handleLogout = () => {
    // e.preventDefault();
    logOut();
    window.location.href = "/";
    // history.push("/");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };
  useEffect(() => {
    const user: any = localStorage.getItem("user");
    const userData = JSON.parse(user);
    for (let i of userData.roles) {
      if (i.name === "admin") {
        setIsAdmin(true);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (designTitle) {
  //     setTitle(designTitle);
  //   }
  // }, [designTitle]);

  // useEffect(() => {
  //   if (canvas) {
  //     canvas.on("object:added", () => {
  //       setCanvasObj(canvas.getObjects());
  //     });
  //     canvas.on("object:removed", () => {
  //       setCanvasObj(canvas.getObjects());
  //     });
  //   }
  // }, [canvas]);

  useCanvasEvent(
    "object:added",
    () => {
      setCanvasObj(canvas.getObjects());
    },
    canvas
  );

  useCanvasEvent(
    "object:removed",
    () => {
      setCanvasObj(canvas.getObjects());
    },
    canvas
  );

  const confirmation = (btn: any) => {
    if (btn === "discard") {
      setShowDiscardPopup(false);
      canvas.clear();
      setCanvasObj([]);
      setBtn("discard");
    }
    if (btn === "save") {
      dispatch({
        type: "toSave",
        toSave: true,
      });
      setBtn("save");
      setCanvasObj([]);
    }
  };

  const handleChangeUploadClose = () => {
    dispatch({
      type: "isCanvasLoad",
      isCanvasLoadData: {
        type: "loadCanvas",
        status: false,
      },
    });
    setShowDiscardPopup(!showDiscardPopup);
  };

  useEffect(() => {
    if (btn === "discard" && !canvasObj.length) {
      if (path === "logout") {
        handleLogout();
      } else {
        history.push(path);
      }
      setBtn("");
    }
    if (btn === "save" && !toSave) {
      setShowDiscardPopup(false);
      if (path === "logout") {
        handleLogout();
      } else {
        history.push(path);
      }
      setBtn("");
    }
  }, [btn, canvasObj, toSave]);

  const changeAddress = (path: string) => {
    if (
      canvas &&
      canvas.getObjects() &&
      canvas.getObjects().length > 1 &&
      !compositionId
    ) {
      setShowDiscardPopup(true);
      setPath(path);
    } else {
      if (path === "logout") {
        handleLogout();
      } else {
        history.push(path);
      }
    }
  };

  return (
    <>
      {/* -------------------NEW DESIGN - New header start ------------- */}
      {/* <NewDwHeader
        changeAddress={changeAddress}
        canvasObj={canvasObj}
        {...props}
      /> */}
      {/* ----------------------NEW DESIGN - New header end ------------- */}

      {/* ------------------------OLD DESIGN - header  start-------------- */}
      {/* <div>
          <StyledHeaderDiv id="app-header">
            <div style={{ display: "flex" }}>
              {/* <Link style={{ color: "white" }} to="/home"> */}
      {/* <LogoDiv onClick={() => changeAddress("/home")}>
                <Logo style={{ width: "60px", height: "60px" }} />
              </LogoDiv> */}
      {/* </Link> */}
      {/* {location.pathname !== "/home" &&
                location.pathname !== "/planned-features" && (
                  <>
                    <TitleBar>
                      <TitleBarSpan>
                        <Title>Title: </Title>
                        <TitleInput
                          type="text"
                          value={title}
                          onChange={(e: any) => {
                            dispatch({
                              type: "setTitle",
                              title: e.target.value,
                            });
                          }}
                        />
                      </TitleBarSpan>
                    </TitleBar>
                  </>
                )}
            </div>
            {editor === "image" &&
              JSON.parse(userData).limits.credits !== 0 &&
              useLocation().pathname === "/dashboard/workspace" && (
                <Credit>
                  <CreditSpan>
                    <CreditOne>
                      <IconStar>
                        <Star style={{ width: "17px", height: "17px" }} />{" "}
                        {JSON.parse(userData).limits.credits}/{" "}
                        {JSON.parse(userData).limits.totalCredits}
                      </IconStar>
                    </CreditOne>
                  </CreditSpan>
                </Credit>
              )}
            {editor === "video" &&
              JSON.parse(userData).limits.videoCredits !== 0 &&
              useLocation().pathname === "/dashboard/workspace" && (
                <Credit>
                  <CreditSpan>
                    <CreditOne>
                      <IconStar>
                        <Star style={{ width: "17px", height: "17px" }} />{" "}
                        {JSON.parse(userData).limits.videoCredits}/{" "}
                        {JSON.parse(userData).limits.totalVideoCredits}
                      </IconStar>
                    </CreditOne>
                  </CreditSpan>
                </Credit>
              )}
            {useLocation().pathname.includes("/dashboard/workspace") && (
              <ImageVideoEditorToggle {...props} />
            )}

            <NavDiv>
              {!isAdmin &&
                userType !== "wbm" &&
                location.pathname !== "/planned-features" && (
                  <Button onClick={togglePopup}>Upgrade</Button>
                )}
              <AccountMenu
                changeAddress={changeAddress}
                canvasObj={canvasObj}
                setAnchorEl={props?.setAnchorEl}
                editor={editor}
                {...props}
              />
            </NavDiv> */}
      {/* {isAdmin && (
            <NavDiv>
              <AccountMenu
              changeAddress={changeAddress}
             canvasObj={canvasObj}
              {...props} />
            </NavDiv>
          )} */}
      {/* </StyledHeaderDiv>
        </div> */}
      {/* ------------------------OLD DESIGN - header  end-------------- */}

      {isOpen && <UpgradePayemntPopUp handleClose={togglePopup} />}
      {showDiscardPopup && (
        <ChangeUploadPopup
          confirmation={confirmation}
          handleClose={handleChangeUploadClose}
        />
      )}
    </>
  );
};

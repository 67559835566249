import { ReactComponent as Template } from "../new-ui/assets/icons/LeftsidePanalButtons/template.svg";
import { ReactComponent as Item } from "../new-ui/assets/icons/LeftsidePanalButtons/myitems.svg";
import { ReactComponent as Element } from "../new-ui/assets/icons/LeftsidePanalButtons/elements.svg";
import { ReactComponent as Texts } from "../new-ui/assets/icons/LeftsidePanalButtons/text.svg";
import { ReactComponent as Branding } from "../new-ui/assets/icons/LeftsidePanalButtons/branding.svg";
import { ReactComponent as FilterButton } from "../new-ui/assets/icons/LeftPanalSearch/icon_in_search_button.svg";
import { ReactComponent as DwLogo } from "../new-ui/assets/icons/Header/dwlogo.svg";
import { ReactComponent as Save } from "../new-ui/assets/icons/Header/save.svg";
import { ReactComponent as Share } from "../new-ui/assets/icons/Header/share.svg";
import { ReactComponent as RightArrow } from "../new-ui/assets/icons/LeftSidePanal/rightArrow.svg";
import { ReactComponent as UpArrow } from "../new-ui/assets/icons/LeftSidePanal/upArrow.svg";
import Nerd from "../new-ui/assets/images/Emojis/nerd.png";
import Happy from "../new-ui/assets/images/Emojis/happy.png";
import Love from "../new-ui/assets/images/Emojis/love.png";
import Wink from "../new-ui/assets/images/Emojis/wink.png";
import TemplateDemo1 from "../new-ui/assets/images/leftside_template/1.png";
import TemplateDemo2 from "../new-ui/assets/images/leftside_template/2.png";
import TemplateDemo3 from "../new-ui/assets/images/leftside_template/3.png";
import TemplateDemo4 from "../new-ui/assets/images/leftside_template/4.png";
import EffectImg from "../new-ui/assets/images/effect.png";
import CanvasTestComposition from "../new-ui/assets/images/canvas/test_composition.png";
import { ReactComponent as DownArrowToolBar } from "../new-ui/assets/icons/LeftSidePanal/downArrow.svg";
import { ReactComponent as AlignRightSvg } from "../new-ui/assets/icons/Toolbar/alignRight.svg";
import { ReactComponent as AlignTopSvg } from "../new-ui/assets/icons/Toolbar/arrowUpWideShort.svg";
import { ReactComponent as ItalicSvg } from "../new-ui/assets/icons/Toolbar/italic.svg";
import { ReactComponent as UnderlineSvg } from "../new-ui/assets/icons/Toolbar/underline.svg";
import { ReactComponent as FontCaseSvg } from "../new-ui/assets/icons/Toolbar/fontCase.svg";
import { ReactComponent as LinkSvg } from "../new-ui/assets/icons/Toolbar/link.svg";
import { ReactComponent as AlignTop } from "../new-ui/assets/icons/Toolbar/align-top.svg";
import { ReactComponent as AlignMiddle } from "../new-ui/assets/icons/Toolbar/align-middle.svg";
import { ReactComponent as AlignBottom } from "../new-ui/assets/icons/Toolbar/align-bottom.svg";
import { ReactComponent as AlignLeft } from "../new-ui/assets/icons/Toolbar/align-left.svg";
import { ReactComponent as AlignCenter } from "../new-ui/assets/icons/Toolbar/align-center.svg";
import { ReactComponent as Grid } from "../new-ui/assets/icons/Toolbar/gridIcon.svg";
import { ReactComponent as CrownSvg } from "../new-ui/assets/icons/AllFonts/crown.svg";
import { ReactComponent as Arrow } from "../new-ui/assets/icons/LeftSidePanal/arrow.svg";

// Elements icons

import { ReactComponent as RectIcon } from "../new-ui/assets/icons/elements_icons/Rectangle 8.svg";
import { ReactComponent as PolygonIcon } from "../new-ui/assets/icons/elements_icons/Polygon 1.svg";
import { ReactComponent as EllipseIcon } from "../new-ui/assets/icons/elements_icons/Ellipse 1.svg";
import { ReactComponent as StarIcon } from "../new-ui/assets/icons/elements_icons/Star 1.svg";
import { ReactComponent as Line1Icon } from "../new-ui/assets/icons/elements_icons/Line 1.svg";
import { ReactComponent as Line2Icon } from "../new-ui/assets/icons/elements_icons/Line 2.svg";
import { ReactComponent as Line3Icon } from "../new-ui/assets/icons/elements_icons/Line 3.svg";
import { ReactComponent as Line4Icon } from "../new-ui/assets/icons/elements_icons/Line 4.svg";
import { ReactComponent as NerdEmojiIcon } from "../new-ui/assets/icons/elements_icons/002-nerd.svg";
import { ReactComponent as HappyEmojiIcon } from "../new-ui/assets/icons/elements_icons/003-happy.svg";
import { ReactComponent as WinkEmojiIcon } from "../new-ui/assets/icons/elements_icons/004-wink.svg";
import { ReactComponent as InLoveEmojiIcon } from "../new-ui/assets/icons/elements_icons/001-in-love.svg";

import { ReactComponent as UploadeSvg } from "../new-ui/assets/icons/Uplode/uploade.svg";
import Logoipsum from "../new-ui/assets/icons/Branding/ipsumLogo.png";
import Result from "../new-ui/assets/icons/Branding/brandingImg1.png";
import { ReactComponent as TransparentBg } from "../new-ui/assets/icons/Branding/bgTransparent.svg";
import transparentImage from "../new-ui/assets/icons/Branding/transparentImage.png";
import BackgroundImage from "../new-ui/assets/images/image-background.png";

import { ReactComponent as LayerIcon } from "../new-ui/assets/icons/Toolbar/layerGroup.svg";
import { ReactComponent as RotateLeftIcon } from "../new-ui/assets/icons/Toolbar/rotateLeft.svg";
import { ReactComponent as RotateRightIcon } from "../new-ui/assets/icons/Toolbar/rotateRight.svg";
import { ReactComponent as arroesRotateIcon } from "../new-ui/assets/icons/Toolbar/arrowsRotate.svg";
import { ReactComponent as RemoveBg } from "../new-ui/assets/icons/Toolbar/removeBackground.svg";
import { ReactComponent as Crop } from "../new-ui/assets/icons/Toolbar/crop.svg";
import { ReactComponent as Delete } from "../new-ui/assets/icons/Toolbar/delete.svg";
import { ReactComponent as TrashIcon } from "../new-ui/assets/icons/Toolbar/trashIcon.svg";
import { ReactComponent as DeleteIcon } from "../new-ui/assets/icons/AllFonts/MyItem/deleteIcon.svg";
import { ReactComponent as AddIcon } from "../new-ui/assets/icons/addIcon.svg";
import { ReactComponent as CloseIcon } from "../new-ui/assets/icons/closeIcon.svg";

import BgDotImage from "../new-ui/assets/images/workspace-bg-dots.png";
import { ReactComponent as AdjustIcon } from "../new-ui/assets/icons/LeftSidePanal/wandIcon.svg";
import { ReactComponent as Brightness } from "../new-ui/assets/icons/LeftSidePanal/brightness.svg";
import { ReactComponent as Contrast } from "../new-ui/assets/icons/LeftSidePanal/contrast.svg";
import { ReactComponent as Saturation } from "../new-ui/assets/icons/LeftSidePanal/saturation.svg";
import { ReactComponent as Temperature } from "../new-ui/assets/icons/LeftSidePanal/temperatur.svg";
import { ReactComponent as Sharpness } from "../new-ui/assets/icons/LeftSidePanal/sharpness.svg";
import { ReactComponent as LeftArrow } from "../new-ui/assets/icons/LeftSidePanal/leftArrow.svg";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import DeleteIconMaterial from "@material-ui/icons/Delete";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import SaveIcon from "@material-ui/icons/Save";
import IosShareIcon from "@material-ui/icons/Share";

import DownloadIcon from "@material-ui/icons/CloudDownload";
import { ReactComponent as Correct } from "../new-ui/assets/icons/LeftSidePanal/correctIcon.svg";
import { ReactComponent as Cross } from "../new-ui/assets/icons/LeftSidePanal/wrongIcon.svg";
import { ReactComponent as Undo } from "../new-ui/assets/icons/LeftSidePanal/undoIcon.svg";
import { ReactComponent as Reposition } from "../new-ui/assets/icons/LeftSidePanal/reposition.svg";
import { ReactComponent as FlipX } from "../new-ui/assets/icons/LeftSidePanal/flipX.svg";
import { ReactComponent as FlipY } from "../new-ui/assets/icons/LeftSidePanal/flipY.svg";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import { ReactComponent as minusIcon } from "../new-ui/assets/icons/minusIcon.svg";
import { ReactComponent as plusIcon } from "../new-ui/assets/icons/plusIcon.svg";

import { ReactComponent as OpacityIcon } from "../new-ui/assets/icons/opacityIcon.svg";
import transparent from "../new-ui/assets/images/transparent.png";

import Background from "../new-ui/assets/images/textboxEffect.png";
import Shadow from "../new-ui/assets/images/shadowEffect.png";
import Lift from "../new-ui/assets/images/liftImg.png";
import hollow from "../new-ui/assets/images/hollowImg.png";
import splice from "../new-ui/assets/images/spliceImg.png";
import outline from "../new-ui/assets/images/outlineEffect.png";
import eco from "../new-ui/assets/images/echoImg.png";
import glitch from "../new-ui/assets/images/GlitchImg.png";
import neon from "../new-ui/assets/images/neonImg.png";
import noShape from "../new-ui/assets/images/noshape.png";
import curve from "../new-ui/assets/images/curve.png";
import { ReactComponent as FBShareIcon } from "../new-ui/assets/icons/social_media/fb_share.svg";
import { ReactComponent as LinkedinShareIcon } from "../new-ui/assets/icons/social_media/linkedin_share.svg";
import { ReactComponent as TwitterShareIcon } from "../new-ui/assets/icons/social_media/twitter_share.svg";
import { ReactComponent as InspiremeIcon } from "../new-ui/assets/icons/inspiremeIcon.svg";
import { ReactComponent as FilpHorizontal } from "../new-ui/assets/icons/flipHorizontal.svg";
import { ReactComponent as FilpVertical } from "../new-ui/assets/icons/flipVertical.svg";
import { ReactComponent as SearchClose } from "../new-ui/assets/icons/searchCloseIcon.svg";
import { ReactComponent as deletesvg } from "../new-ui/assets/icons/deletesvgIcon.svg";
import { ReactComponent as FreeDrawing } from "../new-ui/assets/icons/LeftSidePanal/freeDraw.svg";
import { ReactComponent as GroupIcon } from "../new-ui/assets/icons/google/group-objects-icon.svg";
import { ReactComponent as UngroupIcon } from "../new-ui/assets/icons/google/ungroup-objects-icon.svg";
import { ReactComponent as RightBottomButton } from "../new-ui/assets/icons/RightSideBottomButton1.svg";
import { ReactComponent as AddPage } from "../new-ui/assets/icons/AddPageIcon.svg";
import { ReactComponent as openClose } from "../new-ui/assets/icons/openCloseBtn.svg";
import { ReactComponent as downArrow } from "../new-ui/assets/icons/downArrow.svg";
import { ReactComponent as upArrow } from "../new-ui/assets/icons/UpArrow.svg";
import { ReactComponent as frontPosition } from "../new-ui/assets/icons/Beingfont.svg";
import { ReactComponent as forwardPosition } from "../new-ui/assets/icons/BeingForward.svg";
import { ReactComponent as backPosition } from "../new-ui/assets/icons/sendBack.svg";
import { ReactComponent as backwardPosition } from "../new-ui/assets/icons/sendBackward.svg";
import CrownImg from "new-dw-ui/src/new-ui/assets/images/crown-icon.png"

import {
  LockOpen,
  Lock,
  FileCopy,
  DeleteForever,
  CenterFocusStrong,
  Visibility, 
  VisibilityOff
} from "@material-ui/icons";
export const AllAsset = {
  DwLogo,
  Template,
  Item,
  Element,
  Texts,
  Branding,
  FilterButton,
  Save,
  Share,
  RightArrow,
  Nerd,
  Happy,
  Love,
  Wink,
  TemplateDemo1,
  TemplateDemo2,
  TemplateDemo3,
  TemplateDemo4,
  CanvasTestComposition,
  DownArrowToolBar,
  AlignRightSvg,
  AlignTopSvg,
  ItalicSvg,
  UnderlineSvg,
  FontCaseSvg,
  LinkSvg,
  UpArrow,
  CrownSvg,
  RectIcon,
  PolygonIcon,
  EllipseIcon,
  StarIcon,
  Line1Icon,
  Line2Icon,
  Line3Icon,
  Line4Icon,
  NerdEmojiIcon,
  HappyEmojiIcon,
  WinkEmojiIcon,
  InLoveEmojiIcon,
  UploadeSvg,
  Logoipsum,
  Result,
  TransparentBg,
  transparentImage,
  LayerIcon,
  RotateLeftIcon,
  RotateRightIcon,
  arroesRotateIcon,
  RemoveBg,
  Crop,
  Delete,
  DeleteIcon,
  BgDotImage,
  AddIcon,
  CloseIcon,
  AdjustIcon,
  Brightness,
  Contrast,
  Saturation,
  Temperature,
  Sharpness,
  LeftArrow,
  FormatAlignCenterIcon,
  FormatAlignLeftIcon,
  FormatAlignRightIcon,
  FormatBoldIcon,
  OpacityIcon,
  EffectImg,
  Background,
  Shadow,
  Lift,
  splice,
  hollow,
  outline,
  eco,
  glitch,
  neon,
  noShape,
  curve,
  Correct,
  Cross,
  Undo,
  Reposition,
  FlipX,
  FlipY,
  minusIcon,
  plusIcon,
  AlignTop,
  AlignMiddle,
  AlignBottom,
  AlignLeft,
  AlignCenter,
  DownloadIcon,
  FBShareIcon,
  LinkedinShareIcon,
  TwitterShareIcon,
  InspiremeIcon,
  FilpHorizontal,
  FilpVertical,
  SearchClose,
  deletesvg,
  FreeDrawing,
  GroupIcon,
  UngroupIcon,
  UnlockIcon: LockOpen,
  LockIcon: Lock,
  DuplicateIcon: FileCopy,
  DeleteForever: DeleteForever,
  Grid,
  RightBottomButton,
  AddPage,
  openClose,
  downArrow,
  upArrow,
  CenterFocusStrong,
  DeleteIconMaterial,
  frontPosition,
  forwardPosition,
  backPosition,
  backwardPosition,
  BackgroundImage,
  SaveIcon,
  IosShareIcon,
  transparent,
  Arrow,
  TrashIcon,
  CrownImg,
  VisibilityIcon: Visibility, 
  VisibilityOffIcon: VisibilityOff
};

import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { AppRouting } from "../../../routing";
import { PrivateHeader } from "./Header/header";
import "./style.less";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { AvailableTask } from "../../../admin/AvailableTask/AvailableTask";
import { useDispatch, useSelector } from "react-redux";

export const AdminContext = React.createContext("user");

export const PrivateLayout = () => {
  const location: any = useLocation();
  const [design, setDesign] = useState(0);
  const [isTask, setIsTask] = useState(false);
  const [isAdmin, setIsAdmin] = useState("user");

  const setDesignCount = (value: any) => {
    setDesign(value);
  };
  const dispatch = useDispatch();
  const toggleTaskPopup = () => {
    setIsTask(!isTask);
  };
  React.useEffect(() => {
    const user: any = localStorage.getItem("user");
    const userData = JSON.parse(user);
    for (let i of userData.roles) {
      if (i.name === "admin") {
        setIsAdmin("admin");
      }
    }
  }, []);

  useEffect(() => {
    const user: any = localStorage.getItem("user");
    const userData = JSON.parse(user);
    for (let i of userData.roles) {
      if (i.realm === "wbm") {
        dispatch({
          type: "userType",
          user: "wbm",
        });
      }
    }
  }, []);

  useEffect(() => {
    const printfulCategories: any = [
      {
        categoryId: 221,
        categoryName: "A3 landscape",
        shown: false,
      },
      {
        categoryId: 201,
        categoryName: "A3 portrait",
        shown: false,
      },
      {
        categoryId: 225,
        categoryName: "A4 landscape",
        shown: false,
      },
      {
        categoryId: 211,
        categoryName: "A4 portrait",
        shown: false,
      },
      {
        categoryId: 222,
        categoryName: "A5 landscape",
        shown: false,
      },
      {
        categoryId: 212,
        categoryName: "A5 portrait",
        shown: false,
      },
      {
        categoryId: 223,
        categoryName: "A6 landscape",
        shown: false,
      },
      {
        categoryId: 219,
        categoryName: "A6 portrait",
        shown: false,
      },
      {
        categoryId: 260,
        categoryName: "Book cover",
        shown: false,
      },
      {
        categoryId: 220,
        categoryName: "Business card landscape",
        shown: false,
      },
      {
        categoryId: 235,
        categoryName: "Business card landscape (US)",
        shown: false,
      },
      {
        categoryId: 218,
        categoryName: "Business card portrait",
        shown: false,
      },
      {
        categoryId: 231,
        categoryName: "Business card portrait (US)",
        shown: false,
      },
      {
        categoryId: 246,
        categoryName: "Card invitation square",
        shown: false,
      },
      {
        categoryId: 226,
        categoryName: "DL landscape",
        shown: false,
      },
      {
        categoryId: 213,
        categoryName: "DL portrait",
        shown: false,
      },
      {
        categoryId: 264,
        categoryName: "Email header",
        shown: false,
      },
      {
        categoryId: 203,
        categoryName: "Flyer landscape (US)",
        shown: false,
      },
      {
        categoryId: 230,
        categoryName: "Flyer portrait (US)",
        shown: false,
      },
      {
        categoryId: 236,
        categoryName: "Large photo landscape",
        shown: false,
      },
      {
        categoryId: 257,
        categoryName: "Large photo panoramic",
        shown: false,
      },
      {
        categoryId: 237,
        categoryName: "Large photo portrait",
        shown: false,
      },
      {
        categoryId: 275,
        categoryName: "Large photo square",
        shown: false,
      },
      {
        categoryId: 233,
        categoryName: "Legal landscape (US)",
        shown: false,
      },
      {
        categoryId: 228,
        categoryName: "Legal portrait (US)",
        shown: false,
      },
      {
        categoryId: 232,
        categoryName: "Letter landscape (US) ",
        shown: false,
      },
      {
        categoryId: 227,
        categoryName: "Letter portrait (US) ",
        shown: false,
      },
      {
        categoryId: 234,
        categoryName: "Poster landscape (US)",
        shown: false,
      },
      {
        categoryId: 229,
        categoryName: "Poster portrait (US)",
        shown: false,
      },
      {
        categoryId: 270,
        categoryName: "Presentation",
        shown: false,
      },
    ];

    sessionStorage.setItem(
      "printableCategories",
      JSON.stringify(printfulCategories)
    );
  }, []);

  return (
    <>
      <div>
        <ToastContainer position="top-center" />
        {/* <MyDesignsContext.Provider value={{ design, setDesign }}> */}
        <AdminContext.Provider value={isAdmin}>
          {location.pathname !== "/trial-offer" &&
            location.pathname !== "/choose-business/" &&
            !location.pathname.includes("/dashboard/choose-size/") &&
            location.pathname !== "/dashboard/choose-type" &&
            !location.pathname.includes("/dashboard/choose-template") &&
            location.pathname !== "/reset-password" &&
            location.pathname !== "/taxamo/callback" &&
            location.pathname !== "/mobile-registration" &&
            location.pathname !== "/close-account" && (
              <PrivateHeader
                design={design}
                toggleTaskPopup={toggleTaskPopup}
              />
            )}

          {isTask && <AvailableTask handleTaskClose={toggleTaskPopup} />}
          <AppRouting setDesignCount={setDesignCount} />
        </AdminContext.Provider>
        {/* </MyDesignsContext.Provider> */}
      </div>
    </>
  );
};

import request from "./request";
import isAdminUser from "../isAdminUser";

export const getProfile = (filter: Object) =>
  request()
    .get(`/users/me`)
    .then((res) => res.data);

export const getProfileForGoogleLogin = (filter: any, token: any) =>
  request(token)
    .get(`/users/me?filter=${filter}`)
    .then((res) => res.data);

export const getinAppNotifications = (filter: Object) =>
  request()
    .get(`/inAppNotifications`)
    .then((res) => res.data);

export const getDesignCounts = (where: Object) =>
  request()
    .get(`/users/me/compositions/count?where=${JSON.stringify(where)}`)
    .then((res) => res.data);

export const getTemplateCategories = (filter: Object = {}) =>
  request()
    .get(`/templateCategories?filter=${JSON.stringify(filter)}`)
    .then((res) => res.data);

export const searchTemplateCategories = (data: any) =>
  request()
    .post(`/templatecategories-search`, data)
    .then((res) => res.data);

export const getUserCompositions = (filters: any) => {
  return request()
    .get(`/users/me/compositions?filter=${JSON.stringify(filters)}`)
    .then((res) => res.data);
};

export const getUserCompositionsCustom = (filters: any) => {
  return request()
    .get(`/users/me/compositions-thumb?filter=${JSON.stringify(filters)}`)
    .then((res) => res.data);
};

export const getTemplatesAcctoCats = (filters: any) => {
  return request()
    .get(`/templates/accordingToCategory?filter=${JSON.stringify(filters)}`)
    .then((res) => res.data);
};

export const templateSearch = (data: any) => {
  return request()
    .post(`/templates/search`, data)
    .then((res) => res.data);
};

export const templateOpenSearch = (data: any) => {
  return request()
    .post(`/template-search`, data)
    .then((res) => res.data);
};

export const getUserFolders = (userId: any, filters: any) => {
  return request()
    .get(`/users/${userId}/folders?filter=${JSON.stringify(filters)}`)
    .then((res) => res.data);
};

export const createFolder = (data: any) => {
  return request()
    .post(`/users/${data.userId}/folders`, data.data)
    .then((res) => res.data);
};

export const getCategoryData = (data: any) => {
  return request()
    .post(`/get-category-data`, data)
    .then((res) => res.data);
};

export const getCategoryTemplates = (data: any) => {
  return request()
    .post(`/templates/category-templates`, data)
    .then((res) => res.data);
};

export const getdwFonts = (filters: any) => {
  return request()
    .get(`/dwFonts/fonts?filter=${JSON.stringify(filters)}`)
    .then((res) => res.data);
};

export const getUserFonts = () => {
  return request()
    .get(`/users/me/userFonts`)
    .then((res) => res.data);
};

export const getBrandingKit = (userId: any, filters: any = {}) => {
  return request()
    .get(`users/${userId}/brandingKits?filter=${JSON.stringify(filters)}`)
    .then((res) => res.data);
};

export const brandingKitCount = (userId: any, filters: any = {}) => {
  return request()
    .get(`users/${userId}/brandingKits/count?where=${JSON.stringify(filters)}`)
    .then((res) => res.data);
};

export const uploadUserAssets = (payload: any) => {
  return request()
    .post("/users/me/userUploads", payload)
    .then((res) => res.data);
};

export const updateBrandingKits = (userId: any, payload: any) => {
  return request()
    .put(`users/${userId}/brandingKits/${payload.id}`, payload)
    .then((res) => res.data);
};

export const deleteUserUpload = (payload: any) => {
  return request()
    .post(`/users/me/userUploads/${payload.id}/delete`, payload)
    .then((res) => res.data);
};

export const uploadUserFonts = (payload: any) => {
  return request()
    .post(`users/me/uploadFont`, payload)
    .then((res) => res.data);
};

export const deleteUserFont = (payload: any) => {
  return request()
    .delete(`/users/me/deleteFont/${payload}`)
    .then((res) => res.data);
};

export const userstrashItems = (userId: any, data: any) => {
  return request().post(`/users/${userId}/trashItems`, data);
};

export const waveflowTasks = (filter: any) => {
  return request()
    .get(`/waveflowTasks?filter=${JSON.stringify(filter)}`)
    .then((res) => res.data);
};

export const editUserFolderDetails = (userId: any, data: any) => {
  return request()
    .put(`/users/${userId}/folders/${data.id}`, data)
    .then((res) => res.data);
};

export const bulkUpdateFolderAssets = (payload: any) => {
  return request()
    .post(`/folderAssets/my-assets/bulk`, payload)
    .then((res) => res.data);
};

export const copyFolderAssets = (payload: any) => {
  return request()
    .post(`/folderAssets/my-asset/copy`, payload)
    .then((res) => res.data);
};

export const PostUserComposition = (payload: any) => {
  return request()
    .post(`/compositions`, payload)
    .then((res) => res.data);
};

export const compositionDL = (payload: any) => {
  return request()
    .post(`/users/me/compositionDL`, payload)
    .then((res) => res.data);
};
export const CheckStatus = (payload: any) => {
  return request()
    .post(`/users/me/checkDownloadQueue`, payload)
    .then((res) => res.data);
};

export const updateUserComposition = (payload: any) => {
  const isAdmin = isAdminUser();
  if (isAdmin) {
    // if it's admin, then use the compositions endpoint because we might be updating someone else's composition
    // also we remove the ownerId from the payload to make sure the composition is not updated with the wrong ownerId
    delete payload.ownerId;
    return request()
      .put(`compositions/${payload.compositionId}`, payload)
      .then((res) => res.data);
  }

  return request()
    .put(
      `users/${payload.ownerId}/compositions/${payload.compositionId}`,
      payload
    )
    .then((res) => res.data);
};

export const getUsage = (filter: any) => {
  return request()
    .get(`users/me/usage?filter=${JSON.stringify(filter)}`)
    .then((res) => res.data);
};

export const getUsageCount = () => {
  return request()
    .get(`/users/me/usage/count`)
    .then((res) => res.data);
};

export const downloadDesignImages = (payload: any) => {
  return request()
    .post(`/users/me/downloadImages`, payload)
    .then((res) => res.data);
};

export const publishUserCompositions = (userId: any, payload: any) => {
  return request()
    .post(`/users/${userId}/publishComposition`, payload)
    .then((res) => res.data);
};

export const changeCardDetails = (payload: any) => {
  return request()
    .post("/users/me/stripeChangeCard", payload)
    .then((res) => res.data);
};

export const getAssetsAccToFilter = (type: any, filter: any = {}) => {
  return request().get(`/users/me/${type}?filter=${JSON.stringify(filter)}`);
};

export const getAssetsCountAccToFilter = (type: any, filter: any = {}) => {
  return request().get(
    `/users/me/${type}/count?where=${JSON.stringify(filter)}`
  );
};

export const deleteCompositions = (userId: any, compositionIds: any) => {
  return request().delete(`/users/${userId}/compositions/[${compositionIds}]`);
};

export const deleteUserUploads = (id: any, payload: any) => {
  return request().post(`/users/me/userUploads/${id}/delete`, payload);
};

export const deleteUserFavorites = (id: any) => {
  return request().delete(`/users/me/favoriteDelete/${id}`);
};

export const wfFindTemplates = (payload: any) => {
  return request().post(`/wf/findtemplate`, payload);
};

export const getWFtaskDesign = (compositionId: any) => {
  return request().get(
    `/waveflowTaskDesigns/?filter={"where":{"compositionId":"${compositionId}"},"include":"task"}`
  );
};

export const bulkCreateWftaskDesigns = (payload: any) => {
  return request().post(`/waveflowTaskDesigns/bulkCreate`, payload);
};

export const deleteWFtaskDesign = (compId: any) => {
  return request().delete(`/waveflowTaskDesigns/${compId}`);
};

export const updateWFtaskDesign = (compId: any, payload: any) => {
  return request().put(`/waveflowTaskDesigns/${compId}`, payload);
};

export const updateComposition = (userId: any, compId: any, payload: any) => {
  return request().put(`/users/${userId}/compositions/${compId}`, payload);
};

export const getStripeTransactions = () => {
  return request().get(`/users/me/stripeTransactions`);
};

export const renameCompositions = (payload: any) => {
  return request().patch(`/compositions/rename-compositions`, payload);
};

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Loader } from "../../pages/private/dashboard/workspace/Loader/loaders";
import { useUserUploads } from "new-dw-ui/src/lib/api/workspacequeryhooks";
export const PickUpload = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(false);
  const [data, setData] = useState({});
  const [imageId, setImageId] = useState("");
  const [alreadyUpload, setAlreadyUpload] = useState([]);

  const getUpload = useUserUploads();

  useEffect(() => {
    if (getUpload.isSuccess) {
      setSkip(true);
      setAlreadyUpload(getUpload.data);
    }
  }, [getUpload.isSuccess]);

  const checkUser = () => {
    const user: any = localStorage.getItem("user");
    if (user) return true;
    else return false;
  };
  const checkAlreadyUploaded = (assetId: any) => {
    alreadyUpload.forEach((item: any) => {
      if (item.id === assetId) {
        setData(item);
        return false;
      }
    });
    return true;
  };
  useEffect(() => {
    var location: any, appId: any, assetId: any, imageUrl: any;
    location = decodeURIComponent(window.location.href);
    assetId = location.split("assetId=")[1];
    appId = location.split("appId=")[1].split("&")[0];
    imageUrl = location.split("imageUrl=")[1].split("&")[0];
    if (appId || assetId) {
      sessionStorage.setItem("url", imageUrl);
      sessionStorage.setItem("appId", JSON.stringify(appId));
      sessionStorage.setItem("assetId", JSON.stringify(assetId));
      if (checkUser()) {
        history.push("/dashboard/workspace");
      } else {
        history.push("/signup");
      }
    }
  }, []);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default PickUpload;

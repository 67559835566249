import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

export const PopupBox = styled.div`
  position: fixed;
  background-color: transparent;
  width: 100%;
  height: 100vh;
  top: 72px;
  left: 68px;
  z-index: 999;
`;
export const Boxx = styled.div`
   {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: auto;
    max-height: 100vh;
    margin-top: 15px;
    background: #222841eb;
    border-radius: 0;
    padding: 0;
    overflow: auto;
  }
`;
// export const CloseIcon = styled.span`
//    {
//     content: "x";
//     cursor: pointer;
//     position: fixed;
//     right: 32px;
//     top: 81px;
//     background: #ffffff;
//     color: #000000;
//     width: 25px;
//     height: 25px;
//     border-radius: 50%;
//     line-height: 20px;
//     text-align: center;
//     border: 1px solid #999;
//     font-size: 20px;
//   }
// `;
export const PlanBox = styled.div`
   {
    margin: 0 auto;
  }
`;

export const TutorialWrap = styled.div`
   {
    display: flex;
    flex: 1;
  }
`;

export const LeftSide = styled.div`
  overflow: hidden;
  width: 265px;
  background: #283d4e;
  color: #e5e6e6;
  padding: 20px;
  height: 79vh;
  &:hover {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background-color: #c0c0c0;
    }
    ::-webkit-scrollbar-thumb {
      background: #696969;
      border-radius: 6px;
      height: 20px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #696969;
    }
    overflow-y: overlay;
  }
`;

export const RightSide = styled.div`
  // flex: 1;
  padding: 10px 40px;
  // min-width: 500px;
  width: 75vw;
  height: calc(100vh - 135px) !important;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }

  // &:hover {
  //   ::-webkit-scrollbar {
  //     width: 8px;
  //   }
  //   ::-webkit-scrollbar-track {
  //     background-color: #c0c0c0;
  //   }
  //   ::-webkit-scrollbar-thumb {
  //     background: #696969;
  //     border-radius: 6px;
  //     height: 20px;
  //   }
  //   ::-webkit-scrollbar-thumb:hover {
  //     background: #696969;
  //   }
  //   overflow-y: overlay;
  // }
`;

export const RightSideHead = styled.h1`
   {
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    margin: 0px;
    margin-bottom: 4px;
  }
`;

export const RightSideHeadSpan = styled.span`
   {
    color: #f296a3;
    font-weight: 300;
    display: inline-block;
    margin-left: 30px;
  }
`;

export const TutorialBoxMain = styled.div`
  width: calc(100% - 5%);
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }
  &:hover {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #696969;
      border-radius: 6px;
      height: 20px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #696969;
    }
  }
`;

export const TutorialBox = styled.div`
   {
    margin-bottom: 100px;
  }
`;

export const TutorialHeadTwo = styled.h2`
   {
    color: #f296a3;
    font-size: 1.5em;
    font-weight: 300;
    padding-bottom: 10px;
    border-bottom: 1px solid #f296a3;
  }
`;

export const TutorialVideoUI = styled.ul`
   {
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }
`;

export const TutorialVideoList = styled.li`
   {
    list-style: none;
    display: inline-block;
    width: 80%;
    margin: 0 20px;
  }
`;

export const NewFeature = styled.img`
  width: 100%;
  margin: auto;
  display: block;
`;

export const VideoBox = styled.div`
   {
    width: 100%;
    margin: auto;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
`;

export const Polygon = styled.div`
  transform: rotate(90deg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 70px;
  height: 70px;
  transition: stroke 0.5s ease;
  stroke: #2fc6c0;
`;
export const PolyGon = styled.polygon`
  stroke: #f296a3;
  &:hover {
    stroke: #f296a3;
  }
`;
export const SVG = styled.svg``;
export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.55);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: block;
  transition: background-color 0.5s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.765);
    transition: background-color 0.5s;
  }
  &: hover ${PolyGon} {
    transition: all 0.3s ease-in-out;
    stroke: #2fc6c0;
  }
`;

export const PlayBtn = styled.div`
   {
    transition: transform 0.5s;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const PlayBtnSpan = styled.span`
   {
    margin-top: 15px;
    color: white;
    text-align: center;
    font-size: 16px;
    display: inline-block;
  }
`;

export const SearchBox = styled.div`
   {
    text-align: right;
    margin-right: 70px;
  }
`;

export const GoBackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  display: flex;
  width: 25%;
  transition: color 0.5s;
  &:hover {
    color: rgb(242, 150, 163);
  }
`;

export const GoBack = styled.p`
  margin-top: 0;
  width: 100%;
  text-align: left;
  line-height: 30px;
  display: inline-block;
  vertical-align: super;
  padding-left: 7px;
  font-size: 17.6px;
  font-family: Lato, sans-serif;
`;

export const RecommededVideosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  align-items: center;
  width: 30%;
  height: 61vh;

  margin-right: 25px;
  padding-left: 40px;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  &:hover {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #696969;
      border-radius: 6px;
      height: 20px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #696969;
    }
    overflow-y: auto;
  }
`;

export const BootstrapButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    top: "10px",
    padding: "7px 21px",
    border: "1px solid",
    lineHeight: 1.5,
    background: "#2fc6c0",
    borderColor: "#2fc6c0 ",
    color: "#fff",
    minWidth: "135px",
    minheight: "100px",
    borderRadius: "0px 3px 3px 0px",
    marginTop: "-4px",
    height: "calc(1.5em + 0.75rem + 3px)",
    fontFamily: "Lato, sans-serif",
    left: "-3px",

    backgroundImage:
      "linear-gradient(30deg, #208884, #2ab1ac, #3dcbd1, #67b9db)",
    transition: "border .3s ease,background-color .3s ease",

    "&:hover": {
      backgroundColor: "#1b7470",
      borderColor: "#1b7470",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#099892",
      borderColor: "#099892",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);

export const useStyles = makeStyles(() => ({
  textField: {
    width: "100%",
    padding: " 0.375rem 0.75rem",
    fontWeight: 500,
    borderWidth: "0",

    "& .MuiInput-input": {
      color: "#495057",
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5",
    },
  },
}));

import styled, { keyframes } from "styled-components";
export const loadRing = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`;

export const loadRingTurn = keyframes`
0% {
    transform: rotate(0deg);
    font-size: 1em;
  }
  100% {
    transform: rotate(-360deg);
    font-size: 1.2em;
  }
}
`;

export const star = keyframes`
    0% {
      opacity: 0;
      transform: rotate(0deg);
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
      transform: rotate(360deg);
    }
  }
`;

// export const loadRingTurn = keyframes`
// 0% {

//   }
//   100% {

//   }
// }
// `
export const BigPreloader = styled.div`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #e6e6e8;
  z-index: 99999999;
  animation-duration: 1s;
  animation-fill-mode: both;
`;

export const Wrapper = styled.div`
  position: relative;
  right: 0;
  left: 0;
  margin: auto;
  display: block;
  top: 300px;
  bottom: auto;
  width: 100%;
  height: 200px;
  text-align: center;
  transform: translateY(-20%);
  display: flex;
  justify-content: center;
`;

export const Ngisolatescope = styled.div`
  display: inline-block;
`;

export const Initiatingmagic = styled.div`
  z-index: 99999999;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  opacity: 1;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 200px;
  height: 200px;
  transform: translateY(-35%);
`;

export const Magicwrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
`;
export const RingOne = styled.div`
  position: absolute;
  border-radius: 100%;
  animation-delay: 0;
  animation-duration: 2s;
  animation-name: ${loadRing};
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  &:before {
    content: "";
    border-radius: 100%;
    border: 1px solid #592e6f;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -1px;
    left: -1px;
  }
  &:after {
    top: 1px;
    left: 1px;
    content: "";
    border-radius: 100%;
    border: 1px solid #592e6f;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const RingTwo = styled.div`
  position: absolute;
  border-radius: 100%;
  animation-delay: 0.5s;
  animation-duration: 2s;
  animation-name: ${loadRingTurn};
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  top: -2px;
  left: -2px;
  width: 100%;
  height: 100%;
  transform: rotate(20deg);
  &:before {
    content: "";
    border-radius: 100%;
    border: 1px solid #592e6f;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -2px;
    left: -2px;
  }
  &:after {
    top: 2px;
    left: 2px;
    content: "";
    border-radius: 100%;
    border: 1px solid #592e6f;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const RingThree = styled.div`
  animation-delay: 1s;
  animation-duration: 2s;
  animation-name: ${loadRing};
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  top: 2px;
  left: -2px;
  width: 100%;
  height: 100%;
  transform: rotate(-10deg);
  position: absolute;
  border-radius: 100%;
  &:before {
    content: "";
    border-radius: 100%;
    border: 1px solid #592e6f;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 2px;
    left: -2px;
  }
  &:after {
    top: -2px;
    left: 2px;
    content: "";
    border-radius: 100%;
    border: 1px solid #592e6f;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const Textloading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 70%;
  height: 70%;
  text-align: center;
`;
// export const Pathone= styled.path`
// fill: #592e6f;
//   transform: translate(-12, 21.19);
// `;

export const Starswrapper = styled.div`
  animation-delay: 0.5s;
  animation-duration: 5s;
  animation-name: ${star};
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
`;

export const StarholderOne = styled.div`
  animation-delay: 0.5s;
  animation-duration: 5s;
  animation-name: ${star};
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
`;

export const StarOne = styled.div`
  transform: rotate(35deg) scale(0.12);
  margin: 15px 15px;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: #e87855;
  animation-name: ${star};
  width: 0;
  height: 0;
  border-right: 100px solid transparent;
  border-bottom: 70px solid #e87855;
  border-left: 100px solid transparent;
  &:before {
    border-bottom: 80px solid #e87855;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: -45px;
    left: -65px;
    display: block;
    content: "";
    transform: rotate(-35deg);
  }
  &:after {
    position: absolute;
    display: block;
    color: #e87855;
    top: 3px;
    left: -105px;
    width: 0;
    height: 0;
    border-right: 100px solid transparent;
    border-bottom: 70px solid #e87855;
    border-left: 100px solid transparent;
    transform: rotate(-70deg);
    content: "";
  }
`;

export const StarholderTwo = styled.div`
  animation-delay: 1s;
  animation-duration: 6.67s;
  animation-name: ${star};
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  opacity: 0;
`;

export const StarTwo = styled.div`
  transform: rotate(35deg) scale(0.1);
  margin: 15px 15px;
  top: 0;
  left: 0;
  position: absolute;
  animation-name: ${star};
  display: block;
  color: #e87855;
  width: 0;
  height: 0;
  border-right: 100px solid transparent;
  border-bottom: 70px solid #e87855;
  border-left: 100px solid transparent;
  &:before {
    border-bottom: 80px solid #e87855;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: -45px;
    left: -65px;
    display: block;
    content: "";
    transform: rotate(-35deg);
  }
  &:after {
    position: absolute;
    display: block;
    color: #e87855;
    top: 3px;
    left: -105px;
    width: 0;
    height: 0;
    border-right: 100px solid transparent;
    border-bottom: 70px solid #e87855;
    border-left: 100px solid transparent;
    transform: rotate(-70deg);
    content: "";
  }
`;

export const StarholderThree = styled.div`
  animation-delay: 1.5s;
  animation-duration: 10s;
  animation-name: ${star};
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  opacity: 0;
`;

export const StarThree = styled.div`
  transform: rotate(35deg) scale(0.14);
  margin: 15px 15px;
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  color: #e87855;
  width: 0;
  height: 0;
  border-right: 100px solid transparent;
  border-bottom: 70px solid #e87855;
  border-left: 100px solid transparent;
  &:before {
    border-bottom: 80px solid #e87855;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: -45px;
    left: -65px;
    display: block;
    content: "";
    transform: rotate(-35deg);
  }
  &:after {
    position: absolute;
    display: block;
    color: #e87855;
    top: 3px;
    left: -105px;
    width: 0;
    height: 0;
    border-right: 100px solid transparent;
    border-bottom: 70px solid #e87855;
    border-left: 100px solid transparent;
    transform: rotate(-70deg);
    content: "";
  }
`;

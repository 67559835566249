import { toast } from "react-toastify";
import {
  leftSidebarConstant,
  leftSidebarIdConstant,
} from "../layout/leftside/leftsidebar/utils/constant";
import { handleRefreshInfList } from "../layout/leftside/leftsidepanel/infiniteScroll/utils/constants";
import axios from "axios";

export const WsUpdateIsCanvasEventsFired = (dispatch: any) => {
  dispatch({
    type: "setWsIsCanvasEventsTrigger",
    wsIsCanvasEventsTrigger: Date.now(),
  });
};

export const NEWUIColorPicker = "COLOR_PICKER";

export const ColorPickerLSTypes = {
  COLOR_PICKER: NEWUIColorPicker,
  BORDER: "stroke",
  BORDERWIDTH: "strokewidth",
  BACKGROUND: "backgroundColor",
  FILL: "fill",
  TEXTFILL: "textFill",
  SHADOW_COLOR: "SHADOW_COLOR",
  DRAWLINECOLOR: "LineColor",
  DRAWSHADOWcOLOR: "ShadowColor",
  SHADOWMODE: "ShadowMode",
  CANVASBG: "CanvasBg",
};

export const UpdateColorPickerPanel = (type: any) => {
  return `${ColorPickerLSTypes.COLOR_PICKER} ${type}`;
};

export const ToolbarLeftsidePanelType = {
  TextEffects: "TextEffects",
  ImageEffects: "ImageEffects",
  VideoEffects: "VideoEffects",
  ColorPickerBorder: UpdateColorPickerPanel(ColorPickerLSTypes.BORDER),
  ColorPickerFill: UpdateColorPickerPanel(ColorPickerLSTypes.FILL),
  ColorPickerBackground: UpdateColorPickerPanel(ColorPickerLSTypes.BACKGROUND),
  Border: ColorPickerLSTypes.BORDER,
  Fill: ColorPickerLSTypes.FILL,
  TextFill: ColorPickerLSTypes.TEXTFILL,
  Background: ColorPickerLSTypes.BACKGROUND,
  BorderWidth: UpdateColorPickerPanel(ColorPickerLSTypes.BORDERWIDTH),
  ColorPicker: NEWUIColorPicker,
  ShadowColor: ColorPickerLSTypes.SHADOW_COLOR,
  // ShadowColor: UpdateColorPickerPanel(ColorPickerLSTypes.SHADOW_COLOR),
  DrawLineClr: ColorPickerLSTypes.DRAWLINECOLOR,
  DrawShadowClr: ColorPickerLSTypes.DRAWSHADOWcOLOR,
  ShadowMode: ColorPickerLSTypes.SHADOWMODE,
  CanvasBg: ColorPickerLSTypes.CANVASBG,
  FontFamily: "FontFamily",
  Masking: "Masking",
  Layers: "Layers",
  Animation: "Animation",
  ShapeEffects: "ShapeEffects",
};

export const initilRXState = "";

export const lspSelectedType = {
  LspBar: "LspBar",
  ActiveObject: "activeObject",
  TextEffects: ToolbarLeftsidePanelType?.TextEffects,
};
export const lspSelectedInitState = {
  type: lspSelectedType.LspBar,
};
// export const lspSelectedInitState = ""

export const lspSearchType = {
  noType: undefined,
  ...leftSidebarIdConstant,
};

export const lspSearchTypeInitState = {
  type: lspSearchType.noType,
  searchText: "",
  status: false,
};

export const wsHandleDelete = async (
  payload: any,
  deleteUpload: any,
  dispatch: any,
  msg: string
) => {
  try {
    await deleteUpload.request(payload);
    handleRefreshInfList(dispatch);
    toast.success(msg);
  } catch (err: any) {
    toast.error("Something went wrong");
  }
};

export const refreshList = async (dispatch: any, msg: string) => {
  try {
    handleRefreshInfList(dispatch);
    toast.success(msg);
  } catch (err: any) {
    toast.error("Something went wrong");
  }
};

export const ActiveFontFamily = (canvas: any) => {
  const activeFontFamily = canvas?.getActiveObject()?.fontFamily;
  return activeFontFamily;
};

export const getDesktopFileData = async (data: any, dispatch: any) => {
  try {
    const dataFromUrl = await axios.get(data);
    return dataFromUrl;
  } catch (error) {
    dispatch({
      type: "isCanvasLoad",
      isCanvasLoadData: {
        type: "image",
        status: false,
      },
    });
    toast.error("Something went Wrong");
  }
};

import React, { useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { Button } from "@material-ui/core";
import TutorialPopup from "../../../../pages/private/home/TutorialPopup/tutorialPopup";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  myProfile,
  DesignCounts,
  logOut,
  resendEmail,
  NotificationDetails,
} from "../../../../lib/contexts/Queries";
import UpgradePayemntPopUp from "../../../../pages/private/home/upgradePayment";
import { ReactComponent as Warning } from "../../../../assets/svg/New folder/warning.svg";
import { ProfilePictureContext } from "../../../../App";
import { ToastContainer, toast } from "react-toastify";
import { AdminContext } from "../index";
import { useDispatch, useSelector } from "react-redux";
import { UserNotification } from "../UserNotification/userNotification";
import { NotificationPopup } from "../../../../admin/NotificationPopup/NotificationPopup";
import { ChangeUploadPopup } from "../../../../pages/private/dashboard/workspace/ChangeUploadPopup/ChangeUploadPopup";
import { ReactComponent as UserSvg } from "../../../../assets/svg/User.svg";
import {
  Profile,
  StyledLink,
  StyledLinkTwo,
  StyledLinkThree,
  StyledDiv,
  ProfileContainerHov,
  ProfileHov,
  DesignStyle,
  Activate,
  Content,
  Para,
  Icon,
  NotificationBox,
  TutorialTooltip,
  useStyles,
} from "./AccountMenuStyled";
import { getUniqueId } from "../../../../constants";
import { useUserData } from "utils/useUserData";
import { isPaidUser } from "new-dw-ui/src/lib/isPaidUser";

export default function AccountMenu(props: any) {
  //const myDesign = useContext(MyDesignsContext);
  const classes = useStyles();
  const updateProfile = useContext(ProfilePictureContext);
  const admin = useContext(AdminContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isTutOpen, setIsTutOpen] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isTask, setIsTask] = React.useState(false);
  const [logoutData] = useMutation(logOut);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [propsDetail, setPropsDetail] = React.useState({});
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  const refetchDataList = useSelector((state: any) => {
    return state.refreshListView;
  });
  const refetchDataGrid = useSelector((state: any) => {
    return state.refreshGridView;
  });

  const location: any = useLocation();
  const history = useHistory();

  const isListUpdate = useSelector((state: any) => {
    return state.isListUpdate;
  });
  const profileUpdated = useSelector((state: any) => {
    return state.isProfileUpdated;
  });
  const canvas = useSelector((state: any) => {
    return state.canvas;
  });
  const logoutswitch = useSelector((state: any) => {
    return state.logout;
  });
  const userType = useSelector((state: any) => {
    return state.user;
  });

  const dispatch = useDispatch();

  const userData = useUserData();

  const confirmation = (btn: any) => {
    if (btn === "discard") {
      setShowPopup(false);
      dispatch({
        type: "setTemplateId",
        templateId: "",
      });
      canvas.clear();

      handleLogout();
    }
    if (btn === "save") {
      dispatch({
        type: "logout",
        logout: "saveIt",
      });
    }
  };
  useEffect(() => {
    countRefetch();
  }, [props.design]);
  useEffect(() => {
    profileRefetch();
  }, [updateProfile.updated, profileUpdated]);
  useEffect(() => {
    for (let i of userData.roles) {
      if (i.name === "admin") {
        setIsAdmin(true);
      }
    }
  }, [profileUpdated]);
  useEffect(() => {
    if (location.pathname !== "/dashboard/workspace") {
      setIsOpen(false);
    }
  }, [location.pathname]);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  React.useEffect(() => {
    if (isTutOpen && location.pathname !== "/dashboard/workspace") {
      history.push("/dashboard/workspace");
    }
  }, [isTutOpen]);
  const toggleTutPopUp = () => {
    setIsTutOpen(!isTutOpen);
  };
  const handleChangeUploadClose = () => {
    dispatch({
      type: "isCanvasLoad",
      isCanvasLoadData: {
        type: "loadCanvas",
        status: false,
      },
    });
    setShowPopup(!showPopup);
  };

  React.useEffect(() => {
    let res = userData.POST_users_login
      ? userData.POST_users_login.emailVerified
      : userData.emailVerified;
    if (res !== true) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [profileUpdated]);

  const [resendEmailData] = useLazyQuery(resendEmail, {
    fetchPolicy: "cache-and-network",
    onCompleted: (e: any) => {
      toast.success("Email sent successfully");
    },
    onError: (e: any) => {
      toast.success("some thing went wrong");
    },
  });

  const {
    data: countData,
    loading: countLoading,
    error: countError,
    refetch: countRefetch,
  } = useQuery(DesignCounts, {
    variables: {
      where: {},
      token: `${localStorage.getItem("token")}`,
    },
  });

  useEffect(() => {
    if (isListUpdate) {
      countRefetch();
      dispatch({
        type: "updateList",
        isListUpdate: false,
      });
    }
  }, [isListUpdate]);

  useEffect(() => {
    if (refetchDataList || refetchDataGrid) {
      countRefetch();
    }
  }, [refetchDataList, refetchDataGrid]);

  useEffect(() => {
    if (countData && countData.GET_users_compositions_count) {
      dispatch({
        type: "setCount",
        myDesignCount: countData.GET_users_compositions_count.count,
      });
    }
  }, [countData]);

  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
    refetch: profileRefetch,
  } = useQuery(myProfile, {
    variables: {
      filter: {},
      token: `${localStorage.getItem("token")}`,
    },
  });
  const {
    data: notificationData,
    loading: notificationLoading,
    error: notificationError,
    refetch: notificationRefetch,
  } = useQuery(NotificationDetails, {
    variables: {
      filter: `{}`,
      token: `${localStorage.getItem("token")}`,
    },
  });

  if (notificationLoading) return <>{/* <Loader /> */}</>;
  // if (notificationError) return <>`Error! ${notificationError.message}`</>;
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    // e.preventDefault();
    logoutData({
      variables: {
        token: localStorage.getItem("token"),
      },
    });
    window.location.href = "/";
    // history.push("/");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };
  if (profileLoading || countLoading) return <></>;
  if (profileError || countError) return <></>;
  const image = profileData.GET_users_me.avatar
    ? profileData.GET_users_me.avatar
        .split("url=")[1]
        .substring(
          0,
          profileData.GET_users_me.avatar.split("url=")[1].length - 1
        ) +
      "?_=" +
      Math.random()
    : "";
  // for design counts data.GET_users_compositions_count.count

  const goToWithChildClass = (e: any) => {
    const anchorTag = e.target.querySelector(".customMenuLink");
    if (anchorTag) {
      const path = anchorTag.getAttribute("data-link");
      if (path) {
        history.push(path);
      }
    }
  };

  const LinkParentHover = (e: any) => {
    const childElem = e.target.querySelector(".customMenuLink");

    if (childElem) {
      childElem.focus();
    }
  };
  const LinkParentBlur = (e: any) => {
    const childElem = e.target.querySelector(".customMenuLink");

    if (childElem) {
      childElem.blur();
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          color: "#dde6ff",
          fontSize: "14",
          cursor: "pointer",
        }}
      >
        {!isAdmin && location.pathname !== "/planned-features" && (
          <Typography
            sx={{
              minWidth: 100,
              cursor: "pointer",
              fontSize: "14px",
              marginRight: "0px",
              fontFamily: ['"Lato"', "sans-serif  !important"].join(","),
              "&:hover": {
                color: "#a2acc4",
              },
            }}
            onClick={toggleTutPopUp}
          >
            <DesignStyle>Tutorials</DesignStyle>
          </Typography>
        )}
        {openPopUp && (
          <NotificationPopup
            propsDetail={propsDetail}
            from="accountMenu"
            openPopUp={openPopUp}
            setOpenPopUp={setOpenPopUp}
          />
        )}

        {isAdmin && location.pathname !== "/planned-features" && (
          <Link
            to="/dashboard/workspace/tutorials"
            style={{ textDecoration: "none", color: "#dde6ff" }}
          >
            <Link
              to="/Tutorials-admin"
              style={{ textDecoration: "none", color: "#dde6ff" }}
            >
              <div onClick={toggleTutPopUp}>
                <TutorialTooltip
                  title="Manage Tutorials"
                  placement="bottom-end"
                >
                  <Typography
                    sx={{
                      minWidth: 100,
                      cursor: "pointer",
                      fontSize: "14.4px",
                      marginRight: "0px",
                      fontFamily: ['"Lato"', "sans-serif  !important"].join(
                        ","
                      ),
                      "&:hover": {
                        color: "#a2acc4",
                      },
                    }}
                  >
                    Tutorials
                  </Typography>
                </TutorialTooltip>
              </div>
            </Link>
          </Link>
        )}

        {/* <Link to="/home" style={{ textDecoration: "none", color: "#dde6ff" }}> */}
        <Typography
          sx={{
            minWidth: 100,
            cursor: "pointer",
            fontSize: "14px",
            marginRight: "10px",
            fontFamily: [['"Lato"', "sans-serif "].join(","), "important"].join(
              "!"
            ),
            "&:hover": {
              color: "#a2acc4",
            },
          }}
        >
          <DesignStyle
            active={history.location.pathname === "/home"}
            onClick={() => props.changeAddress("/home")}
          >
            {location.pathname !== "/planned-features" ? (
              <>My Designs ({countData.GET_users_compositions_count.count})</>
            ) : (
              <>My Designs</>
            )}
          </DesignStyle>
        </Typography>
        {/* </Link> */}
        <Tooltip title="Account settings">
          <IconButton
            onClick={
              location.pathname === "/planned-features"
                ? () => props.changeAddress("/dashboard/profile")
                : handleClick
            }
            size="small"
            style={{ color: "#DDE6FF", fontSize: ".9em", cursor: "pointer" }}
          >
            <Avatar
              sx={{
                width: 45,
                height: 45,
                marginRight: 1,
                backgroundColor: "#222841",
                borderColor: "red",
                border: "1px solid",
              }}
            >
              {image ? <Profile src={image} /> : <UserSvg />}
            </Avatar>
            <ProfileContainerHov>
              <ProfileHov>{profileData.GET_users_me.name}</ProfileHov>
              {location.pathname === "/planned-features" ? (
                <></>
              ) : (
                <span
                  className="caret"
                  style={{
                    marginLeft: "10px",
                    // border: "solid #fbfbff",
                    borderWidth: "0 3px 3px 0",
                    display: "inline-block",
                    padding: "3px",
                    transformOrigin: "3px 1px",
                    // transform: "rotate(45deg)",
                    overflow: "visible",
                    transition: "0.3s all ease",
                  }}
                ></span>
              )}
            </ProfileContainerHov>
          </IconButton>
        </Tooltip>
      </Box>

      {isTutOpen && <TutorialPopup handleClose={toggleTutPopUp} />}
      {!isAdmin && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            // elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              borderRadius: 0,
              right: "35px !important",
              top: "52px !important",
              width: "240px",

              "& .MuiAvatar-root": {
                // width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                overflow: "visible",
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
                overflow: "visible",
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <ToastContainer position="top-center" />
          {showWarning && (
            <MenuItem style={{ paddingLeft: "8px" }}>
              <Activate>
                <div style={{ outline: "none", boxSizing: "border-box" }}>
                  <Content>
                    <Para>
                      <Icon>
                        <Warning
                          style={{
                            width: "46px",
                            display: "inline-block",
                            background: "no-repeat",
                            fontSize: "12px",
                            fontFamily: "Lato, sans-serif",
                          }}
                        />
                      </Icon>
                      Activate Your Account - Please <br />
                      click on the link sent to your
                      <br />
                      email address.
                      <a
                        style={{
                          color: "#cd3333",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          resendEmailData({
                            variables: {
                              token: `${localStorage.getItem("token")}`,
                            },
                          });
                        }}
                      >
                        {" "}
                        Resend <br />
                      </a>
                      verification email.
                    </Para>
                  </Content>
                </div>
              </Activate>
            </MenuItem>
          )}

          {notificationData.GET_inAppNotifications.map(
            (item: any, index: any) => {
              // ;
              return (
                moment(item.startAt)
                  .add(item.durationInDays, "days")
                  .diff(moment(), "days") > 0 && (
                  <NotificationBox key={index}>
                    <UserNotification
                      name={item.name}
                      body={item.compactBody}
                      time={moment(item.startAt)
                        .add(item.durationInDays, "days")
                        .diff(moment(), "days")}
                      checkIsPopupOpen={(value: boolean, propsData: any) => {
                        setOpenPopUp(value);
                        setPropsDetail(propsData);
                      }}
                    />
                  </NotificationBox>
                  // <Divider />
                )
              );
            }
          )}

          <MenuItem
            style={{
              backgroundColor: "#fff",
              paddingLeft: "8px",
              minHeight: "48px",
            }}
            onClick={goToWithChildClass}
            onMouseEnter={LinkParentHover}
            onMouseOut={LinkParentBlur}
          >
            <StyledLink
              className="customMenuLink"
              //to="/dashboard/profile"
              data-link="/dashboard/profile"
              onClick={() => props.changeAddress("/dashboard/profile")}
              style={{ fontSize: "16px", fontFamily: "Lato, sans-serif" }}
            >
              My Profile
            </StyledLink>
          </MenuItem>
          {/* TODO: this menu item should be disabled for now */}
          {/*<Divider />*/}
          {/*<MenuItem*/}
          {/*  style={{*/}
          {/*    backgroundColor: "#fff",*/}
          {/*    padding: "8px",*/}
          {/*    minHeight: "48px",*/}
          {/*  }}*/}
          {/*  onClick={goToWithChildClass}*/}
          {/*  onMouseEnter={LinkParentHover}*/}
          {/*  onMouseOut={LinkParentBlur}*/}
          {/*>*/}
          {/*  <StyledLink*/}
          {/*    onClick={() => props.changeAddress("/planned-features")}*/}
          {/*    style={{ fontSize: "16px", fontFamily: "Lato, sans-serif" }}*/}
          {/*  >*/}
          {/*    Vote*/}
          {/*  </StyledLink>*/}
          {/*</MenuItem>*/}
          {userType === "wbm" && (
            <>
              <Divider />
              <MenuItem
                style={{ backgroundColor: "#fff", padding: "8px" }}
                onClick={props.toggleTaskPopup}
                onMouseEnter={LinkParentHover}
                onMouseOut={LinkParentBlur}
              >
                <StyledLinkThree to="#" className="customMenuLink">
                  Available tasks for WF
                </StyledLinkThree>
              </MenuItem>
            </>
          )}
          <Divider />
          <MenuItem
            style={{
              backgroundColor: "#fff",
              paddingLeft: "8px",
              minHeight: "48px",
            }}
            onClick={goToWithChildClass}
            onMouseEnter={LinkParentHover}
            onMouseOut={LinkParentBlur}
          >
            <StyledLink
              className="customMenuLink"
              //to="/dashboard/purchase-history"
              data-link="/dashboard/purchase-history"
              onClick={() => props.changeAddress("/dashboard/purchase-history")}
              style={{ fontSize: "16px", fontFamily: "Lato, sans-serif" }}
            >
              Purchase History
            </StyledLink>
          </MenuItem>
          <Divider />
          <MenuItem
            style={{
              backgroundColor: "#fff",
              paddingLeft: "8px",
              minHeight: "48px",
            }}
            onClick={() => props.changeAddress("logout")}
            onMouseEnter={LinkParentHover}
            onMouseOut={LinkParentBlur}
          >
            <StyledDiv
              // to="#"
              className="customMenuLink"
              style={{ fontSize: "16px", fontFamily: "Lato, sans-serif" }}
            >
              Log Out
            </StyledDiv>
          </MenuItem>

          {!isAdmin && userType !== "wbm" && !isPaidUser() && (
            <>
              <Divider />
              <MenuItem
                style={{
                  backgroundColor: "#fff",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
                onClick={togglePopup}
                onMouseEnter={LinkParentHover}
                onMouseOut={LinkParentBlur}
              >
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    color: "#fbfbff",
                    fontSize: "16px",
                    padding: "9px 7px",
                    // background: "#2d3559",
                    width: "228px",
                    fontFamily: "Lato, sans-serif",
                    textTransform: "capitalize",
                  }}
                  className={`${classes.upgradeBtn} customMenuLink`}
                >
                  Upgrade
                </Button>
              </MenuItem>
            </>
          )}
        </Menu>
      )}

      {isAdmin && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              padding: "10px 20px",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            style={{ backgroundColor: "#fff", padding: "8px" }}
            onClick={goToWithChildClass}
            onMouseEnter={LinkParentHover}
            onMouseOut={LinkParentBlur}
          >
            <StyledLinkTwo
              to="/dashboard/adminNotificationsPanel"
              className="customMenuLink"
              data-link="/dashboard/adminNotificationsPanel"
            >
              Notification
            </StyledLinkTwo>
          </MenuItem>
          <Divider />
          <MenuItem
            style={{ backgroundColor: "#fff", padding: "8px" }}
            onClick={goToWithChildClass}
            onMouseEnter={LinkParentHover}
            onMouseOut={LinkParentBlur}
          >
            <StyledLink
              //to="/dashboard/profile"
              onClick={() => props.changeAddress("/dashboard/profile")}
              className="customMenuLink"
              data-link="/dashboard/profile"
            >
              My Profile
            </StyledLink>
          </MenuItem>
          <Divider />
          <MenuItem
            style={{ backgroundColor: "#fff", padding: "8px" }}
            onClick={props.toggleTaskPopup}
            onMouseEnter={LinkParentHover}
            onMouseOut={LinkParentBlur}
          >
            <StyledLinkThree to="#" className="customMenuLink">
              Available tasks for WF
            </StyledLinkThree>
          </MenuItem>
          <Divider />
          <MenuItem
            style={{ backgroundColor: "#fff", padding: "8px" }}
            onClick={goToWithChildClass}
            onMouseEnter={LinkParentHover}
            onMouseOut={LinkParentBlur}
          >
            <StyledLink
              //to="/dashboard/purchase-history"
              onClick={() => props.changeAddress("/dashboard/purchase-history")}
              className="customMenuLink"
              data-link="/dashboard/purchase-history"
            >
              Purchase History
            </StyledLink>
          </MenuItem>
          <Divider />
          <MenuItem
            style={{ backgroundColor: "#fff", padding: "8px" }}
            onClick={goToWithChildClass}
            onMouseEnter={LinkParentHover}
            onMouseOut={LinkParentBlur}
          >
            <StyledLinkTwo
              to="/dashboard/manage-users"
              className="customMenuLink"
              data-link="/dashboard/manage-users"
            >
              Accounts
            </StyledLinkTwo>
          </MenuItem>
          <Divider />
          <MenuItem
            style={{ backgroundColor: "#fff", padding: "8px" }}
            onClick={goToWithChildClass}
          >
            <StyledLinkTwo
              to="/Review-Asset"
              className="customMenuLink"
              data-link="/Review-Asset"
            >
              Review Assets
            </StyledLinkTwo>
          </MenuItem>
          <Divider />
          <MenuItem
            style={{ backgroundColor: "#fff", padding: "8px" }}
            onClick={goToWithChildClass}
            onMouseEnter={LinkParentHover}
            onMouseOut={LinkParentBlur}
          >
            <StyledLinkTwo
              to="/Landing-page"
              className="customMenuLink"
              data-link="/Landing-page"
            >
              Landing Pages
            </StyledLinkTwo>
          </MenuItem>
          <Divider />
          <MenuItem
            style={{ backgroundColor: "#fff", padding: "8px" }}
            onClick={() => props.changeAddress("logout")}
            onMouseEnter={LinkParentHover}
            onMouseOut={LinkParentBlur}
          >
            <StyledDiv
              //to="#"
              className="customMenuLink"
            >
              Log Out
            </StyledDiv>
          </MenuItem>
          <Divider />
          <MenuItem
            style={{ backgroundColor: "#fff", padding: "8px" }}
          ></MenuItem>
        </Menu>
      )}
      {isOpen && <UpgradePayemntPopUp handleClose={togglePopup} />}
      {showPopup && (
        <ChangeUploadPopup
          confirmation={confirmation}
          handleClose={handleChangeUploadClose}
        />
      )}
    </React.Fragment>
  );
}

export const fontWeightNames: any = {
  thin: 100,
  extralight: 200,
  ultralight: 200,
  light: 300,
  book: 400,
  normal: 400,
  regular: 400,
  italic: 400,
  roman: 400,
  medium: 500,
  semibold: 600,
  demibold: 600,
  bold: 700,
  extrabold: 800,
  ultrabold: 800,
  black: 900,
  heavy: 900,
};

/**
 * Clean font name to be ready for server, remove single quotes
 * @param fontName Name of the font.
 * @param noSpace If true, any white space will be removed too.
 */
export const cleanFontName = (fontName: any, noSpace: any) => {
  if (noSpace) {
    return fontName.replace(/(\s)|(^')|('$)/g, "");
  }
  return fontName.replace(/(^')|('$)/g, "");
};

/**
 * Returns whether the font is a user uploaded font.
 * @param fontFamily Font family name.
 * @return {boolean} True if user uploaded the font, false otherwise.
 */
// export const isUserFont = (fontFamily: any) => {
//   const noSpaceFontFamily = cleanFontName(fontFamily, true);
//   return !editorData.fontFamily[noSpaceFontFamily];
// };

/**
 * Generate the url for loading Google fonts with their different weights and styles.
 * @param fontData Data of the font to load (name, bold, italic).
 * @return {string} String for WebFont load.
 * @see https://github.com/typekit/fvd for info about formats (n4, n7, ...)
 */
export const getFontFamilyString = (fontData: any, fontFamily: any) => {
  if (!fontData) {
    return;
  }

  //   const fontString = fontData.font;
  const fontWeights = [];
  //   if (isUserFont(fontData.font)) {
  //     for (var key in fontData) {
  //       if (key !== "font") {
  //         let isItalic = false;
  //         let weight;
  //         if (key === "italic") {
  //           isItalic = true;
  //           weight = fontWeightNames[key];
  //         } else {
  //           if (key.indexOf("italic") !== -1) {
  //             isItalic = true;
  //           }
  //           const normalFontName = isItalic ? key.replace("italic", "") : key;
  //           weight = fontWeightNames[normalFontName];
  //         }
  //         fontWeights.push(weight + (isItalic ? "i" : ""));
  //       }
  //     }
  //   } else {
  //     for (var key1 in fontData) {
  //       if (key1 !== "font") {
  //         if (fontData[key1]) {
  //           if (key1 === "italic") {
  //             fontWeights.push("400i");
  //           } else if (key1.indexOf("italic") !== -1) {
  //             fontWeights.push(`${fontWeightNames[key1.replace("italic", "")]}i`);
  //           } else {
  //             fontWeights.push(fontWeightNames[key1]);
  //           }
  //         }
  //       }
  //     }
  //   }

  for (var key1 in fontData) {
    if (key1 !== "font") {
      if (fontData[key1]) {
        if (key1 === "italic") {
          fontWeights.push("400i");
        } else if (key1.indexOf("italic") !== -1) {
          fontWeights.push(`${fontWeightNames[key1.replace("italic", "")]}i`);
        } else {
          fontWeights.push(fontWeightNames[key1]);
        }
      }
    }
  }
  return `${fontFamily}:${fontWeights.join(",")}`;
};

import styled, { keyframes } from "styled-components";

export const LeftColumn = styled.div`
  max-width: 50%;
  flex: 1;
  background-color: #2d3559;
  padding: 40px 35px 50px 28px;
`;

export const customBounceAnimation = keyframes`
  0%,
  100% {
    transform: scale(0.79);
  }
  50% {
    transform: scale(1.04);
  }
`;

export const PriceData = styled.div`
  font-weight: bold;
  text-align: center;

  .subscription-details-placeholder {
    color: red;
  }
`;

export const ProductName = styled.p`
  color: #fbfbff;
  font-size: 2.3em;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const PurchaseDetailsName = styled.p`
  color: #fbfbff;
  font-size: 1.3em;
  text-transform: capitalize;
  text-align: left;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const PlanList = styled.li`
  color: #fbfbff;
  font-size: 12px;
  padding: 3px 0;
  font-weight: bold;
  list-style: none;
`;

export const Binding = styled.p`
  font-size: 1.45em;
  color: #fbfbff;
  margin: 0;
  font-weight: bold;
  text-align: center;
`;

export const InfoP = styled.p`
  font-size: 15px;
  color: #fbfbff;
  text-align: center;
  font-weight: bold;
`;

export const InfoSpan = styled.span`
  font-size: 15px;
  color: #fbfbff;
  text-align: center;
  font-weight: bold;
`;

export const ModalDialog = styled.div`
  width: 650px !important;
  max-width: 820px !important;
  overflow: hidden;
  border: 3px solid #2d3559;
  border-radius: 10px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  font-family: Arial, sans-serif !important;
  @media (max-height: 850px), screen and (max-width: 1366px) {
    transform: translateY(-50%) scale(0.75) !important;
    top: 50%;
    margin: auto;
    right: 0;
    left: 0;
  }
  @media (max-height: 950px) {
    position: absolute;
    transform: translateY(-50%) scale(0.85) !important;
    top: 50%;
    margin: auto;
    right: 0;
    left: 0;
  }
  @media (min-width: 992px) {
    max-width: 800px;
  }
  @media (min-width: 576px) {
    max-width: 500px;
    margin: 1.75rem auto;
  }
`;

export const ModalContent = styled.div`
  background-color: white;
  box-shadow: 0 0 50px 0 rgb(0 0 0 / 80%);
  -webkit-border-radius: 0;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
`;

export const Price = styled.p`
  padding-top: 10px;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  font-size: 35px;
  color: #2fc6c0;
  height: 70px;
`;

export const RightColumn = styled.div`
  max-width: 50%;
  flex: 1;
  margin: 50px 0;
  padding-left: 20px;
`;

export const IframWrapper = styled.div`
  position: relative;
  height: 442px;
  width: 300px;
`;

export const IframeLoaded = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 120px;
  text-align: center;
`;

export const Logos = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  bottom: 5rem;
  right: 0.7rem;
`;
export const Loader = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 34px;
  background: rgba(89, 46, 111, 0.5);
  animation: ${customBounceAnimation} 1s infinite ease-in-out;
  position: relative;
  display: inline-block;
`;

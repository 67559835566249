import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/svg/resetpassword/logo.svg";
import { ReactComponent as DesignLogo } from "../../assets/svg/resetpassword/designwizard.svg";
import { ToastContainer, toast } from "react-toastify";
import {
  MainDiv,
  MainBox,
  InnerWrapper,
  Header,
  Section,
  Footer,
  LogoWrapper,
  NewPassword,
  Group,
  Labels,
  Inputs,
  BtnBox,
  ResetBtn,
  FooterSpan,
  ErrorSpan,
  RecaptchaContainer,
} from "./ResetPasswordRouteStyled";
import { useResetPasswordMuatation } from "new-dw-ui/src/lib/api/mutationhooks";
import ReCAPTCHA from "react-google-recaptcha";

export const ResetPassword = () => {
  let tokenUrl = window.location.href;
  let access_token = tokenUrl.split("access_token=")[1];
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [check, setCheck] = useState(false);
  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const resetPassword: any = useResetPasswordMuatation({
    onSuccess: () => {
      toast.success("Password Changed Successfully");
      // redirect to login page
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    },
    onError: () => {
      toast.error("Something went wrong");
    },
  });

  const handleSubmit = async () => {
    setCheck(true);
    if (!password || !confirm || !isCaptchaChecked) {
      if (!isCaptchaChecked) {
        toast.error("Please verify the captcha");
      }
      return;
    }
    if (password && confirm && isCaptchaChecked) {
      if (password !== confirm) {
        toast.error("Passwords Do not match");
        return;
      }
      resetPassword.mutate({
        token: access_token,
        confirmation: confirm,
        password: password,
      });
    }
  };
  return (
    <>
      <MainDiv>
        <ToastContainer position="top-center" />
        <MainBox>
          <InnerWrapper>
            <Header>
              <LogoWrapper>
                <Logo />
                <DesignLogo />
              </LogoWrapper>
              <NewPassword>New Password</NewPassword>
            </Header>
            <Section>
              <form>
                <Group>
                  <Labels>Type your Password</Labels>
                  <br />
                  <Inputs
                    type="password"
                    placeholder="Type your password"
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                  />
                  {check && password.length < 1 && (
                    <ErrorSpan>*This Field is required</ErrorSpan>
                  )}
                </Group>
                <Group>
                  <Labels>Repeat your Password</Labels>
                  <br />
                  <Inputs
                    type="password"
                    placeholder="Repeat your Password"
                    value={confirm}
                    onChange={(e: any) => setConfirm(e.target.value)}
                  />
                  {check && confirm.length < 1 && (
                    <ErrorSpan>*This Field is required</ErrorSpan>
                  )}
                </Group>
                <RecaptchaContainer>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_PUBLIC_KEY}
                  onChange={(e: any) => {
                    setIsCaptchaChecked(true);
                  }}
                />
                </RecaptchaContainer>
                <BtnBox>
                  <ResetBtn
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    RESET
                  </ResetBtn>
                </BtnBox>
              </form>
            </Section>
            <Footer>
              <FooterSpan>
                Copyright © 2022 Wavebreak Media. All rights reserved.
              </FooterSpan>
            </Footer>
          </InnerWrapper>
        </MainBox>
      </MainDiv>
      <MainDiv></MainDiv>
    </>
  );
};

import request from "new-dw-ui/src/lib/api/request";

export const getTemplateService = (payload: any) => {
  return request().post("/templates/search", payload);
};

export const getTemplateSearchService = (payload: any) => {
  return request().post("/template-search", payload);
};

export const uploadDataService = (payload: any) => {
  return request().post("/users/me/userUploads", payload);
};

export const getUploadDataService = () => {
  return request().get("/users/me/userUploads");
};

export const deleteUploadItemService = (payload: any) => {
  return request().post(`users/me/userUploads/${payload}/delete`, {
    confirmed: true,
  });
};

export const getAssetsService = (payload: any) => {
  return request().post(`/assets/search`, payload);
};

export const assetsSearchService = (payload: any) => {
  return request().post(`/asset-search`, payload);
};

export const similarAssetSearchService = (payload: any) => {
  return request().post(`/similar-assets-search`, payload);
};

export const getFavService = (payload: any) => {
  return request().get(`/users/me/favorites`, {
    params: payload,
  });
};

export const getUploadService = (payload: any) => {
  return request().get(`/users/me/userUploads`, {
    params: {
      filter: {
        where: { isTrashed: false },
        order: "firstCreatedAt DESC",
      },
    },
  });
};

export const getUploadFontListService = (payload: any) => {
  return request().get(`/users/me/userFonts`, {
    params: payload,
  });
};

export const uploadFontService = (payload: any) => {
  return request().post("/users/me/uploadFont", payload);
};

export const deleteUploadIFontService = (payload: any) => {
  return request().delete(`users/me/deleteFont/${payload}`);
};

export const deleteFavService = (payload: any) => {
  return request().delete(`users/me/favoriteDelete/${payload}`);
};

export const getTextPharasesService = (payload: any) => {
  return request().post(`/users/textBlocksSearch`, payload);
};

export const getBrandingKit = (userId: any, filters: any = {}) => {
  return request().get(
    `users/${userId}/brandingKits?filter=${JSON.stringify(filters)}`
  );
};

export const getJourneyStepsService = () => {
  return request().get("/journeySteps");
};

export const getJourneyService = () => {
  return request().get("/journeys");
};

export const updateLoggedInUserDataService = (data: any) => {
  return request().put("users/me", data);
};

export const getUserDataService = (data: any) => {
  return request().get("users/me", data);
};

export const updateBrandingKits = (userId: any, payload: any) => {
  return request().put(`users/${userId}/brandingKits/${payload.id}`, payload);
};

export const addBrandingKits = (userId: any, payload: any) => {
  return request().post(`users/${userId}/brandingKits`, payload);
};

export const deleteBrandKits = (userId: any, id: any) => {
  return request().delete(`/users/${userId}/brandingKits/${id}`);
};

export const getDwFonts = (filters: any) => {
  return request().get(`/dwFonts/fonts?filter=${JSON.stringify(filters)}`);
};
export const editTemplatesService = (payload: any, templateId: any) => {
  return request().put(`templates/${templateId}`, payload);
};

export const getFontList = () => {
  return request().get(
    `/dwFonts/fonts?filter=${JSON.stringify({
      where: { active: true },
    })}`
  );
};

export const getMyCompositionsCount = (userId: any, filters: any) => {
  return request().get(
    `/users/${userId}/compositions/count?where=${JSON.stringify(filters)}`
  );
};

export const addAssetsToFolder = (payload: any) => {
  return request().post(`/folderAssets/my-assets`, payload);
};

export const copyFolderAssets = (payload: any) => {
  return request().post(`/folderAssets/my-asset/copy`, payload);
};

export const getUserFolders = (userId: any, filters: any) => {
  return request().get(
    `/users/${userId}/folders?filter=${JSON.stringify(filters)}`
  );
};

export const addUserFolder = (userId: any, data: any) => {
  return request().post(`/users/${userId}/folders`, data);
};

export const editUserFolderDetails = (userId: any, data: any) => {
  return request().put(`/users/${userId}/folders/${data.id}`, data);
};

export const bulkUpdateFolderAssets = (payload: any) => {
  return request().post(`/folderAssets/my-assets/bulk`, payload);
};

export const getMyCompositions = (filters: any) => {
  return request().get(
    `/users/me/compositions?filter=${JSON.stringify(filters)}`
  );
};

export const trashItems = (userId: any, data: any) => {
  return request().post(`/users/${userId}/trashItems`, data);
};

export const brandingKitCount = (userId: any, filters: any = {}) => {
  return request().get(
    `users/${userId}/brandingKits/count?where=${JSON.stringify(filters)}`
  );
};

export const updateWbmComposition = (compositionId: any, payload: any) => {
  return request().post(
    `compositions/updateWbmComposition/${compositionId}`,
    payload
  );
};

export const getUserUploads = (filters: any) => {
  return request().get(
    `/users/me/userUploads?filter=${JSON.stringify(filters)}`
  );
};

export const getFavAssets = (payload: any) => {
  return request().post(`/users/me/userFavorites`, payload);
};

export const getTemplate = (tempId: any) => {
  return request().get(`/templates/${tempId}`);
};

export const getFavCountAccToFilter = (filter: any = {}) => {
  return request().get(
    `/users/me/favorites/count?where=${JSON.stringify(filter)}`
  );
};

export const updateTempStatus = (payload: any) => {
  return request().post(`/templates/updateTempStatus`, payload);
}

export const shapesDefaultValues = [0, 1, 2, 3, 4, 8, 10, 12, 16, 24, 36, 72];
export const ShapesDrawingPencilModeType = {
  Pencil: "Pencil",
  Circle: "Circle",
  Spray: "Spray",
  Pattern: "Pattern",
  Hline: "Hline",
  Vline: "Vline",
  Square: "Square",
  Diamond: "Diamond",
  Texture: "Texture",
};
export const ShapesDrawingPencilMode = [
  { title: "Pencil", value: "Pencil" },
  { title: "Circle", value: "Circle" },
  { title: "Spray", value: "Spray" },
  { title: "Pattern", value: "Pattern" },
  { title: "Hline", value: "Hline" },
  { title: "Vline", value: "Vline" },
  { title: "Square", value: "Square" },
  { title: "Diamond", value: "Diamond" },
  { title: "Texture", value: "Texture" },
];

export const handleObject = (
  canvas: any,
  dispatch: any,
  setBorderColor: any
) => {
  if (canvas) {
    let activeObj = canvas?.getActiveObject();
    dispatch({
      type: "setOutline",
      outlineValue: activeObj?.strokeWidth,
    });
    setBorderColor(activeObj?.stroke);
  }
};

export const handleShapeFillColor = (obj: any) => {
  obj?.setChangingBgColor(true);
  obj?.setChangeBgClrCommon(false);
  obj?.setChangeBorderClrCommon(false);
  obj?.setChangeClrForSameObj("");
  obj?.setShowColorPicker(true);
  obj?.setChangingFontColor(false);
  obj?.setChangingSvgColor("none");
  obj?.setChangingShadowColor(false);
  const activeObj = obj?.canvas?.getActiveObject?.();
  obj?.setCurrentColor(activeObj.fill);
  obj?.dispatch({
    type: "updateChangeColor",
    changingBgColor: "bg",
  });
};

export const handleBorderColor = (Obj: any) => {
  Obj?.setChangingBgColor(false);
  Obj?.setChangeBgClrCommon(false);
  Obj?.setChangeBorderClrCommon(false);
  Obj?.setChangeClrForSameObj("");
  Obj?.setChangingFontColor(false);
  Obj?.setChangingSvgColor("none");
  Obj?.setShowColorPicker(true);
  Obj?.setChangingShadowColor(false);
  let activeObj = Obj?.canvas?.getActiveObject?.();
  Obj?.setCurrentColor(activeObj.stroke);
  Obj?.dispatch({
    type: "updateChangeColor",
    changingBgColor: "border",
  });
};

export const strokewidth = (dispatch: any, e: any) => {
  dispatch({
    type: "setOutline",
    outlineValue: parseInt(e.target.value),
  });
};

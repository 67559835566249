import React, { useEffect } from "react";
import { PrivateLayout } from "./private";
import { PublicLayout } from "./public";
import { isLoggedIn } from "../../lib/authentication";

export const NewLayout = () => {
  useEffect(() => {
    if (isLoggedIn()) {
      document.body.classList.add("new-dw");
    }
    return () => {
      document.body.classList.remove("new-dw");
    };
  }, []);
  return <>{isLoggedIn() ? <PrivateLayout /> : <PublicLayout />}</>;
};

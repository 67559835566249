import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Link } from "react-router-dom";

export const Profile = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 30px;
`;
export const StyledLink = styled.div`
  color: rgb(0, 0, 0);
  text-decoration: none;
  fontfamily: [ '"Lato"', "sans-serif  !important" ].join(",");
  &:hover {
    color: #e87855;
    background-color: #fff;
  }
  &:focus {
    color: #e87855;
    background-color: #fff;
  }
`;

export const StyledLinkTwo = styled(Link)`
  color: #212529;
  text-decoration: none;
  fontsize: 16px;
  fontfamily: [ '"Lato"', "sans-serif  !important" ].join(",");
  &:hover {
    color: #e87855;
    background-color: #fff;
  }
  &:focus {
    color: #e87855;
    background-color: #fff;
  }
`;
export const StyledLinkThree = styled(Link)`
  color: #212529;
  text-decoration: none;
  fontsize: 16px;
  fontfamily: [ '"Lato"', "sans-serif  !important" ].join(",");
  &:hover {
    color: #e87855;
    background-color: #fff;
  }
  &:focus {
    color: #e87855;
    background-color: #fff;
  }
`;

export const StyledDiv = styled.div`
  color: rgb(0, 0, 0);
  text-decoration: none;
  fontfamily: [ '"Lato"', "sans-serif  !important" ].join(",");
  &:hover {
    color: #e87855;
    background-color: #fff;
  }
  &:focus {
    color: #e87855;
    background-color: #fff;
  }
`;

export const ProfileContainerHov = styled.span`
  .caret {
    border: solid #fbfbff;
    transform: rotate(45deg);
  }
  &:hover {
    color: #a2acc4;
    .caret {
      border: solid #a2acc4;

      transform: translate(0, 2px) rotate(45deg);
      -webkit-transform: translate(0, 2px) rotate(45deg);
    }
  }
`;

export const ProfileHov = styled.span`
  font-size: 14.4px;
  font-family: "Lato", "sans-serif";
  color: #dde6ff;
  text-overflow: ellipsis;
  max-width: 120px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.3s color ease;
  position: relative;
`;
export const DesignStyle = styled.span<{ active?: Boolean }>`
  font-size: 14.4px;
  font-family: "Lato", "sans-serif";
  font-weight: ${(props) => (props?.active ? "700" : "400")};
  text-decoration: ${(props) => (props?.active ? "underline" : "none")};
`;
export const Activate = styled.div`
  padding: 0;
  margin-top: -8px;
  cursor: pointer;
  transition: 0.3s color ease;
  background-color: transparent !important;
`;
export const Content = styled.div`
  margin-bottom: 0;
  padding: 15px 15px 15px 10px;
  background-color: #ffe08e;
  color: #444549;
  font-size: 16px;
  font-family: "Lato", sans-serif;
`;
export const Para = styled.div`
  margin: 0;
  position: relative;
  padding-left: 49px;
  white-space: normal;
  font-size: 12px;
  font-family: "Lato", sans-serif;
`;
export const Icon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
`;

export const NotificationBox = styled.div`
  width: 304px;
`;
export const TutorialTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#ffffff",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "#000000",
    width: "90px",
    height: "40px",
    padding: "15px",
    fontSize: "16px",
    lineHeight: "1.5em",
    fontFamily: "lato",
    textAlign: "left",
    cursor: "pointer",
    "&:hover": {
      color: "#da96a3",
    },
  },
}));

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiMenuItem-root": {
      paddingLeft: "8px",
    },
  },
  upgradeBtn: {
    background: "#2d3559",
    "&:hover": {
      background: "#242b48",
    },
  },
}));

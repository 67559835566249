import { ReactComponent as LeftAlign } from "../../../../../../../assets/svg/animationIcons/align-left.svg";
import { ReactComponent as RightAlign } from "../../../../../../../assets/svg/animationIcons/align-right.svg";
import { ReactComponent as CenterAlign } from "../../../../../../../assets/svg/animationIcons/align-center.svg";
import { ReactComponent as Create } from "../../../../../../../assets/svg/textblock/create.svg";
import { ReactComponent as Update } from "../../../../../../../assets/svg/textblock/edit.svg";
import _ from "lodash";
import axios from "axios";
import { toast } from "react-toastify";
import WebFont from "webfontloader";
// import { fontFamilyData } from "../../../fonts";
import { getFontFamilyString } from "./TextFilterAngularMethod";
import {
  changeTolowerCase,
  changeToupperCase,
  changingFontColorConst,
  sentenceCase,
  titleCase,
} from "../../../../../../../constants";
import { useQuery } from "@apollo/client";
import { GetFont } from "lib/contexts/Queries";
import store from "../../../../../../../redux/store";

export const TextFilterIcon = {
  LeftAlign,
  RightAlign,
  CenterAlign,
  Create,
  Update,
};

export const sampleSpecs = {
  width: {
    actual: 420,
    column: 200,
    single: 400,
  },
  heightStep: 50,
  wideAspect: 5,
};

export const scaleBoundingBox = function (boundingBox: any, scaleFactor: any) {
  return {
    aspect: boundingBox.aspect,
    height: Math.ceil(boundingBox.height * scaleFactor),
    width: Math.ceil(boundingBox.width * scaleFactor),
  };
};

/**
 * The css styles used to display the block
 * @param layers
 */
const updateLayerStyles = function (layers: any) {
  _.forEach(layers, (layer) => {
    layer.style = {
      width: `${layer.width}px`,
      height: `${layer.height}px`,
      top: `${layer.top}px`,
      left: `${layer.left}px`,
      textAlign: layer.textAlign,
      fontStyle: layer.fontStyle,
      fontWeight: layer.fontWeight,
      fontSize: Math.floor(layer.fontSize),
      fontFamily: layer.fontFamily,
      lineHeight: layer.lineHeight || 1,
    };

    const transform = [];
    if (layer.flipX) {
      transform.push("scaleX(-1)");
    }
    if (layer.flipY) {
      transform.push("scaleY(-1)");
    }
    if (transform.length) {
      layer.style.transform = transform.join(" ");
    }

    if (/\r|\n/.exec(layer.value)) {
      layer.style.whiteSpace = "pre";
    }
  });
};

export const scaleSampleBlock = function (
  block: any,
  scaleSpecs: any,
  forceWidth: any
) {
  scaleSpecs = scaleSpecs || sampleSpecs;
  if (forceWidth) {
    scaleSpecs = {
      width: {
        column: forceWidth,
      },
      heightStep: sampleSpecs.heightStep || 0,
    };
  }
  const width = scaleSpecs.width;
  const scaledBlock = _.cloneDeep(block);

  let scaleFactor = width.column / scaledBlock.boundingBox.width;
  let boundingBox = scaleBoundingBox(scaledBlock.boundingBox, scaleFactor);

  if (
    !forceWidth &&
    boundingBox.aspect > scaleSpecs.wideAspect &&
    boundingBox.height < scaleSpecs.heightStep
  ) {
    scaleFactor = width.single / scaledBlock.boundingBox.width;
    boundingBox = scaleBoundingBox(block.boundingBox, scaleFactor);
    scaledBlock.isWide = true;
  }

  const layers = scaleLayerProperties(scaledBlock.layers, scaleFactor);
  updateLayerStyles(layers);

  scaledBlock.boundingBox = boundingBox;
  scaledBlock.aspect = boundingBox.aspect;
  scaledBlock.height = boundingBox.height;
  scaledBlock.width = boundingBox.width;
  scaledBlock.layers = layers;

  scaledBlock.containerHeight = boundingBox.height;
  if (scaleSpecs.heightStep) {
    // the container's height will be a multiple of e.g 50
    scaledBlock.containerHeight =
      Math.ceil(scaledBlock.height / scaleSpecs.heightStep) *
      scaleSpecs.heightStep;
  }

  return scaledBlock;
};

export const scaleLayerProperties = function (layers: any, scaleFactor: any) {
  const propertiesToScale = ["fontSize", "height", "width", "top", "left"];
  _.each(layers, (layer) => {
    _.each(propertiesToScale, (property) => {
      layer[property] *= scaleFactor;
    });
  });
  return layers;
};
export const getActiveObjectForText = (canvas: any) => {
  let obj = canvas.getActiveObject();
  let active = [];
  if (obj?.type === "activeSelection") {
    obj?._objects?.map((item: any) => {
      if (item?.type === "text" || item?.type === "textbox") {
        active.push(item);
      }
    });
  } else {
    active.push(obj);
  }
  return active;
};

export const getActiveObjectForRect = (canvas: any) => {
  let obj = canvas.getActiveObject();
  let active = [];
  if (obj?.type === "activeSelection") {
    obj?._objects?.map((item: any) => {
      if (item?.type === "rect") {
        active.push(item);
      }
    });
  } else {
    active.push(obj);
  }
  return active;
};

export const handleTextOperation = (
  canvas: any,
  objects: any,
  operation: any
) => {
  objects.map((item: any) => {
    operation(item, canvas);
    let rect = getBoundingRectFromGroup(canvas, item);
    if (rect) syncroRectText(item, rect);
  });
  canvas.renderAll();
};

export const createSample = async (
  canvas: any,
  getComposition: any,
  editor: any
) => {
  const textBlock = await buildTextBlock(canvas, getComposition, editor).then(
    (textBlock: any) => {
      let elementWidth = window.innerWidth <= 1366 ? 180 * 0.7 : 180;
      let a = scaleSampleBlock(
        textBlock,
        {
          width: {
            actual: elementWidth,
            single: elementWidth,
            column: elementWidth,
          },
          wideAspect: 5,
          heightStep: null,
        },
        false
      );
      return a;
    }
  );
  return textBlock;
};

export const buildTextBlock = async (
  canvas: any,
  getComposition: any,
  editor: any
) => {
  let object: any;
  if (canvas) {
    object = getActiveTextFromGroup(canvas);
  }
  let textLayers: any = await canvasObjectToComposerObject(
    object,
    canvas,
    getComposition,
    editor
  ).then((textLayers: any) => {
    if (!textLayers.length) {
      return false;
    }

    textLayers = normaliseLayerTopLeft(textLayers);
    const boundingBox = calculateBoundingBox(textLayers);

    const content = _.map(
      textLayers,
      (layer) => layer && layer.value && layer.value.trim()
    )
      .join("; ") // join the text from all layers
      .replace(/ +(?= )/g, "");

    return {
      content,
      boundingBox,
      layers: textLayers,
    };
  });
  return textLayers;
};

export const normaliseLayerTopLeft = (layers: any) => {
  // calculate the min left/top values used in the layers
  const minLeft: any = _?.minBy(layers, "left");
  const left = minLeft?.left;
  const minTop: any = _?.minBy(layers, "top");
  const top: any = minTop?.top;

  _.each(layers, (layer) => {
    layer.left -= left;
    layer.top -= top;
  });
  return layers;
};

export const calculateBoundingBox = (layers: any) => {
  const minLeft: any = _?.minBy(layers, "left");
  const left: any = minLeft?.left;
  const minTop: any = _?.minBy(layers, "top");
  const top: any = minTop?.top;
  const boundingBox = {
    width: 0,
    height: 0,
    left: left,
    top: top,
    aspect: 1,
  };
  _.each(layers, (layer: any) => {
    const scaleX = layer.scaleX || 1;
    boundingBox.width = Math.max(
      boundingBox.width,
      layer.left + layer.width * scaleX - left
    );

    const scaleY = layer.scaleY || 1;
    boundingBox.height = Math.max(
      boundingBox.height,
      layer.top + layer.height * scaleY - top
    );
  });
  boundingBox.aspect = boundingBox.width / boundingBox.height;

  return boundingBox;
};

export const canvasObjectToComposerObject = async (
  obj: any,
  canvas: any,
  getComposition: any,
  editor: any
) => {
  let object: any;
  let arrays: any;
  if (editor === "video") {
    arrays = await canvas.getObjects();
    object = arrays?.filter((item: any) => {
      if (item?.uid !== undefined && item?.uid === obj?.uid) {
        return item;
      }
    });
  }
  if (editor === "image") {
    arrays = await getComposition(canvas);
    object = arrays?.objects.filter((item: any) => {
      if (obj && obj.uid && item && item.uid) {
        if (item?.uid !== undefined && item?.uid === obj?.uid) {
          return item;
        }
      }
    });
  }
  let comp_obj = [
    {
      charSpacing: (object[0] && object[0].charSpacing) || "0",
      effects: [],
      flipX: object[0] && object[0].flipX,
      flipY: object[0] && object[0].flipY,
      fontColor: object[0] && object[0].fill,
      fontFamily: object[0] && object[0].fontFamily,
      fontSize: object[0] && object[0].fontSize,
      fontStyle: object[0] && object[0].fontStyle,
      fontWeight: object[0] && object[0].fontWeight,
      height: object[0] && object[0].height,
      left: object[0] && object[0].left,
      lineHeight: object[0] && object[0].lineHeight,
      locked: object[0] && object[0].locked,
      opacity: object[0] && object[0].opacity,
      r: object[0] && object[0].r,
      skewX: object[0] && object[0].skewX,
      skewY: object[0] && object[0].skewY,
      styles: object[0] && object[0].styles,
      textAlign: object[0] && object[0].textAlign,
      textBlockId: undefined,
      thumb: undefined,
      top: object[0] && object[0].top,
      type: object[0] && object[0].type,
      userFont: object[0] && object[0].userFont,
      value: object[0] && object[0].text,
      width: object[0] && object[0].width,
    },
  ];
  return comp_obj;
};

export const getActiveTextFromGroup = (canvas: any) => {
  if (
    canvas &&
    canvas?.getActiveObject() &&
    canvas?.getActiveObject()?.type === "group"
  ) {
    const activeObj = canvas?.getActiveObject()?._objects.find((item: any) => {
      if (item.type === "textbox" || item.type === "text") {
        console.log("item", item);
        return item;
      }
    });

    return activeObj;
  } else {
    return canvas?.getActiveObject();
  }
};

export const getBoundingRectFromGroup = (canvas: any, activeObject: any) => {
  let rect: any;

  if (canvas && activeObject) {
    rect = canvas.getObjects()?.find((item: any) => {
      if (item?.isBoundingBoxOfText && item.textUid === activeObject.uid) {
        return item;
      }
    });
  }
  return rect;
};
export const setPropertyOfActiveTextInGroup = (
  canvas: any,
  active: any,
  object: any
) => {
  if (canvas)
    active.group._objects[active.group.indexOf(active)].set({ object });
};
export const createTextBlock = async (
  sampleBlock: any,
  handleSetTextId: any
) => {
  let tokens: any = localStorage.getItem("token");
  if (!Object.values(sampleBlock).length) {
    return;
  }
  const payload = {
    boundingBox: sampleBlock.boundingBox,
    content: sampleBlock.content,
    layers: sampleBlock.layers,
  };
  await axios
    .post(
      `${process.env.REACT_APP_API_REDIRECT}/users/me/textBlocksCreate/`,
      payload,
      {
        headers: {
          authorization: tokens,
        },
      }
    )
    .then((res: any) => {
      if (res.status === 201) {
        toast.success("Succesfully Created");
        handleSetTextId(res.data.textBlockId);
      }
    });
};

export const updateTextBlock = async (sampleBlock: any, textBlockId: any) => {
  let tokens: any = localStorage.getItem("token");
  sampleBlock.layers[0].textBlockId = textBlockId;
  const payload = {
    boundingBox: sampleBlock.boundingBox,
    content: sampleBlock.content,
    id: textBlockId,
    layers: sampleBlock.layers,
  };
  await axios
    .put(
      `${process.env.REACT_APP_API_REDIRECT}/users/me/textBlocksUpdate/${textBlockId}`,
      payload,
      {
        headers: {
          authorization: tokens,
        },
      }
    )
    .then((res: any) => {
      if (res.status === 201) {
        toast.success("Succesfully Updated");
      }
    });
};

export const loadFont = async (googleFontsList: any) => {
  try {
    const config: any = {};
    if (googleFontsList && googleFontsList.length) {
      config.google = {
        families: [googleFontsList],
      };
    }

    const fontPromise = await WebFont.load(config);
    return fontPromise;
  } catch (error) {}
};

export const handleFontFamily = async (
  fontFamily: any,
  setTextProperties: any
) => {
  let { dwfonts } = store.getState();
  let fontFamilyData: Object[] = dwfonts;
  const fontData = fontFamilyData.filter((item: any) => {
    if (item.font === fontFamily) {
      return item;
    }
  });

  if (fontData[0]) {
    const fontNewData = await getFontFamilyString(fontData[0], fontFamily);
    loadFont(fontNewData).then(() => {
      setTextProperties(fontData[0]);
    });
  } else {
    customUsersList(fontFamily);
  }
};

const customUsersList = async (fontFamily: any) => {
  let userId = localStorage.getItem("userId");
  console.log("fontFamily", fontFamily);
  if (userId) {
    try {
      const config: any = {};
      if (fontFamily) {
        config.custom = {
          families: fontFamily,
          urls: [
            `${
              process.env.REACT_APP_USER_THUMB_SRC + userId
            }/fonts/fonts.css?_=${Date.now()}`,
          ],
        };
        console.log(config, "urls");
      }

      const fontPromise = await WebFont.load(config);
      return fontPromise;
    } catch (error) {}
  }
};

/**
 * Gets style of a current selection/cursor (at the start position)
 * if startIndex or endIndex are not provided, slectionStart or selectionEnd will be used.
 * @param {Number} [startIndex] Start index to get styles at
 * @param {Number} [endIndex] End index to get styles at, if not specified selectionEnd or startIndex + 1
 * @param {Boolean} [complete] get full style or not
 * @return {Array} styles an array with one, zero or more Style objects
 */
export const getSelectionStyles = (sIndex: any, eIndex: any, cssObj: any) => {
  let fontWeight = "normal";
  if (_.isEmpty(cssObj)) {
    return fontWeight;
  }
  let startIndex = sIndex;
  let endIndex = eIndex;
  var styles = [];
  let allStyles = [];

  if (startIndex === endIndex) {
    let value = cssObj[startIndex]?.fontWeight;
    if (value) {
      fontWeight = value;
    } else {
      fontWeight = "normal";
    }
    return fontWeight;
  }

  for (var i = startIndex; i < endIndex; i++) {
    allStyles.push(cssObj[i]);
    if (cssObj[i]) {
      const value = cssObj[i];
      fontWeight = value?.fontWeight;
      styles.push(value);
    } else {
      styles.push({ fontWeight: "normal" });
      fontWeight = "normal";
    }
  }

  if (allStyles.includes(undefined) || _.isEmpty(allStyles)) {
    fontWeight = "normal";
  }

  return fontWeight;
};

export const getFontColorSelectionStyles = (
  sIndex: any,
  eIndex: any,
  cssObj: any,
  defaultColor: any
) => {
  let fill = defaultColor;
  if (_.isEmpty(cssObj)) {
    return fill;
  }
  let startIndex = sIndex;
  let endIndex = eIndex;
  var styles = [];
  let allStyles = [];

  if (startIndex === endIndex) {
    let value = cssObj[startIndex]?.fill;
    if (value) {
      fill = value;
    } else {
      fill = defaultColor;
    }
    return fill;
  }

  for (var i = startIndex; i < endIndex; i++) {
    allStyles.push(cssObj[i]);
    if (cssObj[i]) {
      const value = cssObj[i];
      fill = value?.fill;
      styles.push(value);
    } else {
      styles.push({ fill: defaultColor });
      fill = defaultColor;
    }
  }

  if (allStyles.includes(undefined) || _.isEmpty(allStyles)) {
    fill = defaultColor;
  }

  return fill;
};

const updateWidthWhenResize = (props: any) => {
  // const target = event?.target;
  const { target, canvas, setFontSized } = props;
  const activeObj = getActiveTextFromGroup(canvas);
  if (activeObj) {
    // const newFontSize = Math.round(activeObj?.fontSize * activeObj?.scaleX);
    const newFontSize = (activeObj.fontSize * activeObj.scaleX).toFixed(0);

    activeObj?.set({
      fontSize: newFontSize,
      scaleX: 1,
      scaleY: 1,
    });
    activeObj.setCoords();
    canvas.requestRenderAll();
    setFontSized(newFontSize);
  }
};

const handeTextOnScale = (props: any) => {
  const { target, setFontSized, canvas } = props;
  if (target && target.type === "textbox") {
    target.off("mouseup", (e: any) =>
      updateWidthWhenResize({ canvas, setFontSized, target: e?.target })
    );
    target.on("mouseup", (e: any) =>
      updateWidthWhenResize({ canvas, setFontSized, target: e?.target })
    );
  }
};

export const handleTextboxRescaleWithFontSizeHelper = (props: any) => {
  const { canvas, setFontSized } = props;
  const activeObj = getActiveTextFromGroup(canvas);
  if (activeObj && activeObj.type === "textbox") {
    const onTextObjectDeselected = () => {
      canvas.off("object:scaling", (e: any) =>
        handeTextOnScale({ canvas, setFontSized, target: e?.target })
      );
      activeObj.off("deselected", onTextObjectDeselected);
      activeObj.off("mouseup", (e: any) =>
        updateWidthWhenResize({ canvas, setFontSized, target: e?.target })
      );
    };
    canvas.on("object:scaling", (e: any) =>
      handeTextOnScale({ canvas, setFontSized, target: e?.target })
    );
    activeObj.on("deselected", onTextObjectDeselected);
  }
};

export const onChangeFontColor = (value: any, canvas: any) => {
  const property = "fill";
  const activeObject = getActiveTextFromGroup(canvas);
  if (activeObject.isEditing) {
    const style: any = {};
    style[property] = value;
    activeObject?.setSelectionStyles?.(style);
  } else {
    if (activeObject.fill !== value) {
      let objects = getActiveObjectForText(canvas);
      handleTextOperation(canvas, objects, (item: any, canvas: any) => {
        if (item) {
          item.set(property, value);
          canvas.renderAll();
        }
      });
      // activeObject.set(property, value);

      // Reset individual styles if we are applying one to the whole object
      const styles = activeObject?.getSelectionStyles?.(
        0,
        activeObject?.text?.length
      );
      // If after this change, styles object is empty (all keys contain empty
      // objects), delete it completely
      let deleteStyles = true;
      styles?.forEach((letterStyle: any) => {
        if (!_.isEmpty(letterStyle) && letterStyle.hasOwnProperty(property)) {
          delete letterStyle[property];
        }
        deleteStyles = deleteStyles && _.isEmpty(letterStyle);
      });
      if (deleteStyles) {
        activeObject.styles = {};
      }
    }
  }
  activeObject.setCoords();
  canvas.requestRenderAll();
};

export const onChangeFontWeight = (e: any, setDecoration: any, canvas: any) => {
  console.log("active123");
  const property = "fontWeight";
  const value = e.target.value;
  setDecoration(e.target.value);
  const activeObject = getActiveTextFromGroup(canvas);
  console.log("active123", activeObject);
  if (
    activeObject.isEditing &&
    (activeObject.selectionStart !== 0 ||
      activeObject.selectionEnd !== activeObject.text.length)
  ) {
    const style: any = {};
    style[property] = value;
    activeObject.setSelectionStyles(style);
  } else {
    // Reset individual styles if we are applying one to the whole object
    // If after this change, styles object is empty (all keys contain empty
    // objects), delete it completely

    let objects = getActiveObjectForText(canvas);

    handleTextOperation(canvas, objects, (item: any, canvas: any) => {
      if (item) {
        item.set(property, value);
        canvas.renderAll();
      }
    });
    // activeObject.set(property, value);
    const styles = activeObject?.getSelectionStyles?.(
      0,
      activeObject?.text?.length
    );
    let deleteStyles = true;
    styles?.forEach((letterStyle: any) => {
      if (!_.isEmpty(letterStyle) && letterStyle.hasOwnProperty(property)) {
        delete letterStyle[property];
      }
      deleteStyles = deleteStyles && _.isEmpty(letterStyle);
    });
    if (deleteStyles) {
      activeObject.styles = {};
    }
  }

  // activeObject.setCoords();
  // canvas.requestRenderAll();
};

export const getTextColorIsEditingHelper = (canvas: any) => {
  const activeObj = getActiveTextFromGroup(canvas);
  // const activeObj = canvas.getActiveObject();
  if (activeObj?.type === "activeSelection") {
    let isFillSame = _.every(activeObj?._objects, [
      "fill",
      activeObj?._objects?.[0]?.fill,
    ]);

    let textArr: any = [];
    if (isFillSame) {
      textArr = _.uniqBy(activeObj._objects, "fill");
    }
    let textClr = "";
    if (textArr?.length > 0) {
      textClr = textArr[0]?.fill;
    }
    const styles = activeObj?.styles;
    const selectionStart = activeObj?.selectionStart;
    const selectionEnd = activeObj?.selectionEnd;
    const textColor = getFontColorSelectionStyles(
      selectionStart,
      selectionEnd,
      styles,
      textClr
    );
    if (textColor) {
      return textColor;
    }
  }
  if (
    !_.isEmpty(activeObj) &&
    activeObj.type === "textbox" &&
    activeObj?.isEditing
  ) {
    const styles = activeObj?.styles;
    const selectionStart = activeObj?.selectionStart;
    const selectionEnd = activeObj?.selectionEnd;
    const textColor = getFontColorSelectionStyles(
      selectionStart,
      selectionEnd,
      styles[0],
      activeObj.fill
    );
    if (textColor) {
      return textColor;
    }

    return false;
  }
};

export const getFontWeightForPartialTextHelper = (canvas: any) => {
  const aObj = getActiveTextFromGroup(canvas);
  if (!_.isEmpty(aObj) && aObj.type === "textbox" && aObj?.isEditing) {
    const fontWeight = getSelectionStyles(
      aObj?.selectionStart,
      aObj?.selectionEnd,
      aObj?.styles[0]
    );
    return fontWeight;
  } else {
    return false;
  }
};

export const textBgClrHelper = (obj: any) => {
  const activeObj = obj?.canvas?.getActiveObject();

  // if (activeObj?.type === "activeSelection") {
  //   obj?.setChangeClrForSameObj(obj?.type);
  // } else {
  //   obj?.setChangeClrForSameObj("");
  // }

  obj?.setShowColorPicker(true);
  obj?.setChangingBgColor(false);
  obj?.setChangeBgClrCommon(false);
  obj?.setChangeBorderClrCommon(false);
  obj?.setChangingSvgColor("none");
  obj?.setChangingShadowColor(false);
  obj?.dispatch({
    type: "updateChangeColor",
    changingBgColor:
      obj?.type === "bgColor"
        ? changingFontColorConst.bgColor
        : obj?.type === "font"
        ? "font"
        : changingFontColorConst.strokeColor,
  });
  obj?.setChangingFontColor(
    obj?.type === "bgColor"
      ? changingFontColorConst.bgColor
      : obj?.type === "font"
      ? changingFontColorConst.color
      : changingFontColorConst.strokeColor
  );
};

export const textAlignmentHelper = (obj: any) => {
  obj?.setAlign(
    obj?.align === "left"
      ? "left"
      : obj?.align === "center"
      ? "center"
      : "right"
  );
  // const activeObj = getActiveTextFromGroup(obj?.canvas);
  // if (activeObj?.type === "activeSelection") {
  //   activeObj?._objects?.map((item: any) => {
  //     if (item?.type === "text" || item?.type === "textbox") {
  //       item.set(
  //         "textAlign",
  //         obj?.align === "left"
  //           ? "left"
  //           : obj?.align === "center"
  //           ? "center"
  //           : "right"
  //       );
  //     }
  //   });
  // } else
  // activeObj.set(
  //   "textAlign",
  //   obj?.align === "left"
  //     ? "left"
  //     : obj?.align === "center"
  //     ? "center"
  //     : "right"
  // );
  // obj?.canvas?.renderAll();

  let objects = getActiveObjectForText(obj?.canvas);
  handleTextOperation(obj?.canvas, objects, (item: any, canvas: any) => {
    if (item) {
      item.set(
        "textAlign",
        obj?.align === "left"
          ? "left"
          : obj?.align === "center"
          ? "center"
          : "right"
      );
      canvas.renderAll();
    }
  });
};

export const changeFontSizeHelper = (obj: any) => {
  obj?.setFontSized(parseInt(obj?.value));
  const activeObj = getActiveTextFromGroup(obj?.canvas);
  activeObj?.set("fontSize", obj?.value);
  obj?.canvas?.renderAll();
};

export const textFontHelper = (obj: any) => {
  obj?.setFontFamily(obj?.value);

  let objects = getActiveObjectForText(obj?.canvas);
  handleTextOperation(obj?.canvas, objects, (item: any, canvas: any) => {
    if (item) {
      item.set("fontFamily", obj?.value);
      canvas.renderAll();
    }
  });

  // const activeText = getActiveTextFromGroup(obj?.canvas);
  // let a = { ...activeText };
  // activeText.set("fontFamily", obj?.value);

  // reduceRectWidthWithText(obj?.canvas, activeText);
  // setTimeout(() => obj?.save(), 0);
  // obj?.canvas.requestRenderAll();
};

export const syncroRectText = (text: any, rect: any) => {
  rect?.set({
    width: text.width,
    height: text.height,
    scaleX: text.scaleX,
    scaleY: text.scaleY,
    top: text.top,
    left: text.left,
    angle: text.angle,
  });
};
export const textSizeHelper = (obj: any) => {
  obj?.setFontSized(parseInt(obj?.value));

  const activeObj = getActiveTextFromGroup(obj?.canvas);
  // if (activeObj?.type === "activeSelection") {
  //   activeObj?._objects.map((item: any) => {
  //     if (item?.type === "text" || item?.type === "textbox")
  //       item.set("fontSize", obj?.value);
  //   });
  // } else {
  // activeObj.set("fontSize", obj?.value);
  // if (getBoundingRectFromGroup(obj?.canvas, activeObj)) {
  //   syncroRectText(
  //     activeObj,
  //     getBoundingRectFromGroup(obj?.canvas, activeObj)
  //   );
  // }
  // }
  // obj?.canvas.renderAll();

  let objects = getActiveObjectForText(obj?.canvas);

  handleTextOperation(obj?.canvas, objects, (item: any, canvas: any) => {
    if (item) {
      item.set("fontSize", obj?.value);
      if (getBoundingRectFromGroup(obj?.canvas, activeObj)) {
        syncroRectText(
          activeObj,
          getBoundingRectFromGroup(obj?.canvas, activeObj)
        );
      }
      canvas.renderAll();
    }
  });
};

export const handleCaseChangeHelper = (obj: any) => {
  obj?.setTcase(obj?.value);
  switch (obj?.value) {
    case "UPPER CASE":
      changeToupperCase(obj?.canvas);
      break;
    case "lower case":
      changeTolowerCase(obj?.canvas);
      break;
    case "Sentence case":
      sentenceCase(obj?.canvas);
      break;
    case "Title Case":
      titleCase(obj?.canvas);
      break;
    default:
      break;
  }
};

export const textAlignVertically = (fabricObject: any, align: any) => {
  var text = fabricObject.text;
  //extract lines without line break
  var multiline = text.split(/\n\r|\n|\r/);
  var origText = "";
  for (var i = 0; i < multiline.length; i++) {
    if (multiline[i].trim() != "" && multiline[i].trim() != "\n") {
      if (i > 0 && origText != "") origText += "\n";
      origText += multiline[i];
    }
  }
  //add line breaks based on alignment.
  multiline = origText.split(/\n\r|\n|\r/);
  var curlines = multiline.length;
  var lineheight =
    fabricObject.fontSize *
    fabricObject.lineHeight *
    fabricObject._fontSizeMult;
  var totallines = Math.trunc(fabricObject.height / lineheight);
  var remlines = totallines - curlines;

  if (align === "bottom") {
    var finalText = "";
    for (var i = 0; i < remlines; i++) {
      finalText += "\n";
    }
    finalText += origText;
    fabricObject.text = finalText;
  }
  if (align === "top") {
    fabricObject.text = origText;
  }
  if (align === "center") {
    // eslint-disable-next-line no-redeclare
    var finalText = "";
    for (var i = 0; i < remlines / 2; i++) {
      finalText += "\n";
    }
    finalText += origText;
    fabricObject.text = finalText;
  }
};

// export const getFonts = useQuery(GetFont, {
//   variables: {
//     token: `${localStorage.getItem("token")}`,
//   },
// });

export const loadCustomFont = async (getFonts: any) => {
  let userId = localStorage.getItem("userId");
  getFonts.data.GET_users_me_userFonts.map(async (customFont: any) => {
    if (userId) {
      try {
        const config: any = {};
        if (customFont) {
          config.custom = {
            families: customFont,
            urls: [
              `${
                process.env.REACT_APP_USER_THUMB_SRC + userId
              }/fonts/fonts.css?_=${Date.now()}`,
            ],
          };
        }

        const fontPromise = await WebFont.load(config);
        return fontPromise;
      } catch (error) {}
    }
  });
};

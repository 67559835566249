import React from "react";
import {
  RightSide,
  RightSideContent,
  PreviewOne,
  PreviewOneContent,
  Element,
  ElementHead,
  ElementLabel,
  Para,
} from "./NotificationPopupStyled";

const NotificationRightSidePopup = (props: any) => {
  return (
    <RightSide id="rightPopup">
      <RightSideContent>
        <PreviewOne>
          <PreviewOneContent>
            {props.otherData && props.otherData.name === "giftTime" && (
              <video
                width="126px"
                height="71px"
                //  autoPlay="autoPlay"
                loop
                autoPlay
              >
                <source
                  src={`https://templates.designwizard.com/339351f0-1fe7-11e9-a3ac-e1091dd0d76b.mp4`}
                  type="video/mp4"
                />
              </video>
            )}
          </PreviewOneContent>
        </PreviewOne>
        <Element>
          <ElementHead
            placeholder="notification compact title"
            onChange={(e) => {
              props.setCompactTitle(e.target.value);
            }}
            value={props.compactTitle}
          />
          <ElementLabel
            placeholder="Describe Notification Compact Body here..."
            onChange={(e) => {
              props.setCompactBody(e.target.value);
            }}
            value={props.compactBody}
          />
          <Para>
            <span style={{ color: " red" }}>
              {props.otherData && props.otherData.offersLeft} Offers left
            </span>
            <span style={{ textDecoration: " underline", marginRight: "6px" }}>
              Learn more
            </span>
          </Para>
        </Element>
      </RightSideContent>
    </RightSide>
  );
};

export default NotificationRightSidePopup;

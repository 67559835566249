import request from "./request";

const APIBASEURL: any = process.env.REACT_APP_API;
export const getUserFavorites = (filters: any) => {
  return request()
    .get(`users/me/favorites?filter=${JSON.stringify(filters)}`)
    .then((res) => res.data);
};

export const addFavorite = (payload: any) => {
  return request().post(`/favorites/add`, payload).then((res) => res.data);
};

export const getTemplates = (filter: any) => {
  return request()
    .get(`/templates?filter=${JSON.stringify(filter)}`)
    .then((res) => res.data);
};

export const getCompositions = (filter: any) => {
  return request()
    .get(`/compositions?filter=${JSON.stringify(filter)}`)
    .then((res) => res.data);
};

export const getPrice = (payload: any) => {
  return request()
    .post(`/compositions/getPrice`, payload)
    .then((res) => res.data);
};

export const logOut = () => {
  return request().post(`/users/logout`).then((res) => res.data);
};

export const updateProfile = (payload: any) => {
  return request().put(`/users/me`, payload).then((res) => res.data);
};

export const requestUserData = () => {
  return request().get(`/users/me/requestUserData`).then((res) => res.data);
};

export const closeAccountRequest = () => {
  return request().post(`/users/me/closeAccountRequest`).then((res) => res.data);
};

export const uploadAvatar = (payload: any) => {
  return request().post(`/users/uploadAvatar`, payload).then((res) => res.data);
};

export const updatePassword = (payload: any) => {
  return request()
    .post(`/users/me/updatePassword`, payload)
    .then((res) => res.data);
};

export const claimPromo = (payload: any) => {
  return request().post(`/users/me/claimPromo`, payload).then((res) => res.data);
};

export const emailVerify = () => {
  return request().get(`/users/me/emailVerify`).then((res) => res.data);
};

export const taxamoSubscribe = (payload: any) => {
  return request()
    .post(`/users/me/taxamoSubscribe`, payload)
    .then((res) => res.data);
};

export const resetPassword = (payload: any) => {
  return request()
    .post(`${APIBASEURL}reset-password?access_token=${payload.token}`, {
      password: payload.password,
      confirmation: payload.confirmation,
    })
    .then((res) => res.data);
};

export const confirmEmailVerify = (payload: any) => {
  return request()
    .post(
      `/users/confirmEmailVerify?token=${payload.token}&uid=${payload.uid}`,
      payload
    )
    .then((res) => res.data);
};

export const getuserUploads = () => {
  return request().get(`users/me/userUploads`).then((res) => res.data);
};

export const unlinkProvider = (payload: any) => {
  return request()
    .post(`users/me/unlinkProvider`, payload)
    .then((res) => res.data);
};

export const getTutorialsFAQs = (filter: any) => {
  return request()
    .get(`/tutorialsFAQs?filter=${JSON.stringify(filter)}`)
    .then((res) => res.data);
};

export const getTutorialsCategoriesFAQs = (filter: any) => {
  return request()
    .get(`/tutorialCategoriesFAQs?filter=${JSON.stringify(filter)}`)
    .then((res) => res.data);
};

export const addNotifications = (filter: Object) =>
  request().post(`/inAppNotifications`).then((res) => res.data);

export const updateNotifications = (payload: any) =>
  request()
    .put(`inAppNotifications/${payload.inAppNotificationsId}`, payload)
    .then((res) => res.data);

export const assetSearch = (payload: any) =>
  request().post(`/assets/search`, payload).then((res) => res.data);

export const updateTutorailFAQ = (payload: any) =>
  request()
    .put(`/tutorialsFAQs/${payload.tutorialsFAQsId}`, payload)
    .then((res) => res.data);

export const tutorialCategoriesFAQs = (payload: any) => {
  return request()
    .post(`/tutorialCategoriesFAQs`, payload)
    .then((res) => res.data);
};
export const updateTutorialFAQs = (payload: any) => {
  return request().post(`/tutorialsFAQs`, payload).then((res) => res.data);
};

export const updatetutorialCategoriesFAQs = (payload: any) => {
  return request()
    .put(`/tutorialCategoriesFAQs/${payload.tutorialCategoriesFAQsId}`, payload)
    .then((res) => res.data);
};

export const stripeUnsubscribe = (payload: any) => {
  return request()
    .post(`/users/me/stripeUnsubscribe`, payload)
    .then((res) => res.data);
};

export const userfeedback = (payload: any) => {
  return request().post(`users/me/feedback`, payload).then((res) => res.data);
};

export const categorizations = (filter: any) => {
  return request()
    .get(`/categorizations?filter=${JSON.stringify(filter)}`)
    .then((res) => res.data);
};

export const sendFeedback = (feedback: string, feedbackType: string) => {
  return request()
    .post("feedbacks/send-feedback", { feedback, feedbackType })
    .then((res) => res.data);
};

export const reviewAssets = (payload: any) => {
  return request().put(`/assets/review`, payload);
};

export const postTemplates = (payload: any) => {
  return request().post(`/templates`, payload);
};

export const editTemplates = (tempId: any, payload: any) => {
  return request().put(`/templates/${tempId}`, payload);
};

export const getTemplateData = (tempId: any) => {
  return request().get(`/templates/${tempId}`);
};

export const getWbmComposition = (compId: any) => {
  return request().get(`/compositions/getWbmComposition/${compId}`);
};

export const uploadAssets = (payload: any) => {
  return request().post("/users/me/userUploads", payload);
};

export const checkUploadReady = (id: any) => {
  return request().get(`/users/uploadReady/${id}`);
};

export const getFavsAccToFilter = (filter: any) => {
  return request()
    .get(`/favorites/get-filtered?filter=${JSON.stringify(filter)}`)
    .then((res) => res.data);
}

import { useMutation } from "@apollo/client";
import { logOut } from "lib/contexts/Queries";
import { useEffect } from "react";

export function useUserData() {
  const [logoutData] = useMutation(logOut);

  const userData: any = localStorage.getItem("user");
  const userDataJSON = JSON.parse(userData);

  const handleLogout = () => {
    logoutData({
      variables: {
        token: localStorage.getItem("token"),
      },
    });
    window.location.href = "/";
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  if (Array.isArray(userDataJSON) && userDataJSON.length === 0) {
    handleLogout();
  }

  useEffect(() => {
    if (
      !userDataJSON ||
      !userDataJSON.hasOwnProperty("email") ||
      !userDataJSON.hasOwnProperty("preferences") ||
      !userDataJSON.hasOwnProperty("limits")
    ) {
      handleLogout();
    }
  }, [userDataJSON]);

  return userDataJSON;
}

import axios from "axios";
import _ from "lodash";

import {
  getActiveObjectForText,
  getActiveTextFromGroup,
  handleTextOperation,
} from "pages/private/dashboard/workspace/Filters/TextFilter/utils/TextFilterConstant";
import { object } from "prop-types";
import { toast } from "react-toastify";
import { AllAsset } from "new-dw-ui/src/new-ui/AllAsset";

export const countries = [
  "Afganistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire",
  "Bosnia & Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Ter",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Canary Islands",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Channel Islands",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos Island",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote DIvoire",
  "Croatia",
  "Cuba",
  "Curaco",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Ter",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Great Britain",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guyana",
  "Haiti",
  "Hawaii",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "Indonesia",
  "India",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea North",
  "Korea Sout",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malaysia",
  "Malawi",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Midway Islands",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Nambia",
  "Nauru",
  "Nepal",
  "Netherland Antilles",
  "Netherlands",
  "Nevis",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau Island",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Phillipines",
  "Pitcairn Island",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of Montenegro",
  "Republic of Serbia",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "St Barthelemy",
  "St Eustatius",
  "St Helena",
  "St Kitts-Nevis",
  "St Lucia",
  "St Maarten",
  "St Pierre & Miquelon",
  "St Vincent & Grenadines",
  "Saipan",
  "Samoa",
  "Samoa American",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tahiti",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos Is",
  "Tuvalu",
  "Uganda",
  "United Kingdom",
  "Ukraine",
  "United Arab Erimates",
  "United States of America",
  "Uraguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City State",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (Brit)",
  "Virgin Islands (USA)",
  "Wake Island",
  "Wallis & Futana Is",
  "Yemen",
  "Zaire",
  "Zambia",
  "Zimbabwe",
];

export const VIDEO_IDS = [10, 12, 13, 113, 114];

export const videoCategory = {
  displayHeight: "1080 px",
  displayWidth: "1920 px",
  dpi: 72,
  featuredTemplate: "cdfbfb60-89b1-11e8-82ec-dd629db35bef",
  height: 1080,
  id: 10,
  name: "Video",
  order: 14,
  parents: [{ id: 113 }],
  visible: true,
  webQualityDownload: true,
  width: 1920,
};

export const compositionStatus = [
  {
    id: 1,
    name: "Draft",
  },
  {
    id: 2,
    name: "Ready for review",
  },
  {
    id: 3,
    name: "Approved",
    access: "CHANGE_TEMPLATE_STATUS",
  },
  {
    id: 4,
    name: "Not Approved",
    access: "CHANGE_TEMPLATE_STATUS",
  },
  {
    id: 5,
    name: "Exported for WBM",
  },
  {
    id: 6,
    name: "Tagged",
  },
  {
    id: 7,
    name: "Sent to WF",
  },
];

export const updateCategory = async (
  dispatch: any,
  width: any,
  height: any,
  name: any,
  id: any
) => {
  dispatch({
    type: "setCanvasWidth",
    categoryWidth: width,
  });
  dispatch({
    type: "setCanvasHeight",
    categoryHeight: height,
  });
  dispatch({
    type: "setCanvasName",
    categoryName: name,
  });
  dispatch({
    type: "setResizeId",
    resizeTitleId: id,
  });
};
export const proPlan = [
  {
    title: "60 image design downloads per month",
  },
  {
    title: "Image uploads",
  },
  {
    title: "Font uploads",
  },
  {
    title: "1GB storage",
  },
];

export const downloadPlan = [
  {
    question: "Is my payment secure?",
    answer:
      "Yes. We use Stripe, one of the world leaders in online payments to process our payments.",
  },
  {
    question: "Can I change plans?",
    answer:
      "Sure! You can upgrade to an even better value plan via your profile.",
  },
];

export const DataUrl = (
  canvas: any,
  clippingHeight: any,
  clippingWidth: any,
  currentHeight: any,
  currentWidth: any,
  removeGrid: any,
  drawGrid: any,
  grid: any,
  gridShow: Boolean
) => {
  const urlObj = {
    format: "jpeg",
    left: 250 * (clippingHeight / currentHeight),
    top: 250 * (clippingHeight / currentHeight),
    width: clippingWidth,
    height: clippingHeight,
    quality: 0.75,
  };
  //  removeGrid
  setOpacity(0, canvas);
  const canvasThumb = canvas.toDataURL(urlObj);
  if (gridShow) {
    //drawGrid
    setOpacity(1, canvas);
  }
  canvas.requestRenderAll();
  return canvasThumb;
};

export const getExtension = (filename: any) => {
  var parts = filename.split(".");
  return parts[parts.length - 1];
};

export const fileTypeConst = {
  image: "Image",
  video: "Video",
};

export const getFileTypeByFileName = (filename: any) => {
  var ext = getExtension(filename).toLowerCase();
  let fileType: any = false;
  if (
    ext === "jpg" ||
    ext === "gif" ||
    ext === "bmp" ||
    ext === "png" ||
    ext === "jpeg"
  ) {
    fileType = fileTypeConst.image;
  } else if (
    ext === "m4v" ||
    ext === "avi" ||
    ext === "mpg" ||
    ext === "mp4" ||
    ext === "mov" ||
    ext === "mp4"
  ) {
    fileType = fileTypeConst.video;
  }

  return fileType;
};

export const getUnqiueArrayByKey = (data: any, key: any) => {
  if (Array.isArray(data)) {
    const returnValue = [
      ...new Map(data.map((item) => [item[key], item])).values(),
    ];
    return returnValue;
  }

  return [];
};

export const CopyRightWaveBreakText =
  "Copyright © 2022 Wavebreak Media. All rights reserved.";

export const getFilteredFont = (font: any) => {
  let filtered: any = _.sortBy(font, [
    function (o) {
      return o.font;
    },
  ]);
  return filtered;
};

export const sortFavItem = (favArray: any) => {
  let filtered: any = _.orderBy(favArray, ["id"], "desc");
  return filtered;
};

const setOpacity = (op: any, canvas: any) => {
  if (canvas) {
    var objects = canvas.getObjects("line");
    if (objects && object.length > 0) {
      for (let i in objects) {
        if (objects[i].name && objects[i].name === "gridLine")
          objects[i].set({
            opacity: op,
          });
        canvas.requestRenderAll();
      }
    }
  }
};

export const changeToupperCase = (canvas: any) => {
  var active = getActiveTextFromGroup(canvas);
  // if (!active || !active.text) return;
  if (!active) return;

  if (active.isEditing) {
    active.hiddenTextarea.value = active.hiddenTextarea.value.toUpperCase();
    active.updateFromTextArea();
    canvas.requestRenderAll();
    return;
  }
  var text;
  let objects = getActiveObjectForText(canvas);
  handleTextOperation(canvas, objects, (item: any, canvas: any) => {
    if (item) {
      text = item.text;
      item.text = text.toUpperCase();
      canvas.renderAll();
    }
  });
};

export const changeTolowerCase = (canvas: any) => {
  var active = getActiveTextFromGroup(canvas);
  // if (!active || !active.text) return;
  if (!active) return;

  //   if (active.isEditing) {
  //     active.hiddenTextarea.value = active.hiddenTextarea.value.toLowerCase();
  //     active.updateFromTextArea();
  //     canvas.requestRenderAll();
  //     return;
  //   }

  var text;
  let objects = getActiveObjectForText(canvas);
  handleTextOperation(canvas, objects, (item: any, canvas: any) => {
    if (item) {
      text = item.text;
      item.text = text.toLowerCase();
      canvas.renderAll();
    }
  });
};

export const sentenceCase = (canvas: any) => {
  var active = getActiveTextFromGroup(canvas);
  // if (!active || !active.text) return;
  if (!active) return;

  // if (active.isEditing) {
  //   active.hiddenTextarea.value = active.hiddenTextarea.value.toLoweCase();
  //   active.updateFromTextArea();
  //   canvas.requestRenderAll();
  //   return;
  // }
  var text;
  let objects = getActiveObjectForText(canvas);
  handleTextOperation(canvas, objects, (item: any, canvas: any) => {
    if (item) {
      text = active.text?.toLowerCase?.();
      active.text = text?.charAt(0)?.toUpperCase?.() + text?.slice(1);
      canvas.renderAll();
    }
  });
};

export const titleCase = (canvas: any) => {
  var active = getActiveTextFromGroup(canvas);
  //if (!active || !active.text) return;
  if (!active) return;

  // if (active.isEditing) {
  //   active.hiddenTextarea.value = active.hiddenTextarea.value.tolowerCase();
  //   active.updateFromTextArea();
  //   canvas.requestRenderAll();
  //   return;
  // }
  var text;
  let objects = getActiveObjectForText(canvas);
  handleTextOperation(canvas, objects, (item: any, canvas: any) => {
    if (item) {
      text = active.text?.toLowerCase()?.split(" ");
      for (var i = 0; i < text?.length; i++) {
        text[i] = text[i]?.charAt(0)?.toUpperCase?.() + text[i]?.slice?.(1);
      }
      active.text = text?.join(" ");
      canvas.renderAll();
    }
  });
};

function isSetenseCase(string: any) {
  if (string.charCodeAt(0) >= 65 && string.charCodeAt(0) <= 90) {
    for (let i = 1; i < string.length; i++) {
      if (string.charCodeAt(i) >= 65 && string.charCodeAt(i) <= 90) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}
function isUpperCase(string: any) {
  for (let i = 1; i < string.length; i++) {
    if (string.charCodeAt(i) >= 97 && string.charCodeAt(i) <= 122) {
      return false;
    }
  }
  return true;
}
function isLowerCase(string: any) {
  for (let i = 1; i < string.length; i++) {
    if (string.charCodeAt(i) >= 65 && string.charCodeAt(i) <= 90) {
      return false;
    }
  }
  return true;
}
function isTitleCase(string: any) {
  var text = string.split(" ");
  for (var i = 0; i < text.length; i++) {
    if (!(text[i].charCodeAt(0) >= 65 && text[i].charCodeAt(0) <= 90))
      return false;
  }
  return true;
}

export const checkCase = (canvas: any) => {
  var active = canvas.getActiveObject();
  let str = active.text;
  if (isUpperCase(str)) return "UPPER CASE";
  else if (isLowerCase(str)) return "lower case";
  else if (isSetenseCase(str)) return "Sentence case";
  else if (isTitleCase(str)) return "Title Case";
  else return "Change case";
};

export const hexToRGB = (hex: any, alpha = 1) => {
  if (!hex) {
    return null;
  }
  let parseString = hex;
  if (hex.startsWith("#")) {
    parseString = hex.slice(1, 7);
  }
  if (parseString.length !== 6) {
    return null;
  }
  const r = parseInt(parseString.slice(0, 2), 16);
  const g = parseInt(parseString.slice(2, 4), 16);
  const b = parseInt(parseString.slice(4, 6), 16);
  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    return null;
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
export const isObjGroup = (canvas: any) => {
  let textBound = false;
  canvas.getObjects().filter((object: any) => {
    textBound = checkBoundingBox(object, canvas);
  });
  return textBound;
};
export const changingFontColorConst = {
  color: "textBoxColor",
  bgColor: "textBoxBgColor",
  strokeColor: "textStrokeColor",
};

export const handleCanvasCopyPaste = (props: any) => {
  const {
    e,
    canvas,
    dispatch,
    deleteObjects,
    deleteObject,
    copyObj,
    cloneObject,
  } = props;
  const activeObject = canvas?.getActiveObject();
  const preDefKey = {
    cmdKey: 91,
    vKey: 86,
    cKey: 67,
    xKey: 88,
  };
  const currentKeyCode = e?.keyCode;
  const ctrl = e?.ctrlKey || e?.metaKey;
  if (activeObject) {
    if (ctrl && currentKeyCode === preDefKey.cKey) {
      dispatch({
        type: "setCopyObj",
        copyObj: activeObject,
      });
    } else if (ctrl && currentKeyCode === preDefKey.xKey) {
      dispatch({
        type: "setCopyObj",
        copyObj: activeObject,
      });
      deleteObjects(canvas, deleteObject);
    }
  }
  if (!_.isEmpty(copyObj)) {
    if (ctrl && currentKeyCode === preDefKey.vKey) {
      cloneObject(copyObj, canvas);
    }
  }
};

export const objectRotateWithCtrl = (event: any, canvas: any) => {
  const activeObj = canvas.getActiveObject();
  if (activeObj) {
    const ctrlKey = event?.e?.ctrlKey || event?.e?.metaKey;
    if (ctrlKey) {
      activeObj.snapAngle = 15;
    } else {
      activeObj.snapAngle = 0;
    }
  }
};

export const ShadowTypeConst = {
  offsetX: "offsetX",
  offsetY: "offsetY",
  blur: "blur",
};

export const ShadowFiltersConst = [
  { title: "Offset X", type: ShadowTypeConst.offsetX, value: 0 },
  { title: "Offset Y", type: ShadowTypeConst.offsetY, value: 0 },
  { title: "Blur", type: ShadowTypeConst.blur, value: 0 },
];

export const texboxFilterTypeConst = {
  Height: "Height",
  Width: "Width",
};

export const TextboxFiltersConst = [
  { title: "Roundness", type: texboxFilterTypeConst.Height, value: 0 },
  // { title: "Spread", type: texboxFilterTypeConst.Width, value: 0 },
];

export const getCompositionDataConstant = async (
  tempId: any,
  addToCanvas: any,
  dispatch: any
) => {
  await axios
    .get(`${process.env.REACT_APP_API_REDIRECT}/templates/${tempId}`, {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      sessionStorage.removeItem("tempId");
      if (res?.data?.active && res?.data?.statusId == 3) {
        addToCanvas(res.data);
      } else {
        toast.error(
          `Requested Template might have been disabled or doesn't exist.`
        );
        dispatch({
          type: "isCanvasLoad",
          isCanvasLoadData: {
            type: "tempData",
            status: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "isCanvasLoad",
        isCanvasLoadData: {
          type: "tempData",
          status: false,
        },
      });
      sessionStorage.removeItem("tempId");
    });
};

export const addTemplateToCanvas = async (
  canvas: any,
  addToCanvas: any,
  dispatch: any
) => {
  if (sessionStorage.getItem("tempId") && canvas) {
    dispatch({
      type: "isCanvasLoad",
      isCanvasLoadData: {
        type: "tempData",
        status: true,
      },
    });
    let tempId: any = sessionStorage.getItem("tempId");
    if (tempId && tempId !== "undefined") {
      tempId = JSON.parse(tempId);
      getCompositionDataConstant(tempId, addToCanvas, dispatch);
    }
  }
};

export const handleAddAssetsErrorOnCanvas = (
  res: any,
  dispatch: any,
  editor: any
) => {
  if (_.isString(res)) {
    dispatch({
      type: "isCanvasLoad",
      isCanvasLoadData: {
        type: { editor },
        status: false,
      },
    });
    toast.error(res);
  } else {
    return res;
  }
};

export const getMultipleCheckedEndPointBasedOnMenuName = (
  homePageMenuName: any,
  checkedArray: any,
  endPointObj: { template: any; allDesign: any; myDesign: any }
) => {
  // const endPointObj = {
  //   template: "templates",
  //   allDesign: "compositions",
  //   myDesign: "compositions",
  // };
  let returnValue: any = false;
  if (homePageMenuName === "Templates") {
    const compArray = checkedArray.map((item: any) => {
      if (item.templateId) return `%22${item.templateId}%22`;
    });
    returnValue = endPointObj.template(compArray);
  } else if (homePageMenuName === "All Design") {
    const compArray = checkedArray.map((item: any) => {
      if (item.compositionId) return `%22${item.compositionId}%22`;
    });
    returnValue = endPointObj.allDesign(compArray);
  } else if (homePageMenuName === "My Designs") {
    const compArray = checkedArray.map((item: any) => {
      if (item.compositionId) return `%22${item.compositionId}%22`;
    });
    returnValue = endPointObj.myDesign(compArray);
  }
  return returnValue;
};

export const getUniqueId = () => {
  let prefix = `${Math.random()}_${Date.now()}`;
  return _.uniqueId(prefix);
};

export const addToFavourite = (favObj: any) => {
  if (!favObj?.alreadyExist.length) {
    favObj?.addFavourite(favObj.item?.wbmId, favObj.item).then(() => {
      if (favObj?.selected === "template") {
        let obj: any = { templateId: favObj?.id };
        favObj?.templateFavoriteEvent(obj);
      }
      var obj: any = {};
      if (favObj?.selected === "image") {
        if (
          favObj?.item._index === "dw-asset" &&
          favObj?.item._source.productId
        ) {
          obj = { assetId: parseInt(favObj?.item._source.productId) };
          // assetFavoriteEvent(obj);
        } else if (favObj?.item._index === "wbm-photo" && favObj?.item._id) {
          obj = { assetId: parseInt(favObj?.item._id) };
          // assetFavoriteEvent(obj);
        }
      }

      if (favObj?.selected === "videos") {
        obj = { assetId: parseInt(favObj?.item._id) };
        // assetFavoriteEvent(obj);
      }
      favObj?.assetFavoriteEvent(obj);
      toast.dismiss();
      toast.success(`${favObj?.selected} added to your favourites`);
    });
  } else {
    toast.error(`This ${favObj?.selected} is already in your favorites list.`);
  }
};

export const favDataHelper = (favObj: any) => {
  if (
    favObj?.favData &&
    favObj?.users_favorites &&
    JSON.parse(favObj?.users_favorites)?.length > 0
  ) {
    if (favObj?.favouritesData.length > 0) {
      favObj?.setFavouritesData(
        (old: any) =>
          getUnqiueArrayByKey(
            sortFavItem([...old, ...JSON.parse(favObj?.users_favorites)]),
            "id"
          )
        // getUnqiueArrayByKey(
        //   ([...old, ...JSON.parse(favObj?.users_favorites)]),
        //   "id"
        // )
      );
    } else {
      favObj?.setFavouritesData(
        sortFavItem(JSON.parse(favObj?.users_favorites))
      );
    }
  }
};

export const fontfamilyHelper = (font: string) => {
  return font;
};

export const checkBoundingBox = (object: any, canvas: any) => {
  var isTextBound = false;
  //console.log("objeeeeect", object.isBoundingBoxOfText);

  if (object?._objects && object?._objects?.length === 2)
    object?._objects.map((rect: any) => {
      if (rect?.isBoundingBoxOfText) isTextBound = true;
    });

  return isTextBound;
};
export const getGradientBackgroundCss = (colorStops: any) => {
  let colorString = "";
  colorStops?.forEach((item: any, i: any) => {
    if (item?.color) {
      colorString += `${i === 0 ? "" : ", "}${item?.color}`;
    }
  });

  if (colorString) {
    return { backgroundImage: `linear-gradient(${colorString})` };
  } else {
    return {};
  }
};

import _ from "lodash";
import {
  getActiveObjectForText,
  getActiveTextFromGroup,
  handleTextOperation,
} from "../../../../pages/private/dashboard/workspace/Filters/TextFilter/utils/TextFilterConstant";
import { object } from "prop-types";
import { toast } from "react-toastify";
import { AllAsset } from "../AllAsset";
import fabric from "new-dw-ui/src/lib/fabricJs";
import dwCanvasUtils from "new-dw-ui/src/utils/dwCanvasUtils";
import { getTemplate } from "../components/workspace/utils/service/apiService";

export const updateCategory = async (
  dispatch: any,
  width: any,
  height: any,
  name: any,
  id: any,
  dpi?: any
) => {
  // dispatch({
  //   type: "setNewCustomSizeValues",
  //   newCustomSizeValues: {
  //     width: width,
  //     height: height,
  //     dpi: dpi,
  //     displayWidth: width,
  //     displayHeight: height,
  //   },
  // });
  dispatch({
    type: "setNewCanvasWidth",
    newCategoryWidth: width,
  });
  dispatch({
    type: "setNewCanvasHeight",
    newCategoryHeight: height,
  });
  dispatch({
    type: "setNewCanvasName",
    newCategoryName: name,
  });
  dispatch({
    type: "setNewResizeId",
    newResizeTitleId: id,
  });
};

export const DataUrl = (
  canvas: any,
  clippingHeight: any,
  clippingWidth: any,
  currentHeight: any,
  currentWidth: any,
  removeGrid: any,
  drawGrid: any,
  grid: any,
  gridShow: Boolean
) => {
  const urlObj = {
    format: "jpeg",
    left: 250 * (clippingHeight / currentHeight),
    top: 250 * (clippingHeight / currentHeight),
    width: clippingWidth,
    height: clippingHeight,
    quality: 0.75,
  };
  //  removeGrid
  setOpacity(0, canvas);
  const canvasThumb = canvas?.toDataURL(urlObj);
  if (gridShow) {
    //drawGrid
    setOpacity(1, canvas);
  }
  canvas?.requestRenderAll();
  return canvasThumb;
};
export const DataUrlforSave = async (
  canvas: any,
  clippingHeight: any,
  clippingWidth: any,
  currentHeight: any,
  currentWidth: any,
  removeGrid: any,
  drawGrid: any,
  grid: any,
  gridShow: Boolean,
  dispatch?: any,
  type?: any
) => {
  if (!canvas.backgroundColor) {
    fabric.Image.fromURL(`${AllAsset.transparent}`, (img: any) => {
      img.set({
        scaleX: (currentWidth + canvas.getWidth()) / img.width,
        scaleY: (currentHeight + canvas.getHeight()) / img.height,
        top: 250,
        left: 250,
        selectable: false, // This prevents the image from being selectable,
      });
      canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {});

      const canvasThumb = DataUrl(
        canvas,
        clippingHeight,
        clippingWidth,
        currentHeight,
        currentWidth,
        removeGrid,
        drawGrid,
        grid,
        gridShow
      );

      canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
      dispatch({
        type: "setCanvasThumb",
        canvasThumb: { thumb: canvasThumb, thumbFor: type ? type : "" },
      });
      canvas.requestRenderAll();
    });
  } else {
    const canvasThumb = DataUrl(
      canvas,
      clippingHeight,
      clippingWidth,
      currentHeight,
      currentWidth,
      removeGrid,
      drawGrid,
      grid,
      gridShow
    );
    dispatch({
      type: "setCanvasThumb",
      canvasThumb: { thumb: canvasThumb, thumbFor: type ? type : "" },
    });
    canvas.requestRenderAll();
  }
};

export const getExtension = (filename: any) => {
  var parts = filename.split(".");
  return parts[parts.length - 1];
};

export const fileTypeConst = {
  image: "Image",
  video: "Video",
};

export const getFileTypeByFileName = (filename: any) => {
  var ext = getExtension(filename).toLowerCase();
  let fileType: any = false;
  if (
    ext === "jpg" ||
    ext === "gif" ||
    ext === "bmp" ||
    ext === "png" ||
    ext === "jpeg"
  ) {
    fileType = fileTypeConst.image;
  } else if (
    ext === "m4v" ||
    ext === "avi" ||
    ext === "mpg" ||
    ext === "mp4" ||
    ext === "mov" ||
    ext === "mp4"
  ) {
    fileType = fileTypeConst.video;
  }

  return fileType;
};

export const getUnqiueArrayByKey = (data: any, key: any) => {
  if (Array.isArray(data)) {
    const returnValue = [
      ...new Map(data.map((item) => [item[key], item])).values(),
    ];
    return returnValue;
  }

  return [];
};

export const getFilteredFont = (font: any) => {
  let filtered: any = _.sortBy(font, [
    function (o) {
      return o.font;
    },
  ]);
  return filtered;
};

export const sortFavItem = (favArray: any) => {
  let filtered: any = _.orderBy(favArray, ["id"], "desc");
  return filtered;
};

const setOpacity = (op: any, canvas: any) => {
  if (canvas) {
    var objects = canvas.getObjects("line");
    if (objects && object.length > 0) {
      for (let i in objects) {
        if (objects[i].name && objects[i].name === "gridLine")
          objects[i].set({
            opacity: op,
          });
        canvas.requestRenderAll();
      }
    }
  }
};

export const changeTextCase = (canvas: any, caseType: string) => {
  console.log("changing case11111");
  var active = getActiveTextFromGroup(canvas);
  if (!active) return;

  if (active.isEditing) {
    active.hiddenTextarea.value = active.hiddenTextarea.value.toUpperCase();
    active.updateFromTextArea();
    canvas.requestRenderAll();
    // canvas.historyUpdate();
    dwCanvasUtils.save();
    return;
  }

  var text;
  let objects = getActiveObjectForText(canvas);
  handleTextOperation(canvas, objects, (item: any, canvas: any) => {
    if (item) {
      text = active.text?.toLowerCase?.();
      if (caseType === "UPPER CASE") {
        active.text = text?.toUpperCase?.();
      } else if (caseType === "lower case") {
        active.text = text?.toLowerCase?.();
      } else if (caseType === "Sentence case") {
        active.text = text?.charAt(0)?.toUpperCase?.() + text?.slice(1);
      } else if (caseType === "Title Case") {
        text = active.text?.toLowerCase()?.split(" ");
        for (var i = 0; i < text?.length; i++) {
          text[i] = text[i]?.charAt(0)?.toUpperCase?.() + text[i]?.slice?.(1);
        }
        active.text = text?.join(" ");
      }
      canvas.renderAll();
      // dwCanvasUtils.save();
      // canvas.historyUpdate();
    }
  });
};

function isSetenseCase(string: any) {
  if (string.charCodeAt(0) >= 65 && string.charCodeAt(0) <= 90) {
    for (let i = 1; i < string.length; i++) {
      if (string.charCodeAt(i) >= 65 && string.charCodeAt(i) <= 90) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}
function isUpperCase(string: any) {
  for (let i = 1; i < string.length; i++) {
    if (string.charCodeAt(i) >= 97 && string.charCodeAt(i) <= 122) {
      return false;
    }
  }
  return true;
}
function isLowerCase(string: any) {
  for (let i = 1; i < string.length; i++) {
    if (string.charCodeAt(i) >= 65 && string.charCodeAt(i) <= 90) {
      return false;
    }
  }
  return true;
}
function isTitleCase(string: any) {
  var text = string.split(" ");
  for (var i = 0; i < text.length; i++) {
    if (!(text[i].charCodeAt(0) >= 65 && text[i].charCodeAt(0) <= 90))
      return false;
  }
  return true;
}

export const checkCase = (canvas: any) => {
  var active = canvas.getActiveObject();
  let str = active.text;
  if (isUpperCase(str)) return "UPPER CASE";
  else if (isLowerCase(str)) return "lower case";
  else if (isSetenseCase(str)) return "Sentence case";
  else if (isTitleCase(str)) return "Title Case";
  else return "Change case";
};

export const hexToRGB = (hex: any, alpha = 1) => {
  if (!hex) {
    return null;
  }
  let parseString = hex;
  if (hex.startsWith("#")) {
    parseString = hex.slice(1, 7);
  }
  if (parseString.length !== 6) {
    return null;
  }
  const r = parseInt(parseString.slice(0, 2), 16);
  const g = parseInt(parseString.slice(2, 4), 16);
  const b = parseInt(parseString.slice(4, 6), 16);
  if (isNaN(r) || isNaN(g) || isNaN(b)) {
    return null;
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
export const isObjGroup = (canvas: any) => {
  let textBound = false;
  canvas.getObjects().filter((object: any) => {
    textBound = checkBoundingBox(object, canvas);
  });
  return textBound;
};
export const changingFontColorConst = {
  color: "textBoxColor",
  bgColor: "textBoxBgColor",
  strokeColor: "textStrokeColor",
};

export const handleCanvasCopyPaste = (props: any) => {
  const {
    e,
    canvas,
    dispatch,
    deleteObjects,
    deleteObject,
    copyObj,
    cloneObject,
  } = props;
  const activeObject = canvas?.getActiveObject();
  const preDefKey = {
    cmdKey: 91,
    vKey: 86,
    cKey: 67,
    xKey: 88,
  };
  const currentKeyCode = e?.keyCode;
  const ctrl = e?.ctrlKey || e?.metaKey;
  if (activeObject) {
    if (ctrl && currentKeyCode === preDefKey.cKey) {
      dispatch({
        type: "setCopyObj",
        copyObj: activeObject,
      });
    } else if (ctrl && currentKeyCode === preDefKey.xKey) {
      dispatch({
        type: "setCopyObj",
        copyObj: activeObject,
      });
      deleteObjects(canvas, deleteObject);
    }
  }
  if (!_.isEmpty(copyObj)) {
    if (ctrl && currentKeyCode === preDefKey.vKey) {
      cloneObject(copyObj, canvas);
    }
  }
};

export const objectRotateWithCtrl = (event: any, canvas: any) => {
  const activeObj = canvas.getActiveObject();
  if (activeObj) {
    const ctrlKey = event?.e?.ctrlKey || event?.e?.metaKey;
    if (ctrlKey) {
      activeObj.snapAngle = 15;
    } else {
      activeObj.snapAngle = 0;
    }
  }
};

export const ShadowTypeConst = {
  offsetX: "offsetX",
  offsetY: "offsetY",
  blur: "blur",
};

export const ShadowFiltersConst = [
  { title: "Offset X", type: ShadowTypeConst.offsetX, value: 0 },
  { title: "Offset Y", type: ShadowTypeConst.offsetY, value: 0 },
  { title: "Blur", type: ShadowTypeConst.blur, value: 0 },
];

export const texboxFilterTypeConst = {
  Height: "Height",
  Width: "Width",
};

export const TextboxFiltersConst = [
  { title: "Roundness", type: texboxFilterTypeConst.Height, value: 0 },
  // { title: "Spread", type: texboxFilterTypeConst.Width, value: 0 },
];

export const getCompositionDataConstant = async (
  tempId: any,
  addToCanvas: any,
  dispatch: any
) => {
  await getTemplate(tempId)
    .then((res) => {
      const resData = res?.data;
      if (resData.active && resData?.statusId == 3) {
        if (
          resData?.categoryId &&
          resData?.composer_object?.[0]?.width &&
          resData?.composer_object?.[0]?.height
        ) {
          localStorage.setItem(
            "redirectedTempCategoryId",
            JSON.stringify({
              categoryId: resData?.categoryId,
              categoryHeight: resData?.composer_object?.[0].height,
              categoryWidth: resData?.composer_object?.[0].width,
            })
          );
        } else {
          if (localStorage.getItem("redirectedTempCategoryId")) {
            localStorage.removeItem("redirectedTempCategoryId");
          }
        }
        addToCanvas(res.data);
      } else {
        toast.error(
          `Requested Template might have been disabled or doesn't exist.`
        );
        dispatch({
          type: "isCanvasLoad",
          isCanvasLoadData: {
            type: "tempData",
            status: false,
          },
        });
      }
      handleAddUpdateTempIdLocal(false);
    })
    .catch(() => {
      dispatch({
        type: "isCanvasLoad",
        isCanvasLoadData: {
          type: "tempData",
          status: false,
        },
      });
      handleAddUpdateTempIdLocal(false);
    });
};

export const addTemplateToCanvas = async (
  canvas: any,
  addToCanvas: any,
  dispatch: any
) => {
  if (sessionStorage.getItem("tempId") && canvas) {
    dispatch({
      type: "isCanvasLoad",
      isCanvasLoadData: {
        type: "tempData",
        status: true,
      },
    });
    let tempId: any = sessionStorage.getItem("tempId");
    if (tempId && tempId !== "undefined") {
      tempId = JSON.parse(tempId);
      getCompositionDataConstant(tempId, addToCanvas, dispatch);
    }
  }
};

export const handleAddAssetsErrorOnCanvas = (
  res: any,
  dispatch: any,
  editor: any
) => {
  if (_.isString(res)) {
    dispatch({
      type: "isCanvasLoad",
      isCanvasLoadData: {
        type: { editor },
        status: false,
      },
    });
    toast.error(res);
  } else {
    return res;
  }
};

export const getMultipleCheckedEndPointBasedOnMenuName = (
  homePageMenuName: any,
  checkedArray: any,
  endPointObj: { template: any; allDesign: any; myDesign: any }
) => {
  // const endPointObj = {
  //   template: "templates",
  //   allDesign: "compositions",
  //   myDesign: "compositions",
  // };
  let returnValue: any = false;
  if (homePageMenuName === "Templates") {
    const compArray = checkedArray.map((item: any) => {
      if (item.templateId) return `%22${item.templateId}%22`;
    });
    returnValue = endPointObj.template(compArray);
  } else if (homePageMenuName === "All Design") {
    const compArray = checkedArray.map((item: any) => {
      if (item.compositionId) return `%22${item.compositionId}%22`;
    });
    returnValue = endPointObj.allDesign(compArray);
  } else if (homePageMenuName === "My Designs") {
    const compArray = checkedArray.map((item: any) => {
      if (item.compositionId) return `%22${item.compositionId}%22`;
    });
    returnValue = endPointObj.myDesign(compArray);
  }
  return returnValue;
};

export const getUniqueId = () => {
  let prefix = `${Math.random()}_${Date.now()}`;
  return _.uniqueId(prefix);
};

export const addToFavourite = (favObj: any) => {
  if (!favObj?.alreadyExist.length) {
    let isTemplate = favObj?.selected === "template";
    favObj
      ?.addFavourite(isTemplate ? favObj?.id : favObj.item?.wbmId)
      .then(() => {
        if (favObj?.selected === "template") {
          let obj: any = { templateId: favObj?.id };
          favObj?.templateFavoriteEvent(obj);
        }
        var obj: any = {};
        if (favObj?.selected === "image") {
          if (
            favObj?.item._index === "dw-asset" &&
            favObj?.item._source.productId
          ) {
            obj = { assetId: parseInt(favObj?.item._source.productId) };
            // assetFavoriteEvent(obj);
          } else if (favObj?.item._index === "wbm-photo" && favObj?.item._id) {
            obj = { assetId: parseInt(favObj?.item._id) };
            // assetFavoriteEvent(obj);
          }
        }

        if (favObj?.selected === "videos") {
          obj = { assetId: parseInt(favObj?.item._id) };
          // assetFavoriteEvent(obj);
        }
        favObj?.assetFavoriteEvent(obj);
        toast.dismiss();
        // toast.success(`${favObj?.selected} added to your favourites`);
      });
  } else {
    toast.error(`This ${favObj?.selected} is already in your favorites list.`);
  }
};

export const favDataHelper = (favObj: any) => {
  if (
    favObj?.favData &&
    favObj?.users_favorites &&
    favObj?.users_favorites?.length > 0
  ) {
    if (favObj?.favouritesData.length > 0) {
      favObj?.setFavouritesData(
        (old: any) =>
          getUnqiueArrayByKey(
            sortFavItem([...old, ...favObj?.users_favorites]),
            "id"
          )
        // getUnqiueArrayByKey(
        //   ([...old, ...JSON.parse(favObj?.users_favorites)]),
        //   "id"
        // )
      );
    } else {
      favObj?.setFavouritesData(sortFavItem(favObj?.users_favorites));
    }
  }
};

export const fontfamilyHelper = (font: string) => {
  return font;
};

export const checkBoundingBox = (object: any, canvas: any) => {
  var isTextBound = false;

  if (object?._objects && object?._objects?.length === 2)
    object?._objects.map((rect: any) => {
      if (rect?.isBoundingBoxOfText) isTextBound = true;
    });

  return isTextBound;
};
export const getGradientBackgroundCss = (colorStops: any) => {
  let colorString = "";
  colorStops?.forEach((item: any, i: any) => {
    if (item?.color) {
      colorString += `${i === 0 ? "" : ", "}${item?.color}`;
    }
  });

  if (colorString) {
    return { backgroundImage: `linear-gradient(${colorString})` };
  } else {
    return {};
  }
};

export const handleAddUpdateTempIdLocal = (data: any) => {
  const key = "tempId";
  if (!data) {
    sessionStorage.removeItem(key);
    return;
  }
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const showContextMenu = (e: any, canvas: any) => {
  var canvasOffset = canvas?.upperCanvasEl?.getBoundingClientRect();
  var left: any = "";
  var top: any = "";

  var activeObject = canvas.getActiveObject();
  var boundingObj = activeObject?.getBoundingRect();
  const isActiveObjectBiggerThanCanvas =
    boundingObj.width > canvasOffset.width - 250 &&
    boundingObj.height > canvasOffset.height - 250;

  if (boundingObj?.height < 150) {
    if (boundingObj?.top < 60) {
      left = boundingObj?.left + 30;
      top = boundingObj?.top + boundingObj?.height + 10;
      if (boundingObj?.top < boundingObj?.height) {
        if (boundingObj?.top < boundingObj?.height) {
          left = boundingObj?.left + 90;
          top = boundingObj?.top + 90;
        } else if (boundingObj?.top < boundingObj?.height) {
          left = boundingObj?.width + 10;
          top = boundingObj?.top;
        }
      } else if (boundingObj?.left < 30) {
        left = boundingObj?.left + 130;
        top = boundingObj?.top + boundingObj?.height + 10;
      } else if (
        boundingObj?.top < 0 &&
        boundingObj?.left > canvasOffset?.width / 2
      ) {
        left = boundingObj?.left - boundingObj?.width;
        top = boundingObj?.top + boundingObj?.height + 10;
      } else if (boundingObj?.height < 30) {
        left = boundingObj?.left - boundingObj?.width + 30;
        top = boundingObj?.top + boundingObj?.height + 10;
      } else if (boundingObj?.width / 2 < boundingObj?.left) {
        left = boundingObj?.left - boundingObj?.width + 30;
        top = boundingObj?.top + boundingObj?.height + 10;
      } else {
        left = boundingObj?.width;
        top = boundingObj?.top + boundingObj?.height + 10;
      }
    } else if (canvasOffset?.width / 2 < boundingObj?.left) {
      left = boundingObj?.width;
      top = boundingObj?.top - 60;
      if (boundingObj?.height < boundingObj?.top + 50) {
        left = boundingObj?.left - boundingObj?.width + 30;
        top = boundingObj?.top - 40;
        if (boundingObj?.height < 100) {
          left = boundingObj?.left - boundingObj?.width - 30;
          top = boundingObj?.top - 75;
          if (boundingObj?.height > boundingObj?.top / 2) {
            left = boundingObj?.left - boundingObj?.width;
            top = boundingObj?.top + 80;
          }
        }
      }
      if (canvasOffset?.height < 30) {
        // top
        left = boundingObj?.left - boundingObj?.width - 30;
        top = boundingObj?.top - 60;
      }
    } else {
      if (boundingObj?.left < 30) {
        left = boundingObj?.left + boundingObj?.width + 10;
        top = boundingObj?.top - 40;
      } else if (boundingObj?.height < 30) {
        left = boundingObj?.left - boundingObj?.width + 30;
        top = boundingObj?.top + boundingObj?.height + 10;
      } else {
        left = boundingObj?.left + boundingObj?.width;
        top = boundingObj?.top - 55;
      }
    }
  } else {
    if (boundingObj?.top < 100) {
      const spacingPadding = 30;
      left = boundingObj?.left + boundingObj?.width;
      top = boundingObj?.top + boundingObj?.height + spacingPadding;
      // top
      if (boundingObj?.top < 0 && boundingObj?.left < 0) {
        left = boundingObj?.left + boundingObj?.width;
        top = boundingObj?.top + boundingObj?.height + spacingPadding;
        // left
      } else if (boundingObj?.top < 0) {
        left = boundingObj?.width + spacingPadding;
        top = boundingObj?.top + boundingObj?.height + spacingPadding;
      } else {
        // when object touched top
        if (boundingObj?.left < 0) {
          left = boundingObj?.left + boundingObj?.width + spacingPadding;
          top = boundingObj?.height;
        } else {
          // left
          if (canvasOffset.width / 2 < boundingObj?.left) {
            left = boundingObj?.left - 150;
            top = boundingObj?.top;
            // left
          } else {
            if (boundingObj?.height > boundingObj?.Width) {
              left = boundingObj?.width;
              top = boundingObj?.height;
            } else {
              if (boundingObj?.top < 60) {
                left = boundingObj?.left + 90;
                top = boundingObj?.top + boundingObj?.height + 30;
              } else {
                left = boundingObj?.left + 90;
                top = boundingObj?.top + boundingObj?.height + spacingPadding;
              }
            }
          }
        }
      }
      // bottom
    } else if (boundingObj?.left < 30) {
      left = boundingObj?.left + boundingObj?.width + 30;
      top = boundingObj?.top - 40;
    } else if (canvasOffset?.width / 2 < boundingObj?.left) {
      //right bottom
      if (boundingObj?.height > boundingObj?.Width) {
        left = boundingObj?.left - 150;
        top = boundingObj?.top + 10;
      } else {
        if (boundingObj?.width < boundingObj?.top) {
          // left = boundingObj?.left - boundingObj?.width;
          //top = boundingObj?.height + 10;
          left = boundingObj?.left - 150;
          top = boundingObj?.top;
        } else {
          left = boundingObj?.left - 150;
          // top = boundingObj?.height + 10;
          top = boundingObj?.top + 10;
        }
      }
      if (
        canvasOffset?.height / 2 + 50 < boundingObj?.left &&
        canvasOffset?.width / 2 > boundingObj?.left
      ) {
        left = boundingObj?.left - 50;
        top = boundingObj?.top - 60;
      }
      // top
    } else {
      const spacingPaddingTop = 80;
      if (boundingObj?.width < boundingObj?.height) {
        left = boundingObj?.left - 60;
        top = boundingObj?.top - 60;
      } else {
        if (canvasOffset?.top < 0) {
          left = boundingObj?.left + 10;
          top = boundingObj?.top - spacingPaddingTop;
        } else if (canvasOffset?.top < 60) {
          left = boundingObj?.left + 10;
          top = boundingObj?.top - spacingPaddingTop;
        } else {
          left = boundingObj?.left - 40;
          top = boundingObj?.top - spacingPaddingTop;
        }
      }
    }
  }
  if (!isActiveObjectBiggerThanCanvas) {
    var contextMenu: any = document.getElementById("context-menu");
    contextMenu.style.display = "block";
    contextMenu.style.left = Math.floor(left) + "px";
    contextMenu.style.top = Math.floor(top) + "px";
  }
};

export const hideContextMenu = () => {
  var contextMenu: any = document.getElementById("context-menu");
  contextMenu.style.display = "none";
};

export const resizeDummyCanvas = (
  width: Number,
  height: Number,
  zoomLevel: any,
  clipDimensions: any
) => {
  let arrAnimationCanvas = dwCanvasUtils.animationCanvas;
  if (!dwCanvasUtils.canvas) {
    return;
  }
  let cssText: any = "";
  let styles = dwCanvasUtils.canvas.wrapperEl.style;
  const { currentWidth, currentHeight } = clipDimensions;
  const canvasHiddenSpacing = 250;

  cssText = Array.from(styles).reduce((str, property) => {
    if (property === "position") {
      return `${str}position:absolute;`;
    } else if (property === "width" || property === "height") {
      return str;
    } else {
      return `${str}${property}:${styles.getPropertyValue(property)};`;
    }
  }, `position:absolute;top:0;left:0;visibility:hidden;pointer-events:none;cursor:default;width:${width}px;height:${height}px;transition:visibility .2s linear .2s;`);

  arrAnimationCanvas.forEach((dummyCanvas: any) => {
    dummyCanvas.wrapperEl.style = cssText;
    dummyCanvas.setWidth(width);
    dummyCanvas.setHeight(height);
    dummyCanvas.zoomToPoint(new fabric.Point(0, 0), zoomLevel / 100);
    let clipPath = new fabric.Rect({
      width: currentWidth,
      height: currentHeight,
      top: canvasHiddenSpacing,
      left: canvasHiddenSpacing,
      objectCaching: false,
    });
    dummyCanvas.clipPath = clipPath;
    dummyCanvas.renderAll();
  });
};

export const changeVisiblityOfAnimationCanvas = (
  flag: Boolean,
  activateArtBoard: Number
) => {
  let currentCanvas = dwCanvasUtils.currentCanvas || activateArtBoard;
  if (dwCanvasUtils.canvas) {
    let arrAnimationCanvas = dwCanvasUtils.animationCanvas;
    arrAnimationCanvas.forEach((dummyCanvas: any, index: number) => {
      dummyCanvas.wrapperEl.style.visibility =
        flag && index + 1 === currentCanvas ? "visible" : "hidden";
    });
    dwCanvasUtils.canvas.wrapperEl.style.transition =
      "visibility .2s linear .2s";
    dwCanvasUtils.canvas.wrapperEl.style.visibility = flag
      ? "hidden"
      : "visible";
  }
};
export const debounce = (fn: Function, ms = 10) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(), ms);
  };
};

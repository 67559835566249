import React, { useEffect, useState } from "react";
import { Button, Modal } from "@material-ui/core";
import { ReactComponent as Stripe } from "../../../../assets/svg/stripe.svg";
import { ReactComponent as Taxamo } from "../../../../assets/svg/taxamo.svg";
import { ReactComponent as Gdpr } from "../../../../assets/svg/gdpr.svg";
import { ReactComponent as Ssl } from "../../../../assets/svg/ssl.svg";
import { ReactComponent as Cross } from "../../../../assets/svg/cross.svg";
import { ReactComponent as Check } from "../../../../assets/svg/checkMarkIcon.svg";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { completeProfileDetails } from "../../../../lib/contexts/Queries";
import { useLazyQuery } from "@apollo/client";
import {
  LeftColumn,
  customBounceAnimation,
  PriceData,
  ProductName,
  PurchaseDetailsName,
  PlanList,
  Binding,
  InfoP,
  InfoSpan,
  ModalDialog,
  ModalContent,
  Price,
  RightColumn,
  IframWrapper,
  IframeLoaded,
  Logos,
  Loader,
} from "./paymentPopupStyled";
import { startSubscriptionEvent, paymentEvent } from "lib/gtmEvents";
import { getUniqueId } from "../../../../constants";
import _ from "lodash";
import { useUserData } from "utils/useUserData";

const PaymentPopup = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [iFrameSrc, setIframeSrc] = useState("");
  let isPopupClosed = useSelector((state: any) => {
    return state.isPopupClosed;
  });
  const dispatch = useDispatch();
  const updateProfile = useSelector((state: any) => {
    return state.isProfileUpdated;
  });
  const templateId = useSelector((state: any) => {
    return state.templateId;
  });
  const userData = useUserData();

  function buildFlatURL(parameterName: string, data: object) {
    if (parameterName !== "meta" && parameterName !== "transaction") {
      throw new Error("Wrong parameter name.");
    }
    return flatten(data).reduce(
      (string: string, flat: string) => `${string + parameterName + flat}&`,
      ""
    );
  }

  const [getProfile] = useLazyQuery(completeProfileDetails, {
    fetchPolicy: "cache-and-network",
    onCompleted: (e: any) => {
      if (e) {
        localStorage.setItem("user", JSON.stringify(e.GET_users_me));
        dispatch({
          type: "updateProfile",
          isProfileUpdated: !updateProfile,
        });
        dispatch({
          type: "closePopup",
          isPopupClosed: false,
        });
      }
    },
    // onError: (e: any) => {},
  });

  const flatten = (obj: any) => {
    // var flattened: flattened[] = [];
    var flattened: string[] = [];
    Object.keys(obj).forEach((key) => {
      const flat = `[${key}]`;
      if (obj[key] && typeof obj[key] === "object") {
        flatten(obj[key]).forEach((part) => flattened.push(flat + part));
      } else {
        flattened.push(`${flat}=${obj[key]}`);
      }
    });
    return flattened;
  };

  const getTaxomoTypeBasedOnMonthlyOrYearly = () => {
    const isMonthly = props.isMonthlyPlan;
    let returnValue: any = "";
    if (props.planName === "Start Pro Trial") {
      returnValue = "master_monthly_trial";
    } else if (props.planName === "Start Business Trial") {
      returnValue = "business_monthly_trial";
    } else if (props.planName === "Get Pro") {
      returnValue = isMonthly ? "master_monthly" : "master_yearly";
    } else if (props.planName === "Get Business") {
      returnValue = isMonthly ? "business_monthly" : "business_yearly";
    }
    return returnValue;
  };

  // useEffect(() => {
  //   // useEffect call to set iframe src
  //   var iframe = "";
  //   if (props.planName === "Start Pro Trial") {
  //     iframe = `https://p.taxamo.com/checkout/api/v1/state/initialize?meta%5Ballowed_payment_providers%5D=stripe&meta%5Bshow_invoice_address%5D=false&meta%5Brequire_invoice_address%5D=false&meta%5Bshow_email%5D=false&meta%5Brequire_email%5D=false&meta%5Brequire_buyer_name%5D=false&meta%5Bshow_coupon_field%5D=false&meta%5Bfinished_redirect_url%5D=${
  //       process.env.REACT_APP_API
  //     }taxamo/callback&meta%5Bsubscription_mode%5D=true&meta%5Bplans%5D%5Bb2c_plan_id%5D=${getTaxomoTypeBasedOnMonthlyOrYearly()}&meta%5Bplans%5D%5Bb2b_plan_id%5D=${getTaxomoTypeBasedOnMonthlyOrYearly()}&transaction%5Bcurrency_code%5D=USD&transaction%5Bbilling_country_code%5D=IN&transaction%5Bbuyer_name%5D=${
  //       JSON.parse(userName).name
  //     }&transaction%5Bbuyer_email%5D=${
  //       JSON.parse(userName).email
  //     }&public_token=${process.env.REACT_APP_TAXAMO_KEY}`;
  //   }

  //   if (props.planName === "Start Business Trial") {
  //     iframe = `https://p.taxamo.com/checkout/api/v1/state/initialize?meta%5Ballowed_payment_providers%5D=stripe&meta%5Bshow_invoice_address%5D=false&meta%5Brequire_invoice_address%5D=false&meta%5Bshow_email%5D=false&meta%5Brequire_email%5D=false&meta%5Brequire_buyer_name%5D=false&meta%5Bshow_coupon_field%5D=false&meta%5Bfinished_redirect_url%5D=${
  //       process.env.REACT_APP_API
  //     }taxamo/callback&meta%5Bsubscription_mode%5D=true&meta%5Bplans%5D%5Bb2c_plan_id%5D=${getTaxomoTypeBasedOnMonthlyOrYearly()}&meta%5Bplans%5D%5Bb2b_plan_id%5D=${getTaxomoTypeBasedOnMonthlyOrYearly()}&transaction%5Bcurrency_code%5D=USD&transaction%5Bbilling_country_code%5D=IN&transaction%5Bbuyer_name%5D=${
  //       JSON.parse(userName).name
  //     }&transaction%5Bbuyer_email%5D=${
  //       JSON.parse(userName).email
  //     }&public_token=${process.env.REACT_APP_TAXAMO_KEY}`;
  //   }

  //   if (props.planName === "Get Pro") {
  //     iframe = `https://p.taxamo.com/checkout/api/v1/state/initialize?meta%5Ballowed_payment_providers%5D=stripe&meta%5Bshow_invoice_address%5D=false&meta%5Brequire_invoice_address%5D=false&meta%5Bshow_email%5D=false&meta%5Brequire_email%5D=false&meta%5Brequire_buyer_name%5D=false&meta%5Bshow_coupon_field%5D=false&meta%5Bfinished_redirect_url%5D=${
  //       process.env.REACT_APP_API
  //     }taxamo/callback&meta%5Bsubscription_mode%5D=true&meta%5Bplans%5D%5Bb2c_plan_id%5D=${getTaxomoTypeBasedOnMonthlyOrYearly()}&meta%5Bplans%5D%5Bb2b_plan_id%5D=${getTaxomoTypeBasedOnMonthlyOrYearly()}&transaction%5Bcurrency_code%5D=USD&transaction%5Bbilling_country_code%5D=IN&transaction%5Bbuyer_name%5D=${
  //       JSON.parse(userName).name
  //     }&transaction%5Bbuyer_email%5D=${
  //       JSON.parse(userName).email
  //     }&public_token=${process.env.REACT_APP_TAXAMO_KEY}`;
  //   }

  //   if (props.planName === "Get Business") {
  //     iframe = `https://p.taxamo.com/checkout/api/v1/state/initialize?meta%5Ballowed_payment_providers%5D=stripe&meta%5Bshow_invoice_address%5D=false&meta%5Brequire_invoice_address%5D=false&meta%5Bshow_email%5D=false&meta%5Brequire_email%5D=false&meta%5Brequire_buyer_name%5D=false&meta%5Bshow_coupon_field%5D=false&meta%5Bfinished_redirect_url%5D=${
  //       process.env.REACT_APP_API
  //     }taxamo/callback&meta%5Bsubscription_mode%5D=true&meta%5Bplans%5D%5Bb2c_plan_id%5D=${getTaxomoTypeBasedOnMonthlyOrYearly()}&meta%5Bplans%5D%5Bb2b_plan_id%5D=${getTaxomoTypeBasedOnMonthlyOrYearly()}&transaction%5Bcurrency_code%5D=USD&transaction%5Bbilling_country_code%5D=IN&transaction%5Bbuyer_name%5D=${
  //       JSON.parse(userName).name
  //     }&transaction%5Bbuyer_email%5D=${
  //       JSON.parse(userName).email
  //     }&public_token=${process.env.REACT_APP_TAXAMO_KEY}`;
  //   }

  //   if (props.planName === "Purchase Details") {
  //     iframe = `https://p.taxamo.com/checkout/api/v1/state/initialize?meta%5Ballowed_payment_providers%5D=stripe&meta%5Bshow_invoice_address%5D=false&meta%5Brequire_invoice_address%5D=false&meta%5Bshow_email%5D=false&meta%5Brequire_email%5D=false&meta%5Brequire_buyer_name%5D=false&meta%5Bshow_coupon_field%5D=false&meta%5Bfinished_redirect_url%5D=${
  //       process.env.REACT_APP_API
  //     }taxamo/callback&meta%5Bshow_summary%5D=true&transaction%5Bcurrency_code%5D=USD&transaction%5Bbilling_country_code%5D=IN&transaction%5Bbuyer_name%5D=${
  //       JSON.parse(userName).name
  //     }&transaction%5Bbuyer_email%5D=${
  //       JSON.parse(userName).email
  //     }&transaction%5Bcustomer_id%5D=cus_LbNupHUky9K1xG&transaction%5Btransaction_lines%5D%5B0%5D%5Bcustom_id%5D=1&transaction%5Btransaction_lines%5D%5B0%5D%5Bdescription%5D=${
  //       props.purchaseDetails
  //     }&transaction%5Btransaction_lines%5D%5B0%5D%5Bamount%5D=${
  //       props.paymentAmount
  //     }&public_token=${process.env.REACT_APP_TAXAMO_KEY}`;
  //   }

  //   if (iframe) setIframeSrc(iframe);
  // }, [props.planName, iFrameSrc]);

  useEffect(() => {
    const baseCheckoutUrl =
      "https://p.taxamo.com/checkout/api/v1/state/initialize";
    const commonParams: any = {
      "meta[allowed_payment_providers]": "stripe",
      "meta[show_invoice_address]": false,
      "meta[require_invoice_address]": false,
      "meta[show_email]": false,
      "meta[require_email]": false,
      "meta[require_buyer_name]": false,
      "meta[show_coupon_field]": false,
      "meta[finished_redirect_url]": `${process.env.REACT_APP_API}taxamo/callback`,
      "transaction[currency_code]": "USD",
      // "transaction[billing_country_code]": "IN",
      "transaction[buyer_name]": userData.name,
      "transaction[buyer_email]": userData.email,
      public_token: process.env.REACT_APP_TAXAMO_KEY,
    };

    let iframe = "";
    if (props.planName === "Start Pro Trial") {
      const proTrialParams = {
        "meta[subscription_mode]": true,
        "meta[plans][b2c_plan_id]": getTaxomoTypeBasedOnMonthlyOrYearly(),
        "meta[plans][b2b_plan_id]": getTaxomoTypeBasedOnMonthlyOrYearly(),
      };
      iframe = `${baseCheckoutUrl}?${new URLSearchParams({
        ...commonParams,
        ...proTrialParams,
      })}`;
    } else if (props.planName === "Start Business Trial") {
      const businessTrialParams = {
        "meta[subscription_mode]": true,
        "meta[plans][b2c_plan_id]": getTaxomoTypeBasedOnMonthlyOrYearly(),
        "meta[plans][b2b_plan_id]": getTaxomoTypeBasedOnMonthlyOrYearly(),
      };
      iframe = `${baseCheckoutUrl}?${new URLSearchParams({
        ...commonParams,
        ...businessTrialParams,
      })}`;
    } else if (props.planName === "Get Pro") {
      const getProParams = {
        "meta[subscription_mode]": true,
        "meta[plans][b2c_plan_id]": getTaxomoTypeBasedOnMonthlyOrYearly(),
        "meta[plans][b2b_plan_id]": getTaxomoTypeBasedOnMonthlyOrYearly(),
      };
      iframe = `${baseCheckoutUrl}?${new URLSearchParams({
        ...commonParams,
        ...getProParams,
      })}`;
    } else if (props.planName === "Get Business") {
      const getBusinessParams = {
        "meta[subscription_mode]": true,
        "meta[plans][b2c_plan_id]": getTaxomoTypeBasedOnMonthlyOrYearly(),
        "meta[plans][b2b_plan_id]": getTaxomoTypeBasedOnMonthlyOrYearly(),
      };
      iframe = `${baseCheckoutUrl}?${new URLSearchParams({
        ...commonParams,
        ...getBusinessParams,
      })}`;
    } else if (props.planName === "Purchase Details") {
      const purchaseDetailsParams = {
        "meta[show_summary]": true,
        "transaction[customer_id]": "cus_LbNupHUky9K1xG",
        "transaction[transaction_lines][0][custom_id]": 1,
        "transaction[transaction_lines][0][description]": props.purchaseDetails,
        "transaction[transaction_lines][0][amount]": props.paymentAmount,
      };
      iframe = `${baseCheckoutUrl}?${new URLSearchParams({
        ...commonParams,
        ...purchaseDetailsParams,
      })}`;
    }

    if (iframe) {
      setIframeSrc(iframe);
    }
  }, [props.planName, iFrameSrc]);

  const eventForPayment = (paymentData: any) => {
    let value: number = parseInt(paymentData.data?.payment_result?.amount);
    let transactionId: string = paymentData.data?.payment_result?.key;
    let obj: any = { transactionId, templateId, value };
    paymentEvent(obj);
  };
  const eventForSubscribed = (paymentData: any) => {
    let transactionId: string;
    let label = "";
    transactionId = paymentData.data?.payment_result?.key;
    let subInfo = paymentData.data?.payment_result?.description;
    if (subInfo.includes("Pro Trial")) {
      label = "Pro Trial";
    } else if (subInfo.includes("Business Monthly Trial")) {
      label = "Business Trial";
    } else if (subInfo.includes("Pro")) {
      label = "Annual pro";
    } else if (subInfo.includes("Business")) {
      label = "Annual Business";
    }
    let obj: any = { transactionId, label };

    startSubscriptionEvent(obj);
  };
  useEffect(() => {
    window.addEventListener("message", (event: any) => {
      // identify correctness of message from iframe
      // dispatch({
      //   type: 'setPaymentData',
      //   paymentData: event.data
      // })
      if (event.data.message === "Subscribed") {
        eventForSubscribed(event.data);
        toast.success("Plan Activated");
        getProfile({
          variables: {
            filter: "{}",
            token: `${localStorage.getItem("token")}`,
          },
        });
        // dispatch({
        //   type: 'setPaid',
        //   isPaid: 'Subscribed'
        // })
      }
      if (event.data.message === "PaymentDone") {
        // dispatch({
        //   type: 'setPaid',
        //   isPaid: 'PaymentDone'
        // })
        eventForPayment(event.data);
      }
    });
    return () => window.removeEventListener("message", () => {});
  }, []);

  const isTrial = _.includes(_.lowerCase(props?.planName), "trial");
  return (
    <>
      <Modal
        open={isPopupClosed}
        aria-labelledby="contained-modal-title-vcenter"
        onClose={() => {
          dispatch({
            type: "closePopup",
            isPopupClosed: false,
          });
          // props.handleClose();
        }}
      >
        <ModalDialog className="modal-dialog modal-lg">
          <ModalContent className="modal-content">
            <div id="taxamo-checkout-wrapper">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  padding: 0,
                  fontFamily: "Arial !important",
                  flex: "1 1 auto",
                }}
              >
                {/* <CloneButton
                  // > */}
                <Button
                  type="button"
                  onClick={() => {
                    dispatch({
                      type: "closePopup",
                      isPopupClosed: false,
                    });
                    // props.handleClose();
                  }}
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                  }}
                >
                  <Cross
                    style={{
                      color: "#b6b6bb",
                      width: "1.6rem",
                      marginRight: 0,
                    }}
                  />
                </Button>
                {/* </CloneButton> */}
                <LeftColumn>
                  <PriceData className="mainDivWrapper">
                    {props.paymentTitle === "Purchase Details" ? (
                      <PurchaseDetailsName className="product-name ng-scope">
                        <span className="ng-binding">{props.paymentTitle}</span>{" "}
                      </PurchaseDetailsName>
                    ) : (
                      <ProductName className="product-name ng-scope">
                        <span className="ng-binding">{props.paymentTitle}</span>{" "}
                      </ProductName>
                    )}
                    {isTrial && (
                      <Price
                        style={{ backgroundColor: "#2d3559" }}
                        ng-class="::{trial: trialPlan}"
                        className="trial"
                      >
                        <span
                          ng-if="::trialPlan"
                          style={{
                            display: "inline-block",
                            width: "150px",
                          }}
                          className="ng-binding ng-scope"
                        >
                          $0{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            now
                          </span>
                        </span>
                      </Price>
                    )}
                  </PriceData>
                  <div
                    ng-if="trialPlan"
                    style={{
                      margin: isTrial ? "40px 0" : "9rem 0 1px 0",
                      display: "flex",
                      justifyContent: "start",
                    }}
                    className="ng-scope"
                  >
                    {isTrial ? (
                      <ul
                        style={{
                          lineHeight: "1.6rem",
                          padding: 0,
                          listStyleType: "none",
                        }}
                      >
                        {props.paymentList.map((item: any, index: any) => {
                          return (
                            <PlanList key={index}>
                              <Check style={{ color: "#4db3a9" }} />
                              &nbsp;&nbsp;
                              <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                            </PlanList>
                          );
                        })}
                      </ul>
                    ) : (
                      <div>
                        <PurchaseDetailsName
                          style={{
                            marginTop: "100px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginBottom: "5px",
                          }}
                        >
                          Frequently Asked Question
                        </PurchaseDetailsName>
                        <ul
                          style={{
                            listStyle: "none",
                            padding: 0,
                            marginTop: "0px",
                          }}
                        >
                          {props.paymentList.map((item: any, index: any) => {
                            return (
                              <PlanList key={index}>
                                <p>{item.question}</p>
                                <p>{item.answer}</p>
                              </PlanList>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>

                  {isTrial && (
                    <Binding
                      id="trial-plan"
                      ng-if="::trialPlan"
                      className="ng-scope"
                    >
                      <p style={{ lineHeight: 0 }} className="ng-binding">
                        After 7 days, only
                      </p>
                      <p style={{ lineHeight: 0.5 }}>
                        <span
                          style={{ color: "#2fc6c0" }}
                          ng-bind="planPrice | currency"
                          className="ng-binding"
                        >
                          {props.paymentAmount}
                        </span>{" "}
                        per month
                      </p>
                      <p
                        style={{
                          textTransform: "lowercase",
                          fontSize: "0.9em",
                          lineHeight: 0,
                        }}
                      >
                        (ex. tax)
                      </p>
                    </Binding>
                  )}

                  {isTrial && (
                    <div
                      ng-if="trialPlan"
                      style={{ marginTop: "50px" }}
                      className="ng-scope"
                    >
                      <InfoSpan>
                        <span style={{ display: "block" }}>
                          Cancel Anytime!
                        </span>
                        <span style={{ display: "block" }}>
                          You won't be charged if you
                        </span>
                        <span style={{ display: "block" }}>
                          cancel before the trial ends
                        </span>
                      </InfoSpan>
                    </div>
                  )}
                </LeftColumn>

                <RightColumn id="right-column">
                  <IframWrapper id="iframe-wrapper">
                    <IframeLoaded
                      ng-hide="iframeLoaded"
                      id="loading-iframe"
                      aria-hidden="true"
                      className="ng-hide"
                    >
                      {isLoading && (
                        <>
                          <Loader className="loading-dot"></Loader>
                          <p>Loading, please wait ...</p>
                        </>
                      )}
                    </IframeLoaded>

                    <iframe
                      // iframe-onload="onIframeLoad(element)"
                      onLoad={(e: any) => {
                        if (e) {
                          setIsLoading(false);
                        }
                      }}
                      style={{
                        display: isLoading ? "none" : "block",
                        margin: "auto",
                        position: "absolute",
                        right: "20px",
                      }}
                      frameBorder="0"
                      scrolling="no"
                      width="594px"
                      height="465px"
                      src={iFrameSrc}
                      name="taxamo-iframe"
                      id="taxamo-iframe"
                      className="ng-isolate-scope"
                      aria-hidden="false"
                    ></iframe>
                  </IframWrapper>
                </RightColumn>
                <Logos id="logos">
                  <Stripe style={{ marginRight: "16px", width: "40px" }} />
                  <Taxamo
                    style={{
                      marginRight: "16px",
                      width: "61px",
                      height: "26px",
                    }}
                  />
                  <Gdpr
                    style={{
                      marginRight: "16px",
                      width: "61px",
                      height: "26px",
                    }}
                  />
                  <Ssl
                    style={{
                      marginRight: "16px",
                      width: "61px",
                      height: "26px",
                    }}
                  />
                </Logos>
              </div>
            </div>
          </ModalContent>
          {/* </div> */}
        </ModalDialog>
      </Modal>
      {/* </div> */}
      <ToastContainer position="top-center" />
    </>
  );
};

export default PaymentPopup;

import styled from "styled-components";
export const LeftSide = styled.div`
  margin-top: 0px;
  padding: 20px;
  flex: 1;
  overflow: hidden;
  width: 81%;
  height: 102%;
`;

export const Header = styled.div`
  position: absolute;
  top: 91px;
  color: #000000;
  text-decoration: underline #fbfbff;
`;

export const LeftSideContent = styled.div`
  margin: auto;
  display: block;
  height: 75%;
  width: 100% !important;
  position: relative;
  background-color: #e6e6e8;
`;

export const ModalTitle = styled.div`
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
    30deg,
    #110915,
    #4b275d,
    #6e3581,
    #a544a5
  ) !important;
  text-align: left;
  font-size: 1.125em;
  line-height: 1.7;
  color: #fbfbff;
  height: 60px;
`;

export const Label = styled.input`
  // display: none;
  margin: 10px 0 22px 20px;
  color: black;
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  &: focus {
    background-color: #d9d9dc !important;
    height: 60%;
    outline: none;
  }
`;

export const InputOne = styled.div`
  margin: 15px;
  width: calc(100% - 15px);
  background-color: transparent;
  border: none;
`;

export const LabelOne = styled.textarea`
  // margin: 20px 0 60px 20px;
  color: black;
  font-size: 14px;
  height: 80px;
  width: 96%;
  padding: 12px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  background: transparent;
  resize: none;
  border: none;
  &: focus {
    background-color: #d9d9dc !important;
    outline: none;
  }
`;

export const Preview = styled.div`
  margin: 15px;
  position: relative;
  height: 300px;
  overflow: hidden;
`;

export const Footer = styled.div`
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
`;

export const OptionWrapper = styled.div`
  display: flex;
  padding-bottom: 15px;
`;

export const Option = styled.div`
  flex: 1;
  text-align: center;
  width: 200px;
`;

export const OptionText = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const OptionTwo = styled.div`
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  flex: 1;
  text-align: center;
  height: 95px;
`;

export const OptionDiv = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const OptionThree = styled.div`
  flex: 1;
  text-align: center;
`;

export const OptionThreeDiv = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const RightSide = styled.div`
  top: 14%;
  left: 52vw;
  position: absolute;
`;

export const RightSideContent = styled.div`
  position: absolute;
  top: 70%;
  width: 388px;
  padding: 15px;
  max-width: 400px;
  height: 116px;
  background-color: #e6e6e8;
  flex-direction: row;
  min-width: 22%;
  display: flex;
  margin-left: 73%;
  margin-top: -88%;
  height: 84px;
`;

export const PreviewOne = styled.div`
  width: 100%;
  position: relative;
  flex: 2;
`;

export const PreviewOneContent = styled.div`
  // border: 42px solid #ccc;
  width: 33%;
  margin-top: 8px;
  margin-left: 4px;
  margin-bottom: -15px;
  // margin-right: 96px;
`;

export const Element = styled.div`
  flex: 3;
  margin-left: -20px;
  width: 100%;
  position: relative;
`;

export const ElementHead = styled.input`
  margin: 6px 0 10px 0;
  color: black;
  font-weight: 600;
  font-size: 13px;
  background-color: transparent;
  border: none;
  width: 100%;
  &: focus {
    background-color: #d9d9dc !important;
    outline: none;
  }
`;

export const ElementLabel = styled.textarea`
  margin-bottom: 3px;
  font-weight: normal;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-box;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  line-height: 1.3;
  max-height: 2.6 em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  resize: none;
  border: none;
  width: 100%;
  background: transparent;
  font-size: 0.85em;
  color: black;
  &: focus {
    background-color: #d9d9dc !important;
    outline: none;
  }
`;

export const Para = styled.div`
  font-size: 0.75em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const UpgradeNoti = styled.div`
  text-align: center;
  margin-left: -15px;
  min-height: -7px;
  min-width: 96px;
  margin-top: 15px;
  margin-bottom: 30px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #fbfbff !important;
  background-color: #e87855;
  height: 16px;
  padding: 12px 15px 22px;
  border: 1px solid #e6e6e8 !important;
  background-image: linear-gradient(30deg, #d4481d, #e5663f, #eb946b, #f1cb98);
  transition: border 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-image: linear-gradient(
      30deg,
      #7a2a11,
      #a73917,
      #d4581d,
      #e59e3f
    );
  }
`;

// export const hr = styled.hr`
// color:#dde6ff;
// margin-top:50px;
// `

export const LeftSpan = styled.span`
color: #f06b8e;
fontWeight: bold
lineHeight: 1.2;
textAlign: left;
`;

export const HurryUpSpan = styled.span`
  color: #444549;
  display: block;
  lineheight: 1.2;
  textalign: left;
  marginbottom: 32px;
`;

export const UpgradeToSpan = styled.span`
  margintop: -49px;
  marginleft: 113px;
  fontweight: bold;
  color: #854f8f;
  display: block;
  lineheight: 1.2;
  textalign: left;
`;

export const NotificationPopupWrapper = styled.div`
  height: 100vh;
  position: absolute;
  marginleft: 13%;
  top: 18px;
  left: 451px;
  zindex: 9999;
`;

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import axios from "axios";

import { ReactComponent as Cross } from "../../assets/svg/New folder/cross.svg";
import { AdminAvailableTask } from "../../lib/contexts/Queries";
import { useQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  MainDiv,
  Modal,
  ModalDialog,
  AddTask,
  Header,
  Close,
  ModalBody,
  InputSearch,
  Task,
  Table,
  TableBody,
  BottomActionButtonWrapper,
  BottomActionButton,
  useStyles,
  TblH,
} from "./AvailableTaskStyled";
import { getUniqueId } from "../../constants";

export const AvailableTask = (props: any) => {
  const classes = useStyles();
  let tokens: any = localStorage.getItem("token");
  const [searchData, setSearchData] = useState<any>([]);
  const [date, setDate] = useState("");
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [taskId, setTaskId] = useState<number>();
  const [compId, setCompId] = useState<number>();
  const [updateBtn, setUpdateBtn] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [skip, setSkip] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const checkedArray = useSelector((state: any) => {
    return state.listViewChecked;
  });

  const dispatch = useDispatch();
  const pastDate = new Date();
  useEffect(() => {
    pastDate.setDate(pastDate.getDate() - 3);
    setDate(moment(pastDate).toISOString());
    setSkip(false);
    // filterTasks();
  }, []);
  const { data, loading, error } = useQuery(AdminAvailableTask, {
    variables: {
      filter: `{\"where\":{\"designerIds\":{\"like\":\"%${localStorage.getItem(
        "userId"
      )}%\"},\"deadline\":{\"gt\":\"${date}\"},\"type\":{\"inq\":[\"design-wizard\",\"dw-template\"]},\"order\":\"deadline ASC\"}}`,
      token: `${localStorage.getItem("token")}`,
    },
    skip: skip,
  });
  useEffect(() => {
    if (data && data.GET_waveflowTasks) {
      setSearchData([...data.GET_waveflowTasks]);
      setUpdateBtn(false);
      filterTasks();
    }
  }, [data, checkedArray]);
  const filterTasks = async () => {
    if (checkedArray.length === 1) {
      await axios
        .get(
          `${process.env.REACT_APP_API_REDIRECT}/waveflowTaskDesigns/?filter={"where":{"compositionId":"${checkedArray[0].compositionId}"},"include":"task"}`,
          {
            headers: {
              authorization: tokens,
            },
          }
        )
        .then((res: any) => {
          if (res.status === 200 && res.data && res.data.length) {
            setSearchData([{ ...res.data[0].task }]);
            setUpdateBtn(true);
            setCompId(res.data[0].id);
          }
        });
    }
  };

  const save = async () => {
    setIsSaveDisabled(true);
    let compositionIds = checkedArray.map((item: any) => {
      return item.compositionId;
    });
    let payload = {
      compositionIds: compositionIds,
      taskId: taskId,
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_REDIRECT}/waveflowTaskDesigns/bulkCreate`,
        payload,
        {
          headers: {
            authorization: tokens,
          },
        }
      )
      .then((res: any) => {
        if (res.status === 200) {
          props.handleTaskClose();
          dispatch({
            type: "setRefreshListView",
            refreshListView: true,
          });
          toast.success("Your designs are saved");
          props.setCheckedArray([]);
          dispatch({
            type: "deSelectListnGrid",
            deSelectAll: true,
          });
          setIsSaveDisabled(false);
        }
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.error?.message || "Task failed");
      });
  };
  const remove = async () => {
    await axios
      .delete(
        `${process.env.REACT_APP_API_REDIRECT}/waveflowTaskDesigns/${compId}`,
        {
          headers: {
            authorization: tokens,
          },
        }
      )
      .then((res: any) => {
        if (res.status === 200) {
          updateStatus();
          toast.success("Your designs are removed");
          props.setCheckedArray([]);
          dispatch({
            type: "deSelectListnGrid",
            deSelectAll: true,
          });
        }
      });
  };

  const updateStatus = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_API_REDIRECT}/users/${localStorage.getItem(
          "userId"
        )}/compositions/${checkedArray[0].compositionId}`,
        {
          compositionId: checkedArray[0].compositionId,
          statusId: 1,
        },
        {
          headers: {
            authorization: tokens,
          },
        }
      )
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: "setRefreshListView",
            refreshListView: true,
          });
          props.handleTaskClose();
        }
      });
  };

  const updateDesign = async () => {
    let payload = {
      compositionId: checkedArray[0].compositionId,
      id: compId,
      ownerId: localStorage.getItem("userId"),
    };
    await axios
      .put(
        `${process.env.REACT_APP_API_REDIRECT}/waveflowTaskDesigns/${compId}`,
        payload,
        {
          headers: {
            authorization: tokens,
          },
        }
      )
      .then((res: any) => {
        if (res.status === 200) {
          toast.success("Your designs are updated successfully!");
          dispatch({
            type: "setRefreshListView",
            refreshListView: true,
          });
          props.handleTaskClose();
        }
      });
  };
  if (loading) return <></>;
  if (error) return <>`Error! ${error.message}`</>;

  return (
    <MainDiv>
      <Modal>
        <ModalDialog>
          <AddTask>
            <Header>
              <Close onClick={props.handleTaskClose}>
                <Cross></Cross>
              </Close>
              <h3
                style={{
                  fontSize: "1.425em",
                  color: "#11243b",
                  fontWeight: "400",
                  textAlign: "center",
                  margin: "35px auto",
                }}
              >
                WaveFlow Tasks
              </h3>
            </Header>
            <ModalBody>
              <Task>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ marginTop: "0", marginBottom: "1rem" }}>
                    Assign a Task to the selected Design
                  </p>
                  <InputSearch
                    type="text"
                    placeholder="Search task"
                    value={searchText}
                    onChange={(e: any) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        var filterData = data.GET_waveflowTasks.filter(
                          (item: any) => {
                            return item.title
                              .toString()
                              .toLowerCase()
                              .includes(searchText.toLowerCase());
                          }
                        );
                        setSearchData(filterData);
                      } else {
                        setSearchData(data.GET_waveflowTasks);
                      }
                    }}
                  ></InputSearch>
                </div>
                <Table>
                  <TableBody>
                    <tr>
                      <TblH width="20%">Title</TblH>
                      <TblH width="18%">Description</TblH>
                      <TblH width="18%">Link</TblH>
                      <TblH width="37%"> Template Task</TblH>
                      <TblH width="40%"> Deadline</TblH>
                    </tr>
                    {searchData.map((item: any, index: number) => (
                      <tr
                        key={item.title}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            selectedRow === index && checkedArray.length > 0
                              ? "#b1b1b7"
                              : "#fff",
                        }}
                        onClick={() => {
                          setTaskId(item.id);
                          setSelectedRow(index);
                        }}
                      >
                        <TblH width="20%" style={{ fontWeight: "400" }}>
                          {item.title}
                        </TblH>
                        <TblH width="18%" style={{ fontWeight: "400" }}>
                          {item.description}
                        </TblH>
                        <TblH width="18%" style={{ fontWeight: "400" }}>
                          {item.link}
                        </TblH>
                        <TblH width="37%" style={{ fontWeight: "400" }}>
                          {"No"}
                        </TblH>
                        <TblH width="40%" style={{ fontWeight: "400" }}>
                          {moment(item.deadline).format("DD/MM/YYYY")}
                        </TblH>
                      </tr>
                    ))}
                  </TableBody>
                  <BottomActionButtonWrapper>
                    <BottomActionButton
                      type="button"
                      onClick={props.handleTaskClose}
                    >
                      Cancel
                    </BottomActionButton>
                    {!updateBtn && (
                      <BottomActionButton
                        type="button"
                        disabled={
                          selectedRow > -1 && !isSaveDisabled ? false : true
                        }
                        onClick={save}
                      >
                        Save
                      </BottomActionButton>
                    )}

                    {updateBtn && (
                      <BottomActionButton type="button" onClick={remove}>
                        Remove from Task
                      </BottomActionButton>
                    )}
                    {updateBtn && (
                      <BottomActionButton type="button" onClick={updateDesign}>
                        Update Design
                      </BottomActionButton>
                    )}
                  </BottomActionButtonWrapper>
                </Table>
              </Task>
            </ModalBody>
          </AddTask>
        </ModalDialog>
      </Modal>
      <ToastContainer position="top-center" />
    </MainDiv>
  );
};


export const proPlan = [
  {
    title: "<sup>*</sup>Unlimited Downloads",
  },
  {
    title: "Video uploads",
  },
  {
    title: "Image uploads",
  },
  {
    title: "Font uploads",
  },
  {
    title: "Add text & image to video",
  },
  {
    title: "1GB storage",
  },
];

export const downloadPlan = [
  {
    question: "Is my payment secure?",
    answer:
      "Sure! You can upgrade to Pro by clicking on the button above or via your My Profile section of the app.",
  },
  {
    question: "Can I change plans?",
    answer:
      "Sure! You can upgrade to Pro by clicking on the button above or via your My Profile section of the app.",
  },
];

export const annualPlan = [
  {
    question: "Is my payment secure?",
    answer:
      "Sure! You can upgrade to Pro by clicking on the button above or via your My Profile section of the app.",
  },
  {
    question: "Can I change plans?",
    answer:
      "Sure! You can upgrade to Pro by clicking on the button above or via your My Profile section of the app.",
  },
  {
    question: "When will my account be debited?",
    answer:
      "Your account will be debited once per month (every 30 days) on the date which you began your subscription.",
  },
  {
    question: "How long does my subscription last for?",
    answer:
      " Your subscription will continue on a monthly basis, but you can cancel anytime.",
  },
];

export const downloadTerms = "<sup>*</sup> Fair usage applies - a maximum of 500 downloads per user per month"
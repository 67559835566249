import { lazy } from "react";
import { lazily } from "react-lazily";

export const { DasboardWorkspace } = lazily(
  () => import("../../pages/private/dashboard/workspace")
);
export const PublicHome = lazy(() => import("../../pages/public/home"));
export const SignUp = lazy(() => import("../../pages/public/signUp"));
export const { PrivateHome } = lazily(
  () => import("../../pages/private/home/Index")
);
export const { Profile } = lazily(
  () => import("../../pages/private/dashboard/profile")
);
export const { ForgetPassword } = lazily(
  () => import("../../pages/public/forgetPassword")
);
export const { PurchaseHistory } = lazily(
  () => import("../../pages/private/dashboard/purchaseHistory")
);
export const { ChooseTypes } = lazily(
  () => import("../../pages/private/dashboard/chooseTypes")
);
export const { ChooseSize } = lazily(
  () => import("../../pages/private/dashboard/chooseSize")
);
export const { TrialOffer } = lazily(
  () => import("../../pages/private/home/TrialOffer/trialOffer")
);
export const { ChooseBusiness } = lazily(
  () => import("../../pages/private/dashboard/chooseBusiness")
);
export const { ChooseTemplate } = lazily(
  () => import("../../pages/private/dashboard/chooseTemplate")
);
export const { ResetPassword } = lazily(
  () => import("../../pages/public/resetPassword")
);
export const { TutorialPopup } = lazily(
  () => import("../../pages/private/home/TutorialPopup/tutorialPopup")
);
export const { Landing } = lazily(() => import("../../admin/Landing/Landing"));
export const { TutorialsAdmin } = lazily(
  () => import("../../admin/TutorialsAdmin/TutorialsAdmin")
);
export const { TutorialsRightSide } = lazily(
  () => import("../../admin/TutorialsRightSide/TutorialsRightSide")
);
export const { Notification } = lazily(
  () => import("../../admin/Notification/Notification")
);
export const { NotificationPopup } = lazily(
  () => import("../../admin/NotificationPopup/NotificationPopup")
);
export const { Account } = lazily(() => import("../../admin/Account/Account"));
export const { LandingTitle } = lazily(
  () => import("../../admin/LandingTitle/LandingTitle")
);
export const { ReviewAsset } = lazily(
  () => import("../../admin/ReviewAsset/ReviewAsset")
);
export const { PageResponsiveMsg } = lazily(
  () => import("../PageResponsiveMsg/pageResponsiveMsg")
);
export const { Test } = lazily(
  () => import("../../pages/private/dashboard/workspace/Test")
);

export const { UserVotingTable } = lazily(
  () => import("../../pages/public/user-voting/userVoting")
);

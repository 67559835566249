import styled from "styled-components";

export const TransactionP = styled.p`
  display: flex;
  justify-content: center;
  margin-left: 50%;
  margintop: 3rem;
  font-weight: bold;
  font-size: 1.1rem;
`;

import React, { Dispatch, SetStateAction, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./App.css";
import { AppProviders } from "./lib/contexts";
import { Layout } from "./components/layout";
import { NewLayout } from "new-dw-ui/src/components/layout";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 1000 * 60 * 10, refetchOnMount: "always" },
  },
});
type ProfilePicContextState = { updated: number };
type ProfilePicContextValue = {
  updated: ProfilePicContextState;
  setUpdated: Dispatch<SetStateAction<ProfilePicContextState>>;
};
const ProfilePictureDefaultValue: ProfilePicContextValue = {
  updated: { updated: 0 },
  setUpdated: (updated) => {
    //some value
  },
};

export const ProfilePictureContext = React.createContext(
  ProfilePictureDefaultValue
);

function App() {
  const [updated, setUpdated] = React.useState(
    ProfilePictureDefaultValue.updated
  );

  const userData: any = useMemo(() => {
    const user: any = localStorage.getItem("user");
    return JSON.parse(user);
  }, []);

  return (
    <ProfilePictureContext.Provider value={{ updated, setUpdated }}>
      <AppProviders>
        <QueryClientProvider client={queryClient}>
          {!userData || userData?.preferences?.usingNewUi ? (
            <NewLayout />
          ) : (
            <Layout />
          )}
          {/* <NewLayout /> */}
          <ReactQueryDevtools />
        </QueryClientProvider>
      </AppProviders>
    </ProfilePictureContext.Provider>
  );
}

export default App;

export const isPaidUser = () => {
  const user: any = localStorage.getItem("user");
  const userData = JSON.parse(user);
  let returnValue: any = false;
  for (let i of userData.roles) {
    if (i.name.includes("business")) {
      returnValue = "BUSINESS";
    }
    if (i.name.includes("master")) {
      returnValue = "PRO";
    }
  }
  return returnValue;
};

import isWbmUser from "./isWbmUser";
import axios from "axios";
const getUserId = () => {
  try {
    const user = JSON.parse(localStorage.user);
    return user.userId;
  } catch (e) {
    return "";
  }
};

const getUserPlan = () => {
  const user = localStorage.getItem("user");
  const userData = JSON.parse(user);
  let plan = "Basic";
  for (let i of userData.roles) {
    if (i.name.includes("business") && i.validUntil !== null) {
      plan = "Business";
      break;
    } else if (i.name.includes("master") && i.validUntil !== null) {
      plan = "Pro";
      break;
    }
  }
  return plan;
};

const sendEvent = (event) => {
  if (window?.dataLayer && !isWbmUser()) {
    window.dataLayer.push({
      event: "ga4_event",
      uuid: getUserId(),
      ...event,
    });
  }
};

/**
 * Event happens after a successful login and after the user data is already
 * available in the localstorage
 * @param method {string} the method for login, it can be google, facebook or email
 */
const loginEvent = ({ method }) =>
  sendEvent({
    event_name: "login",
    method,
  });

/**
 * Event happens after a successful sing-up and after the user data is already
 * available in the localstorage
 * @param method {string} the method for the sign-up, it can be google, facebook or email
 */
const signUpEvent = ({ method }) =>
  sendEvent({
    event_name: "sign_up",
    method,
  });

/**
 * Event happens after a successful single payment for an image or video
 * using credits should be excluded
 * @param transactionId {string} the taxamo transaction id
 * @param templateId {string} the template ID of the design if it exists
 * @param value {number} the amount paid by the customer
 */
const paymentEvent = ({ transactionId, templateId, value }) =>
  sendEvent({
    event_name: "payment",
    transaction_id: transactionId,
    template_id: templateId,
    value,
  });

/**
 * Event happens after a successful start of a subscription, even the trails
 * @param transactionId {string} the taxamo transaction id
 * @param label {string} the name of the subscription like "Pro Trial" or "Annual Business"
 */
const startSubscriptionEvent = ({ transactionId, label }) =>
  sendEvent({
    event_name: "start_subscription",
    transaction_id: transactionId,
    label,
  });

/**
 * Event happens after a successful download of a design
 * @param templateId {string} the template ID of the design if it exists
 */
const designDownloadEvent = async ({ templateId }) => {
  let eventObj = {
      event_name: "design_download",
      template_id: templateId,
    },
    eventLabel = "";
  await axios
    .get(`${process.env.REACT_APP_API_REDIRECT}/templates/${templateId}`, {
      headers: {
        authorization: `${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      let price = res?.data?.price,
        composerObj = res?.data?.composer_object[0],
        extension = "";
      if (composerObj) {
        extension = composerObj.extension;
      }
      if (extension && price !== undefined) {
        let type = ["jpg", "png"].indexOf(extension) > -1 ? "Image" : "Video";
        eventLabel = `${getUserPlan()}/${type}/${price}`;
        eventObj.label = eventLabel;
      }
    })
    .catch((err) => {
      console.log("error while fetching template in designDownloadEvent", err);
    });
  sendEvent(eventObj);
  // sendEvent({
  //   event_name: "design_download",
  //   template_id: templateId,
  // });
};

/**
 * Event happens after a successful share of a design
 * @param templateId {string} the template ID of the design if it exists
 */
const designShareEvent = ({ templateId }) =>
  sendEvent({
    event_name: "design_share",
    template_id: templateId,
  });

/**
 * Event happens after a successful loading a template to the canvas
 * @param templateId {string} the template ID of the design
 */
const templateLoadEvent = ({ templateId }) =>
  sendEvent({
    event_name: "template_load",
    template_id: templateId,
  });

/**
 * Event happens after a successful a template is marked as favorite
 * @param templateId {string} the template ID of the design
 */
const templateFavoriteEvent = ({ templateId }) =>
  sendEvent({
    event_name: "template_favorite",
    template_id: templateId,
  });

/**
 * Event happens after a successful loading an asset to the canvas
 * if the asset doesn't have an asset ID like shapes and text it should be ignored
 * for asset coming from the wbm-photo or wbm-video index then the id should be the _id of the document
 * but for asset coming from dw-asset the id should be _source.productId
 * @param assetId {number} asset ID as a number like 5415698 not strings like bbcb84f6-53eb-82ba-ef6f-0fcedc4939e3
 */
const assetLoadEvent = ({ assetId }) =>
  sendEvent({
    event_name: "asset_load",
    asset_id: assetId,
  });

/**
 * Event happens after a successful an asset is marked as favorite
 * if the asset doesn't have an asset ID like shapes and text it should be ignored
 * for asset coming from the wbm-photo or wbm-video index then the id should be the _id of the document
 * but for asset coming from dw-asset the id should be _source.productId
 * @param assetId {number} asset ID as a number like 5415698 not strings like bbcb84f6-53eb-82ba-ef6f-0fcedc4939e3
 */
const assetFavoriteEvent = ({ assetId }) =>
  sendEvent({
    event_name: "asset_favorite",
    asset_id: assetId,
  });

/**
 * Event happens when a user switches between the old and new UI or vice versa
 * @param label {string}
 */
const switchUiEvent = ({ label }) =>
  sendEvent({
    event_name: "switch_ui",
    label,
  });

export {
  loginEvent,
  signUpEvent,
  paymentEvent,
  startSubscriptionEvent,
  designDownloadEvent,
  designShareEvent,
  templateLoadEvent,
  templateFavoriteEvent,
  assetLoadEvent,
  assetFavoriteEvent,
  switchUiEvent,
};

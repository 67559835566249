import React, { useState } from "react";
import ControlledAccordions from "../Accordion/accourdian";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { TextField } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { ReactComponent as Cancel } from "./../../../../assets/svg/cross.svg";

import {
  GetTutorialsFaq,
  GetTutorialsFaqOrder,
} from "../../../../lib/contexts/Queries";
import { useQuery } from "@apollo/client";

import fetchJsonp from "fetch-jsonp";
import {
  PopupBox,
  Boxx,
  TutorialWrap,
  LeftSide,
  RightSide,
  RightSideHead,
  RightSideHeadSpan,
  TutorialBoxMain,
  TutorialBox,
  TutorialHeadTwo,
  TutorialVideoUI,
  TutorialVideoList,
  NewFeature,
  VideoBox,
  Polygon,
  PolyGon,
  SVG,
  Overlay,
  PlayBtn,
  PlayBtnSpan,
  SearchBox,
  BootstrapButton,
  PlanBox,
  useStyles,
} from "./TutorialPopupStyled";
import VideoPlayer from "./VideoPlayer";
import { responsive } from "./utils/TutorialPopupConstant";
import { getUniqueId } from "../../../../constants";

export const TutorialPopup = (props: any) => {
  const [showVid, setShowVideo] = useState(false);
  const [videoData, setVideoData] = useState({});
  const [open, setOpen] = useState(false);
  const [searchInput, setsearchInput] = useState([]) as any;
  const [imgData, setImgData] = useState([
    { id: "", src: "", parentId: Number },
  ]);
  const [displayState, setDisplayState] = useState([]) as any;
  const [displayCategory, setDisplayCategory] = useState([]) as any;
  const goBack = (val: boolean) => {
    setShowVideo(false);
  };
  const vidData = (value: any) => {
    setVideoData(value);
    setShowVideo(true);
  };
  var getVideoId = function (videoUrl: string) {
    const match = videoUrl.match(
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
    );

    if (match && match[0].indexOf("vimeo") !== -1) {
      return { provider: "vimeo", id: match[6] };
    } else if (match && match[0].indexOf("youtube") !== -1) {
      return { provider: "youtube", id: match[6] };
    }
    return { provider: "noProvider", id: "" };
  };
  const {
    data: GetTutorialsData,
    error: GetTutorialsError,
    loading: GetTutorialsLoading,
  } = useQuery(GetTutorialsFaq, {
    variables: {
      filter: "{}",
      token: `${localStorage.getItem("token")}`,
    },
  });
  React.useEffect(() => {
    // ;
  }, [imgData]);

  const handleThumbNial = async () => {
    let srcArr: any = [];
    const getData = GetTutorialsData.GET_tutorialsFAQs.map((item: any) => {
      return new Promise(async (resolve: any, reject) => {
        let obj: any;
        const videoProvider = getVideoId(item.videoUrl).provider;
        const videoId = getVideoId(item.videoUrl).id;
        if (videoProvider === "youtube") {
          //
          obj = {
            id: videoId,
            src: `https://i3.ytimg.com/vi/${videoId}/mqdefault.jpg`,
            parentId: item.parentId,
          };
          srcArr.push(obj);
          resolve(srcArr);
        } else if (videoProvider === "vimeo") {
          //
          let data = await fetchJsonp(
            `https://vimeo.com/api/v2/video/${videoId}.json?callback=JSON_CALLBACK&_=${new Date().getTime()}`
          );
          let dta2 = await data.json();
          let dt3 = await dta2[0].thumbnail_large;
          obj = { id: videoId, src: dt3, parentId: item.parentId };
          srcArr.push(obj);
          resolve(srcArr);
        } else {
          obj = {
            id: "",
            src: "/videoPlaceHolder.png",
            parentId: item.parentId,
          };
          srcArr.push(obj);
          resolve(srcArr);
        }
      });
    });
    Promise.all(getData).then(() => {
      setImgData(srcArr);
    });
  };
  React.useEffect(() => {
    if (GetTutorialsData) {
      let check = GetTutorialsData.GET_tutorialsFAQs.filter((item: any) => {
        if (item.videoUrl.includes("http")) {
          return item;
        }
      });
      setDisplayState(check);
      handleThumbNial();
    }
  }, [GetTutorialsData]);
  const GetTutorialsOrder = useQuery(GetTutorialsFaqOrder, {
    variables: {
      filter: "{}",
      token: `${localStorage.getItem("token")}`,
    },
  });
  const classes = useStyles();
  React.useEffect(() => {
    if (
      GetTutorialsOrder.data &&
      GetTutorialsData &&
      GetTutorialsData.GET_tutorialsFAQs
    ) {
      let result: any = GetTutorialsOrder.data.GET_tutorialCategoriesFAQ.filter(
        (item: any) => {
          for (let i of GetTutorialsData.GET_tutorialsFAQs) {
            if (i.parentId === item.id && getVideoId(i.videoUrl).id) {
              return item;
            }
          }
        }
      );

      setDisplayCategory(result);
    }
  }, [GetTutorialsOrder.data, GetTutorialsData]);

  if (GetTutorialsLoading || GetTutorialsOrder.loading) return <>Loading...</>;
  if (GetTutorialsError || GetTutorialsOrder.error) return <>Error...</>;
  const toVid = GetTutorialsData.GET_tutorialsFAQs.filter(
    (item: any, index: number) => {
      return index < 6;
    }
  );
  const handleSearchClick = () => {
    let temp = GetTutorialsData.GET_tutorialsFAQs.filter((item: any) => {
      if (item.title.includes(searchInput)) return item;
    });
    setDisplayState([...temp]);
    let temp2 = GetTutorialsOrder.data.GET_tutorialCategoriesFAQ.filter(
      (item: any) => {
        if (temp.some((x: any) => x.parentId === item.id)) return item;
      }
    );
    setDisplayCategory([...temp2]);
  };
  return (
    <PopupBox>
      <Boxx>
        {props.content}
        <PlanBox>
          <SearchBox>
            <div>
              {GetTutorialsData && (
                <Autocomplete
                  sx={{
                    color: "#495057",
                    background: "#fff",
                    display: "inline-block",
                    padding: "-40px",
                    width: "17%",
                    height: "calc(1.5em + 0.75rem + 3px)",
                    marginTop: "27px",
                    borderWidth: 0,
                  }}
                  disableClearable
                  freeSolo
                  open={open}
                  onInputChange={(event, value) => {
                    setsearchInput(value);
                    value.length > 0 ? setOpen(true) : setOpen(false);
                  }}
                  id="custom-input-demo"
                  options={GetTutorialsData.GET_tutorialsFAQs.map(
                    (option: any) => option.title
                  )}
                  renderInput={(params) => (
                    <TextField
                      className={classes.textField}
                      {...params}
                      placeholder="Search tutorials...."
                      InputProps={{
                        ...params.InputProps,
                        type: "text",
                        disableUnderline: true,
                        startAdornment: <></>,
                        endAdornment: <></>,
                      }}
                    />
                  )}
                />
              )}

              <BootstrapButton
                onMouseEnter={() => setOpen(false)}
                onClick={handleSearchClick}
              >
                {" "}
                Search{" "}
              </BootstrapButton>
              <Cancel
                onClick={props.handleClose}
                style={{
                  width: "28px",
                  height: "24px",
                  color: "#D3D3D3",
                  marginLeft: "35px",
                  marginRight: "20px",
                  top: "15px",
                  position: "relative",
                }}
              ></Cancel>
            </div>
          </SearchBox>
          <TutorialWrap>
            <LeftSide>
              <ControlledAccordions vidData={vidData} imgData={imgData} />
            </LeftSide>
            <RightSide>
              <RightSideHead
                style={
                  showVid
                    ? {
                        borderBottom: "1px solid #f296a3",
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }
                    : {}
                }
              >
                Tutorials
                <RightSideHeadSpan>All categories</RightSideHeadSpan>
              </RightSideHead>
              {showVid && (
                <VideoPlayer
                  videoData={videoData}
                  toVid={toVid}
                  imgData={imgData}
                  goBack={goBack}
                />
              )}
              {!showVid && (
                <TutorialBoxMain>
                  {displayCategory.map((cateogory: any) => {
                    return (
                      <TutorialBox>
                        <TutorialHeadTwo
                          style={{ width: "1248px", height: "36px" }}
                        >
                          {cateogory.title}
                        </TutorialHeadTwo>
                        <TutorialVideoUI>
                          <Carousel
                            responsive={responsive}
                            swipeable={false}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlaySpeed={100000}
                          >
                            {displayState.map((categoryData: any) => {
                              const videoProvider = getVideoId(
                                categoryData.videoUrl
                              ).provider;
                              const videoId = getVideoId(
                                categoryData.videoUrl
                              ).id;
                              const vidUrl =
                                videoProvider === "youtube"
                                  ? `https://www.youtube.com/embed/${videoId}`
                                  : `https://player.vimeo.com/video/${videoId}`;
                              let srcStr: any;
                              if (imgData.length > 2) {
                                srcStr = imgData.filter((item) => {
                                  if (item.id === videoId) {
                                    return item.src;
                                  }
                                });
                              }
                              if (
                                categoryData.parentId === cateogory.id &&
                                categoryData.isFAQ === false
                              ) {
                                return (
                                  <TutorialVideoList>
                                    <VideoBox>
                                      <Overlay>
                                        <PlayBtn
                                          onClick={() => {
                                            setShowVideo(true);
                                            setVideoData({
                                              title: categoryData.title,
                                              url: vidUrl,
                                            });
                                          }}
                                        >
                                          <Polygon>
                                            <SVG height="70" width="70">
                                              <PolyGon
                                                points="35,10, 60,57,10,57"
                                                style={{
                                                  strokeWidth: "3",
                                                  fill: "transparent",
                                                }}
                                              ></PolyGon>
                                              Sorry, your browser does not
                                              support inline SVG.
                                            </SVG>
                                          </Polygon>
                                        </PlayBtn>
                                      </Overlay>
                                      <NewFeature
                                        src={
                                          srcStr
                                            ? srcStr[0].src
                                            : "/videoPlaceHolder.png"
                                        }
                                        style={{
                                          backgroundImage:
                                            "url('/videoPlaceHolder.png')",
                                        }}
                                      />
                                    </VideoBox>
                                    <PlayBtnSpan>
                                      {categoryData.title}
                                    </PlayBtnSpan>
                                  </TutorialVideoList>
                                );
                              }
                            })}
                          </Carousel>
                        </TutorialVideoUI>
                      </TutorialBox>
                    );
                  })}
                </TutorialBoxMain>
              )}
            </RightSide>
          </TutorialWrap>
        </PlanBox>
      </Boxx>
    </PopupBox>
  );
};
export default TutorialPopup;

import React, { useState } from "react";

import { ReactComponent as D1Logo } from "../../assets/svg/New folder/designwizard.svg";
import { ReactComponent as D2Logo } from "../../assets/svg/New folder/logo.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  MainDiv,
  LoaderDiv,
  CloseAccount,
  LogoDiv,
  customBounceAnimation,
  Loader,
  CryDiv,
  CryH2,
  CryPara,
  CanceleBtn,
  CloseBtn,
} from "./DeleteAccountCallbackStyled";

export const DeleteAccount = () => {
  let txt = window.location.href;
  const handleAccClose = async (e: any) => {
    e.preventDefault();
    await axios
      .post(
        `${process.env.REACT_APP_API_REDIRECT}/users/closeAccount?${
          txt.split("?")[1]
        }`
      )
      .then((res: any) => {
        if (res) {
          toast.success("Account Deleted");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("userId");
          localStorage.removeItem("email");
          setLoading(false);
          setTimeout(() => {
            window.location.href = `/`;
          }, 1000);
        } else {
          toast.error("Something went Wrong");
          setLoading(false);
        }
      });
  };
  const handleCancel = () => {
    window.location.href = `/`;
  };
  const [loading, setLoading] = useState(false);
  return (
    <MainDiv>
      <ToastContainer position="top-center" />
      <CloseAccount>
        <LogoDiv>
          <D2Logo
            style={{
              overflow: "hidden",
              verticalAlign: "middle",
              height: "100px",
            }}
          />
          <D1Logo
            style={{
              overflow: "hidden",
              verticalAlign: "middle",
              marginTop: "20px",
            }}
          />
        </LogoDiv>
        {!loading && (
          <div>
            <CryDiv></CryDiv>
            <CryH2>We're sad to see you go.</CryH2>
            <CryPara>
              Please click on the "close account" button below to confirm.
            </CryPara>
            <CanceleBtn onClick={handleCancel}>CANCEL</CanceleBtn>
            <CloseBtn
              onClick={(e) => {
                setLoading(true);
                handleAccClose(e);
              }}
            >
              CLOSE ACCOUNT
            </CloseBtn>
          </div>
        )}

        {loading && (
          <LoaderDiv>
            <Loader />
          </LoaderDiv>
        )}
      </CloseAccount>
    </MainDiv>
  );
};

import styled from "styled-components";
import { motion } from "framer-motion";
export const OuterDiv = styled(motion.div)`
  z-index: 1050;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  transition: opacity 0.15s linear;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
`;
export const Model = styled.div`
  margin-top: 75px;
  overflow: hidden;
  margin-left: 35%;
  border: 3px solid #2d3559;
  border-radius: 10px;
  position: relative;
  width: 30%;
  pointer-events: none;
`;

export const ModalContent = styled.div`
  background-color: white;
  box-shadow: 0 0 50px 0 rgb(0 0 0 / 80%);
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
`;

export const ConfirmStyled = styled.div`
  outline: none;
`;

export const ModalBody = styled.div`
  padding: 40px 50px 40px 50px;
  text-align: center;
  position: relative;
  flex: 1 1 auto;
`;

export const ModalBodyOne = styled.h1`
  background: transparent;
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: -10px;
  padding-bottom: 5px;
  border-radius: 50%;
  z-index: 99999;
  opacity: 1;
  transition: background-color 0.25s ease;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  //color: #000;
  text-shadow: 0 1px 0 #fff;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
export const ContentWrapper = styled.div`
  margin-bottom: 30px;
`;
export const Textrow = styled.div`
  outline: none;
`;
export const Maintext = styled.div`
  text-align: center;
  color: #2d3559;
  font-weight: bold;
`;
export const ContentWrappertwo = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0;
`;
export const Buttonswrapper = styled.div`
  width: 100%;
  // margin: auto;
  // margin-left: 20%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;
export const Buttonwrapperone = styled.div`
  flex: 1;
`;
export const Discard = styled.div`
  margin: 0;
  // width: 45%;
  cursor: pointer;
`;
export const DiscardButton = styled.div`
  min-width: 60px;
  min-height: 45px;
`;
export const Discardbuttontext = styled.div`
  padding: 0;
  // margin-right: 20px;
  // padding-right: 10px;
  // padding-left: 10px;
`;
export const DiscardText = styled.div`
  text-transform: capitalize;
  font-size: 1.5em;
  line-height: 1em;
  display: block;
  font-weight: bold;
  letter-spacing: 1px;

  color: #2d3559 !important;
  outline: none !important;
  border: 1px solid #2d3559 !important;
  padding: 7px;
  background-color: transparent;
  background-image: linear-gradient(
    30deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(13, 13, 13, 0),
    rgba(38, 38, 38, 0)
  );
  transition: border 0.3s ease, background-color 0.3s ease;
  background-image: none !important;
  margi-right: 30px;
  border-radius: 3px;
`;
export const Buttonwrappertwo = styled.div`
  // margin-left: 15px;
`;
export const Confirm = styled.div`
  margin: 0;
  // width: 45%;
  cursor: pointer;
`;
export const ConfirmButton = styled.div`
  min-width: 60px;
  min-height: 45px;
  border-radius: 3px;
`;
export const ConfirmButtonText = styled.div`
  padding: 0;
  width: 117px;
`;

export const ConfirmText = styled.div`
  text-transform: capitalize;
  font-size: 1.5em;
  line-height: 1em;
  display: block;
  font-weight: bold;
  letter-spacing: 1px;

  color: #fbfbff !important;
  outline: none !important;
  border: 1px solid #2d3559 !important;
  padding: 7px;
  background-color: #2d3559;
  background-image: linear-gradient(30deg, #131726, #242b48, #363b6a, #51478c);
  transition: border 0.3s ease, background-color 0.3s ease;
  background-image: none !important;
  border-radius: 3px;
`;

import { AllAsset } from "../../../../../../AllAsset";
import { activeCompConst } from "new-dw-ui/src/new-ui/components/tourGuide/dashboard/utils/constants";

export const leftSidebarIdConstant = {
  template: "template",
  upload: "upload",
  element: "element",
  text: "text",
  branding: "branding",
};

export const leftSidebarConstant = [
  {
    title: "Templates",
    Icon: AllAsset.Template,
    id: leftSidebarIdConstant.template,
    tourClass: "tour_templateMenu",
    tourGuide: "wsLeftSideTemplate",
  },
  {
    title: "My Items",
    Icon: AllAsset.Item,
    id: leftSidebarIdConstant.upload,
    tourClass: "tour_myItemMenu",
    tourGuide: "wsLeftSideMyItem",
  },
  {
    title: "Elements",
    Icon: AllAsset.Element,
    id: leftSidebarIdConstant.element,
    tourClass: "tour_elementMenu",
    tourGuide: "wsLeftSideElement",
  },
  {
    title: "Text",
    Icon: AllAsset.Texts,
    id: leftSidebarIdConstant.text,
    tourClass: "tour_textMenu",
    tourGuide: "wsLeftSideText",
  },
  // {
  //   title: "Branding",
  //   Icon: AllAsset.Branding,
  //   id: leftSidebarIdConstant.branding,
  //   tourClass: "tour_brandingMenu",
  //   tourGuide: "wsLeftSideBrandingKit",
  // },
];

import { useMutation } from "@tanstack/react-query";
import {
  getTemplatesAcctoCats,
  templateSearch,
  createFolder,
  deleteUserUpload,
  compositionDL,
  getUserCompositions,
  getUsage,
  getProfile,
  PostUserComposition,
  updateUserComposition,
  templateOpenSearch,
  getdwFonts,
  changeCardDetails,
} from "./dashoboard";
import {
  addFavorite,
  getTemplates,
  updateProfile,
  requestUserData,
  closeAccountRequest,
  uploadAvatar,
  updatePassword,
  claimPromo,
  emailVerify,
  taxamoSubscribe,
  resetPassword,
  confirmEmailVerify,
  unlinkProvider,
  addNotifications,
  updateNotifications,
  assetSearch,
  updateTutorailFAQ,
  tutorialCategoriesFAQs,
  updateTutorialFAQs,
  updatetutorialCategoriesFAQs,
  stripeUnsubscribe,
  userfeedback,
} from "./workspace";

export const useGetTemplatesAcctoCatsMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: getTemplatesAcctoCats,
    ...callbacks,
  });
};

export const useTemplateSearchMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: templateSearch,
    ...callbacks,
  });
};

export const useTemplateOpenSearchMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: templateOpenSearch,
    ...callbacks,
  });
};

export const useCreateFolder = (callbacks: any) => {
  return useMutation({
    mutationFn: createFolder,
    ...callbacks,
  });
};

export const useDeleteUserUploadsMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: deleteUserUpload,
    ...callbacks,
  });
};

export const useImgDonwloadImage = (callbacks: any) => {
  return useMutation({
    mutationFn: compositionDL,
    ...callbacks,
  });
};

export const useAddFavoriteMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: addFavorite,
    ...callbacks,
  });
};

export const useUserCompositionMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: getUserCompositions,
    ...callbacks,
  });
};

export const useTemplateMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: getTemplates,
    ...callbacks,
  });
};

export const useUsageMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: getUsage,
    ...callbacks,
  });
};

export const useUpdateProfileMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: updateProfile,
    ...callbacks,
  });
};

export const useRequestUserDataMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: requestUserData,
    ...callbacks,
  });
};

export const useCloseAccountMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: closeAccountRequest,
    ...callbacks,
  });
};

export const useUploadAvatarMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: uploadAvatar,
    ...callbacks,
  });
};
export const useUpdatePasswordMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: updatePassword,
    ...callbacks,
  });
};

export const useClaimPromoMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: claimPromo,
    ...callbacks,
  });
};

export const useEmailVerifyMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: emailVerify,
    ...callbacks,
  });
};

export const useTaxamoSubscribeMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: taxamoSubscribe,
    ...callbacks,
  });
};

export const useResetPasswordMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: resetPassword,
    ...callbacks,
  });
};

export const useConfirmEmailVerifyMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: confirmEmailVerify,
    ...callbacks,
  });
};

export const useProfileMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: getProfile,
    ...callbacks,
  });
};

export const useUnlinkProviderMuatation = (callbacks: any) => {
  return useMutation({
    mutationFn: unlinkProvider,
    ...callbacks,
  });
};

export const useAddNotificationsMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: addNotifications,
    ...callbacks,
  });
};
export const useUpdateNotificationsMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: updateNotifications,
    ...callbacks,
  });
};
export const useAssetSearchMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: assetSearch,
    ...callbacks,
  });
};

export const useUpdateTutorailFAQMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: updateTutorailFAQ,
    ...callbacks,
  });
};

export const useTutorialCategoriesFAQsMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: tutorialCategoriesFAQs,
    ...callbacks,
  });
};

export const useUpdateTutorialFAQsMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: updateTutorialFAQs,
    ...callbacks,
  });
};

export const useUpdatetutorialCategoriesFAQsMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: updatetutorialCategoriesFAQs,
    ...callbacks,
  });
};

export const useUpdateUserCompositionMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: PostUserComposition,
    ...callbacks,
  });
};

export const usePutUserCompositionMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: updateUserComposition,
    ...callbacks,
  });
};

export const useStripeUnsubscribeMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: stripeUnsubscribe,
    ...callbacks,
  });
};
export const useUserfeedbackMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: userfeedback,
    ...callbacks,
  });
};

export const useDwFontMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: getdwFonts,
    ...callbacks,
  });
};

export const changeCardDetailsMutation = (callbacks: any) => {
  return useMutation({
    mutationFn: changeCardDetails,
    ...callbacks,
  });
};

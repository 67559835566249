import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import {
  AUTH_TYPE,
  createAuthLink as awsCreateAuthLink,
} from "aws-appsync-auth-link";
import { onError } from "@apollo/client/link/error";

const url = process.env.REACT_APP_API_URL || "";
const api_key = process.env.REACT_APP_API_KEY || "";
const region = "eu-west-1";

const createAuthLink = () => {
  return awsCreateAuthLink({
    auth: {
      type: AUTH_TYPE.API_KEY,
      apiKey: api_key,
    },
    region,
    url,
  });
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // if (graphQLErrors) {
  //   graphQLErrors.forEach(({ message, locations, path }) =>
  //   );
  // }
  // if (networkError) ;
});

export const createApolloClient = () =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: from([createAuthLink(), new HttpLink({ uri: url }), errorLink]),
  });

import _ from "lodash";
import {
  WsHandleLsp,
  objectTypeConst,
} from "../../../rightside/toolbar/utils/constants";
import { wsActiveToolbarDispatch } from "../../../rightside/toolbar/components/WsToolBarButtonItem/utils/ToolbarConst";

export const lspTypes = {
  FilterImage: {
    type: "image",
    subType: {
      type: "EffectFilterType",
      subType: { type: "EffectFilterSubType" },
    },
  },

  MyAssets: {
    type: "MyAssets",
    subType: {
      type: "MyAssetsSubtype",
      subType: { type: "MyAssetsChildType" },
    },
  },

  MyFont: {
    type: "MyFont",
    subType: {
      type: "MyFontSubtype",
      subType: { type: "MyFontChildType" },
    },
  },

  MyFav: {
    type: "MyFav",
    subType: {
      type: "MyFontSubtype",
      subType: { type: "MyFontChildType" },
    },
  },

  MyFavTemp: {
    type: "MyFavTemp",
    subType: {
      type: "MyFontSubtype",
      subType: { type: "MyFontChildType" },
    },
  },

  MyFavAsset: {
    type: "MyFavAsset",
    subType: {
      type: "MyFontSubtype",
      subType: { type: "MyFontChildType" },
    },
  },

  MyFavGraphics: {
    type: "MyFavGraphics",
  },

  MyFavVideos: {
    type: "MyFavVideos",
    subType: {
      type: "MyFontSubtype",
      subType: { type: "MyFontChildType" },
    },
  },

  MyFavAnimatedStickers: {
    type: "MyFavAnimatedStickers",
  },

  Template: {
    type: "myTemp",
    subType: {
      type: "MyTempSubtype",
      subType: { type: "MyTempChildType" },
    },
  },

  Images: {
    type: "ImageList",
    subType: {
      type: "ImageListType",
      subType: { type: "ImageListSubType" },
    },
  },

  graphics: {
    type: "graphicList",
  },

  Videos: {
    type: "VideoList",
    subType: {
      type: "VideoListType",
      subType: { type: "VideoListSubType" },
    },
  },
  animatedstickers: {
    type: "animatedStickerList",
  },
};

export const updateLspSelected = (dispatch: any, type: any) => {
  dispatch({
    type: "setLspSelected",
    lspSelected: type,
  });
};

export const multiSelectObjFilters = (canvas: any) => {
  const activeObj = canvas?.getActiveObject();
  if (activeObj?.type === "activeSelection") {
    const objs = activeObj?._objects;
    let isTextBox = true;
    let isMultiObj = false;
    objs.forEach((item: any) => {
      if (
        item.type !== "textbox" &&
        item.type !== "text" &&
        !item?.isBoundingBoxOfText
      ) {
        isTextBox = false;
      }
    });

    if (isTextBox) {
      return objectTypeConst.Text;
    }
    if (isMultiObj) {
      return objectTypeConst.MultiObj;
    }
    let isImage = _.every(objs, ["type", "image"]);
    if (isImage) {
      return objectTypeConst.Image;
    }

    // let isMultiObj = true;
    // objs.forEach((item: any) => {
    //   if (
    //     item.type !== "rect" ||
    //     item.type !== "triangle" ||
    //     item.type !== "ellipse" ||
    //     item.type !== "circle" ||
    //     item.type !== "textbox" ||
    //     item.type !== "text"
    //   ) {
    //     isMultiObj = false;
    //   }
    // });

    // if (isMultiObj) {
    //   return objectTypeConst.MultiObj;
    // }

    let isShape = false;
    objs.forEach((item: any) => {
      if (
        item.type === "rect" ||
        item.type === "triangle" ||
        item.type === "ellipse" ||
        item.type === "circle" ||
        item.type === "star"
      ) {
        if (item.type === "textbox" || item.type === "text") {
          isMultiObj = true;
        } else isShape = true;
      }
    });

    if (isShape) {
      return objectTypeConst.Shape;
    }
    if (isMultiObj) {
      return objectTypeConst.MultiObj;
    }
  }
};

export const leftsideFilterImg = (canvas: any) => {
  const activeObj = canvas?.getActiveObject();
  const isWbmImage = ["wbm", "dw"].includes(activeObj?.source);

  let img = "";
  if (isWbmImage && activeObj?.thumb) {
    const assetId = activeObj?.thumb?.split("/").pop().split(".")[0];
    img = `${process.env.REACT_APP_WBM_ASSET_THUMB_SRC}${assetId}.webp`;
  } else {
    img = activeObj?.src || activeObj?.thumb;
  }

  return img;
};

export const showActiveToolbarItem = (
  activeType: any,
  lspType: any,
  dispatch: any
) => {
  let obj = {
    name: activeType,
    id: 0,
  };
  wsActiveToolbarDispatch(dispatch, obj);
  WsHandleLsp(dispatch, lspType);
};

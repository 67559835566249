import styled from "styled-components";

export const ToggleWrapper = styled.div`
  width: 280px;
  display: flex;
  flex-direction: row;
  height: 39px;
  margin: 14px 13px 13px;
  border: 1px solid #dde6ff;
  border-radius: 5px;
  overflow: hidden;
  width: 348px;
  font-size: 16px solid;
  font-family: "Lato", sans-serif;
  margin-left: 225px;
`;

export const ToggleWrapperBtn = styled.button`
  color: #f0ecf5;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out;
  border: 0 none;
  flex: 1;
  padding: 0;
  position: relative;
  font-size: 1em;
  height: 36px;
  margin-top: 1px;
  margin-right: 3px;
  margin-left: 2px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #f0ecf5;
  }
`;

export const ToggleWrapperBtnSpan = styled.span`
  display: block;
  font-weight: bold;
  font-size: 1em;
  font-family: "Lato", sans-serif;
`;

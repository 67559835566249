import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { SignUp } from "./CallBackErrorStyled";

export const LoginCallBackErr = () => {
  return (
    <div
      style={{
        margin: "auto",
      }}
    >
      <p
        style={{
          width: "100%",
          marginTop: "50%",
        }}
      >
        Oops! Looks like your email doesn't exist in our System. Please use the
        Sign Up button instead.
      </p>
      <Link to="/signup">
        <SignUp style={{ height: "auto" }}>Go to Sign Up Page</SignUp>
      </Link>
    </div>
  );
};

// import "./performanceTest/wdyr"; // TODO disable after testing
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "@fontsource/roboto";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CanvasWithDragAndDropProvider } from "@wbm-npm/dw-canvas-legacy";
import store from "./redux/store";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

console.log(`
 Welcome to
  ____            _          __        ___                  _ 
 |  _ \\  ___  ___(_) __ _ _ _\\ \\      / (_)______ _ _ __ __| |
 | | | |/ _ \\/ __| |/ _\` | '_ \\ \\ /\\ / /| |_  / _\` | '__/ _\` |
 | |_| |  __/\\__ \\ | (_| | | | \\ V  V / | |/ / (_| | | | (_| |
 |____/ \\___||___/_|\\__, |_| |_|\\_/\\_/  |_/___\\__,_|_|  \\__,_|
                    |___/                                     
`);

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <CanvasWithDragAndDropProvider>
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </Provider>
      </DndProvider>
    </CanvasWithDragAndDropProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

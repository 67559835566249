import styled, { keyframes } from "styled-components";
export const MainDiv = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const LoaderDiv = styled.div`
  margin: 20px 0px 15px;
`;
export const CloseAccount = styled.div`
  color: #592e6f;
  width: 500px;
  margin: 150px auto 0;
  text-align: center;
`;

export const LogoDiv = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #bebec3;
  display: flex;
`;
export const customBounceAnimation = keyframes`
  0%,
  100% {
    transform: scale(0.79);
  }
  50% {
    transform: scale(1.04);
  }
}
`;
export const Loader = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: rgb(89, 46, 111, 0.5);
  position: relative;
  animation: ${customBounceAnimation} 1s infinite ease-in-out;
  margin: 0 auto 15px;
`;

export const CryDiv = styled.div`
  margin-bottom: 20px;
  height: 180px;
  background-image: url(./cry.png);
  background-repeat: no-repeat;
  background-position: center;
`;

export const CryH2 = styled.h2`
  color: #592e6f;
`;

export const CryPara = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const CanceleBtn = styled.div`
  cursor: pointer;
  border: 0.5px solid #592e6f;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  padding: 9px 30px;
`;

export const CloseBtn = styled.div`
  cursor: pointer;
  border: 0.5px solid #592e6f;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  padding: 9px 30px;
  background-color: #592e6f;
  color: white;
  margin-left: 12px;
`;

import React from "react";

import { AppRouting } from "../../../routing";
import { ToastContainer } from "react-toastify";

export const PublicLayout = () => {
  return (
    <>
      <ToastContainer position="top-center" />
      <AppRouting />
    </>
  );
};
